import React, { useLayoutEffect, useState } from "react";

const CookiesBanner = () => {
  const htmlContent = `We use cookies in the delivery of our services. To learn about that, take a look at our <a href="/privacy-policy" class="text-orange-400 underline font-bold">Privacy policy</a>.`;

  const [cookie] = useState(localStorage.getItem("accept-cookies") || "false");

  const [showBanner, setShowBanner] = useState(false);

  useLayoutEffect(() => {
    if (cookie === "true") {
      setShowBanner(true);
    }
  }, [cookie]);

  return (
    !showBanner && (
      <div className="fixed left-auto md:left-30 lg:left-auto bottom-88 px-4 md:p-0 z-40 md:bottom-10 w-full text-black lg:text-white">
        <div className="max-w-sm lg:mx-auto lg:max-w-2xl px-10 py-6 bg-white shadow-2xl lg:bg-gray-800 rounded-lg flex flex-col lg:flex-row">
          <p dangerouslySetInnerHTML={{ __html: htmlContent.trim() }}></p>
          <button
            className="bg-orange-500 inline-block px-4 py-2 lg:ml-4 rounded-md text-white mt-6 lg:mt-0"
            onClick={() => {
              localStorage.setItem("accept-cookies", "true");
              setShowBanner(true);
            }}
          >
            Accept
          </button>
        </div>
      </div>
    )
  );
};

export default CookiesBanner;
