import React from "react";
import { Icon } from "../../Icon/Icon";

export const CardTypeThree = ({ data }) => {
  return (
    <div className="flex items-start my-8">
      <div>
        <Icon iconName={data.iconName} classes="w-24 h-24 text-blue-600" />
      </div>
      <div className="flex flex-col items-start ml-8">
        <p className="text-2xl font-bold capitalize">{data.heading}</p>
        <p className="text-lg">{data.description}</p>
      </div>
    </div>
  );
};
