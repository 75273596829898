import React, { useState } from "react";
import { Link } from "react-router-dom";

export const AdditionalNav = () => {
  const [callService, setCallService] = useState(false);

  const showCallService = () => {
    setCallService((prevState) => !prevState);
  };

  return (
    <nav className="flex items-center -mb-1">
      <ul style={{ display: "inherit" }}>
        {/* Mail to component */}
        <li style={{ display: "inherit" }} className="group">
          {/* Mail icon */}
          <span>
            <svg
              className="h-6 w-6 stroke-current group-hover:text-orange-400"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </span>
          {/* Mail to link component */}
          <a
            href="mailto:sales@kdrsoftsolutions.com"
            className="text-sm ml-2 cursor-pointer group-hover:text-orange-400"
          >
            sales@kdrsoftsolutions.com
          </a>
        </li>
        {/* Divider */}
        <span className="ml-6">|</span>
        {/* Call button component */}
        <li
          style={{ display: "inherit" }}
          className="group ml-6 flex items-center cursor-pointer"
          onMouseEnter={showCallService}
          onMouseLeave={showCallService}
        >
          {/* Call button icon */}
          <span>
            <svg
              className="h-4 w-4 fill-current group-hover:text-orange-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
          </span>
          {/* Chevron for the call button */}
          <span>
            <svg
              className="h-4 w-4 fill-current group-hover:text-orange-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          {/* code for call service modal */}
          {callService && (
            <div
              className="origin-top-right absolute bg-white rounded-md flex flex-col py-2"
              style={{ top: "35px", right: "215px", zIndex: 999 }}
            >
              <p className="text-lg font-sans font-bold text-gray-800 border-b-2 border-gray-200 px-4 pb-2">
                KDR SOFT™ CONTACTS
              </p>
              <div className="flex text-gray-900 px-4 py-2 items-center justify-evenly">
                <span className="border-r-2 border-gray-500 px-2">
                  <svg
                    className="h-12 w-12 fill-current text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                  </svg>
                </span>
                <div className="px-2">
                  <p className="text-md text-gray-700 mb-3">
                    For Sales Inquiry
                  </p>
                  <p className="text-blue-700 font-sans font-bold text-md flex items-center justify-start">
                    <img
                      className="h-6 w-6"
                      src={require("../../assets/Images/india.svg")}
                      alt="india flag"
                    />
                    <a className="ml-4" href="tel:917399884433">
                      +91- 7399884433
                    </a>
                  </p>
                  <p className="text-blue-700 font-sans font-bold text-md flex items-center justify-start">
                    <img
                      className="h-6 w-6"
                      src={require("../../assets/Images/india.svg")}
                      alt="india flag"
                    />
                    <a className="ml-4" href="tel:18008898589">
                       1800 889 85 89
                    </a>
                  </p>
                  <p className="text-blue-700 font-sans font-bold text-md flex items-center justify-start mt-2">
                    <img
                      className="h-4 w-6"
                      src={require("../../assets/Images/us.svg")}
                      alt="U.S flag"
                    />

                    <a className="ml-4" href="tel:+19294461414">
                      +1 929 446 1414{" "}
                    </a>
                  </p>
                  <p className="text-blue-700 font-sans font-bold text-md flex items-center justify-start mt-2">
                    <img
                      className="h-4 w-6"
                      src={require("../../assets/Images/uk.svg")}
                      alt="U.K flag"
                    />

                    <a className="ml-4" href="tel:+447915610025 ">
                      {" "}
                      +44 79 1561 0025{" "}
                    </a>
                  </p>
                  <p className="text-blue-700 font-sans font-bold text-md flex items-center justify-start mt-2">
                    <svg
                      className="h-6 w-6 stroke-current text-blue-600"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <a
                      className="ml-4"
                      href="mailto:sales@kdrsoftsolutions.com"
                    >
                      sales@kdrsoftsolutions.com
                    </a>
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    (If We Don’t Pick Up,{" "}
                    <Link to="contact-us" className="text-blue-500 underline">
                      Drop Inquiry
                    </Link>
                    .)
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* End of call service modal */}
        </li>
        {/* End of call button component */}
      </ul>
    </nav>
  );
};
