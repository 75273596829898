import React from "react";
import HeroSectionTwo from "./components/HeroSection/HeroSectionTwo";
import { Navbar } from "./components/Navbar/Navbar";
import { Whoweare } from "./components/WhoWeAre/Whoweare";
import { Whatweoffer } from "./components/Whatweoffer/Whatweoffer";
import { Knowhow } from "./components/KnowHow/Knowhow";
import { Partnership } from "./components/Partnership/Partnership";
import { HappyClients } from "./components/HappyClients/HappyClients";
import { WorkWithUs } from "./components/WorkWithUs/WorkWithUs";
import { Footer } from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Navbar otherPages={false} transparent={true} />
      <HeroSectionTwo />
      {/* <HeroSection /> // landing page with svg icon */}
      <Whoweare />
      <Whatweoffer />
      <Knowhow />
      <Partnership />
      <HappyClients />
      <WorkWithUs />
      <Footer />
    </>
  );
}

export default App;
