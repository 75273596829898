import React from "react";
import { Icon } from "../../Icon/Icon";

export const TimeLineTypeTwo = ({ data }) => {
  return (
    <div className="py-12 px-4 xl:px-0 container mx-auto">
      {data.details.map((el, i) => (
        <div className="grid grid-cols-1 md:grid-cols-8 lg:grid-cols-12 grid-flow-row p-4 md:p-16 my-8 border-2 border-gray-200 hover:shadow-md rounded-md" key={i}>
          <span className="hidden md:inline-flex md:col-span-1 justify-center items-center w-20 h-20 rounded-full font-bold text-base text-white bg-blue-400">
            0{i + 1}
          </span>
          <div className="hidden md:block md:col-span-1">
            <Icon iconName={el.iconName} classes="w-20 h-20 text-gray-700" />
          </div>
          <div className="md:col-span-6 md:pl-8 text-center md:text-left">
            <p className="text-2xl md:text-3xl text-main-leftGradient">{el.label}</p>
            <p className="text-sm mt-2 md:text-base leading-7 text-gray-600 max-w-lg break-words">
              {el.content}
            </p>
          </div>
          <div className="mt-8 lg:mt-0 md:col-span-4">
            <div className="mb-4">
              {el.points.map((el,i) => (
                <div className="flex items-center mb-4 text-base" key={i}>
                  <span className="inline-block w-2 h-2 border-2 border-main-blue rounded-full"></span>
                  <p className="list-outside list-disc ml-4">{el}</p>
                </div>
              ))}
            </div>
            <span className="inline-block p-3 text-lg border-2 border-dotted border-blue-600 text-blue-600 capitalize font-bold rounded-md">
              {el.badge}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
