export const emergingTechComponent = {
  "wearable-app-development": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_one: true,
    stack_employed: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "product-engineering": {
    navbar: true,
    image_banner: true,
    stack_used: true,
    top_type_three: true,
    top_faq_banner: true,
    connect_with_us: true,
    tech_we_use: true,
    faq_banner: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "it-consulting": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_one: true,
    stack_employed: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  devops: {
    navbar: true,
    image_banner: true,
    top_type_three: true,
    image_tiles: true,
    connect_with_us: true,
    service_icon: true,
    work_with_us: true,
    footer: true,
  },
  "qa-testing": {
    navbar: true,
    image_banner: true,
    top_type_three: false,
    image_tiles: true,
    top_faq_banner: true,
    connect_with_us: true,
    life_cycle: true,
    faq_banner: true,
    work_with_us: true,
    footer: true,
  },
  "cloud-computing": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_three: true,
    type_five: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "iot-development": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_one: true,
    type_three: true,
    stack_used: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "consulting-services": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    top_type_three: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "software-maintenance-support": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_five: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "opensource-development": {
    navbar: true,
    type_two_image_banner: true,
    open_source: true,
    type_three: true,
    stack_employed: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "staff-augmentation-dedicated-teams": {
    navbar: true,
    image_banner: true,
    type_three: true,
    stack_used: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
    how_hire_works: true,
    hire_dedicated: true,
  },
};
