export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "development.jpg",
    imageText2: "Multi-Disciplinary Project Development",
    imageText3: "WE FOLLOW A PURSUIT FOR DEVELOPING CUSTOMER-CENTRIC PRODUCTS FOR WEB AND MOBILE",
  },
  showButton: false,
  showIcon: true,
  iconName: "award-badge",
};

export const typeFourBannerData = {
  heading: "The Most Intelligently Integrated End-to-End Development Process",
  text1:
    "From B2B to B2E, IT provides multiple projects, which consist of websites and web apps for top brands, medium-level enterprises, and startups. There is a considerable amount we offer through our integrated, adaptive agile approach. Great things are not achieved by impulse but through a series of processes brought together. Here are the steps we take for our à la carte development process.",
};

export const timeLineTypeTwoData = {
  details: [
    {
      iconName: "dev-process-SI",
      label: "Sketching Your Idea",
      content:
        "As clients express an interest in doing business with us, our expert strategists begin the work process. Once everyone in the team has an agreement on an idea, all of us immerse ourselves in the project.",
      points: [
        "First Contact",
        "Conceptualizing the Idea",
        "In-depth Research and Planning",
        "Requirement & Estimation Approval",
      ],
      badge: "Project Agreement",
    },
    {
      iconName: "dev-process-AP",
      label: "Analysis & Prototyping",
      content:
        "After approval of the project, we advance to the pre-engineering process. This is where we address the root-level construction, comprehensive analysis, concept strategy, technical consultation, and complete the specifications with submission of the resource plan. Through the practice of prototyping, we can formulate the right development platforms and structures.",
      points: [
        "Internal Analysis",
        "Creating Wireframe/ Prototype/ Storyboards",
        "Submit a Resource plan",
        "Select right development platforms and structures",
      ],
      badge: "Scope Sign-off",
    },
    {
      iconName: "dev-process-UU",
      label: "Design: User Interface (UI) & User Experience (UX)",
      content:
        "To us, design is much more than just the aesthetics. It's about the functionality. We want your users to have the best experience with your digital business, so we focus on making user experience intriguing, addictive, intuitive and friendly.",
      points: [
        "Study Demographics and Use Cases",
        "Use of latest, relevant UI design features",
        "Emotional design with colors",
        "UX Personalization",
        "UX Metrics Evaluation",
      ],
      badge: "Visual Design Ready",
    },
    {
      iconName: "dev-process-DI",
      label: "Development & Implementation",
      content:
        "Advanced project management tools like Jira, GitLab and Hubstaff form the essential part of our development process. Experienced and competent developers at Kdr take the lead and pair the right technology for a perfect solution. We update our routine status so that we can optimize the code.",
      points: [
        "Release Plan",
        "Coding with stipulated timeframe",
        "Full stack backend development",
        "Update routine status",
      ],
      badge: "Optimized Code",
    },
    {
      iconName: "dev-process-BTQA",
      label: "Beta Testing and Quality Assurance",
      content:
        "Now starts the real excitement, when you can see your website or app being brought to life! We put the whole developed plan into action by creating a beta version of your product. Each module of the project is scrupulously tested and goes through multiple testing phases under various environments and conditions to ensure the best result.",
      points: [
        "Static and automated tests",
        "Eliminate bugs",
        "Real-time User testing",
        "Gather feedback",
        "Make final adjustments",      
      ],
      badge: "Premier Quality",
    },
    {
      iconName: "dev-process-DFL",
      label: "Deployment & Final Launch",
      content:
        "Get ready to see your finished masterpiece! After all of the testing has been completed and after incremental quality assurance, our experts deploy the end product on respective app stores and web browsers, assuring the passing of the fickle approval process and the standards of the online stores.",
      points: [
        "Release to live environment",
        "Ensure integrity",
        "Post-deployment maintenance & support",
      ],
      badge: "Perfectly Working App",
    },
  ],
};

export const requestQuoteBannerTypeTwo = {
  text1: "We are ready to Get Started! Share your idea with us",
  text2:
    "Tell us a little bit about your project, we will get back to you at the earliest",
  showButton: true,
  buttonText: "Let's Connect",
  buttonLink: "/request-quote",
};
