export const imageSection = {
  imageName: "becomePartner",
  imageText1: "THE POWER OF PARTNERSHIP",
  imageText2: "Let’s Build Something Profitable. Together",
  imageText3: "YOU’VE COME TO THE RIGHT PLACE",
};

export const tabData = [
  {
    label: "Associate partnership",
    link: "#associate-partnership",
  },
  {
    label: "Strategic partnership",
    link: "#strategic-partnership",
  },
  {
    label: "Referral partnership",
    link: "#referral-partnership",
  },
];

export const text1 = "Join Our Partner Ecosystem- You Will be Valued";
export const text2 =
  "KDR’s success is directly tied to our relationships. Through our partner program, KDR Partnership Program (KPP), we support the evolving needs of our partners and thus, we share in both- collaboration and success with our partners.";

export const associate_partnership = {
  text1: `In our associate partner program, we act as an extension to your existing development setup. You can either choose to outsource the entire project to us or work collectively with us on a project, and we will assist and support you with our state-of-the-art infrastructure and highly skilled design and development teams.`,
  text2:
    "As our associate partner, all your projects will be taken on priority. We would work for you on an hourly basis at a competitive price, which is exclusively applicable only for our Associate partners.",
  cards: [
    {
      heading: "Benefits",
      content: [
        "Highly talented team of designers and developers will work on your project. You will experience client-centric services.",
        "All your work will be executed on priority.",
        "You will receive great discounts on hourly rates on all your projects. This is only offered to our associate projects.",
        "You can enjoy our amazing services as and when required.",
      ],
    },
    {
      heading: "Eligibility",
      content: [
        "You must have properly maintained infrastructure and development team at your end.",
        "You must have prior experience in the field of web and mobile development.",
        "You must be capable of closing business deals and managing projects.",
      ],
    },
    {
      heading: "Responsibilities",
      content: [
        "Need to have premises with proper infrastructure.",
        "Company credibility and experience in the field of mobile solutions.",
        "Ability and experience to drive sales and marketing initiatives and run campaigns.",
      ],
    },
  ],
};
export const strategic_partnership = {
  text1: `With our strategic partner program, you no longer need to worry about technical staff and its management, health, safety, pension, benefits and other responsibilities. You don't have to hire technical manpower and train or update them on different technologies. You don't have to think about changing technology, new equipment, software licenses and upgrades. All you need to do as our Strategic partner is to focus on getting more and more business.`,
  text2:
    "In this program, we work in a joint venture, where you act as the front-end sales executive and marketing group while we act as the back-end technical development group. Profits are shared on a pre-agreed percentage basis.",
  cards: [
    {
      heading: "Benefits",
      content: [
        "No headaches of managing a team or overlooking project development or any technical aspects of the project.",
        "Focus is required only on bringing business and closing project deals.",
        "Extremely competitive pricing offered for projects to our strategic partners.",
        "Enjoy the high-end quality services of an extensively experienced design and development team without the physical pains of maintaining it.",
      ],
    },
    {
      heading: "Eligibility",
      content: [
        "Required to have a talented in-house business and sales team.",
        "Required to have prior experience in dealing with clients and closing web and mobile business deals.",
        "The necessary establishment and infrastructure.",
        "Should be able to provide regular business.",
      ],
    },
    {
      heading: "Responsibilities",
      content: [
        "To enhance the reputation of our brand.",
        "To bring regular web design and mobile application development business.",
        "To pass on the complete project requirements to us.",
      ],
    },
  ],
};
export const referral_partnership = {
  text1: `Our referral partnership program is outstandingly rewarding and comes without any concerns of complex contracts or commitments. By becoming our referral partner, you can market our products, services and solutions to potential clients across the world. And for each of the closed deals from these referred projects, you receive commissions in the form of credit on all future projects that you bring to us. We would be responsible for finalizing the deal and working out the details from your referrals without any effort from your side. Rest assured, the clients you refer to us will be grateful to you.`,
  cards: [
    {
      heading: "Benefits",
      content: [
        "No complexities; all you need to do is to recommend us to the potential clients. Your job ends here.",
        "We work closing the deals. And then with our expert and experienced design and development teams, we successfully complete the project.",
        "Our top-notch service ensures that all your recommendations are well handled and thank you by for recommending us.",
        "You receive commission in the form of credit on all your future projects.",
      ],
    },
    {
      heading: "Eligibility",
      content: ["There is no eligibility for this partnership program."],
    },
    {
      heading: "Responsibilities",
      content: [
        "No responsibilities. Simply recommend trusted and potential clients to us and enjoy your rewards.",
      ],
    },
  ],
};

export const becomeAPartner = {
  label: "Become a partner",
  link: "#workWithUs",
};

export const partnerShipProgram = {
  heading: "KDR Partnership Program",
  description: "SPECIALIZED, RECOGNIZED WORLDWIDE. PREFERRED BY ENTERPRISES",
  details: [
    {
      iconName: 'become-partner-RDP',
      label: "Rich & Diverse Portfolio",
      content:
        "Our work says it all- we have successfully delivered a total of 3,500+ projects across various industries.",
    },
    {
      iconName: 'become-partner-LC',
      label: "Loyal Clientele",
      content:
        "We innovate with confidence for the best ideas that come to us and have built strong-term relationships with prospective clients.",
    },
    {
      iconName: 'become-partner-ILT',
      label: "Industry-leading Technologies",
      content:
        "Along with Mac Screens and advanced Android and iOS devices, we work with most trending and breakthrough technologies.",
    },
    {
      iconName: 'become-partner-DAM',
      label: "Dedicated Account Manager",
      content:
        "For each project, we have an Account Manager dedicatedly and seamlessly working with client.",
    },
    {
      iconName: 'become-partner-IR',
      label: "Internationally Recognized",
      content:
        "Awarded top rankings in mobile app development, web development by Clutch, AppFutura, BusinessOfApps worldwide.",
    },
    {
      iconName: 'become-partner-CP',
      label: "Competitive Pricing",
      content:
        "We set up a price based on analysis and research complied on the target market and assure the best in client’s budget.",
    },
    {
      iconName: 'become-partner-SF',
      label: "Strong Foundation",
      content:
        "We have been in the industry for 14 years and completely understand the coalition between technology and business.",
    },
    {
      iconName: 'become-partner-FRS',
      label: "Future-Ready Solutions",
      content:
        "We develop value-added solutions for all businesses- be it startup, enterprise or any corporate organization.",
    },
  ],
};
