export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "partnership.jpg",
    imageText2: "Building a lifetime partnership in business",
    imageText3: "WE RELY ON OUR PARTNERS TO LEAD CUSTOMERS TO THE NEW STYLE OF BUSINESS",
  },
  showButton: false,
  showIcon: true,
  iconName: "partnership",
};

export const typeOneTabData = {
  text1: "Accelerating partner’s profitability and sales",
  text2:
    "With our strategic partnerships, together we perform beyond our client’s and their customer’s expectations. Our partners are vetted according to what they do best, helping to create better client matches. We have been maintaining powerful partnerships with some of the world’s leading technology providers, services provider and solution providers.",
  tabData: [
    {
      label: "Our Partners",
      buttonBehavior: "data",
    },
    {
      label: "Become a Partner",
      buttonBehavior: "new",
      link: "/become-partner"
    },
  ],
  cardFormat: true,
  cardData: [
    {
      id: 1,
      folderName: "why/awards",
      name: "TECHNOLOGY PARTNER",
      imageName: "microsoft-partner-logo.png",
      title: "Microsoft Partner",
      description:
        "Kdr Soft Solutions has always been a foremost authority in the importance of new, innovative and functional web development technologies. This claim is backed by our esteemed Microsoft Partner badge, an honor bestowed on few.",
    },
    {
      id: 1,
      folderName: "why/awards",
      name: "PAYMENT PARTNER",
      imageName: "stripe-partner.jpg",
      title: "Stripe Partner",
      description:
        "Kdr Soft Solutions excited to join the Stripe Partner Program and looking forward to provide benefits of Stripe's seamless payments platform to our customers.",
    },
    {
      folderName: "why/awards",
      id: 2,
      name: "PAYMENT PARTNER",
      imageName: "authorize-logo.png",
      title: "Authorize.Net Certified Developer",
      description:
        "Kdr Soft Solutions is a reliable developers of Authorize.net. Here we have the expertise to integrate different methods of Authorize.net including EX. AIM, CIM and SIM.",
    },
    {
      id: 3,
      folderName: "why/awards",
      name: "PAYMENT PARTNER",
      imageName: "paypal-part.png",
      title: "Pals with PayPal",
      description:
        "As a PayPal partner, we have access to valuable tools, support and resources, allowing us to offer merchants with rich payment integration and service facility.",
    },
    {
      id: 4,
      folderName: "why/awards",
      name: "CLOUD PARTNER",
      imageName: "amazon-part.png",
      title: "Making most of AWS",
      description:
        "Empowering our clients with powerful web solutions, inventively exploring AWS-based business practices and solutions as a committed Amazon Partner.",
    },
    {
      id: 5,
      folderName: "why/awards",
      name: "ECOMMERCE PARTNER",
      imageName: "magestore-logo.png",
      title: "Authorized Magestore Partner",
      description:
        "Kdr Soft Solutions is an official Magestore Silver Partner having submitted some great Magento 1.0 and Magento 2.0 Extensions to Magento Connect.",
    },
    {
      id: 6,
      folderName: "why/awards",
      name: "PAYMENT PARTNER",
      imageName: "cashfree_partner.png",
      title: "CashFree Certified Partner",
      description:
        "As a CashFree Certified Partner, we have access to valuable tools, support and resources, allowing us to offer merchants with rich payment integration and service facility.",
    },
    {
      id: 7,
      folderName: "why/awards",
      name: "OPENSOURCE PARTNER",
      imageName: "acquia-logo.png",
      title: "Acquia Partner",
      description:
        "Kdr Soft Solutions has tied with Acquia in enhance our Open Source Drupal based solutions and CMS technology in order to better service our clients.",
    },
    {
      id: 8,
      folderName: "why/awards",
      name: "MOBILE PARTNER",
      imageName: "apple-partner-logo.png",
      title: "Member of iOS Developer Program",
      description:
        "Kdr Soft Solutions has been a prominent member of the Apple iOS Developer Program almost since its inception and has developed and deployed some truly breath-taking Apps under its tutelage.",
    },
    {
      id: 9,
      folderName: "why/awards",
      name: "PAYMENT PARTNER",
      imageName: "Razorpay.png",
      title: "Razorpay Authorized Partner",
      description:
        "As a Razorpay partner, we have access to valuable tools, support and resources, allowing us to offer merchants with rich payment integration and service facility.",
    },
    {
      id: 10,
      folderName: "why/awards",
      name: "OPENSOURCE PARTNER",
      imageName: "drupal-logo.png",
      title: "Drupal Association Member",
      description:
        "Kdr Soft Solutions is a member of the Drupal Association, with several Drupal based projects to our name.",
    },
  ],
};
