const services = [
  {
    logo: "ERP",
    name: "Enterprise",
    description:
      "Create complex enterprise software, ensure reliable software integration, modernise your legacy system.",
  },
  {
    logo: "MAD",
    name: "Mobile Application Development",
    description:
      "Create an impactful mobile app that fits your brand and industry within a shorter time frame.",
  },
  {
    logo: "ITCon",
    name: "Consulting Services",
    description:
      "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.",
  },
  {
    logo: "DevTeams",
    name: "Staff Augmentation & Dedicated Teams",
    description:
      "Hire a loyal software development team with niche skills and deep expertise.",
  },
  {
    logo: "UX/UI",
    name: "UI UX Designing",
    description:
      "Build the product you need on time with an experienced team that uses a clear and effective design process.",
  },
  {
    logo: "QAT",
    name: "QA & Testing",
    description:
      "Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.",
  },
];

const technologies = [
  {
    logo: "CC",
    name: "Cloud Computing",
    description: "Use cloud computing solutions to create a scalable, flexible, and connected enterprise environment.",
  },
  {
    logo: "BCD",
    name: "Blockchain Development",
    description:
      "Introduce the highest level of security and automate your operations with our blockchain solutions.",
  },
  {
    logo: "ARS",
    name: "Augmented Reality Solutions",
    description:
      "Build better prototypes, improve the user experience, and lower costs with AR solutions.",
  },
  {
    logo: "UX/UI",
    name: "UX / UI Design",
    description:
      "Build the product you need on time with an experienced team that uses a clear and effective design process.",
  },
  {
    logo: "AI",
    name: "Artificial Intelligence",
    description:
      "Custom AI-based solutions like machine and deep learning will help you automate any operation.",
  },
  {
    logo: "IOT",
    name: "IOT Development",
    description:
      "Secure IoT solutions will let you gather Big Data, optimise key processes, and improve decision-making.",
  },
];

const industries = [
  {
    logo: "Build",
    name: "Real Estate Property",
    description:
      "Advanced software solutions that take Construction into the Digital Era.",
  },
  {
    logo: "HC",
    name: "Healthcare Fitness",
    description:
      " Patient-friendly software that empowers healthcare industry workers.",
  },
  {
    logo: "RE",
    name: "Ecommerce Retail B2B",
    description:
      " Engaging and exciting software solutions for modern retail.",
  },
  {
    logo: "FT",
    name: "Banking Finance Insurance",
    description:
      "Meet the demands of modern customers in speed and security with scalable financial technology.",
  },
  {
    logo: "TH",
    name: "Travel & Hospitality",
    description:
      "Extend the comfort of your resort with practical software solutions.",
  },
  {
    logo: "IN",
    name: "Event Tickets",
    description:
      "Boost the profitability and safety of your business processes through innovative insurance software solutions.",
  },
];

export const whatWeOfferData = {
  services,
  technologies,
  industries,
};
