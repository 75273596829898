import React from "react";
import { Link } from "react-router-dom";

export const ServicesOffered = ({ data }) => {
  return (
    <div className=" py-16">
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full px-4 xl:px-0 lg:max-w-6xl lg:mx-auto gap-10">
        <div>
          <p className="text-4xl leading-9 mb-3">{data.text1}</p>
          <p className="text-base">{data.text2}</p>
        </div>
        {data.data.map((el, i) => (
          <div key={i} className="shadow-lg border-t-2 border-gray-200 p-4">
            <ul className="list-disc list-outside">
              {el.map((el1) => (
                <li
                  key={el1.linkLabel}
                  className="text-blue-700 hover:underline mb-2 ml-4"
                >
                  <Link to={el1.link}>{el1.linkLabel}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      { data.showButtin &&
        <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between items-center text-center md:text-left mt-10">
          <div>
            <p className="my-2 max-w-lg text-2xl">{data.buttonLeftText1}</p>
            <p className="text-xl uppercase text-gray-600">
              {data.buttonLeftText2}
            </p>
          </div>
          <div className="mt-6 md:mt-0">
            <Link
              to={data.buttonLink}
              className="inline-block p-4 text-white font-bold text-lg uppercase rounded-lg bg-orange-500"
            >
              {data.buttonText}
            </Link>
          </div>
        </div>
      }
    </div>
  );
};
