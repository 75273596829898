import React from "react";

export const AppInfographics = ({ data }) => {
  return (
    <div className="bg-gray-300 py-16 px-4 xl:px-0">
      <div className="container mx-auto">
        {data.heading && (
          <p className="text-3xl lg:text-4xl capitalize font-bold text-center mb-5">
            {data.heading}
          </p>
        )}
        {data.description && (
          <p className="text-center lg:text-left text-sm lg:text-base text-gray-600 mt-2">
            {data.description}
          </p>
        )}
        <div>
          {data.infoContent.map((el, i) => (
            <div data-aos="fade-in" data-aos-delay={50*`${i + 1}`} className="grid grid-flow-row grid-cols-6 lg:grid-cols-12" key={`${el}${i}`}>
              <div
                className={`${
                  i % 2 === 0 ? "order-1" : "order-2"
                } col-span-6 lg:max-w-2xl mx-auto flex flex-col justify-center items-center lg:items-start`}
              >
                <p className="font-bold text-center lg:text-left text-2xl lg:text-3xl my-2">{el.heading}</p>
                <p className="text-gray-600 text-sm lg:text-base">{el.description}</p>
              </div>
              <div
                className={`${i % 2 !== 0 ? "order-1" : "order-2"}  col-span-6 hidden lg:block`}
              >
                <img
                  className="max-w-sm mx-auto"
                  src={require(`../../assets/Images/services/Mobile/${el.imageFolder}/${el.imageName}`)}
                  alt={el.imageName}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
