import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "dotnet.png",
    imageText2: ".NET Application Development we connect the dots right",
    imageText3:
      "BANK ON OUR SERVICES FOR CONSISTENT AND FUTURE-READY SOLUTIONS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get Estimation for your project",
    },
  },
};

export const multiRowData = {
  text1: "TAKE YOUR BUSINESSES TO NEW HEIGHTS WITH OUR SIMPLIFIED SOLUTIONS",
  text2: "ASP.NET development company trusted for excellent solutions",
  text3:
    "With a resource bank of proficient developers, thinkers and technology aficionados, we provide optimum .NET solutions to meet your strategic goals at a fast pace, with competitive pricing. We reserve a top spot as the top ASP.NET development company worldwide and have been recognized by leading research and ranking firms like extract.co. ",
  text4: "Services that make us stand out include the following:",
  carouselData: [
    {
      iconName: `${companyName}-NET-ANE`,
      label: "ASP.NET Enterprise",
    },
    {
      iconName: `${companyName}-NET-CANWS`,
      label: "Custom ASP.NET Web & Software",
    },
    {
      iconName: `${companyName}-NET-MAE`,
      label: "Microsoft Azure Expertise",
    },
    {
      iconName: `${companyName}-NET-ANMD`,
      label: "ASP.NET MVC Development",
    },
    {
      iconName: `${companyName}-NET-ANEC`,
      label: "ASP.NET E-Commerce",
    },
    {
      iconName: `${companyName}-NET-ANC`,
      label: "ASP.NET CMS/Migration",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const stackUsedData = {
  stackData: [
    {
      name: "Languages",
      logo: "coding",
      techUsed: [
        {
          techName: "C#",
          techLogo: "c-sharp.png",
        },
      ],
    },
    {
      name: "Frameworks",
      logo: `${companyName}-NET-F`,
      techUsed: [
        {
          techName: "MVC framework",
          techLogo: "mvc_framework.png",
        },
        {
          techName: "Net framework 4.5",
          techLogo: "netframework4.5.png",
        },
        {
          techName: "WCF",
          techLogo: "wcf.png",
        },
        {
          techName: "Angular JS",
          techLogo: "angularjs.png",
        },
      ],
    },
    {
      name: "ORMs & Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "SQL Server 2014",
          techLogo: "sql_server2014.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "dashboard.jpg",
};

export const typeFourBannerData = {
  heading:
    "Expert ASP.NET web development solutions for all your business needs",
  text1:
    "Our product development journey starts with you and continues till the time product lives. Our team of software engineers are acquainted with modern technology and experience - to assure that developmental challenges are overthrown and tasks are accomplished within set deadlines.",
  description: "BUILD THE PRODUCT OF YOUR DREAMS AND FLY HIGH",
};

export const typeThreeBannerData = {
  heading: "We are here to help you",
  description:
    "WE STAND SECOND TO NONE FOR .NET APPLICATION DEVELOPMENT SERVICES",
  details: [
    {
      label: "Happy Customers",
      content:
        "We are committed to delighting our reputed clients with feature-reach applications and cutting-edge .NET development services.",
      iconName: `${companyName}-NET-HC`,
    },
    {
      label: "Agile Software Dev Team",
      content:
        "We are professional and agile in our web development approach making things look easy and simple even when they are not.",
      iconName: `${companyName}-NET-ASDT`,
    },
    {
      label: "Creative yet effective approach",
      content:
        "We empower our clients with effective web solutions immersed in a liquid called creativity.",
      iconName: `${companyName}-NET-CYEA`,
    },
    {
      label: "Bespoke Software Solutions",
      content:
        "We combine development with our expertise and experience to deliver .NET development solutions that are rich in quality.",
      iconName: `${companyName}-NET-BSS`,
    },
    {
      label: "Customized Software Development",
      content:
        "Complementing our knowledge and expertise, we focus on providing personalized experience to our clients.",
      iconName: `${companyName}-NET-CSD`,
    },
    {
      label: "Unparalleled Communication",
      content:
        "We understand that project discussion is a never-ending process and therefore, we employ a dedicated team for the same.",
      iconName: `${companyName}-NET-UC`,
    },
    {
      label: "Trusted and Reliable Technology",
      content:
        "For us, technology implementation maintains the highest degree, and we ensure we get it right every single time.",
      iconName: `${companyName}-NET-TART`,
    },
    {
      label: "Unmatched Security",
      content:
        "Feel secure with us as we have a bucketful of tricks to assure security of our projects.",
      iconName: `${companyName}-NET-US`,
    },
  ],
};
