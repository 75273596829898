import React from "react";

export const StackEmployed = ({ data, background }) => {
  return (
    <div
      className={`${
        background ? background : "bg-blue-600"
      } py-12 text-white text-center`}
    >
      <div className="container mx-auto text-center">
        {data.text1 && (
          <p className="text-2xl lg:text-4xl max-w-4xl capitalize mx-auto">{data.text1}</p>
        )}
        {data.text2 && (
          <p className="text-base text-gray-600 mt-2">{data.text2}</p>
        )}
        {data.para && (
          <p className="text-base text-gray-400 my-6">{data.para}</p>
        )}
        <div className="grid grid-flow-row grid-cols-2 lg:grid-cols-5 gap-10 mt-10">
          {data.techData.map((el,i) => (
            <div
              className="flex flex-col justify-center items-center"
              key={`${el.name}${i}`}
            >
              <img
                src={require(`../../assets/Images/solutions/solution/logos/${el.logo}`)}
                className="w-24 h-24"
                alt={el.name}
              />
              {el.name && <p className={`mt-4 font-bold ${data.textUnderline && 'border-b-2 border-white py-2'}`}>{el.name}</p>}
              {el.tag && (
                <p className="capitalize text-base font-bold">({el.tag})</p>
              )}
            </div>
          ))}
        </div>
        {
          data.showButton && (
            <a className="inline-block p-4 text-white font-bold text-lg uppercase rounded-lg bg-orange-500 mt-10" href={data.buttonLink}>
              {data.buttonText}
            </a>
          )
        }
      </div>
    </div>
  );
};
