import { patterns } from "../../components/config";
import React, { useState, useEffect, useRef } from "react";

export const FormInputFields = ({
  labelName,
  type,
  inputFieldName,
  inputFiledId,
  fieldId,
  svg,
  required,
}) => {
  let [inputValue, setInputValue] = useState("");
  let [labelGoTop, setLabelGoTop] = useState(false);

  const onFocusHandler = () => {
    setLabelGoTop(true);
  };

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const notFocusHandler = () => {
    inputValue === "" ? setLabelGoTop(false) : setLabelGoTop(true);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current.type === "email") {
      inputRef.current.setAttribute("pattern", patterns.email);
    }
  }, []);

  return (
    <div
      id={fieldId}
      className={`relative flex items-center w-full pt-10 pb-2 border-b-2 ${
        labelGoTop ? " border-main-blue" : "border-gray-300"
      }`}
    >
      {svg}
      <div
        className={`relative ${svg ? "ml-4" : "ml-0"}`}
        style={{
          width: "100%",
        }}
      >
        <input
          ref={inputRef}
          type={type}
          onFocus={onFocusHandler}
          name={inputFieldName}
          id={inputFiledId}
          value={inputValue}
          onChange={inputChangeHandler}
          onBlur={notFocusHandler}
          className="focus:outline-none w-full"
          required={required}
        />
        <label
          htmlFor={inputFiledId}
          className={`absolute left-0 uppercase ${
            labelGoTop ? "top-20 text-xs" : "top-0 text-sm"
          } transition-all duration-200 ease-linear`}
          style={{
            color: "#486e90",
          }}
        >
          {labelName}*
        </label>
      </div>
    </div>
  );
};
