export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "clients.jpg",
    imageText2: "Whatever we are, we owe it to our clients",
    imageText3: "WE’RE RECOMMENDED BY COMPANIES AROUND THE WORLD- FROM CLOSE-KNIT STARTUPS TO FORTUNE 500",
  },
  showButton: false,
  showIcon: true,
  iconName: "clients",
};

export const typeOneTabData = {
  text1: "Empowering clients by effectively responding to their needs",
  text2:
    "Our team at Kdr works with a simple and powerful rule to always give our clients more than what they expect. We are acknowledged for our unmatched commitment towards delivering intuitive and scalable apps that create value for their business.",
  tabData: [
    {
      label: "Our Clients",
      buttonBehavior: "data",
    },
    {
      label: "CLIENT REVIEWS",
      buttonBehavior: "new",
      link: "/testimonials",
    },
  ],
  cardFormat: false,
  disclaimerText:
    "Disclaimer: Some of our clients are referred by our trusted partners.",
  multiRow: {
    Brands: {
      text2: "Brands",
      text3:
        "Most leading brands trust us to deliver latest technology solutions that help them grow their business exponentially. We strive hard to create a emotional bonding with our clients via our websites and mobile applications. We imbibe technology in our solutions that makes people's lives more responsive to their needs and help them to live smart eventually.",
      carouselData: [
        {
          imageName: "airtel",
          imageFolder: "whoweare",
          image: "airtel.png",
          label: "Telecom",
        },
        {
          imageName: "Dell",
          imageFolder: "whoweare",
          image: "dell.png",
          label: "Information Technology",
        },
        {
          imageName: "Telangana",
          imageFolder: "whoweare",
          image: "Telangana.PNG",
          label: "Government",
        },
        {
          imageName: "Goa",
          imageFolder: "whoweare",
          image: "goa.png",
          label: "Government",
        },
        {
          imageName: "JBL",
          imageFolder: "whoweare",
          image: "jbl.png",
          label: "Audio Electronics",
        },
        {
          imageName: "Himachal",
          imageFolder: "whoweare",
          image: "hp.png",
          label: "Government",
        },
        {
          imageName: "Sunrisers",
          imageFolder: "whoweare",
          image: "sunrisers.png",
          label: "Sports",
        },
        {
          imageName: "Talasha",
          imageFolder: "whoweare",
          image: "talasha.png",
          label: "Fashion",
        },
        {
          imageName: "Ting",
          imageFolder: "whoweare",
          image: "ting.png",
          label: "Information Technology",
        },
        {
          imageName: "Mananagaram",
          imageFolder: "whoweare",
          image: "mananagaram.png",
          label: "Government",
        },
        {
          imageName: "HSF",
          imageFolder: "whoweare",
          image: "hsf.png",
          label: "Information Technology",
        },
        {
          imageName: "AP",
          imageFolder: "whoweare",
          image: "ap.png",
          label: "Government",
        },
        {
          imageName: "santos",
          imageFolder: "whoweare",
          image: "santos.png",
          label: "Food & Beverages",
        },
        {
          imageName: "startcareer",
          imageFolder: "whoweare",
          image: "startcareer.png",
          label: "Information Technology",
        },
        {
          imageName: "Suryapet",
          imageFolder: "whoweare",
          image: "srpt.png",
          label: "Government",
        },
        {
          imageName: "Crown",
          imageFolder: "whoweare",
          image: "crown.png",
          label: "Audio Electronics",
        },
        {
          imageName: "nalgonda",
          imageFolder: "whoweare",
          image: "nalgonda.png",
          label: "Government",
        },
        {
          imageName: "BGM",
          imageFolder: "whoweare",
          image: "bgm.png",
          label: "Media & Advertising",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jds.png",
          label: "Events & Hopitality",
        },
        {
          imageName: "VH",
          imageFolder: "whoweare",
          image: "vh.png",
          label: "Travel & Hopitality",
        },
        {
          imageName: "TSFOREST",
          imageFolder: "whoweare",
          image: "tsforest.png",
          label: "Government",
        },
        {
          imageName: "KLUBF5",
          imageFolder: "whoweare",
          image: "klubf5.png",
          label: "Food & Beverages",
        },
        {
          imageName: "GHMC",
          imageFolder: "whoweare",
          image: "ghmc.png",
          label: "Government",
        },
        {
          imageName: "WORDSAPP",
          imageFolder: "whoweare",
          image: "wa.png",
          label: "Information Technology",
        },
        {
          imageName: "VANAMALI",
          imageFolder: "whoweare",
          image: "vanamali.png",
          label: "Medical",
        },
        {
          imageName: "DM",
          imageFolder: "whoweare",
          image: "dm.png",
          label: "Information Technology",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jd.png",
          label: "Real Estate",
        },
        {
          imageName: "SHIKARA",
          imageFolder: "whoweare",
          image: "shikara.png",
          label: "Non-Governmental Organisation",
        },
        {
          imageName: "TFS",
          imageFolder: "whoweare",
          image: "tfs.png",
          label: "Educational Research Centre",
        },
        {
          imageName: "Conneightion",
          imageFolder: "whoweare",
          image: "Conneightion.png",
          label: "Information Technology",
        },
      ],
    },
    Start_up: {
      text2: "Start-Ups",
      text3:
        "KDR’s goal is to provide startups that initial ignition so that they get on with the right strategy and move ahead with a flourishing business. With limitless possibilities in app development, we can turn every small idea into a big reality with most engaging tech trends.",
      carouselData: [
        {
          imageName: "HSF",
          imageFolder: "whoweare",
          image: "hsf.png",
          label: "Information Technology",
        },
        {
          imageName: "santos",
          imageFolder: "whoweare",
          image: "santos.png",
          label: "Food & Beverages",
        },
        {
          imageName: "startcareer",
          imageFolder: "whoweare",
          image: "startcareer.png",
          label: "Information Technology",
        },
        {
          imageName: "Suryapet",
          imageFolder: "whoweare",
          image: "srpt.png",
          label: "Government",
        },
        {
          imageName: "Crown",
          imageFolder: "whoweare",
          image: "crown.png",
          label: "Audio Electronics",
        },
        {
          imageName: "nalgonda",
          imageFolder: "whoweare",
          image: "nalgonda.png",
          label: "Government",
        },
        {
          imageName: "HSF",
          imageFolder: "whoweare",
          image: "hsf.png",
          label: "Information Technology",
        },
        {
          imageName: "santos",
          imageFolder: "whoweare",
          image: "santos.png",
          label: "Food & Beverages",
        },
        {
          imageName: "startcareer",
          imageFolder: "whoweare",
          image: "startcareer.png",
          label: "Information Technology",
        },
        {
          imageName: "Suryapet",
          imageFolder: "whoweare",
          image: "srpt.png",
          label: "Government",
        },
        {
          imageName: "Crown",
          imageFolder: "whoweare",
          image: "crown.png",
          label: "Audio Electronics",
        },
        {
          imageName: "nalgonda",
          imageFolder: "whoweare",
          image: "nalgonda.png",
          label: "Government",
        },
        {
          imageName: "BGM",
          imageFolder: "whoweare",
          image: "bgm.png",
          label: "Media & Advertising",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jds.png",
          label: "Events & Hopitality",
        },
        
        {
          imageName: "VH",
          imageFolder: "whoweare",
          image: "vh.png",
          label: "Travel & Hopitality",
        },
        {
          imageName: "TSFOREST",
          imageFolder: "whoweare",
          image: "tsforest.png",
          label: "Government",
        },
        {
          imageName: "KLUBF5",
          imageFolder: "whoweare",
          image: "klubf5.png",
          label: "Food & Beverages",
        },
        {
          imageName: "GHMC",
          imageFolder: "whoweare",
          image: "ghmc.png",
          label: "Government",
        },
        {
          imageName: "WORDSAPP",
          imageFolder: "whoweare",
          image: "wa.png",
          label: "Information Technology",
        },
        {
          imageName: "VANAMALI",
          imageFolder: "whoweare",
          image: "vanamali.png",
          label: "Medical",
        },
        {
          imageName: "NBB",
          imageFolder: "whoweare",
          image: "nbb.png",
          label: "Gaming",
        },
        {
          imageName: "Dorven",
          imageFolder: "whoweare",
          image: "dorvenfoods.png",
          label: "Food & Beverages",
        },
        {
          imageName: "DM",
          imageFolder: "whoweare",
          image: "dm.png",
          label: "Information Technology",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jd.png",
          label: "Real Estate",
        },
        {
          imageName: "SHIKARA",
          imageFolder: "whoweare",
          image: "shikara.png",
          label: "Non-Governmental Organisation",
        },
        {
          imageName: "KAMLESH",
          imageFolder: "whoweare",
          image: "kamlesh.png",
          label: "Independent Music Producer",
        },
        {
          imageName: "TFS",
          imageFolder: "whoweare",
          image: "tfs.png",
          label: "Educational Research Centre",
        },
        {
          imageName: "Conneightion",
          imageFolder: "whoweare",
          image: "Conneightion.png",
          label: "Information Technology",
        },
        {
          imageName: "Dytronix",
          imageFolder: "whoweare",
          image: "dytronix.png",
          label: "Information Technology",
        },
        {
          imageName: "Teeksha",
          imageFolder: "whoweare",
          image: "teeksha.png",
          label: "Health Care",
        },
        {
          imageName: "Dorven",
          imageFolder: "whoweare",
          image: "dorvenretail.png",
          label: "Retail",
        },
        {
          imageName: "KDR FOUNDATION",
          imageFolder: "whoweare",
          image: "kdrf.png",
          label: "Non-Governmental Organisation",
        },
        {
          imageName: "SRI VIGNESWARA",
          imageFolder: "whoweare",
          image: "svmovie.png",
          label: "Media & Entertainment",
        },
        {
          imageName: "Veehaari",
          imageFolder: "whoweare",
          image: "veehaari.png",
          label: "Travel & Hospitality",
        },
        {
          imageName: "TRI",
          imageFolder: "whoweare",
          image: "TRI.png",
          label: "Media",
        },
        {
          imageName: "VHCABS",
          imageFolder: "whoweare",
          image: "vhcabs.png",
          label: "Ridesharing & Outstation Cabs",
        },


      ],
    },
    Enterprises: {
      text2: "Enterprises",
      text3:
        "We believe in reshaping revenue agencies from looking digital to becoming digital and transform the way enterprise-level workforce perform their everyday tasks through business automation and process integration with the help of feature-rich mobile apps.",
      carouselData: [
        {
          imageName: "airtel",
          imageFolder: "whoweare",
          image: "airtel.png",
          label: "Telecom",
        },
        {
          imageName: "Dell",
          imageFolder: "whoweare",
          image: "dell.png",
          label: "Information Technology",
        },
        {
          imageName: "Telangana",
          imageFolder: "whoweare",
          image: "Telangana.PNG",
          label: "Government",
        },
        {
          imageName: "Goa",
          imageFolder: "whoweare",
          image: "goa.png",
          label: "Government",
        },
        {
          imageName: "JBL",
          imageFolder: "whoweare",
          image: "jbl.png",
          label: "Audio Electronics",
        },
        {
          imageName: "Himachal",
          imageFolder: "whoweare",
          image: "hp.png",
          label: "Government",
        },
        {
          imageName: "Sunrisers",
          imageFolder: "whoweare",
          image: "sunrisers.png",
          label: "Sports",
        },
        {
          imageName: "Talasha",
          imageFolder: "whoweare",
          image: "talasha.png",
          label: "Fashion",
        },
        {
          imageName: "Ting",
          imageFolder: "whoweare",
          image: "ting.png",
          label: "Information Technology",
        },
        {
          imageName: "Mananagaram",
          imageFolder: "whoweare",
          image: "mananagaram.png",
          label: "Government",
        },
        {
          imageName: "HSF",
          imageFolder: "whoweare",
          image: "hsf.png",
          label: "Information Technology",
        },
        {
          imageName: "santos",
          imageFolder: "whoweare",
          image: "santos.png",
          label: "Food & Beverages",
        },
        {
          imageName: "startcareer",
          imageFolder: "whoweare",
          image: "startcareer.png",
          label: "Information Technology",
        },
        {
          imageName: "Suryapet",
          imageFolder: "whoweare",
          image: "srpt.png",
          label: "Government",
        },
        {
          imageName: "Crown",
          imageFolder: "whoweare",
          image: "crown.png",
          label: "Audio Electronics",
        },
        {
          imageName: "nalgonda",
          imageFolder: "whoweare",
          image: "nalgonda.png",
          label: "Government",
        },
        {
          imageName: "BGM",
          imageFolder: "whoweare",
          image: "bgm.png",
          label: "Media & Advertising",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jds.png",
          label: "Events & Hopitality",
        },
        {
          imageName: "AP",
          imageFolder: "whoweare",
          image: "ap.png",
          label: "Government",
        },
        {
          imageName: "VH",
          imageFolder: "whoweare",
          image: "vh.png",
          label: "Travel & Hopitality",
        },
        {
          imageName: "TSFOREST",
          imageFolder: "whoweare",
          image: "tsforest.png",
          label: "Government",
        },
        {
          imageName: "KLUBF5",
          imageFolder: "whoweare",
          image: "klubf5.png",
          label: "Food & Beverages",
        },
        {
          imageName: "GHMC",
          imageFolder: "whoweare",
          image: "ghmc.png",
          label: "Government",
        },
        {
          imageName: "WORDSAPP",
          imageFolder: "whoweare",
          image: "wa.png",
          label: "Information Technology",
        },
        {
          imageName: "VANAMALI",
          imageFolder: "whoweare",
          image: "vanamali.png",
          label: "Medical",
        },
        {
          imageName: "NBB",
          imageFolder: "whoweare",
          image: "nbb.png",
          label: "Gaming",
        },
        {
          imageName: "DM",
          imageFolder: "whoweare",
          image: "dm.png",
          label: "Information Technology",
        },
        {
          imageName: "JDS",
          imageFolder: "whoweare",
          image: "jd.png",
          label: "Real Estate",
        },
        {
          imageName: "SHIKARA",
          imageFolder: "whoweare",
          image: "shikara.png",
          label: "Non-Governmental Organisation",
        },
        {
          imageName: "TFS",
          imageFolder: "whoweare",
          image: "tfs.png",
          label: "Educational Research Centre",
        },
      ],
    },
  },
};
