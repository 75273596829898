import React, { useEffect, useState } from "react";
import { Icon } from "../Icon/Icon";
export const VerticalTabs = ({ verticalTabData }) => {
  const [itemData, setItemData] = useState({});
  const [subSectionData, setSubSectionData] = useState("customer");

  const verticalItemClickHandler = (e, data) => {
    const id = e.currentTarget.id;
    Array.from(document.getElementsByClassName("vertical-list")).map((el) => {
      el.classList.remove("text-main-leftGradient");
      el.children[0].classList.remove(
        "text-white",
        "border-b-2",
        "border-orange-600"
      );
      el.children[0].classList.add("text-gray-600");
      return null;
    });
    const clickedItem = document.getElementById(id);
    clickedItem.children[0].classList.remove("text-gray-600");
    clickedItem.children[0].classList.add(
      "text-white",
      "border-b-2",
      "border-orange-600"
    );
    clickedItem.classList.add("text-main-leftGradient");
    setSubSectionData("customer");
    setItemData(data);
  };

  const dataHandler = (e, data) => {
    Array.from(document.getElementsByClassName("tabButtons")).map((el) => {
      el.classList.remove("bg-blue-500");
      el.classList.add("bg-darkOpacityBackground");
      return null;
    });
    document
      .getElementById(e.target.id)
      .classList.remove("bg-darkOpacityBackground");
    document.getElementById(e.target.id).classList.add("bg-blue-500");
    setSubSectionData(data);
  };

  useEffect(() => {
    const clickedItem = document.getElementById(verticalTabData[0]["uniqueId"]);
    clickedItem.children[0].classList.remove("text-gray-600");
    clickedItem.children[0].classList.add(
      "text-white",
      "border-b-2",
      "border-orange-600"
    );
    clickedItem.classList.add("text-main-leftGradient");
    setItemData(verticalTabData[0]["data"]);
  }, [verticalTabData]);

  return (
    <div className="bg-contacPageFormRightColor-darkBlue py-16 w-full text-white">
      <div data-aos="fade-in" className="w-full px-2 md:px-0">
        <p className="text-2xl lg:text-4xl max-w-6xl mx-auto text-center font-semibold mb-2">
          Feature-rich on-demand experiences catering to every user & business
          owner{" "}
        </p>
        <p className="text-base max-w-6xl mx-auto text-center uppercase text-gray-600 mb-10">
          DELIVER APPS YOUR USERS WILL LOVE
        </p>
        <div className="grid grid-flow-row grid-cols-1 xl:grid-cols-2 sm:mx-10 md:mx-20 lg:mx-40">
          <div className="flex flex-col md:flex-row">
            <ul className="md:pt-24">
              {verticalTabData.map((el, i) => (
                <li
                  className="flex vertical-list cursor-pointer mb-10 justify-around items-center text-gray-600"
                  id={el.uniqueId}
                  key={el.id}
                  onClick={(e) => {
                    verticalItemClickHandler(e, el.data);
                  }}
                >
                  <span className="list-index text-base font-bold text-gray-600">
                    0{i + 1}
                  </span>
                  <span className="ml-6">
                    <Icon iconName={el.iconName} classes="w-10 h-10" />
                  </span>
                </li>
              ))}
            </ul>
            <div className="md:ml-10 px-2 md:px-0 max-w-6xl xl:max-w-lg w-full">
              <div className="flex items-center justify-between">
                {Object.keys(itemData).map((el) => (
                  <button
                    id={el}
                    onClick={(e) => dataHandler(e, el)}
                    className="focus:outline-none tabButtons p-4 uppercase rounded-lg bg-darkOpacityBackground hover:bg-blue-500 hover:text-white text-gray-900 font-bold cursor-pointer"
                    key={el}
                  >
                    {el.replace("_", " ")}
                  </button>
                ))}
              </div>
              <div className="bg-darkBackground mt-6 p-10">
                <p className="font-bold text-white text-2xl mb-3">
                  {itemData[subSectionData]?.heading}
                </p>
                <p className="text-gray-500 text-base leading-6">
                  {itemData[subSectionData]?.description}
                </p>
                <div className="mt-5">
                  {itemData &&
                    itemData[subSectionData]?.points.map((el) => (
                      <div
                        className="flex items-center justify-start text-gray-500 mt-2"
                        key={el}
                      >
                        <Icon
                          iconName="tick"
                          classes="w-5 h-5 mr-4 text-orange-500 fill-current"
                        />
                        {el}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {itemData &&
            !!subSectionData &&
            Object.keys(itemData?.[subSectionData] || {}).length > 0 && (
              <div
                data-aos="slide-left"
                className="hidden xl:flex xl:justify-center xl:items-center"
              >
                <img
                  className="w-64"
                  src={require(`../../assets/Images/solutions/${itemData[subSectionData]?.imgFolder}/${itemData[subSectionData]?.imgName}`)}
                  alt="app"
                />
            
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
