import React from "react";
import { FAQBanner } from "../../../lib/Banner/FAQBanner/FAQBanner";
// import { AppCarousel } from "../../../lib/Carousel/AppCarousel";
import { MultiRowCarousel } from "../../../lib/Carousel/MultiRowCarousel";
import { HireDedicated } from "../../../lib/HireDedicated/HireDedicated";
import { ImageTypeOneBanner } from "../../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { ImageTypeTwoBanner } from "../../../lib/ImageTypeTwoBanner/ImageTypeTwoBanner";
import { StackEmployed } from "../../../lib/StackEmployed/StackEmployed";
import { StackUsed } from "../../../lib/StackUsed/StackUsed";
import { TypeFiveBanner } from "../../../lib/TypeFiveBanner/TypeFiveBanner";
import { TypeOneBanner } from "../../../lib/TypeOneBanner/TypeOneBanner";
import { TypeSevenBanner } from "../../../lib/TypeSevenBanner/TypeSevenBanner";
import { TypeThreeBanner } from "../../../lib/TypeThreeBanner/TypeThreeBanner";
import { ToolsUsed } from "../../../lib/ToolsUsed/ToolsUsed";
import { Footer } from "../../Footer/Footer";
import { Navbar } from "../../Navbar/Navbar";
import { WorkWithUs } from "../../WorkWithUs/WorkWithUs";
import { emergingTechComponent } from "./emergingTechPageComponent";
import { ConnectBanner } from "../../../lib/Banner/ConnectBanner/ConnectBanner";
import { Type15Banner } from "../../../lib/Type15Banner/Type15Banner";
import { IconBanner } from "../../../lib/Banner/IconBanner/IconBanner";
import { Type16Banner } from "../../../lib/Type16Banner/Type16Banner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const EmergingTech = ({ url }) => {
  // const appData = require(`./${url}`)["soultionAppCarouselData"];
  const imageTypeOneBannerData = require(`./${url}`)["imageTypeOneBannerData"];
  const imageTypeTwoBannerData = require(`./${url}`)["imageTypeTwoBannerData"];
  const multiRowData = require(`./${url}`)["multiRowData"];
  const stackEmployedData = require(`./${url}`)["stackEmployedData"];
  const typeOneBannerData = require(`./${url}`)["typeOneBannerData"];
  const typeThreeBannerData = require(`./${url}`)["typeThreeBannerData"];
  const typeFiveBannerData = require(`./${url}`)["typeFiveBannerData"];
  const topTypeThreeBannerData = require(`./${url}`)["topTypeThreeBannerData"];
  const stackUsedData = require(`./${url}`)["stackUsedData"];
  const hireDedicated = require(`./${url}`)["hireDedicated"];
  const openSourceData = require(`./${url}`)["openSourceData"];
  const howHiringWorks = require(`./${url}`)["howHiringWorks"];
  const faqBannerData = require(`./${url}`)["faqBannerData"];
  const toolsUsedData = require(`./${url}`)["toolsUsedData"];
  const connectBannerData = require(`./${url}`)["connectBannerData"];
  const topFaqBannerData = require(`./${url}`)["topFaqBannerData"];
  const imageTilesData = require(`./${url}`)["imageTilesData"];
  const iconBannerData = require(`./${url}`)["iconBannerData"];
  const type16BannerData = require(`./${url}`)["type16BannerData"];
  const whichComponents = emergingTechComponent[url];

  const location = useLocation().key;

  return (
    <div key={location}>
      {whichComponents.navbar && (
        <Navbar otherPages={true} transparent={true} />
      )}
      {whichComponents.image_banner && (
        <ImageTypeOneBanner
          imageName={imageTypeOneBannerData.imageSection.imageName}
          folderName={imageTypeOneBannerData.imageSection.folderName}
          imageText2={imageTypeOneBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeOneBannerData.imageSection.imageText3}
          buttonType={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonType
          }
          buttonLink={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonLink
          }
          buttonText={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonText
          }
        />
      )}
      {whichComponents.hire_dedicated && (
        <HireDedicated data={hireDedicated} formatOne={true} />
      )}
      {whichComponents.type_two_image_banner && (
        <ImageTypeTwoBanner
          imageName={imageTypeTwoBannerData.imageSection.imageName}
          folderName={imageTypeTwoBannerData.imageSection.folderName}
          imageText2={imageTypeTwoBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeTwoBannerData.imageSection.imageText3}
          points={imageTypeTwoBannerData.imageSection.points}
          buttonType={imageTypeTwoBannerData.imageSection.buttonType}
          buttonLink={imageTypeTwoBannerData.imageSection.buttonLink}
          buttonText={imageTypeTwoBannerData.imageSection.buttonText}
        />
      )}

      {whichComponents.open_source && <TypeSevenBanner data={openSourceData} />}
      {whichComponents.multi_row && (
        <MultiRowCarousel data={multiRowData} showButton={true} />
      )}
      {whichComponents.top_type_three && (
        <TypeThreeBanner
          data={topTypeThreeBannerData}
          backgroundColor="bg-gray-900"
          showButton={topTypeThreeBannerData.showButton}
          buttonScrollToId={topTypeThreeBannerData.buttonScrollToId}
          buttonText={topTypeThreeBannerData.buttonText}
        />
      )}
      {whichComponents.image_tiles && <Type15Banner data={imageTilesData} />}
      {whichComponents.type_five && (
        <TypeFiveBanner data={typeFiveBannerData} />
      )}
      {whichComponents.type_one && (
        <TypeOneBanner
          text1={typeOneBannerData.text1}
          text2={typeOneBannerData.text2}
        />
      )}
      {whichComponents.stack_used && <StackUsed data={stackUsedData} />}
      {whichComponents.stack_employed && (
        <StackEmployed
          data={stackEmployedData}
          background="bg-contacPageFormRightColor-darkBlue"
        />
      )}
      {whichComponents.how_hire_works && (
        <HireDedicated data={howHiringWorks} formatTwo={true} />
      )}
      {whichComponents.type_three && (
        <TypeThreeBanner data={typeThreeBannerData} />
      )}
      {whichComponents.top_faq_banner && <FAQBanner data={topFaqBannerData} />}
      {whichComponents.connect_with_us && (
        <ConnectBanner data={connectBannerData} />
      )}
      {whichComponents.tech_we_use && <ToolsUsed data={toolsUsedData} />}
      {whichComponents.life_cycle && <Type16Banner data={type16BannerData} />}
      {whichComponents.faq_banner && <FAQBanner data={faqBannerData} />}
      {/* {whichComponents.app_carousel && (
        <AppCarousel appData={appData} showButtn={true} />
      )} */}
      {whichComponents.service_icon && <IconBanner data={iconBannerData} />}
      {whichComponents.work_with_us && <WorkWithUs />}
      {whichComponents.footer && <Footer />}
    </div>
  );
};
