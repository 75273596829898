export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "engagementmodel.jpg",
    imageText2: "KDR's Engagement Models",
    imageText3: "ENSURING A FLEXIBLE ENGAGEMENT MODEL TO CATER TO YOUR BUSINESS NEEDS",
  },
  showButton: false,
  showIcon: false,
};

export const typeFourBannerData = {
  text1:
    "No two businesses are exactly alike. At Kdr Soft Solutions, we make sure that no two development processes are the same and we develop an efficient project model that is in accordance with the varied and dynamic business needs.",
};

export const pointsSectionData = {
  para:
    "What makes our business process model unbeatable is its flexibility. Clients' requirements constantly change (either by choice or chance), and we adjust to these changes anytime before delivery. Our engagement models are beneficial to clients because they propose competitive prices, dedicated resources, no hidden costs, and zero overheads. We want to work with you to the best of our abilities.",
  data: {
    heading: "Some of the benefits of choosing our engagement models",
    points: [
      "No complexities",
      "Transparent quotes",
      "Secured development environment",
      "No compromise with quality",
      "Calculated costs and budgeted rates",
      "Close-knit support",
      "100% Privacy Maintained",
      "Standardized Approach",
    ],
  },
};

export const hireDedicated = {
  text1:
    "You are free to make a choice- Choose from our range of models that suits you best",
  card: {
    iconName: "clients",
    heading: "Hire a Developer Model",
    description:
      "As an experienced Information Technology solutions provider, we understand your concerns towards having a managerial control over the development process. So, we present to you our 'Hire a Developer' Model that lets you hire dedicated web developers, web designers, mobile application developers and SEO experts. This model further provides 3 options that highlight your needs and requirements.",
  },
  data: [
    {
      iconName: "hourglass",
      heading: "Full-time Hiring",
      para:
        "One of the most popular options under our Hire a Developer model. Most clients prefer this option as it employs direct and clear communication with the person working on the project and to provide them full control over the development process. You can hire experts in web development, web designing, mobile app development and SEO throughout the duration of your project development.",
    },
    {
      iconName: "stopwatch",
      heading: "Part Time Hiring",
      para:
        "One of the most popular options under our Hire a Developer model. Most clients prefer this option as it employs direct and clear communication with the person working on the project and to provide them full control over the development process. You can hire experts in web development, web designing, mobile app development and SEO throughout the duration of your project development.",
    },
    {
      iconName: "clock",
      heading: "Hourly Hiring",
      para:
        "One of the most popular options under our Hire a Developer model. Most clients prefer this option as it employs direct and clear communication with the person working on the project and to provide them full control over the development process. You can hire experts in web development, web designing, mobile app development and SEO throughout the duration of your project development.",
    },
  ],
  bottomCards: [
    {
      iconName: "engagement-FP",
      heading: "Fixed Price",
      description:
        "This model is well suited to clients who have well-structured process to gather requirements, scope, deliverable and acceptance criteria for the project. As you provide to us a clear idea, requirements, scope along with the deliverable and acceptance, we execute the projects on a fixed-price and billing basis. During development, we provide phase-wise delivery and keep up with the delivery schedules. Consider it as one of the most effective and convenient models to cut down on your costs and stay within budget.",
    },
    {
      iconName: "engagement-TM",
      heading: "Time and Material",
      description:
        "This is used when the resources and time can’t be estimated upfront, at the start. For a project that has a constantly-evolving scope and constantly-changing requirements, our Time & Material model is perfect. It's a great agile model that’s the best fit for iterative and incremental application development. We take your input and considerations at each stage keeping in mind that you require flexibility and continuous changes and advancements in the project. Adding to this, we follow a sorted project management process based on a daily/weekly reporting system, without any complexity. We charge the client based on the number of hours dedicated by each resource.",
    },
  ],
};
