import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/Images/KDR-WHITE.png";
import { FormInputFields } from "../../lib/Contact/FormInputFields";
import FormSelectFields from "../../lib/Contact/FormSelectFields";
import { countryNames, whenToStart } from "./requestQuote.js";
import {
  cCodeData,
  interestedInData,
  budgetData,
  reCaptchaKey,
  allowedFormats,
} from "../../lib/Contact/contactFormData";
import { FormTextAreaField } from "../../lib/Contact/FormTextAreaField";
import ReCAPTCHA from "react-google-recaptcha";
import { sendMail } from "../../lib/utils/sendMail";
import { toast } from "react-toast";

export const RequestQuote = () => {
  const captchaRef = React.createRef();

  const history = useHistory();

  let [selectedFile, setSelectedFile] = useState("");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();
    if (!!captchaValue) {
      const formData = document.getElementById("contactFormData");
      const data = new FormData(formData);
      data.delete("g-recaptcha-response");
      data.delete("file");
      data.set("attachment", selectedFile);
      const res = await sendMail(data);
      if (res.success) {
        captchaRef.current.reset();
        formData.reset();
        toast.success(res.message, {
          backgroundColor: "green",
          color: "#fff",
        });
      } else {
        toast.error(res.message, {
          backgroundColor: "red",
          color: "#ffff",
        });
      }
    } else {
      toast.error("Please fill the captcha field.", {
        backgroundColor: "red",
        color: "#ffff",
      });
    }
  };

  useEffect(() => {
    const removeSlash = window.location.pathname.slice(
      window.location.pathname.indexOf("/") + 1
    );
    const path = removeSlash || "Home Page";
    document.title = `Kdr Soft Solutions | ${path
      .replace("-", " ")
      .replace(
        /\w\S*/g,
        (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
      )}`;
  }, []);

  const fileHandler = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split(".")[1];
    if (Math.round(file.size / 1024) > 2048) {
      toast.warn("File should be less than 2mb of size", {
        backgroundColor: "#dd6b20",
      });
    } else if (allowedFormats.includes(extension)) {
      setSelectedFile(file);
    } else {
      toast.error(
        `${extension} is not allowed. please upload ${allowedFormats.join(
          " or "
        )}`,
        {
          backgroundColor: "red",
        }
      );
    }
  };

  return (
    <div className="bg-gray-900">
      <div
        data-aos="fade-in"
        className="sticky top-0 flex justify-between px-10 bg-gray-900 z-50"
      >
        <Link to="/">
          <img className="w-20 rounded-full" src={logo} alt="kdr logo" />
        </Link>
        <div className="flex items-center justify-center cursor-pointer">
          <span onClick={() => history.goBack()}>
            <svg
              className="w-8 h-8 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
            </svg>
          </span>
        </div>
      </div>
      <p data-aos="fade-in" className="text-3xl text-center text-white mb-4">
        Get a perfect quote
      </p>
      <p
        data-aos="fade-in"
        className="text-center w-full max-w-6xl mx-auto text-xl leading-8 text-gray-600"
      >
        We’re eager to work with you. Please share your project goals and
        contact information. We respond to 97% of queries within 1-2 business
        days. Really!
      </p>
      <div className="w-full max-w-6xl mx-auto mt-10" data-aos="fade-up">
        <form
          className="bg-white p-10 rounded-lg"
          onSubmit={onSubmitHandler}
          id="contactFormData"
          autoComplete="on"
        >
          <p className="text-center mt-2 text-xl font-bold uppercase text-main-leftGradient">
            TELL US ABOUT YOU
          </p>
          <div className="mx-4">
            <FormInputFields
              fieldId="name"
              labelName="Your Name"
              type="text"
              inputFieldName="username"
              inputFiledId="formName"
              required={true}
            />
          </div>
          <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 transition-all duration-200 ease-linear">
            <FormSelectFields
              data={countryNames}
              labelName="Select Country"
              name="country"
              selectCodeField="formcCountryNameSelect"
              required={true}
            />
            <FormInputFields
              labelName="Email Address"
              fieldId="email"
              type="email"
              inputFieldName="email"
              inputFiledId="formEmail"
              required={true}
            />
          </div>
          <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 grid-cols-1">
            <div className="grid grid-flow-col grid-rows-1 col-gap-5">
              <FormSelectFields
                labelName="Code"
                selectCodeField="formCodeSelect"
                data={cCodeData}
                required={true}
                name="code"
              />
              <FormInputFields
                labelName="Phone Number"
                fieldId="number"
                type="number"
                inputFieldName="number"
                inputFiledId="formNumber"
                required={true}
              />
            </div>
            <FormInputFields
              labelName="Skype ID/Whatsapp No."
              fieldId="socialNumber"
              type="text"
              inputFieldName="socialNumber"
              inputFiledId="formSocialNumbers"
              required={true}
            />
          </div>
          <p className="text-center mt-10 text-xl font-bold uppercase text-main-leftGradient">
            TELL US ABOUT YOUR PROJECT
          </p>
          <FormTextAreaField
            textAreaFieldId="formTextArea"
            labelName="What’s your project all about?"
            required={true}
            name="description"
          />
          <p className="text-center mt-10 text-xl font-bold uppercase text-main-leftGradient">
            What Are You Looking For?*
          </p>
          <FormSelectFields
            labelName={"Interested In"}
            data={interestedInData}
            selectCodeField="formInterestedIn"
            required={true}
            name="interestedIn"
          />
          <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 transition-all duration-200 ease-linear">
            <FormSelectFields
              labelName="Select your budget range"
              selectCodeField="formBudget"
              data={budgetData}
              required={true}
              name="budget"
            />
            <FormSelectFields
              labelName="When do you want to get started?"
              selectCodeField="formStart"
              data={whenToStart}
              required={true}
              name="whenToStart"
            />
          </div>
          <div className="mt-10">
            <label
              htmlFor="file"
              className="flex items-center justify-center border-dotted p-10 border-gray-400 hover:border-main-blue cursor-pointer border-2"
            >
              <svg
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-10 h-10 text-main-blue hidden md:block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="flex flex-col items-center justify-center md:ml-10">
                <span className="text-blue-600 text-lg">
                  Attach any files you feel would be useful
                </span>
                <span className="text-xs">
                  (Attach Files (docx, pdf, txt, max Size 2mb)
                </span>
              </p>
            </label>
            <input
              className="hidden"
              name="file"
              onChange={fileHandler}
              type="file"
              id="file"
            />
          </div>
          {selectedFile !== "" && (
            <p className="text-center font-bold">{`You have selected: ${selectedFile.name}`}</p>
          )}
          <div className="flex md:flex-row flex-col justify-evenly items-center mt-4">
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onExpired={() => {
                captchaRef.current.reset();
                toast.warn("Captcha selection expired please verify again", {
                  backgroundColor: "#dd6b20",
                });
              }}
              ref={captchaRef}
              size="compact"
            />

            <button
              type="submit"
              className="px-8 py-6 mt-4 md:mt-0 w-full hover:bg-gradient-purple-blue hover:text-white md:max-w-lg font-bold rounded-md uppercase text-lg bg-gradient-blue-green text-white transition-all duration-200 ease-linear"
            >
              Let's go
            </button>
          </div>
        </form>
      </div>
      <p className="mt-10 pr-10 pb-10 text-base text-white text-center">
        Or send us an email at:{" "}
        <a href="mailto:sales@kdrsoftsolutions.com" className="text-main-blue">
          sales@kdrsoftsolutions.com
        </a>
      </p>
    </div>
  );
};
