const firstColumn = ["On Demand App Solutions"];
const secondColumn = [
  "Real Estate & Property",
  "Social Networking",
  "Food & Restaurant",
  "Banking, Finance & Insurance",
  "Education & eLearning",
  "Ecommerce, Retail & B2B",
];

const thirdColumn = [
  "Travel & Hospitality",
  "Media & Entertainment",
  "Healthcare & Fitness",
  "Transport & Automotive",
  "Gaming & Leisure",
  "Event & Tickets",
];


export const externalLinksForSolutions = {
  "Hospital Management Solution": "http://hms.kdrsoftsolutions.com/",
  "Point of Sale Solution": "http://pos.kdrsoftsolutions.com/",
  "Crowdfunding Solution": "http://cf.kdrsoftsolutions.com/",
  "Ecommerce Solution": "http://ecomm.kdrsoftsolutions.com/",
  "School Management System": "http://school.kdrsoftsolutions.com/",
  "Support Ticket Management": "http://support.kdrsoftsolutions.com/",
};

export const names = {
  firstColumn,
  secondColumn,
  thirdColumn,
  externalLinksForSolutions,
};