import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { AppCarousel } from "../../../lib/Carousel/AppCarousel";
import { MultiRowCarousel } from "../../../lib/Carousel/MultiRowCarousel";
import { ImageTypeOneBanner } from "../../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { StackEmployed } from "../../../lib/StackEmployed/StackEmployed";
import { StackUsed } from "../../../lib/StackUsed/StackUsed";
import { TypeOneBanner } from "../../../lib/TypeOneBanner/TypeOneBanner";
import { TypeThreeBanner } from "../../../lib/TypeThreeBanner/TypeThreeBanner";
import { VerticalTabs } from "../../../lib/VerticalTabs/VerticalTabs";
import { Footer } from "../../Footer/Footer";
import { Navbar } from "../../Navbar/Navbar";
import { WorkWithUs } from "../../WorkWithUs/WorkWithUs";
import { pageComponents } from "./pageComponents";
import { ImageTypeThreeBanner } from "../../../lib/ImageTypeThreeBanner/ImageTypeThreeBanner";
import { Type17Banner } from "../../../lib/Type17Banner/Type17Banner";
import ImageTypeFourBanner from "../../../lib/ImageTypeFourBanner/ImageTypeFourBanner";
import Type18Banner from "../../../lib/Type18Banner/Type18Banner";

export const SolutionComponent = ({ url }) => {
  const data = require(`./${url}`)["verticalTabData"];
  // const appData = require(`./${url}`)["soultionAppCarouselData"];
  const imageTypeOneBannerData = require(`./${url}`)["imageTypeOneBannerData"];
  const multiRowData = require(`./${url}`)["multiRowData"];
  const onDemandModelData = require(`./${url}`)["onDemandModelData"];
  const stackEmployedData = require(`./${url}`)["stackEmployedData"];
  const stackUsedData = require(`./${url}`)["stackUsedData"];
  const typeOneBannerData = require(`./${url}`)["typeOneBannerData"];
  const typeThreeBannerData = require(`./${url}`)["typeThreeBannerData"];
  const type17Data = require(`./${url}`)["type17Data"];
  const type18Data = require(`./${url}`)["type18Data"];
  const imageTypeThreeBannerData = require(`./${url}`)[
    "imageTypeThreeBannerData"
  ];
  const imageTypeFourBannerData = require(`./${url}`)[
    "imageTypeFourBannerData"
  ];

  const whichComponents = pageComponents[url];

  const location = useLocation().key;

  return (
    <div key={location}>
      {whichComponents.navbar && (
        <Navbar otherPages={true} transparent={true} />
      )}
      {whichComponents.image_banner && (
        <ImageTypeOneBanner
          imageName={imageTypeOneBannerData.imageSection.imageName}
          folderName={imageTypeOneBannerData.imageSection.folderName}
          imageText2={imageTypeOneBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeOneBannerData.imageSection.imageText3}
          buttonType={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonType
          }
          buttonLink={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonLink
          }
          buttonText={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonText
          }
        />
      )}
      {whichComponents.image_type_three && (
        <ImageTypeThreeBanner data={imageTypeThreeBannerData} />
      )}
      {whichComponents.type17 && <Type17Banner data={type17Data} />}
      {whichComponents.image_type_four && (
        <ImageTypeFourBanner data={imageTypeFourBannerData} />
      )}
      {whichComponents.type18 && <Type18Banner data={type18Data} />}
      {whichComponents.multi_row && <MultiRowCarousel data={multiRowData} />}
      {whichComponents.on_demand && (
        <div className="bg-white text-center py-16">
          <p className="max-w-6xl w-full mx-auto text-3xl font-bold lg:text-4xl">
            {onDemandModelData.text1}
          </p>
          <p className="max-w-6xl w-full mx-auto text-base text-gray-600">
            {onDemandModelData.text2}
          </p>
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 px-4 lg:grid-cols-4 gap-10 max-w-6xl w-full lg:mx-auto mt-10">
            {onDemandModelData.modelData.map((el, i) => (
              <div
                className="border-2 border-gray-300 px-4 py-6 hover:shadow-lg relative"
                key={i}
              >
                <p className="text-xl font-bold">{el.heading}</p>
                <p className="text-base text-gray-700 mt-4">{el.content}</p>
              </div>
            ))}
          </div>
          <a
            className="inline-block py-4 bg-orange-500 text-white shadow hover:shadow-md uppercase text-base mt-4 rounded-lg px-4"
            href={onDemandModelData.buttonDetails.buttonScrollId}
          >
            {onDemandModelData.buttonDetails.buttonText}
          </a>
        </div>
      )}
      {whichComponents.vertical_tabs && <VerticalTabs verticalTabData={data} />}
      {whichComponents.type_one && (
        <TypeOneBanner
          text1={typeOneBannerData.text1}
          text2={typeOneBannerData.text2}
        />
      )}
      {whichComponents.stack_used && <StackUsed data={stackUsedData} />}
      {whichComponents.stack_employed && (
        <StackEmployed data={stackEmployedData} />
      )}
      {whichComponents.type_three && (
        <TypeThreeBanner
          data={typeThreeBannerData}
          semiHeading={true}
          showButton={true}
          buttonText={typeThreeBannerData.buttonDetails.buttonText}
          buttonScrollToId={typeThreeBannerData.buttonDetails.buttonScrollId}
        />
      )}
      {/* {whichComponents.app_carousel && (
        <AppCarousel appData={appData} showButtn={true} />
      )} */}
      {whichComponents.work_with_us && <WorkWithUs />}
      {whichComponents.footer && <Footer />}
    </div>
  );
};
