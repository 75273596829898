import { companyName } from "../../config";

export const verticalTabData = [
  {
    id: 1,
    uniqueId: "on-board-1",
    iconName: `user-add`,
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "On Boarding",
        description:
          "Personalized as per the demands of the customer. An interesting and simple app walkthrough is encouraged. From approval to authentication, everything is possible with few taps.",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
        imgFolder: "solution",
        imgName: "d1.png",
      },
      service_provider: {
        heading: "On Boarding",
        description:
          "Smoother and seamless on boarding for service provider is taken care on the set. Service provider can easily connect with the customer as all screens are managed well for better authentication and thorough user verification.",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
        imgFolder: "solution",
        imgName: "d2.png",
      },
    },
  },
  {
    id: 2,
    uniqueId: "on-board-2",
    iconName: `${companyName}-ODAS-SS`,
    imgFolder: "solution",
    imgName: "2.png",
    data: {
      customer: {
        heading: "Service Selection and Management",
        description:
          "Service provider can directly and dynamically manage (create, edit, delete and match) the array of services provided, their selection by the user from admin account.",
        points: ["Select Service type", "Sub services"],
        imgFolder: "solution",
        imgName: "d3.png",
      },
    },
  },
  {
    id: 3,
    uniqueId: "on-board-3",
    iconName: "ticket",
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "Matching/Booking",
        description: "Book Now - Book services which are available.",
        points: [
          "Schedule for later",
          "Matching - As per filters (Location, Time and availability ) matching will be done automatically",
        ],
        imgFolder: "solution",
        imgName: "d3.png",
      },
      service_provider: {
        heading: "Matching/Booking",
        description:
          "One of the most important modules of on-demand is allocating the right service provider to the right customers. Just like allocating a driver for hire a cab service.",

        points: ["Schedule", "Reject", "Allocate new bookings"],
        imgFolder: "solution",
        imgName: "d4.png",
      },
    },
  },
  {
    id: 4,
    uniqueId: "on-board-4",
    iconName: `${companyName}-ODAS-PN`,
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "Push Notification",
        description:
          "User will get notification for each important event in the application via email or message from the service provider. Both service provider and user will get notification for each important event in the application. From booking confirmation to booking cancellation user will be updated.",
        points: [
          "Booking Confirmation",
          "Booking cancellation",
          "Arrival of service provider",
          "New Offers",
        ],
        imgFolder: "solution",
        imgName: "d5.png",
      },
      service_provider: {
        heading: "Push Notification",
        description:
          "Service providers get notification for any new bookings and cancelations done.",

        points: ["New Booking Request", "Cancellations"],
        imgFolder: "solution",
        imgName: "d8.png",
      },
    },
  },
  {
    id: 5,
    uniqueId: "on-board-5",
    iconName: `${companyName}-ODAS-L`,
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "Location Tracking",
        description:
          "On-demand cab services, food delivery, flower delivery all rely on location-based solutions. They build trust as customers can know expected delivery, expected arrival in real time on the interface.",
        points: ["Track Vendor’s Location- real-time ETAs"],
        imgFolder: "solution",
        imgName: "d6.png",
      },
      service_provider: {
        heading: "Location Tracking",
        description:
          "Service provider can reach the right destination by using map services and can start and end the task once delivered or once the customer is dropped at destination.",

        points: [
          "Navigation & Maps",
          "Reach exact location",
          "Start a service",
          "Reject if out of limited area",
        ],
        imgFolder: "solution",
        imgName: "d11.png",
      },
    },
  },
  {
    id: 6,
    uniqueId: "on-board-6",
    iconName: `${companyName}-ODAS-RAR`,
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "Rating & Reviews",
        description:
          "Review and rating help customers gain trust towards the service as they feel their feedback and suggestions are valued.",
        points: [
          "Share feedback",
          "Seek required attention",
          "Get improved products",
        ],
        imgFolder: "solution",
        imgName: "d7.png",
      },
      service_provider: {
        heading: "Rating & Reviews",
        description:
          "While you can give customers an excellent interface to share their feedback for the service received and the product used, as a business owner, you can collect the information about user’s likes, preferences and know how to serve better.",

        points: ["Internal curation", "Control & improve service quality"],
        imgFolder: "solution",
        imgName: "d9.png",
      },
    },
  },
  {
    id: 7,
    uniqueId: "on-board-7",
    iconName: `${companyName}-ODAS-PAD`,
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "Promotions & Discounts",
        description:
          "Customers love to pay less than original price using a promotional code. They would visit again if they have a discount code. Referral bonuses also generate more sales and retention.",
        points: ["Apply discount code", "Use referral coupons"],
        imgFolder: "solution",
        imgName: "d10.png",
      },
    },
  },
];

export const typeOneBannerData = {
  text1: "Top on-demand technologies implemented",
  text2:
    "For the best service deliverables, each on-demand project at Kdr is built with a technical edge using all modern and prime tools and frameworks.",
};

export const typeThreeBannerData = {
  semiHeading: "FAST & EFFICIENT ACCESS TO SERVICES",
  heading: "Building promising relationships",
  para:
    "We will help you find out how to supply the rightest as per user demands, which is sure to make them wonder that how did they survive without your app all these days. We develop to meet macro-level and micro-level delivery requirements of users across all industries.",
  buttonDetails: {
    buttonScrollId: "#workWithUs",
    buttonText: "Drop us a line",
  },
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "solutions/solution",
    imageName: "ondemand.jpg",
    imageText2: "Disrupting on-demand economy with addictive app solutions",
    imageText3: "WE BUILD INFINITE POSSIBLE SOLUTIONS ON A MOBILE TAP",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get estimation for your project",
    },
  },
};

export const multiRowData = {
  text1: "PERFECT TECHNOLOGY PARTNER TO BUILD YOUR CLASSIFIED APP",
  text2: "Next generation on-demand app development services",
  text3:
    "As one of the leading on-demand app developers hailing from India, Kdr understands that the way your business respond to customer's demands of 'being available instantly as, when and where they need' will significantly influence your future success. Our on-demand mobility service model will help your startup or enterprise to innovate faster and deliver matchless services to customers across all industries and businesses.",
  text4: "Next generation on-demand app development services",
  carouselData: [
    {
      iconName: `${companyName}-ODAD-TS`,
      label: "Taxi Service",
    },
    {
      iconName: `${companyName}-ODAD-HC`,
      label: "House Cleaning",
    },
    {
      iconName: `${companyName}-ODAD-FO`,
      label: "Food Ordering",
    },
    {
      iconName: `${companyName}-ODAD-DOD`,
      label: "Doctor on-demand",
    },
    {
      iconName: `${companyName}-ODAD-LC`,
      label: "Laundry",
    },
    {
      iconName: `${companyName}-ODAD-BS`,
      label: "Beauty/Salon",
    },
    {
      iconName: `${companyName}-ODAD-G`,
      label: "Grocery",
    },
    {
      iconName: `${companyName}-ODAD-CT`,
      label: "Coaching/Tutor",
    },
    {
      iconName: `${companyName}-ODAD-L`,
      label: "Logistics",
    },
    {
      iconName: `${companyName}-ODAD-F`,
      label: "Fitness",
    },
    {
      iconName: `${companyName}-ODAD-HS`,
      label: "Home Services",
    },
    {
      iconName: `${companyName}-ODAD-CPD`,
      label: "Courier Delivery",
    },
    {
      iconName: `${companyName}-ODAD-WAD`,
      label: "Wine Delivery",
    },
    {
      iconName: `${companyName}-ODAD-PC`,
      label: "Pet Care",
    },
    {
      iconName: `${companyName}-ODAD-RM`,
      label: "Repair & Maintenance",
    },
  ],
};

export const stackUsedData = {
  stackData: [
    {
      name: "Programming Language",
      logo: "coding",
      techUsed: [
        {
          techName: "Ionic 2",
          techLogo: "ionic.png",
        },
        {
          techName: "AngularJS 2",
          techLogo: "angularjs.png",
        },
        {
          techName: "HTML5",
          techLogo: "html.png",
        },
        {
          techName: "CSS3",
          techLogo: "css.png",
        },
      ],
    },
    {
      name: "API Language",
      logo: "server",
      techUsed: [
        {
          techName: "Node.js",
          techLogo: "nodejs.png",
        },
      ],
    },
    {
      name: "Protocol",
      logo: "database-icon",
      techUsed: [
        {
          techName: "XAMPP",
          techLogo: "aws.png",
        },
      ],
    },
    {
      name: "Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "MySQL",
          techLogo: "mysql.png",
        },
        {
          techName: "MongoDB",
          techLogo: "mongo_db.png",
        },
      ],
    },
  ],
};

export const onDemandModelData = {
  text1: "On-demand service-centric development model",
  text2: "TO DELIVER BUSINESS VALUE ACROSS IOS AND ANDROID",
  modelData: [
    {
      heading: "On-Demand App for Consumers (B2B, B2C)",
      content:
        "Develop a mobile app solution that is easy to use and customizable as per business need",
    },
    {
      heading: "Custom Front-End",
      content:
        "The front-line API of an app consists of several stateless endpoints that join together multiple services",
    },
    {
      heading: "Back-end Engineering",
      content:
        "Our backend engineers develop the best data storage solutions to meet a project's unique needs",
    },
    {
      heading: "Implementation & launch",
      content:
        "Finally, we do quality check, security compliance check and submit it to Apple & Android App Stores. Post-deployment support & maintenance",
    },
  ],
  buttonDetails: {
    buttonText: "Plan your project with us",
    buttonScrollId: "#workWithUs",
  },
};

export const stackEmployedData = {
  text1: "Top IM solution technologies to build a reliable, quality product",
  text2: "Some of the languages, frameworks, backend server, tools used by us",
  techData: [
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat1",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
  ],
};
