export const servicesOffered = {
    text1: "Services That We Offer",
    text2:
      "Fully editable Pages, Fast loading, Google Analytics, Integrated IDX, Interactive Map Research, Easy-to-save multiple search, Agent lead routing, Lead capture forms.",
    data: [
      [
        {
          linkLabel: "Consulting Services",
          link: "consulting-services",
        },
        {
          linkLabel: "UI/UX Design",
          link: "/ui-ux-designing",
        },
        {
          linkLabel: "Web Application Development",
          link: "web-development",
        },
      ],
      [
        {
          linkLabel: "Mobile Application Development",
          link: "cmobile-application-development",
        },
        {
          linkLabel: "Enterprise Software Development",
          link: "enterprise",
        },
        {
          linkLabel: "Product Strategy for Startups",
          link: "/start-up",
        },
      ],
      [
        {
          linkLabel: "Quality Assurance",
          link: "quality-assurance",
        },
        {
          linkLabel: "Staff Augmentation & Dedicated Developers",
          link: "staff-augmentation-dedicated-teams",
        },
        {
          linkLabel: "Maintenance & Support",
          link: 'software-maintenance-support',
        },
      ],
    ],
  };