import React from "react";
import { Icon } from "../Icon/Icon";

export const InfoBanner = ({ data }) => {
  return (
    <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
      {data.map((el, i) => (
        <div data-aos="flip-up" className={`flex items-center justify-center bg-gray-200 py-8 border-r-2 border-white ${data.length === i+1 && "md:col-span-2 lg:col-span-1"}`} key={i}>
          <Icon iconName={el.iconName} classes="w-16 h-16 text-gray-500" />
          <div className="flex flex-col items-start justify-center ml-2">
            <p className="text-3xl text-blue-700 font-bold">{el.amount}</p>
            <p className="text-base font-bold uppercase">{el.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
