import React from "react";
import { Icon } from "../Icon/Icon";

const Type17Banner = ({ data }) => {
  return (
    <div
      className="text-white px-4 lg:px-2"
      style={{
        background: `url(${require(`../../assets/Images/${data.folderName}/${data.imageName}`)})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="max-w-6xl py-12 lg:py-24 mx-auto flex flex-col items-center">
        {data.heading && (
          <p className="uppercase font-bold text-2xl lg:text-4xl text-center">
            {data.heading}
          </p>
        )}
        {data.list && (
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 col-gap-12">
            {data.list.map((singleList, index) => (
              <div key={index} className="py-5 flex justify-start items-center">
                <Icon
                  iconName={singleList.iconName}
                  classes="w-12 h-12 text-main-blue flex-shrink-0"
                />
                <div className="ml-5">
                  {singleList.heading && (
                    <p className="text-base font-bold uppercase text-left">
                      {singleList.heading}
                    </p>
                  )}
                  {singleList.description && (
                    <p className="text-sm mt-3 max-w-sm text-gray-300">
                      {singleList.description}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {data.table && (
          <table className="table-auto text-center my-10 lg:mb-0 lg:mt-20">
            <thead>
              <tr>
                <th
                  className="uppercase text-center p-4 text-xl"
                  colSpan={data?.table?.tools?.length || 1}
                >
                  {data.table.heading}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.table.tools && (
                <tr
                  className={`grid grid-cols-2 md:grid-cols-7 col-span-6 md:col-span-1`}
                >
                  {data.table.tools.map((tool, index) => (
                    <td
                      className="divide-y p-4 lg:p-8 flex flex-col justify-center items-center"
                      key={index}
                    >
                      <img
                        className="w-12 h-12"
                        src={require(`../../assets/Images/${tool.folderName}/${tool.imgName}`)}
                        alt={tool.name}
                      />
                      <p className="uppercase border-none mt-4 font-bold text-base">
                        {tool.name}
                      </p>
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export { Type17Banner };
export default Type17Banner;
