import React from "react";
import { Icon } from "../Icon/Icon";

const ImageTypeFourBanner = ({ data }) => {
  return (
    <div className="relative bg-gray-200 px-4 lg:px-2">
      <div
        className="absolute w-full m-auto z-10 left-0 right-0 max-w-4xl"
        data-aos="slide-up"
        style={{
          backgroundImage: `url(${require(`../../assets/Images/solutions/solution/${data.imgName}`)})`,
          top: "-70px",
          height: "550px",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        data-aos="fade-in"
        className="max-w-4xl mx-auto grid grid-cols-1 pt-40 md:pt-450px md:grid-cols-2 col-gap-6 pb-20"
      >
        <div>
          {data.heading && (
            <h3 className="text-2xl lg:text-4xl font-bold leading-tight" data-aos="fade-in">
              {data.heading}
            </h3>
          )}
          {data.points && (
            <ul className="list-inside list-disc mt-6 text-gray-700">
              {data.points.map((point, index) => (
                <li className="text-sm mt-2" key={`${point}_${index}`}>
                  {point}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="mt-4 md:mt-0">
          <table className="table-auto text-center border border-gray-400 border-collapse rounded-md w-full box-content">
            <thead className="border border-gray-400 border-collapse">
              <tr>
                <th
                  className="capitalize text-center p-4 text-2xl"
                  colSpan={data?.sideBar?.points?.length || 1}
                >
                  {data.sideBar.heading}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.sideBar?.points && (
                <tr className="grid grid-cols-2">
                  {data?.sideBar?.points?.map((tool, index) => (
                    <td
                      className="p-8 flex flex-col justify-center items-center border-r border-b border-gray-400 border-collapse"
                      key={index}
                    >
                      <Icon iconName={tool.iconName} classes="w-10 h-10" />
                      <p className="uppercase text-base mt-3">{tool.name}</p>
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export { ImageTypeFourBanner };
export default ImageTypeFourBanner;
