import React from "react";
import { TypeOneImage } from "../Image/TypeOneImage/TypeOneImage";

export const Type12Banner = ({ data }) => {
  return (
    <div className="px-4 xl:px-0 pb-10 pt-2">
      {data.heading && (
        <p
          data-aos="fade-down"
          className="text-center text-3xl capitalize mb-8 text-blue-600 font-bold"
        >
          {data.heading}
        </p>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-flow-row mt-2">
        {data.imageCardData.map((el, i) => (
          <TypeOneImage data={el} key={i} id={i} />
        ))}
      </div>
    </div>
  );
};
