import React from "react";

export const TypeTwoImageCard = ({ data, id }) => {
  return (
    <div
      className="relative"
      style={{
        height: "630px",
        background: `url(${data.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      data-aos="fade-in"
      data-aos-delay={`100 * ${id + 1}`}
    >
      <div className="absolute h-full left-0 group hover:bg-main-blue text-white text-center px-6 w-full transition-all ease-linear duration-150">
        <p className="text-xl uppercase font-bold mt-48 group-hover:mt-24 py-4 text-white rounded-md group-hover:transition-all group-hover:ease-linear duration-300">
          {data.title}
        </p>
        <p className="hidden group-hover:block mt-2">{data.description}</p>
      </div>
    </div>
  );
};
