import React, { useState } from "react";
import { CardTypeFive } from "../../lib/Card/CardTypeFive/CardTypeFive";
import { whatWeOfferData } from "./data";

export const Whatweoffer = () => {
  const defaultState = {
    services: false,
    technologies: false,
    industries: false,
  };

  const [buttonActive, setButtonActive] = useState({
    services: true,
    technologies: false,
    industries: false,
  });

  const [currentActiveName, setCurrentActiveName] = useState("services");

  const activeChangeHandler = async (e) => {
    setButtonActive({
      ...defaultState,
      [e.target.name]: true,
    });
    setCurrentActiveName(e.target.name);
  };

  return (
    <div
      data-aos="fade-out"
      className="flex flex-col justify-center items-center bg-gray-200 py-16 w-full mx-auto"
    >
      <p className="uppercase md:text-3xl font-bold mb-12">WHAT WE OFFER</p>
      <div className="flex justify-evenly w-full max-w-5xl items-center mb-16">
        {["services", "technologies", "industries"].map((el, i) => (
          <button
            name={el}
            onClick={activeChangeHandler}
            className={`text-xs md:text-base font-semibold border-b-2 pb-2 w-full outline-none focus:outline-none uppercase transition-all duration-200 ease-linear ${
              buttonActive[el] ? "border-blue-600" : " border-gray-400"
            }`}
            key={el}
          >
            {el}
          </button>
        ))}
      </div>
      <div
        id={currentActiveName}
        className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mx-4 sm:mx-24 gap-4 sm:gap-6 md:gap-8 lg:gap-12"
      >
        {whatWeOfferData[currentActiveName].map((el, i) => (
          <CardTypeFive data={el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Whatweoffer;
