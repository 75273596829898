import React from "react";
import { Icon } from "../../Icon/Icon";

export const CircleCard = ({ data, id }) => {
  return (
    <div
      className="p-4 bg-blue-700 mx-auto bg-opacity-25 hover:bg-blue-500 rounded-full flex items-center justify-center"
      data-aos-delay={50 * `${id + 1}` * 2}
      data-aos="zoom-in"
      style={{
        width: "18rem",
        height: "18rem",
      }}
    >
      <div className="rounded-full w-64 h-64 mx-auto bg-white  font-bold flex justify-center flex-col items-center capitalize">
        {data.iconName && <Icon iconName={data.iconName} classes="w-16 h-16 text-orange-500" />}
        {data.heading && (
          <p className="text-2xl text-blue-700">{data.heading}</p>
        )}
        {data.para1 && <p className="text-base">{data.para1}</p>}
        {data.para2 && <p className="text-base">{data.para2}</p>}
      </div>
    </div>
  );
};
