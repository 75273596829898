export const typeOneBannerData = {
  text1: "Top on-demand technologies implemented",
  text2:
    "For the best service deliverables, each on-demand project at Kdr is built with a technical edge using all modern and prime tools and frameworks.",
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "wearable.png",
    imageText2: "Smart Meaningful Wearable Apps for Android Wear & iWatch",
    imageText3: "WE DRIVE INNOVATION FOR FUTURE WEARABLE TECHNOLOGIES",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const multiRowData = {
  text1: "PERFECT TECHNOLOGY PARTNER TO BUILD YOUR CLASSIFIED APP",
  text2: "Next generation on-demand app development services",
  text3:
    "As one of the leading on-demand app developers hailing from India, Kdr understands that the way your business respond to customer's demands of 'being available instantly as, when and where they need' will significantly influence your future success. Our on-demand mobility service model will help your startup or enterprise to innovate faster and deliver matchless services to customers across all industries and businesses.",
  text4: "Next generation on-demand app development services",
  carouselData: [
    {
      iconName: "user-add",
      label: "User Add1",
    },
    {
      iconName: "user-add",
      label: "User Add2",
    },
    {
      iconName: "user-add",
      label: "User Ad3",
    },
    {
      iconName: "user-add",
      label: "User Add4",
    },
    {
      iconName: "user-add",
      label: "User Add5",
    },
    {
      iconName: "user-add",
      label: "User Add6",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const stackEmployedData = {
  techData: [
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Why choose KDR as your wearable app development partner?",
  description: "TOP-CLASS EXPERIENCES FOR MOBILE AND WEARABLE DEVICES",
  details: [
    {
      label: "Experienced team",
      content:
        "Experts in Android, iOS, E and M-commerce, Magento, PhoneGap, Xamarin, HTML5 & others.",
    },
    {
      label: "Scalable development",
      content:
        "A focused and phased approach to build-to-market, to scale and to grow.",
    },
    {
      label: "Custom-built solutions",
      content:
        "For every unique idea, we offer adaptability and flexibility to suit your business needs",
    },
    {
      label: "Cost-effective services",
      content:
        "Be it a low-funded startup or a billion-dollar venture, we guarantee cost-effectiveness.",
    },
    {
      label: "Technology leadership",
      content:
        "IoT, AR/VR, Cloud, Cross-platform, Wearable, Beacon- we create everything & beyond.",
    },
    {
      label: "Value proposition",
      content:
        "The end-product is assured to add value to your business and deliver it to users.",
    },
    {
      label: "Innovation & novelty",
      content:
        "Innovation is our core value and we love to go beyond the limitations to deliver out-of-the-box.",
    },
    {
      label: "Full support ecosystem",
      content:
        "Your queries and doubts are resolved and taken care of even after launch",
    },
  ],
};
