export const pageComponents = {
  "on-demand-app-solutions": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    on_demand: true,
    vertical_tabs: true,
    type_one: true,
    stack_used: true,
    stack_employed: false,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "directory-organization": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    vertical_tabs: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "artificial-intelligence": {
    navbar: true,
    image_type_three: true,
    type17: true,
    type18: true,
    work_with_us: true,
    footer: true,
  },
  "blockchain-development": {
    navbar: true,
    image_type_three: true,
    type17: true,
    type18: true,
    work_with_us: true,
    footer: true,
  },
  "augmented-reality-solutions": {
    navbar: true,
    image_type_three: true,
    type17: true,
    image_type_four: true,
    type18: true,
    work_with_us: true,
    footer: true,
  },
};
