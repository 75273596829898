import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { RequestQuoteBanner } from "../../lib/RequestQuoteBanner/RequestQuoteBanner";
import { Footer } from "../Footer/Footer";
import { tabData, text1, text2 } from "./resourcePageData";
import "./resourcePage.css";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";

export const ResourcesPage = () => {
  return (
    <div>
      <Navbar otherPages={true} transparent={true} />
      <section
        className="relative"
        style={{
          background: `url(${require("../../assets/Images/resourcesPage/resources.jpg")})`,
          height: "70vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="absolute text-white w-full text-center flex flex-col items-center justify-center"
          style={{
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -60%)",
          }}
        >
          <img
            className="w-32"
            src={require("../../assets/Images/resourcesPage/meet.png")}
            alt="hand meet"
          />
          <p className="text-4xl font-bold">The Tech Thought</p>
          <p className="text-base text-gray-300">
            Insightful * Educational * Trending
          </p>
        </div>
      </section>
      <TypeOneBanner
        text1={text1}
        text2={text2}
        tabData={tabData}
        id="resource-information"
      />
      <RequestQuoteBanner />
      <Footer />
    </div>
  );
};
