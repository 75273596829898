import React, { useEffect, useState } from "react";
import { Icon } from "../Icon/Icon";

const Ratings = ({ rating }) => {
  const [ratings, setRatings] = useState(Array(5).fill("KDR-Ratings-NR"));

  useEffect(() => {
    if (rating % 1 === 0) {
      if (rating === 5) {
        setRatings(Array(5).fill("KDR-Ratings-S"));
      } else {
        setRatings([
          ...Array(rating).fill("KDR-Ratings-S"),
          ...Array(5 - rating).fill("KDR-Ratings-NR"),
        ]);
      }
    }
    else{
        const baseValue = parseInt(rating)
        setRatings([
            ...Array(baseValue).fill("KDR-Ratings-S"),
            ...Array(1).fill("KDR-Ratings-HS")
        ])
    }
  }, [rating]);

  return (
    <div className="flex justify-center my-4">
      {ratings.map((singleIcon, i) => (
        <Icon
          classes={`w-6 h-6 ${i !== 0 && "ml-2"}`}
          key={i}
          iconName={singleIcon}
        />
      ))}
    </div>
  );
};

export default Ratings;
