import React, { useState } from "react";

const FormSelectFields = ({
  svg,
  labelName,
  selectCodeField,
  data,
  required,
  name,
  multiple,
}) => {
  let [inputValue, setInputValue] = useState("");
  let [labelGoTop, setLabelGoTop] = useState(false);

  const onFocusHandler = () => {
    setLabelGoTop(true);
  };

  const selectHandler = (e) => {
    setInputValue(e.target.value);
  };

  const notFocusHandler = () => {
    inputValue === "" ? setLabelGoTop(false) : setLabelGoTop(true);
  };

  return (
    <div
      className={`relative flex justify-center items-center pt-10 pb-2 border-b-2 ${
        labelGoTop ? " border-main-blue" : "border-gray-300"
      }`}
    >
      {svg}
      <div
        className="relative ml-4"
        style={{
          width: "100%",
        }}
      >
        <select
          aria-required="true"
          aria-invalid="false"
          id={selectCodeField}
          onFocus={onFocusHandler}
          onBlur={notFocusHandler}
          onChange={selectHandler}
          className="w-full text-black"
          required={required}
          name={name}
          multiple={multiple}
        >
          {Object.keys(data).map((el, i) => (
            <option key={`${el}${i}`} value={data[el]}>
              {data[el]}
            </option>
          ))}
        </select>
        { labelName && (
          <label
            htmlFor={selectCodeField}
            className={`absolute left-0 uppercase ${
              labelGoTop ? "top-20 text-xs" : "top-0 text-sm"
            } transition-all duration-200 ease-linear`}
            style={{
              color: "#486e90",
            }}
          >
            {labelName}*
          </label>
        )}
      </div>
    </div>
  );
};

export default FormSelectFields;
