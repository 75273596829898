export const verticalTabData = [
  {
    id: 1,
    uniqueId: "on-board-1",
    iconName: "user-add",
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "On boarding1",
        description:
          "Personalized as per the demands of the customer. An interesting and simple app walkthrough is encouraged. From approval to authentication,",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
      },
      service_provider: {
        heading: "On boarding",
        description: "Smoother and seamless on ",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
      },
    },
  },
  {
    id: 2,
    uniqueId: "on-board-2",
    iconName: "ticket",
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "On boarding2",
        description: "Personalized as",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
      },
      service_provider: {
        heading: "On boarding",
        description:
          "Smoother and seamless on boarding for service provider is taken care on the set. Service provider can easily connect with.",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
      },
    },
  },
  {
    id: 3,
    uniqueId: "on-board-3",
    iconName: "tick",
    imgFolder: "solution",
    imgName: "app.png",
    data: {
      customer: {
        heading: "On boarding3",
        description: "Personalized as per the demands of the customer. ",
        points: [
          "Introduction",
          "Authentication",
          "Personal Info",
          "Verification",
        ],
      },
    },
  },
];

export const typeOneBannerData = {
  text1: "Top on-demand technologies implemented",
  text2:
    "For the best service deliverables, each on-demand project at Kdr is built with a technical edge using all modern and prime tools and frameworks.",
};

export const typeThreeBannerData = {
  semiHeading: "FAST & EFFICIENT ACCESS TO SERVICES",
  heading: "Building promising relationships",
  para:
    "We will help you find out how to supply the rightest as per user demands, which is sure to make them wonder that how did they survive without your app all these days. We develop to meet macro-level and micro-level delivery requirements of users across all industries.",
  buttonDetails: {
    buttonScrollId: "#workWithUs",
    buttonText: "Drop us a line",
  },
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "solutions/solution",
    imageName: "ondemand.jpg",
    imageText2: "Disrupting on-demand economy with addictive app solutions",
    imageText3: "WE BUILD INFINITE POSSIBLE SOLUTIONS ON A MOBILE TAP",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get estimation for your project",
    },
  },
};

export const multiRowData = {
  text1: "PERFECT TECHNOLOGY PARTNER TO BUILD YOUR CLASSIFIED APP",
  text2: "Next generation on-demand app development services",
  text3:
    "As one of the leading on-demand app developers hailing from India, Kdr understands that the way your business respond to customer's demands of 'being available instantly as, when and where they need' will significantly influence your future success. Our on-demand mobility service model will help your startup or enterprise to innovate faster and deliver matchless services to customers across all industries and businesses.",
  text4: "Next generation on-demand app development services",
  carouselData: [
    {
      iconName: "user-add",
      label: "User Add1",
    },
    {
      iconName: "user-add",
      label: "User Add2",
    },
    {
      iconName: "user-add",
      label: "User Ad3",
    },
    {
      iconName: "user-add",
      label: "User Add4",
    },
    {
      iconName: "user-add",
      label: "User Add5",
    },
    {
      iconName: "user-add",
      label: "User Add6",
    },
  ],
};

export const stackUsedData = {
  stackData: [
    {
      name: "Programming Language",
      logo: "coding",
      techUsed: [
        {
          techName: "html",
          techLogo: "html.png",
        },
        {
          techName: "html",
          techLogo: "html.png",
        },
        {
          techName: "html",
          techLogo: "html.png",
        },
      ],
    },
    {
      name: "API Language",
      logo: "server",
      techUsed: [
        {
          techName: "html",
          techLogo: "html.png",
        },
      ],
    },
    {
      name: "Protocol",
      logo: "database-icon",
      techUsed: [
        {
          techName: "html",
          techLogo: "html.png",
        },
      ],
    },
    {
      name: "Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "html",
          techLogo: "html.png",
        },
        {
          techName: "html",
          techLogo: "html.png",
        },
      ],
    },
  ],
};

export const stackEmployedData = {
  text1: "Top IM solution technologies to build a reliable, quality product",
  text2: "Some of the languages, frameworks, backend server, tools used by us",
  techData: [
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
    {
      logo: "html.png",
      name: "HTML",
      tag: "chat",
    },
  ],
};
