const firstColumnobject = {
  "Mobile Application Development": ["iOS", "Android", "PWA"],
  "Cross Platform App Development": ["React Native", "Flutter", "Ionic"],
  "App Prototype and Strategy": ["UI & UX Designing"],
};
const secondColumnObject = {
  "Web Development": ["PHP", ".NET", "JAVA"],
  "Trending JS Framework": ["AngularJS", "ReactJS", "NodeJS"],
  "E-Commerce & CMS": ["Magento", "WordPress"],
};

const thirdColumn = [
  "Product Engineering",
  "DevOps",
  "QA & Testing",
  "Consulting Services",
  "Staff Augmentation & Dedicated Teams",
  // "Marketing & Advertising",
];

const fourthcolumn = [
  "Wearable App Development",
  "Cloud Computing",
  "IoT Development",
  "Software Maintenance & Support",
  "Opensource Development",
];

export const names = {
  firstColumnobject,
  secondColumnObject,
  thirdColumn,
  fourthcolumn,
};

export const notALink = ["app-prototype-and-strategy", "trending-js-framework"];
