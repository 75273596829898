import React from "react";
import { Link } from "react-router-dom";

export const MainNavWithLinks = ({
  toggleWhyKDR,
  toggleService,
  toggleSolution,
}) => {
  return (
    <nav className="flex flex-row mt-2">
      <button
        onClick={toggleWhyKDR}
        className="flex font-e mr-4 px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700"
      >
        <span>Why kdr</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      <button
        onClick={toggleService}
        className="flex mr-4 px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700"
      >
        <span>Services</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      <button
        onClick={toggleSolution}
        className="flex mr-4 px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700"
      >
        <span>solutions</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      <Link to="portfolios" className="flex mr-4 px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700">
        work
      </Link>
      <Link  to="resources" className="flex mr-4 px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700">
        resources
      </Link>
      <Link
        className="flex px-5 py-2 uppercase rounded-md text-sm font-extrabold text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700"
        to="/contact-us"
      >
        Contact
      </Link>
    </nav>
  );
};
