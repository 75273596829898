export const dominateData = {
  heading: "Complete web & mobility solutions for B2B & B2C retail",
  para:
    "Trust takes time to build, but done right, your business will have a greater opportunity to build a strong customer base in the region. With our unparalleled mobile application development and competence in E-commerce, we cater to every customer requirements by personalizing each buying experience and providing better quality of customer services - both online and on mobile. Book a one-on-one interaction with our experts now!",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "While a majority of manufacturers sought the best product, they also need to find the best way to engage their users.Without a platform, they will not be able to sell their outstanding product. This is where we come in.",
  text2:
    "At Kdr, we provide scalable online commerce solutions across all electronic platforms. Leveraging our experience in top-notch quality design and development, the team is motivated to build projects to promote online shopping to B2B and B2C ventures so that they are able to sell their best products on the right platforms.",
};

export const challengesData = {
  heading: "Challenges in retail and e-commerce",
  description:
    "INSTANT ACCESS TO PRODUCTS AND BRANDS FOR CONSUMERS AT HOME, IN OFFICE OR ON-THE-MOVE",
  para:
    "As everything in retail revolves around consumer, from product development to providing a service to after sales support, there are a number of challenges that most retailers face today.",
  details: [
    {
      label: "Be Relevant To Customer",
      content:
        "The customers buying online has become digitally mature and understand the difference between what’s meaningful to them and what’s just an advertising trick. The challenge for retailers is how to give exactly what’s relevant to the user and avoid the irrelevant to ensure consumer loyalty for the product and services.",
    },
    {
      label: "Amazon",
      content:
        "E-commerce giant, Amazon, has become a threat for every retailer planning to start an online store. In an online survey conducted in North America, they discovered that between 2009 and 2011, the number of customers beginning their research for an online purchase on Google dropped from 24% to 13% whereas Amazon saw staggering growth from 17% to 30%. To think of ways to constantly compete and work under a premise that Amazon eventually will, is challenging.",
    },
    {
      label: "Ominchannel Dilemma",
      content:
        "Another concern is Omni-commerce experience wherein e-Tailers are looking to maximize the inventory they have and to ensure that it is accessible via all selling channels and customer engagement points. Many of them are leveraging technology that needs to be updated and replaced with novel and advanced tools to keep up with customer’s changing demands.",
    },
    {
      label: "Targeting Millennials",
      content:
        "Gen ‘Y’ is the demographic generation of consumers that are digitally native and are fast becoming the most influential group of consumers. The challenge is to delight the informed and technology-driven millennials who are looking for highest quality standards and utmost level of convenience.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "Gen ‘Y’ is the demographic generation of consumers that are digitally native and are fast becoming the most influential group of consumers. The challenge is to delight the informed and technology-driven millennials who are looking for highest quality standards and utmost level of convenience.",
  details: [
    {
      label: "Holistic Customer Experience",
      content:
        "With this mindset, we deploy a real-time model across all channels to enhance the customer experience. Our experts focus on creating a single, unified commerce platform, which eliminates individual channel silos and offers a holistic customer experience across all touch points.",
    },
    {
      label: "Advanced Tools",
      content:
        "The most modern tools and technologies are used to build eCommerce solutions. Magento, Shopify, WooCommerce, Opencart are the tools we have been using since their inception. We have successfully migrated to Magento 2.0 from Magento 1.0 and developed useful and innovative extensions on Magento 2.",
    },
    {
      label: "Point of Sale Security and Payments",
      content:
        "Our solutions are aimed at securing the POS and all customer data from breach- whether on website or mobile app. As data security is high on everyone’s list of concerns, we ensure using trustworthy payment options that are cost-effective for the clients and provide 100% customer satisfaction and customer loyalty.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Web & Mobile Booking Systems",
        text2: "#2. Cloud Mobility Solutions",
        text3: "#3. Integration with existing enterprise services & data",
      },
      {
        text1: "#4. Enterprise Mobility",
        text2: "#5. Cross-platform accommodation/support",
        text3: "#6. m-Commerce, B2B & B2C",
      },
      {
        text1: "#7. Application Maintenance Services",
        text2: "#8. Product Stock Management & Tracking",
        text3: "#9. Any custom application development",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
