import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "ionic.png",
    imageText2: "Ionic App Development Service",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeThreeBannerData = {
  heading: "Why Choose kdr for Ionic App Development?",
  details: [
    {
      iconName: `${companyName}-Ionic-ADL`,
      label: "App Development Life Cycle",
      content:
        "Every project follows software life cycle and Agile methodology.",
    },
    {
      iconName: `${companyName}-Ionic-EA`,
      label: "Enjoyable Apps",
      content: "kdr has been creating apps and website that users enjoy.",
    },
    {
      iconName: `${companyName}-Ionic-WSU`,
      label: "We stay Updated",
      content:
        "Our ionic app development team stays updated with the latest features and skills.",
    },
    {
      iconName: `${companyName}-Ionic-AA`,
      label: "Analytical Approach",
      content: "We follow the analytical and result-driven approach.",
    },
    {
      iconName: `${companyName}-Ionic-SAM`,
      label: "Support and Maintenance",
      content:
        "It’s our guarantee to provide all the support and maintenance required by the clients after deployment.",
    },
    {
      iconName: `${companyName}-Ionic-M`,
      label: "Migration",
      content:
        "We have app migration services to migrate native mobile apps on the hybrid platform.",
    },
    {
      label: "Effective Communication",
      iconName: `${companyName}-Ionic-EC`,
      content:
        "We have highly skilled people who keep clients informed about every stage of development.",
    },
    {
      iconName: `${companyName}-Ionic-E`,
      label: "Experience",
      content:
        "kdr has created 100+ projects and has 3+ years of experience in Hybrid mobile app development. We are experienced in 3rd-party framework integration, web services, and social integration.",
    },
    {
      iconName: `${companyName}-Ionic-I`,
      label: "Hire an Ionic Developer",
      content:
        "kdr has 35+ years of collective experience in web and Mobile App development along with a consortium of experts in various technologies who have lots of a platter to showcase. Have a look at our ionic development portfolio to before hiring an ionic developer from us.",
    },
  ],
};

export const topSecondTypeThreeBannerData = {
  heading: "Ionic App Development Company",
  para:
    "kdr is an ionic app development company who have been serious developing ionic applications since 6+ years. Having built more than 50 projects, we have to work with all associated technologies like JavaScript, HTML, CSS, AngularJS. Ionic is an open-source framework for ionic mobile app development that is used with Cordova and PhoneGap as wrapper along with the support of Angular.JS. The ionic framework is thus useful for creating rich applications that appeal at first look. These apps are curated for both native and web platforms.",
  para2:
    "We have an experient flock of Ionic Framework who are adept with the latest technological innovations and all tricks and hacks in app development process and methodologies to craft high-performing ionic app solution for organizations of all sizes. kdr follows best coding practices and has always adhered to rules and policies of various mobile operating systems to create feature-rich, customized cross-platform applications.",
};
export const appInfographics = {
  heading: "Why Choose Ionic Framework for Building Cross-Platform Apps?",
  infoContent: [
    {
      description:
        "It is a pre-determined belief since the alpha versions of ionic evolved that it is being used to develop an application for all mobile OS (iOS, Android) or web. But do we ever use Ionic to create simple browser extensions? How to manage the updates of PWA? With the mobile-first approach in place at the ionic inception, it didn't suit well to larger displays of laptops and desktops.Content and navigation could not be aligned properly leaving a lot of white space on the screen, giving developers a feeling of a maximized version of Android rather than a website.Ionic is the topmost development framework to create hybrid mobile applications that focus on UI/UX of the application, focus on interaction with user thus making it incredible.",
      imageFolder: "ionic",
      imageName: "ionic.svg",
    },
  ],
};
export const serviceOfferedTypeTwo = {
  heading: "Why choose kdr for React Native App Development Services?",
  description:
    "Hire React Native developers with vast experience from kdr who are passionate about their React Native Applications. Our React native developers provide the best possible solutions to satisfy the most urgent needs of the businesses in marketing their products and services in no time. Here are some key reasons to hire our React Native developers for building state-of-art successful mobile applications:",
  data: [
    "100% Client Satisfaction",
    "Expertise In React Native Development",
    "On-Time Project Delivery",
    "Quality Services",
    "Immediate Support and Maintenance",
    "Hire Dedicated Developers (Hourly, Fixed Price)",
  ],
};
