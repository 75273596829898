export const dominateData = {
  heading: "Tech & travel redefined with mobile",
  para:
    "With the increased penetration of mobile devices, travelers have a reliable companion that guides them with information on routes, places to visit, connect with locals, weather forecasts and much more. You can hire our Travel & Hospitality Expert Developers for your development job. We’ll listen to your needs, ask questions and provide actionable insights.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Kdr’s travel and hospitality industry experience provides robust web and mobile solutions to develop, manage and upgrade business processes in the industry, thereby contributing in your business gains.",
  text2:
    "We are committed to helping our clients in surviving various challenges like business expansion and utilizing emerging technology to innovate their business. We help them gain a competitive edge through convenient booking methods, providing guests with all the necessary information before they arrive, quick hotel-check-ins, upsell in-hotel services during their stay and follow up with them to create lasting customer relationships.",
};

export const challengesData = {
  heading: "Challenges in online travel & hospitality",
  description: "MOBILE FUELS THE ONLINE TRAVEL AGENCIES",
  details: [
    {
      label: "Constantly-Changing Competitive Landscape",
      content:
        "The next few years will see a significant change in the online travel, tourism and hotel distribution competitive environment with the rise in mobile apps, big data, in-destination services and emergence of new players in the market coming up with innovative ventures.",
    },
    {
      label: "Evolving Customer Expectations",
      content:
        "Customers are increasingly sophisticated and smart in their use of technology to research and select a particular brand. As a result, the development and marketing approach needs to be adopted and updated to effectively reach the customer.",
    },
    {
      label: "Big Data",
      content:
        "To truly understand customer’s needs and deliver outstanding experiences, travel and hospitality companies should be able to access and leverage the right information at the right time, which is a challenging task. New technologies are generating more data than ever before.",
    },
    {
      label: "Escalating Operating Costs",
      content:
        "One of the major concerns faced by hospitality groups is the continuous rise in operating costs on consumer travel and hotel demand. For that, efficient inventory management and resource utilization becomes difficult.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "At Kdr, we understand the potential of online services and implement them successfully for travel and hospitality, leading this sector in terms of innovation and business growth. From inventory management to API integration to customer relationship management, we do it all.",
  details: [
    {
      label: "Personalized Experience",
      content:
        "To deal with customer demands and expectations, we craft value proposition solutions that provide an outstanding, individualized experience. The business analysis, project management, design and development teams work towards enhancing the search and booking options via personalized offers and intuitive user experience not only on native, but also through hybrid mobile apps. We build solutions that personalize the entire journey, not just a segment of it. We incorporate location-based services, schedule disruptions, options of in-journey merchandizing and more.",
    },
    {
      label: "Insight-rich solutions",
      content:
        "We provide a competitive advantage to businesses to wisely utilize the big data generated online. We work for an online (web/mobile app) solution that access and capture meaningful data and let customers enjoy a seamless booking, check-in, traveling experience across multiple devices with the same performance. We implement most impressive and easy-to-go referral functionality to increase traffic and sales.",
    },
    {
      label: "Automated experience  ",
      content:
        "Automating inventory management, data capture, navigation, payment system, check-ins and booking, we help our clients reduce the overall energy and labor costs in the T&H industry. We have been catering to many travel startups and tourism enterprises with custom solutions that helped them earn customer loyalty, high ROI and profits in their business.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Web & Mobile Booking Systems",
        text2: "#2. Inventory & Procurement Management",
        text3: "#3. Payment Systems Integration",
      },
      {
        text1: "#4. Travel Process re-engineering",
        text2: "#5. Navigation Systems",
        text3: "#6. Transaction Processing Systems",
      },
      {
        text1: "#7. Pre & Post-trip Management Applications",
        text2: "#8. Customer Relationship Management",
        text3: "#9. Legacy Modernizations",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
