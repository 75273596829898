import React from "react";

export const PointsSection = ({ data }) => {
  return (
    <div className="pt-16 pb-2 px-4 lg:px-2 container mx-auto">
      <p className="text-sm md:text-lg leading-10 mb-6">{data.para}</p>
      <div>
        <p className="text-lg mb-2 font-bold">{data.data.heading}</p>
        <div className="grid grid-flow-row grid-cols-2 lg:grid-cols-4 gap-2 mt-4">
          {data.data.points.map((el,i) => (
            <div className="flex items-center" key={i}>
              <span className="w-2 h-2 rounded-full bg-blue-600 inline-block flex-shrink-0"></span>
              <p className="ml-4">{el}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
