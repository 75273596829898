import React from "react";
import { CardTypeFour } from "../Card/CardTypeFour/CardTypeFour";

export const Type14Banner = ({ data }) => {
  return (
    <div className="py-16 px-4 xl:px-0 text-center">
      <div className="container mx-auto">
        {data.heading && <p data-aos="fade-in" className="text-4xl">{data.heading}</p>}
        {data.description && <p data-aos="fade-in" className="uppercase text-base text-gray-600">{data.description}</p>}
        {data.cardDetails && (
          <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-3 gap-6 mt-16">
            {data.cardDetails.map((el, i) => (
              <CardTypeFour data={el} key={i} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
