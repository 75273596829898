import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "itcons.png",
    imageText2: "IT consulting services",
    imageText3:
      "PROVIDING THE BEST TECHNOLOGY AND BUSINESS IT CONSULTING SERVICES",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Contact Our Experts",
    },
  },
};

export const multiRowData = {
  text1: "OUR BUSINESS IS TO TAKE CARE OF YOUR BUSINESS",
  text2: "We transform your business through acute consulting talent",
  text3:
    "Our team stress-tests every project that comes to the kdr’s doors. From novel, raw ideas to full-grown concepts, we want to know and tell you why your idea pans out and how it’s going to become user’s favorite. This is where our expert analysts, strategists, project managers, designers, developers come together to build a top-notch digital product for you and overhaul the business plan- be it an enterprise software, a business website or a mobile application. ",
  text4: "The various consulting solutions that we provide include:",
  carouselData: [
    {
      iconName: `${companyName}-consulting-services-IA`,
      label: "Idea Assessment",
    },
    {
      iconName: `${companyName}-consulting-services-CB`,
      label: "Cognitive Business",
    },
    {
      iconName: `${companyName}-consulting-services-MC`,
      label: "Mobile Consulting",
    },
    {
      iconName: `${companyName}-consulting-services-EA`,
      label: "Enterprise Applications",
    },
    {
      iconName: `${companyName}-consulting-services-BPS`,
      label: "Business Process Services",
    },
    {
      iconName: `${companyName}-consulting-services-AIC`,
      label: "App Innovation Consulting",
    },
    {
      iconName: `${companyName}-consulting-services-BDA`,
      label: "Big Data Analytics",
    },
    {
      iconName: `${companyName}-consulting-services-CBS`,
      label: "Cloud Business Solutions",
    },
    {
      iconName: `${companyName}-consulting-services-DTS`,
      label: "Design Thinking Strategy",
    },
    {
      iconName: `${companyName}-consulting-services-TA`,
      label: "Technology Architecture",
    },
    {
      iconName: `${companyName}-consulting-services-PS`,
      label: "Product Strategy",
    },
    {
      iconName: `${companyName}-consulting-services-BRC`,
      label: "Budget & Risk Consulting",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Let's Connect",
};

export const topTypeThreeBannerData = {
  heading: "Helping Startups and Enterprises win in the connected world",
  description:
    "UNLOCK THE FULL CAPABILITIES OF YOUR ORGANIZATION- LET US GUIDE YOU TO THE GREATEST",
  para:
    "Our digital experts help companies across all industries, regions and growth stages to determine not what digital will do to them, but what they will do with digital. We team up with clients to define their vision and operating strategies, adapt their organizations and offerings to meet consumers' changing needs and expectations.",
  para2:
    "With our IT consulting services and support, companies can capture wholly new sources of value. We never restrict ourselves to say whether your idea is viable, businesslike or not. From finding out why your idea makes sense to thinking of a design strategy and technology architecture to innovating your existing business, we offer professional and growth-oriented consulting for an optimum business-IT alignment that generates high revenue and maximum conversions.",
  showButton: true,
  buttonScrollToId: "#workWithUs",
  buttonText: "Get in Touch",
};

export const typeThreeBannerData = {
  heading: "Why rely on kdr’s IT consulting services and experts",
  description: "COMMITTED CONSULTANTS WITH ADAPTIVE INTELLIGENCE",
  details: [
    {
      label: "Consulting linked to implementation",
      iconName: `${companyName}-consulting-services-CLTI`,
      content:
        "We build strategies around real-time, feasible implementation experience that can successfully execute on our own advice.",
    },
    {
      label: "Unmatched cognitive expertise",
      iconName: `${companyName}-consulting-services-UCE`,
      content:
        "Smartest team of analysts and coders that can harness cutting-edge technology and cognitive computing to power digital transformation.",
    },
    {
      label: "Agile, effective management",
      iconName: `${companyName}-consulting-services-AEM`,
      content:
        "Breaking down silos, we work responsively and with agility to enable clients execute their projects at fast pace, ahead of competitors.",
    },
    {
      label: "Aesthetic design thinking",
      iconName: `${companyName}-consulting-services-ADT`,
      content:
        "Along with a design-forward, user-centric process and quick deliveries, our team employs a comprehensive approach while prototyping.",
    },
    {
      label: "Unique perspective",
      iconName: `${companyName}-consulting-services-UP`,
      content:
        "With every new idea coming from the client, our team brings a fresh perspective that simply integrates creativity and technology.",
    },
    {
      label: "Not okay with Okay",
      iconName: `${companyName}-consulting-services-NOWO`,
      content:
        "We never settle for anything fine because we deliver awesome. We see every project with focus, tenacity and a sharp analytical mind and then start over.",
    },
    {
      label: "Insightful & Innovative",
      iconName: `${companyName}-consulting-services-II`,
      content:
        "Our 16+ years of IT experience and skilled team provides the best insights into an idea and create novel, technology-enabled solutions.",
    },
    {
      label: "Powerful Metrics Analysis",
      iconName: `${companyName}-consulting-services-PMA`,
      content:
        "We have the acumen to access real-time customer insights and understand consumer and employee behaviors towards a service.",
    },
  ],
};

export const typeFiveBannerData = {
  text1:
    "Most purposeful enterprise mobility solutions with cloud services revolutionizing the way you do business",
  text2: "IOS, ANDROID, CLOUD, WEARABLES, INTERNET OF THINGS",
  details: [
    {
      label: "Mobile Strategy",
      content:
        "Establish the right strategy to bridge the gap between business requirements and mobile technology that will ultimately benefit your enterprise tasks, your employees and your customers.",
    },
    {
      label: "Development & Delivery",
      content:
        "We use our expert technical knowledge and competence to build resourceful solutions suiting your requirements, helping you mobilize and evolve to accomplish your advanced business goals.",
    },
    {
      label: "Solution Support",
      content:
        "Maintaining long-term relationships with the clients, we provide high-end support for any further queries or if there are any OS upgrade changes, and provide maintenance system.",
    },
  ],
  buttonDetails: {
    buttonText: "Talk to our experts",
    buttonScrollId: "#workWithUs",
  },
};
