import React, { useState } from "react";
import { Navbar } from "../Navbar/Navbar";
import { apps } from "./portFolios.js";
import { Footer } from "../Footer/Footer";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import "./portfolio.css";
import { AppCard } from "../../lib/AppCard/AppCard";
import { LoadMore } from "../../lib/LoadMore/LoadMore";

export const PortFolios = () => {
  let [initialArray, setInitialArray] = useState(apps.slice(0, 8));

  return (
    <div className="flex flex-col">
      <Navbar otherPages={true}/>
      <div className="pt-40 flex flex-col items-center justify-center w-full max-w-4xl mx-auto py-12">
        <p className="italic text-sm md:text-base uppercase mb-1">
          / OUR PORTFOLIO - BREAKTHROUGH PROJECTS /
        </p>
        <p className="font-extrabold text-xl md:text-4xl text-center leading-10 mb-2">
          WE WORK TO INNOVATE & ARE PROUD OF WHAT WE'VE CREATED.
        </p>
        <p className="text-md md:text-xl text-gray-700 text-center">
          YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!
        </p>
      </div>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-6 row-gap-4 md:gap-4 mb-10">
        {initialArray.map((el) => (
          <AppCard
            appId={el.appId}
            category={el.category}
            logo={el.logo}
            name={el.name}
            publishedDate={el.publishedDate}
            screenshot={el.screenshot}
            shortDescription={el.shortDescription}
            shortName={el.shortName}
            tags={el.tags}
            key={el.appId}
          />
        ))}
      </div>
      {apps.length > 8 && (
        <LoadMore
          apps={apps}
          loadMoreText="Load More"
          initialArray={initialArray}
          setInitialArray={setInitialArray}
        />
      )}
      <WorkWithUs />
      <Footer />
    </div>
  );
};
