import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "php.png",
    imageText2: "PHP Web Development we’re experts",
    imageText3: "FAR BEYOND WHAT’S EXPECTED FROM A PHP DEVELOPMENT COMPANY",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get Estimation for your project",
    },
  },
};

export const multiRowData = {
  text1: "FOR STARTUPS, ENTERPRISES & CONSUMERS",
  text2: "Scalable, interactive & custom PHP solutions on your way",
  text3:
    "Do you dream of having a popular website like Facebook, Wikipedia, Yahoo!, Flickr, Tumblr? They are all built on PHP. kdr, renowned as the top-level PHP web development company, offers a wide range of high-quality PHP development services.",
  carouselData: [
    {
      iconName: `${companyName}-php-CA`,
      label: "Custom Applications",
    },
    {
      iconName: `${companyName}-php-DW`,
      label: "Dynamic Website",
    },
    {
      iconName: `${companyName}-php-CD`,
      label: "CMS Development",
    },
    {
      iconName: `${companyName}-php-PBEC`,
      label: "PHP based E-commerce",
    },
    {
      iconName: `${companyName}-php-AI`,
      label: "API Integration",
    },
    {
      iconName: `${companyName}-php-C`,
      label: "Cloud (SaaS, PaaS)",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Get a Free Quote",
};

export const stackUsedData = {
  stackData: [
    {
      name: "PHP Versions",
      logo: `${companyName}-php-PV`,
      techUsed: [
        {
          techName: "PHP4.X",
          techLogo: "php4x.png",
        },
        {
          techName: "PHP5.X",
          techLogo: "php5x.png",
        },
        {
          techName: "PHP7.X",
          techLogo: "php7x.png",
        },
      ],
    },
    {
      name: "Frameworks",
      logo: "database-icon",
      techUsed: [
        {
          techName: "CakePHP",
          techLogo: "cake-php.png",
        },
        {
          techName: "Yii",
          techLogo: "yii.png",
        },
        {
          techName: "Zend",
          techLogo: "zend.png",
        },
        {
          techName: "Codeigniter",
          techLogo: "codeigniter.png",
        },
        {
          techName: "Symfony",
          techLogo: "symfony.png",
        },
        {
          techName: "Laravel",
          techLogo: "laravel.png",
        },
      ],
    },
    {
      name: "Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "MySQL 4.X",
          techLogo: "mysql.png",
        },
        {
          techName: "MySQL 5.X",
          techLogo: "mysql5x.png",
        },
        {
          techName: "MongoDB (NoSQL)",
          techLogo: "mysql5x.png",
        },
      ],
    },
    {
      name: "CMS",
      logo: `${companyName}-php-CD`,
      techUsed: [
        {
          techName: "Magento",
          techLogo: "magento.png",
        },
        {
          techName: "WordPress",
          techLogo: "wordPress.png",
        },
        {
          techName: "Drupal",
          techLogo: "drupal.png",
        },
      ],
    },
    {
      name: "Front End",
      logo: `${companyName}-php-FE`,
      techUsed: [
        {
          techName: "HTML5",
          techLogo: "html.png",
        },
        {
          techName: "CSS3",
          techLogo: "css.png",
        },
        {
          techName: "JavaScript",
          techLogo: "js.png",
        },
      ],
    },
    {
      name: "Web Services",
      logo: `${companyName}-php-WS`,
      techUsed: [
        {
          techName: "SOAP",
          techLogo: "soap.png",
        },
        {
          techName: "XML",
          techLogo: "xml.png",
        },
        {
          techName: "JSON",
          techLogo: "json.png",
        },
        {
          techName: "AJAX",
          techLogo: "ajax.png",
        },
      ],
    },
    {
      name: "Js Libraries",
      logo: `${companyName}-php-JL`,
      techUsed: [
        {
          techName: "jQuery",
          techLogo: "jQuery.png",
        },
        {
          techName: "Prototype JS",
          techLogo: "prototypejs.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "dashboard.jpg",
};

export const typeFourBannerData = {
  heading: "We are a top-ranked PHP development company",
  text1:
    "kdrPHP developers have a technical breadth and experience of developing fully customizable websites in PHP. We have helped startups, enterprises and business providers to launch their projects as per latest PHP application development standards and trends.",
  description: "OFFERING PREMIER PHP WEB APPLICATION DEVELOPMENT SERVICES",
};

export const resultInfo = {
  text1: "We generate outstanding results",
  text2:
    "Most enterprises have transformed their working with our solutions and services",
  content: [
    {
      label: "Total Website Developed By kdr Team",
      value: "1K+",
      color: "green",
    },
    {
      label: "Our Happy Clients",
      value: "100+",
      color: "indigo",
    },
    {
      label: "Total Number of Employees",
      value: "50+",
      color: "red",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "We stand out for 90% client satisfaction",
  description: "HERE'RE REASONS WHY HUNDREDS OF INNOVATIVE BUSINESSES TRUST US",
  details: [
    {
      label: "Dedicated PHP Developers",
      content:
        "You can select from a pool of over 35 qualified and skilled PHP developers capable of exceeding all performance expectations.",
      iconName: `${companyName}-php-PV`,
    },
    {
      label: "Quick Turnaround Time",
      content:
        "No working hours are wasted in lengthy discussions and communicating with the team-process cycle is completed before time.",
      iconName: `${companyName}-php-QTT`,
    },
    {
      label: "20% Average Cost Saving",
      content:
        "With us, you are sure to save on development costs as processes are sorted and no hidden costs come as a surprise.",
      iconName: `${companyName}-php-2ACS`,
    },
    {
      label: "Core Competence",
      content:
        "PHP is our core competence and our business analysts lay down a structured strategy for your PHP project.",
      iconName: `${companyName}-php-CC`,
    },
    {
      label: "Agile Methodology",
      content:
        "All PHP coordinators are well equipped with scrum calling ensuring effective project management.",
      iconName: `${companyName}-php-AM`,
    },
    {
      label: "Cohesive Environment",
      content:
        "A congenial and close-knit working environment keep our developers motivated throughout the project development.",
      iconName: `${companyName}-php-CE`,
    },
    {
      label: "Strong Skillset",
      content:
        "With certified developers in different frameworks like Zend, Laravel, we have a strong skillset for doing most complex projects.",
      iconName: `${companyName}-php-SS`,
    },
    {
      label: "Adaptive to new versions and technologies",
      content:
        "We keep you ahead of the technology curve allowing you solutions on latest technologies.",
      iconName: `${companyName}-php-ATNVAT`,
    },
  ],
};
