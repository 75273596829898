import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toast";

import App from "./App";
import "./assets/css/main.css";
import { Careers } from "./components/Careers/Careers";
import { ContactPage } from "./components/ContactPage/ContactPage.jsx";
import { Enterprise } from "./components/Enterprise/Enterprise";
import { PortFolios } from "./components/PortFolios/PortFolios.jsx";
import { RedirectComponent } from "./components/RedirectComponent/RedirectComponent";
import { RequestQuote } from "./components/RequestQuote/RequestQuote.jsx";
import { ResourcesPage } from "./components/ResourcesPage/ResourcesPage.jsx";
import { StartUp } from "./components/StartUp/StartUp.jsx";
import { Testimonials } from "./components/Testimonials/Testimonials.jsx";
import "./index.css";
import { AppDetails } from "./lib/AppDetails/AppDetails.jsx";
import * as serviceWorker from "./serviceWorker";

import AOS from "aos";
import "aos/dist/aos.css";
import { ProgressBar } from "./lib/ProgressBar/ProgressBar";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import { RefundPolicy } from "./components/RefundPolicy/refundPolicyData";

import { TermsNCondition } from "./components/TermsNCondition/TermsNCondition";
import { Disclaimer } from "./components/Disclaimer/Disclaimer";
import Sitemap from "./components/Sitemap/Sitemap";
import { BecomeAPartner } from "./components/BecomeAPartner/BecomeAPartner";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import CookiesBanner from "./components/CookiesBanner/CookiesBanner";
// import PromotionBanner from "./components/PromotionBanner/PromotionBanner";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTopFunction />
      <ProgressBar />
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route exact={true} path="/contact-us">
          <ContactPage />
        </Route>
        <Route exact={true} path="/request-quote">
          <RequestQuote />
        </Route>
        <Route exact={true} path="/resources">
          <ResourcesPage />
        </Route>
        <Route exact={true} path="/portfolios">
          <PortFolios />
        </Route>
        <Route exact={true} path="/portfolios/app/:appId">
          <AppDetails />
        </Route>
        <Route exact={true} path="/careers">
          <Careers />
        </Route>
        <Route exact={true} path="/become-partner">
          <BecomeAPartner />
        </Route>
        <Route exact={true} path="/start-up">
          <StartUp />
        </Route>
        <Route exact={true} path="/enterprise">
          <Enterprise />
        </Route>
        <Route exact={true} path="/testimonials">
          <Testimonials />
        </Route>
        <Route exact={true} path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact={true} path="/refund-policy">
          <RefundPolicy />
        </Route>
        <Route exact={true} path="/terms-conditions">
          <TermsNCondition />
        </Route>
        <Route exact={true} path="/disclaimer">
          <Disclaimer />
        </Route>
        <Route exact={true} path="/sitemap">
          <Sitemap />
        </Route>
        <Route path="/:url">
          <RedirectComponent />
        </Route>
      </Switch>
      {/* For Promotional Banner */}
      {/* <PromotionBanner /> */}
      <ScrollToTop />
      <ToastContainer delay="6000" position="bottom-left" />
      <CookiesBanner />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

export default function ScrollToTopFunction() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Enable at the end of the project
  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      useClassNames: false,
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
    });

    window.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    return () =>
      window.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  return null;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
