import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import logo from "../../assets/Images/KDR-WHITE.png";
import { RqAndMenu } from "./RqAndMenu";
import { MainNavWithLinks } from "./MainNavWithLinks";
import { AdditionalNav } from "./AdditionalNav";
import { names as whyNames } from "./Data/whyKdr";
import { names as serviceNames, notALink } from "./Data/services";
import { names as solutionNames } from "./Data/solutions";
import { Link } from "react-router-dom";

export const Navbar = ({ otherPages, transparent }) => {
  const [toggleWhy, setToggleWhy] = useState(false);

  const [toggleServices, setToggleServices] = useState(false);

  const [toggleSolutions, setToggleSolutions] = useState(false);

  const [toggleHamburger, setToggleHamburger] = useState(false);

  const [mobileToggleWhy, setMobileToggleWhy] = useState(false);
  const [mobileToggleService, setMobileToggleService] = useState(false);
  const [mobileToggleSolution, setMobileToggleSolution] = useState(false);

  const toggleWhyKDR = () => {
    setToggleWhy((prevState) => !prevState);
  };

  const toggleService = () => {
    setToggleServices((prevState) => !prevState);
  };

  const toggleSolution = () => {
    setToggleSolutions((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setToggleHamburger((prevState) => !prevState);
  };

  const [prevScroll, setPrevScroll] = useState(window.pageYOffset);

  function scrollFunction() {
    const navBar = document.getElementById("headerSection");
    let currentScrollPos = window.pageYOffset;
    setPrevScroll(currentScrollPos);
    if (this.scrollY > 100) {
      if (otherPages) {
        navBar.style.background = "#262626";
      } else {
        // navBar.style.background = "linear-gradient(225deg, #0080d6, #00dda1)";
        navBar.style.background = "#1a202c";
      }
      if (prevScroll > currentScrollPos) {
        navBar.style.display = "flex";
      } else {
        navBar.style.display = "none";
      }
    } else {
      if (transparent) {
        navBar.style.background = "none";
      } else {
        // navBar.style.background = "linear-gradient(225deg, #0080d6, #00dda1)";
        navBar.style.background = "#1a202c";
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);
    return () => window.removeEventListener("scroll", scrollFunction);
  });

  return (
    <>
      <header
        data-aos="fade-down"
        className={`${
          transparent ? "bg-transparent" : "bg-navbarColor"
        } fixed z-10 px-10 py-1 w-full flex justify-between items-center text-white`}
        id="headerSection"
      >
        <div className="flex-shrink-0">
          <Link to="/">
            <img className="w-20 md:w-24" src={logo} alt="kdr logo" />
          </Link>
        </div>
        <div className="hidden mr-4 xl:flex xl:flex-1 justify-end">
          <nav className="flex flex-col items-end">
            {/* First Nav which includes mail to and call button */}
            <AdditionalNav />
            {/* End of first nav */}
            {/* Start of second nav which includes main links */}
            <MainNavWithLinks
              toggleWhyKDR={toggleWhyKDR}
              toggleService={toggleService}
              toggleSolution={toggleSolution}
            />
            {/* End of second nav which includes main links */}
          </nav>
        </div>
        <RqAndMenu
          otherPages={otherPages}
          toggleMenu={toggleMenu}
          toggleHamburger={toggleHamburger}
        />
      </header>

      {/* Toggle why navlink */}
      {toggleWhy && (
        <>
          <div
            className="fixed z-20 w-full h-screen bg-gray-900 bg-opacity-75 max-h-screen"
            onClick={() => {
              setToggleWhy((prevState) => !prevState);
            }}
          ></div>
          <div
            className="fixed w-full z-20 text-white flex justify-center items-center py-12"
            style={{ top: "80px", backgroundColor: "rgba(12,90,165,.95)" }}
          >
            <div className="flex flex-col justify-center text-gray-400 items-start w-48">
              <Link
                to="careers"
                onClick={() => {
                  setToggleWhy((prevState) => !prevState);
                }}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md uppercase w-full"
              >
                Join our team
              </Link>
              <Link
                to="become-partner"
                onClick={() => {
                  setToggleWhy((prevState) => !prevState);
                }}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md mt-3 uppercase w-full"
              >
                Become a partner
              </Link>
            </div>
            <div className="ml-12">
              <p className="text-lg font-bold mb-5 uppercase">Why KDR</p>
              <div className="flex">
                <div className="flex flex-col text-md mr-8">
                  {whyNames.firstColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleWhy((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex flex-col text-md mr-8">
                  {whyNames.secondColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleWhy((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex flex-col items-start justify-start text-md">
                  {whyNames.thirdColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleWhy((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Toggle service navlink */}
      {toggleServices && (
        <>
          <div
            className="fixed w-full z-10 h-screen bg-gray-900 bg-opacity-75 max-h-screen"
            onClick={() => {
              setToggleServices((prevState) => !prevState);
            }}
          ></div>

          <div
            className="fixed w-full z-20 text-white flex justify-center items-center py-12"
            style={{ top: "80px", backgroundColor: "rgba(12,90,165,.95)" }}
          >
            <div className="flex flex-col justify-center text-gray-400 items-start w-48">
              <Link
                to="start-up"
                onClick={() => setToggleServices((prevState) => !prevState)}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent  p-4 text-sm font-bold rounded-md uppercase w-full"
              >
                Startups
              </Link>
              <Link
                to="enterprise"
                onClick={() => setToggleServices((prevState) => !prevState)}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md mt-3 uppercase w-full"
              >
                Enterprise
              </Link>
              {/* <Link
                to="marketing-advertising"
                onClick={() => setToggleServices((prevState) => !prevState)}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md mt-3 uppercase w-full"
              >
                Marketing & Advertising
              </Link> */}
            </div>
            <div className="ml-12">
              <div className="flex">
                <div className="flex flex-col text-md mr-8">
                  <p className="text-lg font-bold mb-5 uppercase">Mobile</p>

                  {Object.entries(serviceNames.firstColumnobject).map(
                    (mainEl) => {
                      return (
                        <div className="flex flex-col" key={mainEl[0]}>
                          {notALink.includes(
                            mainEl[0]
                              .toLowerCase()
                              .replace(/[&,]/g, "")
                              .replace(/\s+/g, "-")
                          ) ? (
                            <span className="border-l-2 border-blue-600 pl-4 hover:border-white px-4 pb-2 pointer-events-none">
                              {mainEl[0]}
                            </span>
                          ) : (
                            <Link
                              to={mainEl[0]
                                .toLowerCase()
                                .replace(/[&,]/g, "")
                                .replace(/\s+/g, "-")}
                              onClick={() =>
                                setToggleServices((prevState) => !prevState)
                              }
                              className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                            >
                              {mainEl[0]}
                            </Link>
                          )}
                          <div className="flex ml-4 mb-2">
                            <span className="flex justify-center items-center">
                              <svg
                                className="h-4 w-4 fill-current"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M7.3 14.7a1 1 0 010-1.4l3.29-3.3-3.3-3.3a1 1 0 011.42-1.4l4 4a1 1 0 010 1.4l-4 4a1 1 0 01-1.42 0z" />
                              </svg>
                            </span>
                            {mainEl[1].map((el) => (
                              <Link
                                to={el
                                  .toLowerCase()
                                  .replace(/[&,]/g, "")
                                  .replace(/\s+/g, "-")}
                                onClick={() =>
                                  setToggleServices((prevState) => !prevState)
                                }
                                className="border-b-2 border-blue-600 hover:border-white cursor-pointer ml-4 text-sm"
                                key={el}
                              >
                                {el}
                              </Link>
                            ))}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="flex flex-col text-md mr-8">
                  <p className="text-lg font-bold mb-5 uppercase">Web</p>
                  {Object.entries(serviceNames.secondColumnObject).map(
                    (mainEl) => {
                      return (
                        <div className="flex flex-col" key={mainEl[0]}>
                          {notALink.includes(
                            mainEl[0]
                              .toLowerCase()
                              .replace(/[&,]/g, "")
                              .replace(/\s+/g, "-")
                          ) ? (
                            <span className="border-l-2 border-blue-600 pl-4 hover:border-white px-4 pb-2 pointer-events-none">
                              {mainEl[0]}
                            </span>
                          ) : (
                            <Link
                              to={mainEl[0]
                                .toLowerCase()
                                .replace(/[&,]/g, "")
                                .replace(/\s+/g, "-")
                                .replace(/\./g, "dot")}
                              onClick={() =>
                                setToggleServices((prevState) => !prevState)
                              }
                              className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                            >
                              {mainEl[0]}
                            </Link>
                          )}
                          <div className="flex ml-4 mb-2">
                            <span className="flex justify-center items-center">
                              <svg
                                className="h-4 w-4 fill-current"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M7.3 14.7a1 1 0 010-1.4l3.29-3.3-3.3-3.3a1 1 0 011.42-1.4l4 4a1 1 0 010 1.4l-4 4a1 1 0 01-1.42 0z" />
                              </svg>
                            </span>
                            {mainEl[1].map((el) => (
                              <Link
                                to={el
                                  .toLowerCase()
                                  .replace(/[&,]/g, "")
                                  .replace(/\s+/g, "-")
                                  .replace(/\./g, "dot")}
                                onClick={() =>
                                  setToggleServices((prevState) => !prevState)
                                }
                                className="border-b-2 border-blue-600 hover:border-white cursor-pointer ml-4 text-sm"
                                key={el}
                              >
                                {el}
                              </Link>
                            ))}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="flex flex-col items-start justify-start text-md">
                  <p className="text-lg font-bold mb-5 uppercase">
                    Other Services
                  </p>

                  {serviceNames.thirdColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() =>
                          setToggleServices((prevState) => !prevState)
                        }
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex flex-col items-start justify-start text-md">
                  <p className="text-lg font-bold mb-5 uppercase">
                    Emerging Technologies
                  </p>

                  {serviceNames.fourthcolumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() =>
                          setToggleServices((prevState) => !prevState)
                        }
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Toggle solutions navlink */}
      {toggleSolutions && (
        <>
          <div
            className="fixed z-10 w-full h-screen bg-gray-900 bg-opacity-75 max-h-screen"
            onClick={() => {
              setToggleSolutions((prevState) => !prevState);
            }}
          ></div>
          <div
            className="fixed w-full z-20 text-white flex justify-center items-center py-12"
            style={{ top: "80px", backgroundColor: "rgba(12,90,165,.95)" }}
          >
            <div className="flex flex-col justify-center text-gray-400 items-start w-48">
              <Link
                to="testimonials"
                onClick={() => {
                  setToggleSolutions((prevState) => !prevState);
                }}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md uppercase w-full"
              >
                What Clients Say
              </Link>
              <Link
                to="case-studies"
                onClick={() => {
                  setToggleSolutions((prevState) => !prevState);
                }}
                className="text-center border-2 border-white hover:bg-blue-800 hover:border-transparent p-4 text-sm font-bold rounded-md mt-3 uppercase w-full"
              >
                Case Studies
              </Link>
            </div>
            <div className="ml-12">
              <div className="flex">
                <div className="flex flex-col text-md mr-8">
                  <p className="text-lg font-bold mb-5 uppercase">Solutions</p>

                  {solutionNames.firstColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleSolutions((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                  {Object.keys(solutionNames.externalLinksForSolutions).map(
                    (el, i) => {
                      return (
                        <a
                          key={el + i}
                          href={solutionNames.externalLinksForSolutions[el]}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        >
                          {el}
                        </a>
                      );
                    }
                  )}
                </div>
                <div className="flex flex-col text-md mr-8">
                  <p className="text-lg font-bold mb-5 uppercase">
                    Industries We Serve
                  </p>

                  {solutionNames.secondColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleSolutions((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
                <div className="flex flex-col items-start justify-start text-md mt-12">
                  {solutionNames.thirdColumn.map((el) => {
                    return (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() => {
                          setToggleSolutions((prevState) => !prevState);
                        }}
                        className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                        key={el}
                      >
                        {el}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {toggleHamburger &&
        ReactDOM.createPortal(
          <MobileMenu
            setToggleHamburger={setToggleHamburger}
            setMobileToggleWhy={setMobileToggleWhy}
            mobileToggleWhy={mobileToggleWhy}
            mobileToggleService={mobileToggleService}
            setMobileToggleService={setMobileToggleService}
            mobileToggleSolution={mobileToggleSolution}
            setMobileToggleSolution={setMobileToggleSolution}
          />,
          document.getElementById("Menu")
        )}
    </>
  );
};

const MobileMenu = ({
  setToggleHamburger,
  setMobileToggleWhy,
  mobileToggleWhy,
  mobileToggleService,
  setMobileToggleService,
  mobileToggleSolution,
  setMobileToggleSolution,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full bg-gray-800 text-white flex flex-col justify-start overflow-auto items-start h-screen max-h-screen `}
      style={{ zIndex: 9999 }}
    >
      <div
        className="fixed bg-blue-700 hover:bg-blue-500 rounded-full p-4 cursor-pointer"
        style={{ bottom: "80px", right: "10px" }}
        onClick={() => setToggleHamburger((prevState) => !prevState)}
      >
        <svg
          className="fill-current w-8 h-8"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M4.3 4.3a1 1 0 011.4 0L10 8.58l4.3-4.3a1 1 0 111.4 1.42L11.42 10l4.3 4.3a1 1 0 01-1.42 1.4L10 11.42l-4.3 4.3a1 1 0 01-1.4-1.42L8.58 10l-4.3-4.3a1 1 0 010-1.4z" />
        </svg>
      </div>
      <Link
        to="/"
        onClick={() => setToggleHamburger((prevState) => !prevState)}
      >
        <img className="w-24 ml-8" src={logo} alt="kdr logo" />
      </Link>
      <button
        className="flex items-center w-full ml-4 pt-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700 cursor-pointer"
        onClick={() => setMobileToggleWhy((prevState) => !prevState)}
      >
        <span>Why kdr</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5.3 7.3a1 1 0 011.4 0l3.3 3.29 3.3-3.3a1 1 0 111.4 1.42l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 010-1.42z" />
          </svg>
        </span>
      </button>
      <div
        className={`${mobileToggleWhy ? "block" : "hidden"} w-full p-10 mt-4`}
        style={{ backgroundColor: "rgba(12,90,165,.95)" }}
      >
        <div className="flex justify-center text-gray-400">
          <Link
            to="/careers"
            onClick={() => setToggleHamburger((prevState) => !prevState)}
            className="text-center border-2 border-white p-4 text-sm font-bold rounded-md uppercase"
          >
            Join our team
          </Link>
          <Link
            to="/become-partner"
            onClick={() => setToggleHamburger((prevState) => !prevState)}
            className="text-center border-2 border-white p-4 text-sm font-bold rounded-md ml-4 uppercase"
          >
            Become a partner
          </Link>
        </div>
        <div className="flex flex-col mt-3">
          <div className="flex flex-col text-md">
            <p className="text-lg font-bold mb-5 uppercase">Why KDR</p>

            {whyNames.firstColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col text-md mt-4">
            {whyNames.secondColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col items-start justify-start text-md mt-4">
            {whyNames.thirdColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="flex items-center w-full ml-4 pt-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700  cursor-pointer"
        onClick={() => setMobileToggleService((prevState) => !prevState)}
      >
        <span>Services</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5.3 7.3a1 1 0 011.4 0l3.3 3.29 3.3-3.3a1 1 0 111.4 1.42l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 010-1.42z" />
          </svg>
        </span>
      </button>
      <div
        className={`${
          mobileToggleService ? "block" : "hidden"
        } w-full p-10 mt-4`}
        style={{ backgroundColor: "rgba(12,90,165,.95)" }}
      >
        <div className="flex justify-center text-gray-400 items-start">
          <Link
            to="/start-up"
            className="border-2 border-white p-4 text-sm font-bold rounded-md uppercase"
          >
            Startups
          </Link>
          <Link
            to="enterprise"
            className="border-2 border-white p-4 text-sm font-bold rounded-md ml-4 uppercase"
          >
            Enterprise
          </Link>
          {/* <Link
            to="marketing-advertising"
            className="border-2 border-white p-4 text-sm font-bold rounded-md ml-4 uppercase"
          >
            Marketing & Advertising
          </Link> */}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col text-md">
            <p className="text-lg font-bold mb-5 uppercase">Mobile</p>
            {Object.entries(serviceNames.firstColumnobject).map((mainEl) => {
              return (
                <div className="flex flex-col" key={mainEl[0]}>
                  <Link
                    to={mainEl[0]
                      .toLowerCase()
                      .replace(/[&,]/g, "")
                      .replace(/\s+/g, "-")}
                    onClick={() =>
                      setToggleHamburger((prevState) => !prevState)
                    }
                    className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  >
                    {mainEl[0]}
                  </Link>
                  <div className="flex ml-4 mb-2">
                    <span className="flex justify-center items-center">
                      <svg
                        className="h-4 w-4 fill-current"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M7.3 14.7a1 1 0 010-1.4l3.29-3.3-3.3-3.3a1 1 0 011.42-1.4l4 4a1 1 0 010 1.4l-4 4a1 1 0 01-1.42 0z" />
                      </svg>
                    </span>
                    {mainEl[1].map((el) => (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")}
                        onClick={() =>
                          setToggleHamburger((prevState) => !prevState)
                        }
                        className="border-b-2 border-blue-600 hover:border-white cursor-pointer ml-4 text-sm"
                        key={el}
                      >
                        {el}
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col text-md mr-8">
            <p className="text-lg font-bold mb-5 uppercase">Web</p>

            {Object.entries(serviceNames.secondColumnObject).map((mainEl) => {
              return (
                <div className="flex flex-col" key={mainEl[0]}>
                  <Link
                    to={mainEl[0]
                      .toLowerCase()
                      .replace(/[&,]/g, "")
                      .replace(/\s+/g, "-")
                      .replace(/\./g, "dot")}
                    onClick={() =>
                      setToggleHamburger((prevState) => !prevState)
                    }
                    className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  >
                    {mainEl[0]}
                  </Link>
                  <div className="flex ml-4 mb-2">
                    <span className="flex justify-center items-center">
                      <svg
                        className="h-4 w-4 fill-current"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M7.3 14.7a1 1 0 010-1.4l3.29-3.3-3.3-3.3a1 1 0 011.42-1.4l4 4a1 1 0 010 1.4l-4 4a1 1 0 01-1.42 0z" />
                      </svg>
                    </span>
                    {mainEl[1].map((el) => (
                      <Link
                        to={el
                          .toLowerCase()
                          .replace(/[&,]/g, "")
                          .replace(/\s+/g, "-")
                          .replace(/\./g, "dot")}
                        onClick={() =>
                          setToggleHamburger((prevState) => !prevState)
                        }
                        className="border-b-2 border-blue-600 hover:border-white cursor-pointer ml-4 text-sm"
                        key={el}
                      >
                        {el}
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-start justify-start text-md mt-2">
            <p className="text-lg font-bold mb-5 uppercase">Other Services</p>

            {serviceNames.thirdColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col items-start justify-start text-md mt-2">
            <p className="text-lg font-bold mb-5 uppercase">
              Emerging Technologies
            </p>

            {serviceNames.fourthcolumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-2 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="flex items-center w-full ml-4 pt-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700  cursor-pointer"
        onClick={() => setMobileToggleSolution((prevState) => !prevState)}
      >
        <span>solutions</span>
        <span>
          <svg
            className="h-5 w-5 fill-current"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5.3 7.3a1 1 0 011.4 0l3.3 3.29 3.3-3.3a1 1 0 111.4 1.42l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 010-1.42z" />
          </svg>
        </span>
      </button>
      <div
        className={`${
          mobileToggleSolution ? "block" : "hidden"
        } w-full p-10 mt-4`}
        style={{ backgroundColor: "rgba(12,90,165,.95)" }}
      >
        <div className="flex justify-center text-gray-400 items-start">
          <Link
            to="/testimonials"
            onClick={() => setToggleHamburger((prevState) => !prevState)}
            className="border-2 border-white p-4 text-sm font-bold rounded-md uppercase"
          >
            What Clients Say
          </Link>
          <Link
            to="/case-studies"
            onClick={() => setToggleHamburger((prevState) => !prevState)}
            className="border-2 border-white p-4 text-sm ml-4 font-bold rounded-md uppercase"
          >
            Case Studies
          </Link>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col text-md mr-8">
            <p className="text-lg font-bold mb-5 uppercase mt-2">Solutions</p>

            {solutionNames.firstColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col text-md mr-8">
            <p className="text-lg font-bold mb-5 uppercase mt-2">
              Industries We Serve
            </p>

            {solutionNames.secondColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-col items-start justify-start text-md mt-12">
            {solutionNames.thirdColumn.map((el) => {
              return (
                <Link
                  to={el
                    .toLowerCase()
                    .replace(/[&,]/g, "")
                    .replace(/\s+/g, "-")}
                  onClick={() => setToggleHamburger((prevState) => !prevState)}
                  className="border-l-4 border-blue-600 pl-4 hover:border-white cursor-pointer px-4 pb-2"
                  key={el}
                >
                  {el}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <Link
        to="portfolios"
        className="flex w-full ml-4 pt-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700  cursor-pointer"
      >
        work
      </Link>
      <Link
        to="resources"
        className="flex w-full ml-4 pt-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700  cursor-pointer"
      >
        resources
      </Link>
      <Link
        to="contact-us"
        className="flex w-full ml-4 py-6 uppercase rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700  cursor-pointer"
      >
        contact
      </Link>
    </div>
  );
};

export default Navbar;
