import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apps } from "../../components/PortFolios/portFolios";
import { Link, useHistory } from "react-router-dom";
import "./appDetails.css";

export const AppDetails = () => {
  const { appId } = useParams();

  const history = useHistory();
  let [appData, setAppData] = useState({});

  useEffect(() => {
    const data = apps.find((el) => {
      return el.appId === Number(appId);
    });
    setAppData(data);
  }, [appId]);

  return (
    <div
      className="text-center transition-all duration-200 ease-in-out"
      id="noselect"
      style={{
        background: `${appData.background}`,
      }}
    >
      <div
        className="sticky top-0 flex justify-between px-10 shadow-md"
        style={{ zIndex: 70, background: `${appData.background}` }}
      >
        <Link to="/">
          <img
            className="w-20 rounded-full"
            src={require("../../assets/Images/KDR-WHITE.png")}
            alt="kdr logo"
          />
        </Link>
        <div className="flex items-center justify-center cursor-pointer">
          <span onClick={() => history.goBack()}>
            <svg
              className="w-8 h-8 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
            </svg>
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center px-4 md:px-10 justify-center text-white  w-full md:max-w-6xl md:mx-auto">
        <p className="text-3xl md:text-4xl text-center lg:text-5xl uppercase font-bold mt-6 mb-2">
          {appData.name}
        </p>
        <p className="mb-2 text-sm lg:text-base uppercase text-white opacity-75 tracking-wider font-extrabold">
          {appData.category}
        </p>
        <p className="mb-2 mt-10 text-2xl md:text-3xl lg:text-4xl text-center tracking-tight font-bold">
          {appData.shortDescription}
        </p>
        {appData.workedOn && (
          <div className={`mt-10 mb-2 grid grid-flow-row grid-cols-2 sm:grid-cols-3 lg:grid-cols-${appData.workedOn.length} gap-6 text-center`}>
            {appData.workedOn.map((el) => (
              <p
                className="border-2 py-2 px-2 rounded-full text-base"
                style={{
                  borderColor: "rgba(255, 255, 255, 0.2)",
                }}
                key={el}
              >
                {el}
              </p>
            ))}
          </div>
        )}
        {/* Image Screenshot show */}
        <div className="relative hidden lg:flex lg:justify-center lg:items-center h-full mt-10 w-full md:max-w-6xl">
          {appData.image1URL && (
            <img
              alt={`${appData.name}_1`}
              className="lg:rounded-lg lg:h-50vh max-w-full z-10 lg:-mr-16 hover:-translate-y-1 transform duration-200 ease-linear hover:z-50 cursor-pointer"
              src={require(`../../assets/Images/portfolios/${appData.image1URL}.png`)}
            />
          )}
          {appData.image2URL && (
            <img
              alt={`${appData.name}_2`}
              className="lg:rounded-lg lg:h-60vh max-w-full z-20 lg:-mr-16 hover:-translate-y-1 transform duration-200 ease-linear hover:z-50 cursor-pointer"
              src={require(`../../assets/Images/portfolios/${appData.image2URL}.png`)}
            />
          )}

          {appData.image3URL && (
            <img
              alt={`${appData.name}_3`}
              className="lg:rounded-lg lg:h-70vh max-w-full z-30 shadow-2xl hover:-translate-y-1 transform duration-200 ease-linear hover:z-50 cursor-pointer"
              src={require(`../../assets/Images/portfolios/${appData.image3URL}.png`)}
            />
          )}
          {appData.image4URL && (
            <img
              alt={`${appData.name}_4`}
              className="lg:rounded-lg lg:h-60vh max-w-full z-20 lg:-ml-16 hover:-translate-y-1 transform duration-200 ease-linear hover:z-50 cursor-pointer"
              src={require(`../../assets/Images/portfolios/${appData.image4URL}.png`)}
            />
          )}
          {appData.image5URL && (
            <img
              alt={`${appData.name}_5`}
              className="lg:rounded-lg lg:h-50vh max-w-full z-10 lg:-ml-16 hover:-translate-y-1 transform duration-200 ease-linear hover:z-50 cursor-pointer"
              src={require(`../../assets/Images/portfolios/${appData.image5URL}.png`)}
            />
          )}
        </div>
        <div className="flex flex-col px-2 md:px-10 items-center justify-center">
          {appData.logo && (
            <img
              className="w-16 md:w-24 mt-10 rounded-full"
              src={require(`../../assets/Images/portfolios/${appData.shortName}/${appData.logo}`)}
              alt="app logo"
            />
          )}
          <p className="uppercase text-2xl md:text-3xl lg:text-4xl font-bold mt-6 mb-3">{`${appData.name} overview`}</p>
          <p className="text-lg md:text-xl lg:text-2xl">
            {appData.description}
          </p>
          <div
            className="flex flex-col md:flex-row justify-center items-center mt-12"
            id="appLinks"
          >
            {appData.tags &&
              Object.keys(appData.tags).map((el) => (
                <a
                  className="flex flex-col items-center justify-center py-4 md:px-24"
                  href={appData.tags[el].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={el}
                >
                  <img
                    alt={appData.tags[el].name}
                    className="w-16 md:w-24 max-w-full"
                    src={require(`../../assets/Images/portfolios/${appData.tags[el].name}.png`)}
                  />
                  <span className="uppercase mt-3">
                    {appData.tags[el].name}
                  </span>
                </a>
              ))}
          </div>
          {/* Client Testimonal */}
          {appData.clientTestimonals && (
            <div className="pt-16 flex flex-col items-center justify-center">
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-16 md:w-24"
                style={{ transform: "rotateY(180deg)" }}
              >
                <path
                  fillRule="evenodd"
                  d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="uppercase mt-8 mb-2 text-2xl md:text-3xl lg:text-4xl leading-10 font-bold tracking-wider">
                CLIENT TESTIMONIALS
              </p>
              <div className="relative mt-5">
                <img
                  src={require("../../assets/Images/portfolios/quote.svg")}
                  className="absolute w-16 h-16 md:w-24 md:h-24"
                  style={{
                    top: "-30px",
                    left: "-30px",
                  }}
                  alt="testimonal quote"
                />
                <img
                  src={require("../../assets/Images/portfolios/quote.svg")}
                  className="absolute w-16 h-16 md:w-24 md:h-24 transform -rotate-180"
                  style={{
                    right: "-30px",
                    bottom: "-30px",
                  }}
                  alt="testimonal quote"
                />
                <p className=" max-w-3xl text-lg md:text-xl lg:text-2xl">
                  {appData.clientTestimonals.comment}
                </p>
              </div>
              <p className="text-lg md:text-xl lg:text-2xl uppercase mt-12 font-bold">
                {appData.clientTestimonals.name}
              </p>
              <p className="text-base md:text-lg mb-10">
                {appData.clientTestimonals.designation}
              </p>
            </div>
          )}
          {/* Request a quote */}
          <div
            className="flex flex-col justify-center items-center py-16 border-t-2"
            style={{
              borderColor: "rgba(255, 255, 255, 0.2)",
            }}
          >
            <img
              src={require("../../assets/Images/portfolios/send.svg")}
              className="w-16 md:w-24"
              alt="send"
            />
            <p className="text-2xl md:text-3xl lg:text-4xl uppercase font-bold mt-6 tracking-wider">
              INTERESTED IN WORKING TOGETHER?
            </p>
            <p className="text-lg md:text-xl lg:text-2xl mt-4 text-gray-400">
              Request a free quote or consultation, we'll get back to you in
              less than 6 hours
            </p>
            <Link to="/request-quote">
              <button className="py-4 px-6 rounded-lg mt-10 text-xl border-2 hover:bg-white hover:border-transparent hover:text-gray-700">
                Request a Quote
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
