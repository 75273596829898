import { IndustriesWeServe } from "../Solutions/IndustriesWeServe/IndustriesWeServe";
import { SolutionComponent } from "../Solutions/Solution/SolutionComponent";
import { EmergingTech } from "../Services/EmergingTech/EmergingTech";
import { Mobile } from "../Services/Mobile/Mobile";
import { Web } from "../Services/Web/Web";
import { Why } from "../Why/Why";
import { Testimonials } from "../Testimonials/Testimonials";
import { ComingSoon } from "../../lib/ComingSoon/ComingSoon";

export const urlData = {
  "social-networking": "industryweserve",
  "real-estate-property": "industryweserve",
  "food-restaurant": "industryweserve",
  "banking-finance-insurance": "industryweserve",
  "education-elearning": "industryweserve",
  "ecommerce-retail-b2b": "industryweserve",
  "travel-hospitality": "industryweserve",
  "media-entertainment": "industryweserve",
  "healthcare-fitness": "industryweserve",
  "transport-automotive": "industryweserve",
  "gaming-leisure": "industryweserve",
  "event-tickets": "industryweserve",
  "on-demand-app-solutions": "solution",
  "directory-organization": "solution",
  "wearable-app-development": "emerging-tech",
  "cloud-computing": "emerging-tech",
  "iot-development": "emerging-tech",
  "consulting-services": "emerging-tech",
  "software-maintenance-support": "emerging-tech",
  "opensource-development": "emerging-tech",
  "staff-augmentation-dedicated-teams": "emerging-tech",
  "product-engineering": "emerging-tech",
  "it-consulting": "emerging-tech",
  devops: "emerging-tech",
  "qa-testing": "emerging-tech",
  "mobile-application-development": "mobile",
  "cross-platform-app-development": "mobile",
  ios: "mobile",
  android: "mobile",
  pwa: "mobile",
  "ui-ux-designing": "mobile",
  "react-native": "mobile",
  flutter: "mobile",
  ionic: "mobile",
  "web-development": "web",
  "e-commerce-cms": "web",
  php: "web",
  dotnet: "web",
  java: "web",
  wordpress: "web",
  magento: "web",
  angularjs: "web",
  reactjs: "web",
  nodejs: "web",
  "press-media": "why",
  "awards-memberships": "why",
  "client-reviews": "testimonial",
  "our-blog": "coming-soon",
  "quality-assurance": "why",
  "our-partners": "why",
  "our-clients": "why",
  "project-communication-strategy": "why",
  "engagement-models": "why",
  "our-development-process": "why",
  "our-team": "why",
  "about-us": "why",
  "case-studies": "coming-soon",
  "blockchain-development": "solution",
  "artificial-intelligence": "solution",
  "augmented-reality-solutions": "solution",
  "marketing-advertising": "coming-soon",
  // "trending-js-framework": "coming-soon",
  // "app-prototype-and-strategy": "coming-soon",
};

export const componentMapping = {
  industryweserve: IndustriesWeServe,
  solution: SolutionComponent,
  "emerging-tech": EmergingTech,
  mobile: Mobile,
  web: Web,
  why: Why,
  testimonial: Testimonials,
  "coming-soon": ComingSoon
};
