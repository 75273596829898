import React from "react";
import { Icon } from "../Icon/Icon";

export const TypeSixBanner = ({ data }) => {
  return (
    <div className="bg-gray-200 px-4 xl:px-0">
      <div className="container mx-auto py-16">
        <p className="text-3xl lg:text-4xl capitalize mb-4">{data.text1}</p>
        <p className="text-lg text-gray-700 max-w-5xl mb-4">{data.text2}</p>
        <div className="grid grid-flow-row grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="col-span-2 border-r text-gray-600 pr-4">
            <p className="text-base text-main-blue uppercase font-bold tracking-wide mb-4">
              {data.left.heading}
            </p>
            <p className="text-base text-gray-600 mb-4">{data.left.para1}</p>
            <p className="text-base text-gray-600 mb-4">{data.left.para2}</p>
            <p className="text-base text-gray-600 mb-4">{data.left.para3}</p>
          </div>
          <div className="col-span-2 mx-auto lg:mx-0 lg:col-span-1">
            {data.right.points.map((el, i) => (
              <div
                key={`${el}${i}`}
                className="flex justify-start items-center text-sm mb-4"
              >
                <Icon iconName={el.iconName} classes="w-8 h-8 text-gray-500" />
                {el.link ? (
                  <a
                    href={el.link}
                    className="border-b-2 border-transparent hover:border-gray-400 ml-2"
                  >
                    {el.label}
                  </a>
                ) : (
                  <p className="ml-2">{el.label}</p>
                )}
              </div>
            ))}
            <div>
              <span>
                <a
                  className="inline-block mt-4 p-4 rounded-lg uppercase text-white bg-orange-500"
                  href={data.right.buttonLink}
                >
                  {data.right.buttonText}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
