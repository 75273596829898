import React from "react";

export const TypeEightBanner = ({ data }) => {
  return (
    <div className="pt-16 px-4 xl:px-0">
      <div className="max-w-5xl mx-auto text-center">
        <p className="text-3xl lg:text-4xl capitalize">{data.text1}</p>
        <p className="text-base uppercase text-gray-600">{data.text2}</p>
        <img
          className="mt-6"
          src={require(`../../assets/Images/${data.imageFolder}/${data.imageName}`)}
          alt="admin dashboard"
          style={{
            opacity: 0.8,
          }}
        />
      </div>
    </div>
  );
};
