export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "awards.jpg",
    imageText2: "Top Industry Rankings, Awards & Accolades",
    imageText3: "WE'RE PROUD TO BE GLOBALLY RECOGNIZED",
  },
  showButton: false,
  showIcon: true,
  iconName: "award-badge",
};

export const typeOneCardData = {
  text1: "Recognition and rewards motivate us to outperform our competitors",
  text2:
    "Since 2016, we have been going that extra mile to create exceptional digital strategies for our clients and their customers. That said, we are awarded by top-tier research firms and featured in premium magazines and journals.",
  tabData: [
    {
      label: "press releases",
    },
    {
      label: "Memberships",
    },
  ],
  cardData: {
    awardCardData: [
      {
        id: 1,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 1,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        folderName: "why/awards",
        id: 2,
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 3,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 4,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 5,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 6,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 7,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
      {
        id: 8,
        folderName: "why/awards",
        name: "Clutch",
        imageName: "clutch.png",
        title: "Top Mobile App Developers & Development Companies India 2020",
        description:
          "Kdr soft solutions ranked among top mobile app developers on top research firm Clutch.co",
      },
    ],
    memberShipCardData: [
      {
        id: 1,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        folderName: "why/awards",
        id: 2,
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 3,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 4,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 5,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 6,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 7,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
      {
        id: 8,
        folderName: "why/awards",
        imageName: "clutch.png",
        title: "ISO/IEC 27001:2013",
        description: "KONSTANTINFO HAS BEEN CERTIFIED",
      },
    ],
  },
};
