import React, { useState } from "react";
import { Icon } from "../../Icon/Icon";


export const TypeOneImage = ({ data, id }) => {
  const [showOnHover, setShowOnHover] = useState(false);

  return (
    <div
      data-aos="fade-in"
      data-aos-delay={50 * `${id + 1}`}
      data-aos-duration="1000"
      className="hover:shadow-lg"
    >
      <div
        className="relative"
        onMouseOver={() => setShowOnHover(true)}
        onMouseLeave={() => setShowOnHover(false)}
      >
        <img
          className="object-contain w-full"
          src={data.image}
          alt={data.name}
          style={{
            height: "355px",
          }}
        />
        {showOnHover && (
          <div
            data-aos="flip-left"
            data-aos-duration="500"
            className="absolute w-full px-10 top-0 left-0 h-full bg-main-blue flex justify-center items-center text-white font-bold text-2xl"
          >
            "{data.quote}"
          </div>
        )}
      </div>
      <div className=" text-center border-gray-300 flex flex-col justify-center items-center md:p-10">
        {data.name && (
          <p className="text-2xl font-bold capitalize">{data.name}</p>
        )}
        {data.role && (
          <p className="text-base text-gray-700 uppercase mt-1">{data.role}</p>
        )}
        {data.socialProfiles && (
          <div className="mt-4">
            {data.socialProfiles.map((el, i) => (
              <a
                className="w-8 h-8 rounded-full bg-gray-300 inline-flex items-center justify-center"
                key={i}
                href={el.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon iconName={el.iconName} classes="w-4 h-4 text-blue-600" />
              </a>
            ))}
          </div>
        )}
        {data.about && (
          <p
            className="text-base text-gray-700 mt-2"
            style={{
              textAlign: "justify",
            }}
          >
            {data.about}
          </p>
        )}
      </div>
    </div>
  );
};
