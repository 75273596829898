export const dominateData = {
  heading: "Education hubs to future learning enterprises",
  para:
    "This is exactly what we build at Kdr. We aim at empowering every education and learning space into a smarter, technologically-advanced virtual training centers either in the form of websites or mobile apps such that thousands of students, learners and trainees can access them on any device, from anywhere at their convenience on any devices- Android phone, iPad, iPhone, tablet, laptop and desktop.We make daily education as easy as Udemy, Skill Pill, Lynda and other international brands.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Focusing on tailored teaching and learning solutions, our team has all the required expertise in providing smart, connected solutions opening the borders of learning and imparting education to anyone, anywhere on any device. Integrating education systems and methods with upscale technology platforms, we deliver an outstanding learning experience to your audience at large.",
  text2:
    "We strive to make the most of this education expansion to online modes by delivering engaging and interactive learning and training solutions that creates a brand identity for institutions, entrepreneurs, corporate, schools, colleges and universities.",
};

export const challengesData = {
  heading: "Challenges in today’s education industry",
  description:
    "CUSTOM-BUILT E-LEARNING SOLUTIONS FOR CLASSROOM EDUCATION AND WORKPLACE TRAINING ",
  para:
    "Be it self-learning or with the help of an instructor virtually, online learning has minimized barriers, benefiting the learner and the trainer. The learning is flexible for students as they can choose the time, place and device to take the course and learn at their own pace. However, there exist some daunting challenges that need to be dealt well with viable solutions- and we help you do that. Trust us !",
  details: [
    {
      label: "Adaptability Struggle",
      content:
        "With fewer qualified buyers in the online real estate market, customers don’t have the trust and assurance of getting the right property that they dream of. They are dubious about buying online and they hardly find a good UX while browsing through the websites.",
    },
    {
      label: "Technical Problems",
      content:
        "Most times, the e-learning websites and applications are not well-equipped with technical components and break off the connection in between, which creates disturbance and breaks the concentration of the student. A high-performance technical support is required.",
    },
    {
      label: "Feedback",
      content:
        "While in physical teaching, there are various open-ended questions that are discussed in the classroom, clearing the doubts and queries of students at the same time.",
    },
    {
      label: "Isolation",
      content:
        "Students learn better when they are in groups. Currently, however, learners in an online class work mostly on digital islands quarantined from all other landmasses. By connecting students, either online concurrently or geographically close, leads to more explorations and quality learning.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "E-Learning is being driven not only by startup dot-com entrepreneurs but also by big corporations, for-profit spin-off ventures as well as big and small universities. And we offer a balanced solution to each one of them. Using our team’s diverse expertise, we’ve delighted a number of these education groups through professional and user-engaging web and mobile services..",
  details: [
    {
      label: "Interactive & Engaging Solutions",
      content:
        "We are known for developing addictive web and mobile apps, and education industry is no exception for us. To overcome the boredom and complexity of using a technically-advanced education platform among students and corporate learners, we build simple yet collaborating and appealing apps that help them to connect with the system and enjoy the benefits of online learning.",
    },
    {
      label: "Collaborative learning",
      content:
        "For a group learning experience, we incorporate tools and technologies that let the learners interact with their peers or other students taking the same course at the same time and discuss problem solving, also embedding a live tutor feature.",
    },
    {
      label: "Video-based and Game-based training",
      content:
        "To help institutions and education groups create an impact on the students and strengthen their brand among the global audience, our solutions foster thousands of online educational videos from industry experts with bug-free code at backend. Also, our game-based learning solutions offer a blend of entertainment with education, which provides a learning value to users.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. E-learning (Self-paced, Instructor-led)",
        text2: "#2. Web Applications",
        text3: "#3. Payment Systems Integration",
      },
      {
        text1: "#4. Mobile Learning Applications",
        text2: "#5. Learning Management System (LMS)",
        text3: "#6. Content Management System (CMS)",
      },
      {
        text1: "#7. Education Enterprise Solutions",
        text2: "#8. Edu Games Development",
        text3: "#9. Digital Library & Video Integration",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
