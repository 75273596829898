export const siteMapData = [
  {
    sectionName: "Home",
    links: [
      {
        name: "About Us",
        link: "/about-us",
      },
      {
        name: "Our Team",
        link: "/our-team",
      },
      {
        name: "Our Development Process",
        link: "/our-development-process",
      },
      {
        name: "Engagement Models",
        link: "/engagement-models",
      },
      {
        name: "Project Communication Strategy",
        link: "/project-communication-strategy",
      },
      {
        name: "Quality Assurance",
        link: "/quality-assurance",
      },
      {
        name: "Client Reviews",
        link: "/client-reviews",
      },
      {
        name: "Our Clients",
        link: "/our-clients",
      },
      {
        name: "Our Partners",
        link: "/our-partners",
      },
      {
        name: "Awards & Memberships",
        link: "/awards-memberships",
      },
      {
        name: "Our Blog",
        link: "/our-blog",
      },
      {
        name: "Press & Media",
        link: "/press-media",
      },
      {
        name: "Contact Us",
        link: "/contact-us",
      },
      {
        name: "Join our Team",
        link: "/careers",
      },
      {
        name: "Become a Partner",
        link: "/become-partner",
      },
      {
        name: "What Clients Say",
        link: "/testimonials",
      },
      {
        name: "Case Studies",
        link: "/case-studies",
      },
      {
        name: "Portfolios",
        link: "/portfolios",
      },
      {
        name: "Resources",
        link: "/resources",
      },
    ],
  },
  {
    sectionName: "Services",
    links: [
      {
        name: "Startups",
        link: "/start-up",
      },
      {
        name: "Enterprises",
        link: "/enterprise",
      },
      {
        name: "Mobile Application Development",
        link: "/mobile-application-development",
      },
      {
        name: "iOS Application Development",
        link: "/ios",
      },
      {
        name: "Android Application Development",
        link: "/android",
      },
      {
        name: "PWA Application Development",
        link: "/pwa",
      },
      {
        name: "Cross Platform App Development",
        link: "/cross-platform-app-development",
      },
      {
        name: "React Native App Development",
        link: "/react-native",
      },
      {
        name: "Flutter App Development",
        link: "/flutter",
      },
      {
        name: "Ionic App Development",
        link: "/ionic",
      },
      {
        name: "UI and UX Design",
        link: "/ui-ux-designing",
      },
      {
        name: "Web Development Services",
        link: "/web-development",
      },
      {
        name: "PHP Web Development",
        link: "/php",
      },
      {
        name: ".Net Application Development",
        link: "/dotnet",
      },
      {
        name: "Java Application Development",
        link: "/java",
      },
      {
        name: "Angular Js Development",
        link: "/angularjs",
      },
      {
        name: "React Js Development",
        link: "/reactjs",
      },
      {
        name: "Node.Js Development",
        link: "/nodejs",
      },
      {
        name: "Ecommerce Web Development",
        link: "/e-commerce-cms",
      },
      {
        name: "Magento Development Services",
        link: "/magento",
      },
      {
        name: "WordPress Development Services",
        link: "/wordpress",
      },
      {
        name: "Product Engineering",
        link: "/product-engineering",
      },
      {
        name: "DevOps",
        link: "/devops",
      },
      {
        name: "QA & Testing",
        link: "/qa-testing",
      },
      {
        name: "IT Consulting Services",
        link: "/consulting-services",
      },
      {
        name: "Staff Augmentation & Dedicated Teams",
        link: "/staff-augmentation-dedicated-teams",
      },
      {
        name: "Wearable App Development",
        link: "/wearable-app-development",
      },
      {
        name: "Cloud Computing",
        link: "/cloud-computing",
      },
      {
        name: "IoT Development",
        link: "/iot-development",
      },
      {
        name: "Software Maintenance & Support",
        link: "/software-maintenance-support",
      },
      {
        name: "Open Source Development",
        link: "/opensource-development",
      },
    ],
  },
  {
    sectionName: "Solutions",
    links: [
     
      {
        name: "On Demand App Development Services",
        link: "/on-demand-app-solutions",
      },
      {
        name: "Point of Sale Solution",
        link: "/",
      },
      {
        name: "Hospital Management Solution",
        link: "/",
      },
      {
        name: "Crowdfunding Solution",
        link: "/",
      },
      {
        name: "Ecommerce Solution",
        link: "/",
      },
      {
        name: "School Management Solution",
        link: "/",
      },
      {
        name: "Support Ticket Management Solution",
        link: "/",
      },
    ],
  },
  {
    sectionName: "Industries",
    links: [
      {
        name: "Real Estate & Property",
        link: "/real-estate-property",
      },
      {
        name: "Social Networking",
        link: "/social-networking",
      },
      {
        name: "Food & Restaurant",
        link: "/food-restaurant",
      },
      {
        name: "Banking, Finance & Insurance",
        link: "/banking-finance-insurance",
      },
      {
        name: "Education & eLearning",
        link: "/education-elearning",
      },
      {
        name: "Ecommerce, Retail & B2B",
        link: "/ecommerce-retail-b2b",
      },
      {
        name: "Travel & Hospitality",
        link: "/travel-hospitality",
      },
      {
        name: "Media & Entertainment",
        link: "/media-entertainment",
      },
      {
        name: "Healthcare & Fitness",
        link: "/healthcare-fitness",
      },
      {
        name: "Transport & Automotive",
        link: "/transport-automotive",
      },
      {
        name: "Gaming & Leisure",
        link: "/gaming-leisure",
      },
      {
        name: "Event & Tickets",
        link: "/event-tickets",
      },
    ],
  },
];
