import {companyName} from '../../config'

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "flutter.png",
    imageText2: "Flutter App Development Service",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeThreeBannerData = {
  heading: "Why is Flutter preferable for mobile app development?",
  description: "Google Flutter helps create a fast and beautiful app with a productive, extensible and open development model. It is undoubtedly the most versatile mobile app development framework that can be accessed across platforms, offers ‘native’ experience, takes advantage of expressive UI and promising features within an accelerated time frame. This helps drive business agility and service excellence for startups, medium-sized companies as well as enterprises through innovative technology usage.",
  details: [
    {
      iconName: `${companyName}-Flutter-CDN`,
      label: "Custom Design and Navigation",
      content:
        "Flutter is based on modern Application Programming Interface (API) with customizable features that make it suitable for navigation needs and does its rendering using Skia. The concepts of User Interface and navigation can only be written once and can then be shared across multiple platforms. Widgets work like native components.",
    },
    {
      iconName: `${companyName}-Flutter-FDL`,
      label: "Faster Development Cycle",
      content:
        "Flutter is faster than Ionic, NativeScript, and React Native as it gets compiled in less than 25 seconds. It is architected to support glitch-free, jank-free graphics at the native speed of your device. Additionally, hot reload, code-reusability allows for incremental compilation.",
    },
    {
      iconName: `${companyName}-Flutter-EOCS`,
      label: "Ease Of Code Sharing",
      content:
        "Doing more with minimal code is the motto behind Flutter. Having its library of widgets developers may implement Flutter code without getting into the details.",
    },
    {
      iconName: `${companyName}-Flutter-RTFQA`,
      label: "Reduced Time for Quality Assurance",
      content:
        "If Flutter App is tested on iOS it will run smoothly on Android. The tests conducted on one platform remain valid for another platform as well thus fastening of the QA process.",
    },
    {
      iconName: `${companyName}-Flutter-EPWD`,
      label: "Enhanced Performance with Dart",
      content:
        "The need to implement JavaScript frontend is removed as Dart includes code that is required in the application which speeds up the development cycle. The allocation model in DART minimizes poor quality enabling Flutter to develop robust and scalable mobile apps.",
    },
    {
      iconName: `${companyName}-Flutter-EUE`,
      label: "Enjoyable User Experience",
      content:
        "Flutter's layered architecture enables having full control over the screen. Its powerful compositing capabilities give a magical touch to animate graphics, text, video without any inhibitions.",
    },
    {
      iconName: `${companyName}-Flutter-PD`,
      label: "Productive Development",
      content:
        "Flutter offers state-full hot reload, allowing developers to make changes to the code and check immediate effect without restarting or losing its state.",
    },
    {
      iconName: `${companyName}-Flutter-EM`,
      label: "Extensible Model",
      content:
        "Flutter may work with any development tool. It includes editor plug-ins for both Visual Studio Code and IntelliJ / Android Studio. It has thousands of packages to speed up the development, regardless of the target platform.",
    },
    {
      iconName: `${companyName}-Flutter-CM`,
      label: "Community Support",
      content:
        "Flutter community is open to contributions being an open-source project.",
    },
    
  ],
};

export const topTypeThreeBannerData = {
  heading: "Flutter as the Best Framework for Cross-platform App Development",
   details: [
    {
      iconName: `${companyName}-Flutter-FA`,
      label: "Flutter apps",
      content:
        "are built out of a single codebase, compiled directly, make use of the GPU and access platform API and services.",
    },
    {
      iconName: `${companyName}-Flutter-F`,
      label: "Flutter",
      content:
        "also established a primary method of creating applications for Google Fuschia.",
    },
    {
      iconName: `${companyName}-Flutter-IP`,
      label: "It provides ",
      content:
        "reactive-style views without using a JavaScript bridge.",
    },
    {
      iconName: `${companyName}-Flutter-D`,
      label: "Dart shares",
      content:
        "similar features like many modern languages like Kotlin and Swift and can be trans-compiled into JavaScript code.",
    },
    {
      iconName: `${companyName}-Flutter-IMU`,
      label: "It makes use",
      content:
        "of a compiled programming language called Dart, which compiles ahead of time into the native code for multiple platforms.",
    },
    {
      iconName: `${companyName}-Flutter-F`,
      label: "Flutter",
      content:
        "(Google’s SDK) resembles the React Native cross-platform framework closely allowing for a reactive and declarative style of programming.",
    },
    {
      iconName: `${companyName}-Flutter-J`,
      label: "It does not",
      content:
        "need to use a JavaScript bridge thus improving app startup times and overall performance.",
    },
    {
      iconName: `${companyName}-Flutter-DA`,
      label: "Dart achieves",
      content:
        "this by using Ahead-of-time or AOT compilation and Just-In-Time or JIT compilation.",
    },
  ],
  showButton:true,
  buttonType: "button",
  buttonText: "Hire Flutter Developer",
  buttonLink: "/request-quote",
};

export const topSecondTypeThreeBannerData = {
  heading: "Flutter – Open Source Mobile Application Development Framework",
  para:
    "With 88.5K stars on Github (updated March 2020), Flutter is branded as a cross-app development framework for crafting beautiful, fast user experiences for web, mobile (Android/iOS) and desktop from a single codebase.",
};

export const appInfographics = {
  heading: "Flutter App Development Expertise",
  infoContent: [
    {
      heading: "Cross-Platform App Development",
      description:
        "KDR Flutter Development Services is based on the framework of robust knowledge and solid industry experience, are habituated to develop top-notch mobile apps on iOS and Android with agility.",
      imageFolder: "flutter",
      imageName: "cross-platform.svg",
    },
    {
      heading: "Hire Flutter Developers",
      description:
        "Looking for experts on Flutter to work remotely? Hire Flutter Developers from our team of experts to work on an hourly basis. (Conditions Applicable*)",
      imageFolder: "flutter",
      imageName: "hire.svg",
    },
    {
      heading: "Industry-Specific Solutions",
      description:
        "Kdr offers Flutter solutions, customized for each type of industry needs. Our active Flutter contributions combined with 17 years of industry experience serve to be pivotal arms of strength.",
      imageFolder: "flutter",
      imageName: "industry-solutions.svg",
    },
    {
      heading: "End-to-End Support and Maintenance",
      description:
        "Avail the benefits of instant support and continuous post-delivery maintenance of your Flutter apps with kdr Flutter Services.",
      imageFolder: "flutter",
      imageName: "server-maintenance.svg",
    },
  ],
};

export const serviceOfferedTypeTwo = {
  heading: "Why choose kdr for React Native App Development Services?",
  description:
    "Hire React Native developers with vast experience from kdr who are passionate about their React Native Applications. Our React native developers provide the best possible solutions to satisfy the most urgent needs of the businesses in marketing their products and services in no time. Here are some key reasons to hire our React Native developers for building state-of-art successful mobile applications:",
  data: [
    "100% Client Satisfaction",
    "Expertise In React Native Development",
    "On-Time Project Delivery",
    "Quality Services",
    "Immediate Support and Maintenance",
    "Hire Dedicated Developers (Hourly, Fixed Price)",
    "100% Client Satisfaction",
    "Expertise In React Native Development",
    "On-Time Project Delivery",
    "Quality Services",
    "Immediate Support and Maintenance",
    "Hire Dedicated Developers (Hourly, Fixed Price)",
    "100% Client Satisfaction",
    "Expertise In React Native Development",
    "On-Time Project Delivery",
    "Quality Services",
    "Immediate Support and Maintenance",
    "Hire Dedicated Developers (Hourly, Fixed Price)",
  ],
};
