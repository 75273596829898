import React from "react";
import { Link } from "react-router-dom";

export const TeamBanner = ({ data }) => {
  return (
    <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 bg-gradient-to-r from-orange-500 to-orange-700">
      <div
        data-aos="fade-in"
        className="flex flex-col items-start justify-center p-8 lg:p-16 text-white"
      >
        <p className="text-xs font-bold uppercase">{data.leftPart.text1}</p>
        <p className="text-4xl font-bold capitalize mt-2">
          {data.leftPart.text2}
        </p>
        <p className="text-lg mb-2">{data.leftPart.text3}</p>
        <p className="text-white text-opacity-50">{data.leftPart.text4}</p>
        <Link
          to={data.leftPart.buttonLink}
          className="inline-block mt-4 p-4 rounded-md capitalize font-bold text-sm bg-gray-800"
        >
          {data.leftPart.buttonText}
        </Link>
      </div>
      <div className="hidden lg:grid grid-cols-3 grid-rows-2">
        <div className="col-span-2 row-span-1 overflow-hidden" data-aos="fade-in">
          <img
            src={data.rightPart.images[0]}
            className="hover:scale-125 transform duration-1000 object-cover w-full"
            alt="team pic"
            style={{
              height: "300px",
            }}
          />
        </div>
        <div className="col-span-1 row-span-1 overflow-hidden" data-aos="fade-in">
          <img
            src={data.rightPart.images[1]}
            className="hover:scale-125 transform duration-1000 object-cover w-full"
            alt="team pic"
            style={{
              height: "300px",
            }}
          />
        </div>
        <div className="col-span-1 row-span-1 overflow-hidden" data-aos="fade-in">
          <img
            src={data.rightPart.images[2]}
            className="hover:scale-125 transform duration-1000 object-cover w-full"
            alt="team pic"
            style={{
              height: "300px",
            }}
          />
        </div>
        <div className="col-span-1 row-span-1 overflow-hidden " data-aos="fade-in">
          <img
            src={data.rightPart.images[3]}
            className="hover:scale-125 transform duration-1000 object-cover w-full"
            alt="team pic"
            style={{
              height: "300px",
            }}
          />
        </div>
        <div className="col-span-1 row-span-1 overflow-hidden " data-aos="fade-in">
          <img
            src={data.rightPart.images[4]}
            className="hover:scale-125 transform duration-1000 object-cover w-full"
            alt="team pic"
            style={{
              height: "300px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
