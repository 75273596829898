import { companyName } from "../../config";

export const imageTypeTwoBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "crossp.png",
    imageText2:
      "Top Cross-platform app development company developing intuitive and feature-rich apps",
    imageText3:
      "WE USE TOP TECHNOLOGIES AND FRAMEWORKS INCLUDING REACT NATIVE, NATIVESCRIPT, PHONEGAP, FLUTTER",
    points: [
      "Across popular mobile platforms",
      "Affordable React Native apps",
      "High-performance apps",
      "Scalable and reusable code",
      "Custom apps that feel native",
      "Experts in CSS3, HTMl5, jQuery",
    ],
    buttonType: "button",
    buttonLink: "request-quote",
    buttonText: "View our latest Cross Platform app portfolio",
  },
};

export const typeFourBannerData = {
  heading: "Using a wide variety of top technologies",
  description:
    "What's important while developing cross-platform apps is to make sure that the application looks attractive on each platform and functions the same way. This is where we make the real difference count by choosing the best technology stack for cross-platform app development.",
};

export const multiRowData = {
  text1: "MOBILE APPS FOR ALL SCREENS, ALL PLATFORMS",
  text2:
    "Customized cross-platform app development to accomplish your business needs",
  text3:
    "With the existence of multiple mobile devices and multiple smartphone platforms, we’ve gained expertise in creating multi-platform app development solutions for both Android and iOS devices in the price of one codebase and using the same IDE, language and APIs.",
  text4: "The various cross-platform services we provide are:",
  carouselData: [
    {
      iconName: `${companyName}-CPAD-CA`,
      label: "Calendar Apps",
    },
    {
      iconName: `${companyName}-CPAD-C`,
      label: "Classified Apps",
    },
    {
      iconName: `${companyName}-CPAD-EA`,
      label: "Enterprise Apps",
    },
    {
      iconName: `${companyName}-CPAD-ODA`,
      label: "On-Demand Apps",
    },
    {
      iconName: `${companyName}-CPAD-SNA`,
      label: "Social Network Apps",
    },
    {
      iconName: `${companyName}-CPAD-ECA`,
      label: "E-commerce Apps",
    },
    {
      iconName: `${companyName}-PWA-RD`,
      label: "Cross-Platform Desktop Apps",
    },
    {
      iconName: `${companyName}-CPAD-BA`,
      label: "Business Applications",
    },
    {
      iconName: `${companyName}-CPAD-GLA`,
      label: "Geo-based Applications",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const stackEmployedData = {
  textUnderline: true,
  techData: [
    {
      logo: "html.png",
      name: "HTML 5",
    },
    {
      logo: "phonegap.png",
      name: "Phone Gap",
    },
    {
      logo: "xamarian.png",
      name: "Xamarin",
    },
    {
      logo: "flutter.png",
      name: "Flutter",
    },
    {
      logo: "ionic.png",
      name: "Ionic",
    },
    {
      logo: "react_native.png",
      name: "React Native",
    },
  ],
  showButton: true,
  buttonLink: "#wordWithUs",
  buttonText: "connect with our experts",
};

export const typeThreeBannerData = {
  heading: "Reap the benefits of cross-platform",
  description: "WITH OUR EXPERT APP DEVELOPERS",
  details: [
    {
      iconName: `${companyName}-CPAD-CTNUE`,
      label: "Close-to-Native User experience",
      content:
        "We curate the apps to give them slick and polished looks such that they feel native.",
    },
    {
      label: "Save upto 40% Development Cost",
      iconName: `${companyName}-CPAD-LDC`,
      content:
        "We use open-source cross-platform technologies to reduce the development costs and let you save dollars that matter.",
    },
    {
      label: "Ready-to-market cross-platform apps",
      iconName: `${companyName}-CPAD-RTMA`,
      content:
        "We build cross-platform solutions that are ready-to-market, qualified and all tested for quality.",
    },
    {
      label: "High performance across devices",
      iconName: `${companyName}-CPAD-HPA`,
      content:
        "Across each platform and each device, the app will deliver great performance with fast response time.",
    },
    {
      label: "Seasoned Designers",
      iconName: `${companyName}-CPAD-SD`,
      content:
        "The designers ensure feature-rich, highly functional app designs that provide a seamless user experience.",
    },
    {
      label: "Post-deployment Support",
      iconName: `${companyName}-CPAD-PDS`,
      content:
        "For each of our built projects, we provide continued support and maintenance to clients.",
    },
    {
      label: "Powerful Mobile Backend Frameworks",
      iconName: `${companyName}-CPAD-PMBF`,
      content:
        "We employ flexible and powerful backend mobile frameworks including Sencha, jQuery and Ember.js.",
    },
    {
      label: "Rapid Application Time",
      iconName: `${companyName}-CPAD-RAT`,
      content:
        "With our agile development teams, we build apps that quickly reach the market, as per timelines.",
    },
  ],
};
