import { companyName } from "../config";

export const enterpriseImageSection = {
  folderName: "Enterprise",
  imageName: "enterprise.jpg",
  imageText2: "Enterprise app development for Business growth and performance",
  imageText3:
    "COMPLETE ENTERPRISE MOBILITY SOLUTIONS FOR IOS, ANDROID, WEB AND CLOUD",
  buttonText: "GET IN TOUCH",
  buttonLink: "request-quote",
};

export const appDevelopmentData = {
  heading: "Bringing innovation to enterprise mobile app development",
  description: "WE RANK AMONG TOP ENTERPRISE APP DEVELOPMENT COMPANIES",
  para:
    "We know what it takes to help your business prepare for the next generation of digital transformation. Enterprise mobility is the key to acquiring a successful product. At Kdr, we provide a comprehensive suite of enterprise mobility solutions and services that are technologically superior and commercially viable.",
  details: [
    {
      iconName: `${companyName}-Enterprise-MDM`,
      label: "Mobile Device Management",
      content:
        "Integrating a range of mobile devices and OSes into diverse enterprise infrastructures",
    },
    {
      label: "Field Service Mobile Apps",
      iconName: `${companyName}-Enterprise-FSMA`,
      content:
        "To speed up the business process, we build field services apps that can be easily customized",
    },
    {
      label: "B2B Mobile App Development",
      iconName: `${companyName}-Enterprise-BMA`,
      content:
        "Development & deployment of critical B2B mobile apps using strong development and business skills",
    },
    {
      label: "End-to-end Security",
      iconName: `${companyName}-Enterprise-ETES`,
      content:
        "Using a secured container strategy, we build applications that ensure data protection at rest and in transit",
    },
    {
      label: "Cross-Platform Enterprise Apps",
      iconName: `${companyName}-Enterprise-CP`,
      content:
        "Providing mobile access to enterprise applications, we develop seamless apps using tools like Sencha, Xamarin, PhoneGap",
    },
    {
      label: "Enterprise Web Development",
      iconName: `${companyName}-Enterprise-EWD`,
      content:
        "Creating enterprise-level web applications like chat applications, omnichannel ecommerce, real-time tracking applications.",
    },
  ],
};

export const promisingRelationShipData = {
  heading: "Building promising relationships",
  description: "OUR ENTERPRISE APP DEVELOPMENT PARTNERSHIP",
  para:
    "We want to deliver the best results to our clients who we consider our business partners. We see real benefits in creating the right solutions for their evolving enterprise needs, ultimately assuring business growth.",
  buttonDetails: {
    buttonScrollId: "#workWithUs",
    buttonText: "Let's start your enterprise project",
  },
};

export const enterpriseSolutions = {
  text1:
    "Most purposeful enterprise mobility solutions with cloud services revolutionizing the way you do business",
  text2: "IOS, ANDROID, CLOUD, WEARABLES, INTERNET OF THINGS",
  details: [
    {
      iconName: `${companyName}-Enterprise-MS`,
      label: "Mobile Strategy",
      content:
        "Establish the right strategy to bridge the gap between business requirements and mobile technology that will ultimately benefit your enterprise tasks, your employees and your customers.",
    },
    {
      label: "Development & Delivery",
      iconName: `${companyName}-Enterprise-DD`,
      content:
        "We use our expert technical knowledge and competence to build resourceful solutions suiting your requirements, helping you mobilize and evolve to accomplish your advanced business goals.",
    },
    {
      label: "Solution Support",
      iconName: `${companyName}-Enterprise-SS`,
      content:
        "Maintaining long-term relationships with the clients, we provide high-end support for any further queries or if there are any OS upgrade changes, and provide maintenance system.",
    },
  ],
  buttonDetails: {
    buttonText: "Talk to our experts",
    buttonScrollToId: "#workWithUs",
  },
};
