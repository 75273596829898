import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

export const StackUsed = ({ data }) => {
  const [techStack, setTechStack] = useState(data.stackData[0]);
  const clickHandler = (e, data) => {
    const clickedItem = document.getElementById(e.currentTarget.id);
    const stackItems = document.getElementsByClassName("stack-item");
    Array.from(stackItems).map((el) => {
      el.classList.remove("text-white", "border-blue-700");
      el.classList.add("text-gray-600");
      return null;
    });
    clickedItem.classList.remove("text-gray-600");
    clickedItem.classList.add("text-white", "border-blue-700");
    setTechStack(data);
  };

  useEffect(() => {
    const clickedItem = document.getElementById("stack-items");
    clickedItem.children[0].classList.remove("text-gray-600");
    clickedItem.children[0].classList.add("text-white", "border-blue-700");
  }, [data]);
  
  return (
    <div className="bg-contacPageFormRightColor-darkBlue py-16 capitalize">
      {data.text1 && (
        <p className="text-3xl lg:text-4xl mb-2 mx-auto text-center text-white">
          {data.text1}
        </p>
      )}
      {data.text2 && (
        <p className="mb-6 uppercase  container mx-auto text-center text-gray-500">
          {data.text2}
        </p>
      )}
      <div
        data-aos="fade-in"
        className={`container mx-auto grid grid-flow-row grid-cols-2 lg:grid-cols-${data.stackData.length > 6 ? '6' : data.stackData.length} gap-6`}
        id="stack-items"
      >
        {data.stackData &&
          data.stackData.map((el) => (
            <button
              className="focus:outline-none stack-item flex flex-col justify-center items-center text-gray-600 border-b-4 border-transparent hover:border-blue-700 py-4 hover:text-white"
              key={el.name}
              id={el.name.replace(" ", "_").toLowerCase()}
              onClick={(e) => clickHandler(e, el)}
            >
              <Icon iconName={el.logo} classes="w-12 h-12" />
              <p className="mt-2 text-base capitalize font-bold">{el.name}</p>
            </button>
          ))}
      </div>
      {(techStack.techUsedPara || techStack.techUsed) && (
        <div
          data-aos="fade-out"
          className="mx-auto flex flex-col justify-center flex-wrap items-center mt-10"
        >
          {techStack.techUsedPara && (
            <p className="text-base container mx-auto text-gray-500 text-center">
              {techStack.techUsedPara}
            </p>
          )}
          <div
            className={`grid grid-flow-row grid-cols-2 lg:grid-cols-${
              techStack.techUsed.length > 6 ? "6" : techStack.techUsed.length
            } gap-10 mt-10`}
          >
            {techStack.techUsed &&
              techStack.techUsed.map((el, i) => (
                <div
                  className="flex flex-col items-center justify-center text-white"
                  key={i}
                >
                  <img
                    className="w-20 h-20"
                    src={require(`../../assets/Images/solutions/solution/logos/${el.techLogo}`)}
                    alt={el.techName}
                  />
                  <p className="mt-2 text-sm font-bold capitalize">
                    {el.techName}
                  </p>
                </div>
              ))}
          </div>
          {techStack.showButton && (
            <span className="inline-block mt-10">
              <Link
                to={techStack.buttonLink}
                className="px-4 py-4 text-white bg-orange-500 uppercase text-sm lg:text-base  rounded-md font-bold"
              >
                <span className="border-b-2 border-white font-bold inline-block py-0 hover:text-gray-900">
                  {techStack.buttonText}
                </span>
              </Link>
            </span>
          )}
        </div>
      )}
      {data.showButton && (
        <span className="flex justify-center items-center mt-10">
          <a
            className="px-4 py-4 text-white bg-orange-500 text-sm lg:text-base rounded-md font-bold"
            href={data.buttonLink}
          >
            {data.buttonText}
          </a>
        </span>
      )}
      {(techStack.heading || techStack.description) && (
        <div className="container mx-auto text-center mt-8">
          {techStack.heading && (
            <p className="font-bold text-white">{techStack.heading}</p>
          )}
          {techStack.description && (
            <p className="text-blue-700 mt-2">{techStack.description}</p>
          )}
        </div>
      )}
    </div>
  );
};
