import React, { useEffect, useState } from "react";
import ReviewCard from "../../lib/Card/ReviewCard/ReviewCard";
import { LoadMore } from "../../lib/LoadMore/LoadMore";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import {
  appsTestimonial,
  happyClientsImageSection,
  trustData,
} from "./testimonialData";

export const Testimonials = () => {
  const [overAllRating, setOverAllRating] = useState(0);
  let [initialArray, setInitialArray] = useState(appsTestimonial.slice(0, 8));

  useEffect(() => {
    let b = happyClientsImageSection.ratings.reduce((a, b) => ({
      rating: a.rating + b.rating,
    }));
    setOverAllRating(b.rating / happyClientsImageSection.ratings.length);
  }, []);

  return (
    <div>
      <Navbar otherPages={true} transparent={true} />
      <section
        className="relative h-100vh md:h-80vh"
        style={{
          background: `url(${require(`../../assets/Images/Testimonial/testimonial.png`)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="absolute text-white flex w-full max-w-4xl mx-auto flex-col items-center justify-center text-center"
          style={{
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -60%)",
          }}
        >
          <svg
            id="heartIcon"
            className="w-10 h-10 md:w-16 md:h-16 lg:w-32 lg:h-32"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
              clipRule="evenodd"
            />
          </svg>
          <p
            data-aos="fade-in"
            className="text-xl md:text-2xl lg:text-4xl font-extrabold tracking-wider mt-2"
          >
            {happyClientsImageSection.text1}
          </p>
          <p
            data-aos="fade-in"
            className="text-sm md:text-base w-full max-w-xl text-gray-300 mt-2 uppercase"
          >
            {happyClientsImageSection.text2}
          </p>
          <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-10 md:gap-4 lg:gap-2 my-10">
            {happyClientsImageSection.ratings.map((el) => (
              <div key={el.logo} className="flex justify-center items-center">
                <img
                  className="w-16 mr-2"
                  src={require(`../../assets/Images/Testimonial/${el.logo}.png`)}
                  alt={el.name}
                />
                <div className="border-l-2 border-gray-700 pl-2">
                  <p>
                    <span className="text-xl font-bold">{el.rating}</span>/5
                  </p>
                  <p className="text-sm">{el.name}</p>
                </div>
              </div>
            ))}
            <span className="hidden lg:flex items-center justify-center text-4xl">
              =
            </span>
            <div
              className="flex flex-col items-center justify-center border-2 border-gray-400 rounded-md py-4 col-span-2 md:col-start-2 lg:col-span-1"
              style={{
                background: `url(${require("../../assets/Images/Testimonial/star.png")})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <p>
                <span className="text-xl font-bold">{overAllRating}</span>/5
              </p>
              <p className="capitalize text-sm">overall rating</p>
            </div>
          </div>
          <p className="text-sm">{happyClientsImageSection.para}</p>
        </div>
      </section>
      <TypeOneBanner text1={trustData.text1} text2={trustData.text2} />
      <div className="w-full max-w-6xl mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-2  lg:grid-cols-3 row-gap-4 md:gap-4 my-10">
        {initialArray.map((el, i) => (
          <ReviewCard data={el} key={`${el.companyName}_${i}`} />
        ))}
      </div>
      {initialArray.length >= 8 && (
        <div className="flex items-center justify-center w-full max-w-md mx-auto">
          <LoadMore
            apps={appsTestimonial}
            loadMoreText="Load More"
            initialArray={initialArray}
            setInitialArray={setInitialArray}
          />
        </div>
      )}
      <WorkWithUs />
      <Footer />
    </div>
  );
};
