import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "support.png",
    imageText2:
      "Reliable Support & Maintenance ensuring consistent software performance",
    imageText3:
      "A PROMISE TO MAINTAIN AND SUCCESSFULLY HANDLE YOUR SOFTWARE APPLICATION",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const multiRowData = {
  text1: "TECHNOLOGY SUPPORT YOU CAN COUNT ON TODAY… AND TOMORROW",
  text2: "Extend value of your software across their entire lifecycle",
  text3:
    "Need to cope up with the advancing market and changing consumer demands?",
  text4:
    "The best thing that we offer at Kdr is the same expertise and dedication when it comes to providing support and maintenance services. Our technical support specialists, cognitive computing capabilities, advanced analytics, parts network and global footprint help you proactively prevent downtime and keep your systems running. Some of our maintenance and support services include:.",
  carouselData: [
    {
      iconName: `${companyName}-SMS-SAS`,
      label: "Software/Application Support",
    },
    {
      iconName: `${companyName}-SMS-AE`,
      label: "Application Enhancement",
    },
    {
      iconName: `${companyName}-SMS-LAM`,
      label: "Legacy App Modernization",
    },
    {
      iconName: `${companyName}-SMS-SAM`,
      label: "System Monitoring",
    },
    {
      iconName: `${companyName}-SMS-PT`,
      label: "Performance Tuning",
    },
    {
      iconName: `${companyName}-SMS-PSA`,
      label: "Pre-support Audit",
    },
    {
      iconName: `${companyName}-SMS-DS`,
      label: "DevOps Solutions",
    },
    {
      iconName: `${companyName}-SMS-BAR`,
      label: "Backup and Recovery",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const typeThreeBannerData = {
  heading: "What sets us apart",
  description:
    "WE ARE THE SINGLE DESTINATION FOR ALL YOUR APPLICATION/SOFTWARE NEEDS",
  details: [
    {
      label: "Service quality and value",
      iconName: `${companyName}-SMS-SQAV`,
      content:
        "We deliver the best service quality that enhances the value of software or application, without troubling the client.",
    },
    {
      label: "Quickly resolve issues",
      iconName: `${companyName}-SMS-QRI`,
      content:
        "Our technology and expertise can analyze and resolve issues 40% faster, resolving problems in the first attempt.",
    },
    {
      label: "Innovative capabilities",
      iconName: `${companyName}-SMS-IC`,
      content:
        "With more than 14 years of IT experience, we’ve seen just about every type of IT issue and have the acumen to resolve it.",
    },
    {
      label: "Global reach",
      iconName: `${companyName}-SMS-GR`,
      content:
        "We offer software development support and maintenance in 100+ countries covering more than 60 languages.",
    },
    {
      label: "Effective tools",
      iconName: `${companyName}-SMS-ET`,
      content:
        "We provide world-class support, critical bug fixes and mission critical response via effective Atlassian tools like JIRA.",
    },
    {
      label: "Forward thinking",
      iconName: `${companyName}-SMS-FT`,
      content:
        "Foreseeing what more support can be required after 6 months, we use tremendous amount of data and research to fix the problem.",
    },
    {
      label: "Proactive support",
      iconName: `${companyName}-SMS-PS`,
      content:
        "We proactively support your IT needs in this cognitive era and provide integrated support and delivery remotely.",
    },
    {
      label: "Around-the-clock service",
      iconName: `${companyName}-SMS-ATCS`,
      content:
        "Working across various time zones, we manage to be available to resolve our client’s problems whenever they need us.",
    },
  ],
};

export const typeFiveBannerData = {
  text1: "Dedicated to meet specific challenges that your software faces",
  text2:
    "We provide three types of application maintenance services to clients",
  details: [
    {
      label: "Perfective and corrective maintenance",
      iconName: `${companyName}-SMS-PACM`,
      content:
        "We tackle major and minor issues that hamper the performance and functionality of the software, preventing occurrence of errors. This includes documentation, code optimization and code restructuring.",
    },
    {
      label: "Adaptive maintenance",
      iconName: `${companyName}-SMS-AM`,
      content:
        "The team implements the changes that helps the software/website/mobile app adapt to the changes in the environment like hardware or operating system. For instance, implementing changes in an iPhone app to make it function well for the new iOS version.",
    },
    {
      label: "Scheduled maintenance",
      iconName: `${companyName}-SMS-SM`,
      content:
        "To upgrade and make your software work in line with the latest, cutting-edge technologies, new features and functions are implemented in the UI/UX of the application. Our maintenance services help clients stay ahead of their competitors.",
    },
  ],
  buttonDetails: {
    buttonText: "Talk to our experts",
    buttonScrollId: "#workWithUs",
  },
};
