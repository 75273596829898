export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "press.png",
    imageText2: "We've covered the international press",
    imageText3: "STAY UP TO DATE WITH ALL THE LATEST PROJECT RELEASES, SERVICE OFFERINGS, INTERVIEWS, COMPANY RANKINGS & EVENTS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeOneBannerData = {
  text1: "Brand & Portfolio Stories- Hot Off the Press",
  text2:
    "Our work, solutions and services have been mentioned in the following reputed publications that are read by millions of people worldwide. Stay updated with our latest project releases, service offerings, company events, technology adoption and all that we are leading.",
  tabData: [
    {
      label: "press releases",
      link: "#",
    },
    {
      label: "management interview",
      link: "#",
    },
  ],
};
