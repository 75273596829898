import React from "react";
import { flow } from "./knowhowdata";
import { Link } from "react-router-dom";
import { Icon } from "../../lib/Icon/Icon";

export const Knowhow = () => {
  return (
    <div
      data-aos="fade-in"
      className="bg-white flex flex-col justify-center text-center pt-16 px-4 md:px-8"
    >
      <p className="uppercase font-bold md:text-4xl my-4 text-2xl">
        TOP-RATED WEB AND MOBILE APP DEVELOPMENT COMPANY
      </p>
      <p className="text-lg text-gray-800 mb-16 font-normal px-10 md:px-40">
        We’ve worked with hundreds of startups, small-medium enterprises and top
        brands, empowering them with our <b>digital intelligence</b> and{" "}
        <strong>expertise</strong>. We deliver the right business outcomes{" "}
        <b>5X faster</b> than our competitors and help our clients monetize more
        effectively.
      </p>
      <p className="italic text-base font-bold tracking-wide my-2">
        / ON-BOARDING PROCESS - BUILDING LONG-TERM PARTNERSHIPS /
      </p>
      <p className="font-bold text-2xl md:text-3xl mb-5">
        KNOW HOW WE COLLABORATE WITH OUR CLIENTS
      </p>
      <p className="text-lg px-10 md:px-40">
        We’re always excited to listen to new ideas from clients and as one of
        the largest mobile app development and web development companies
        worldwide, we create new levels of value through client’s businesses.
        Here’s how we work together with our clients as one across different
        time zones.
      </p>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-4 xl:mx-auto py-10 md:mx-8 mx-0 md:py-20 gap-8 xl:gap-10">
        {flow.map((data, i) => (
          <div
            key={`${i}`}
            className="relative flex flex-col justify-center items-center h-64 px-6 rounded-lg border-gray-300 shadow-md border-2 w-full max-w-lg mx-auto xl:max-w-xs  transition transform hover:shadow-lg hover:-translate-y-1 duration-200 ease-linear cursor-pointer"
          >
            <Icon iconName={data.logo} classes="w-16 h-16 mb-4" />
            <p className="text-lg md:text-xl font-bold mb-3">{data.name}</p>
            {data.cardDetail.map((el) => (
              <p
                className="text-lg font-medium text-gray-700 flex items-center"
                key={el}
              >
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="check w-6 h-6 text-gray-800"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{el}</span>
              </p>
            ))}
          </div>
        ))}
        <div className="bg-gradient-blue-green flex flex-col justify-center items-center h-64 px-6 rounded-lg font-bold text-white shadow-md border-main-rightGradient w-full max-w-lg mx-auto xl:max-w-xs transition transform hover:shadow-lg hover:-translate-y-1 duration-200 ease-linear">
          <svg
            className="w-8 h-8 stroke-current text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" />
          </svg>
          <p className="text-xl">WORKING TOGETHER</p>
          <Link
            to="/request-quote"
            className="flex items-center mt-6 text-base uppercase border-2 border-white py-2 cursor-pointer hover:bg-white hover:text-gray-800 px-4 rounded-md"
          >
            <span>Let's start with us</span>
            <svg
              viewBox="0 0 20 20"
              className="w-6 h-6 fill-current text-white"
              fill="currentColor"
            >
              <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Knowhow;
