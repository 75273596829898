import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "nodejs.png",
    imageText2:
      "Node.js development services to meet best perspectives of the web!",
    imageText3:
      "OFFERING YOU BEST-IN-CLASS SERVICES POWERED BY PRECISELY PICKED AND ADEPTLY TRAINED RESOURCES, WHO ARE WELL-VERSED WITH THE NUANCES AND DEPTHS OF THE TECHNOLOGY.",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const stackUsedData = {
  stackData: [
    {
      name: "Nodejs experience",
      logo: `${companyName}-Nodejs-RNE`,
      heading: "Ranging Node.js experience",
      description:
        "Working with user-centric mobile and web applications, we’ve used Node.js for a wide range of projects from different industry verticals and functional areas.",
    },
    {
      name: "Models",
      logo: `${companyName}-Nodejs-FEM`,
      heading: "Flexible engagement models",
      description:
        "We don’t work on unnecessarily strict and rigid parameters. We provide flexibility to clients to choose from various hiring and engagement models.",
    },
    {
      name: "Project Managers",
      logo: `${companyName}-Nodejs-DPM`,
      heading: "Dedicated project managers",
      description:
        "For each hiring, we have a dedicated Project Manager, who guides the subordinates at each phase.",
    },
    {
      name: "Communication",
      logo: `${companyName}-Nodejs-SC`,
      heading: "Seamless communication",
      description:
        "Our team is always available for meetings via Skype, phone, video calls as and when required.",
    },
    {
      name: "Process",
      logo: `${companyName}-Nodejs-AP`,
      heading: "Agile process ",
      description:
        "We work on Agile methodology conducting regular scrum events to keep the methods and practices highly optimized to deliver best results.",
    },
    {
      name: "Technologies",
      logo: `${companyName}-Nodejs-STC`,
      heading: "Strong technology competence",
      description:
        "We put up with most advanced and resourceful technologies and tools to allow you finest results and best value possible.",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Why Node.js for Application development",
  description: "LET JAVASCRIPT MAKE THE OPENING MOVE ON SERVER",
  details: [
    {
      label: "The Fast Suit",
      iconName: `${companyName}-Nodejs-TFS`,
      content:
        "Node.js is highly known for the speed and agility it offers across the functional stream, as it is powered by Google and uses JavaScript into native machine code.",
    },
    {
      label: "Real-Time Web Applications",
      iconName: `${companyName}-Nodejs-RTWA`,
      content:
        "The flagship feature of Node.js! Working within the environment you get to build multi-user real-time web apps with great authority and ease.",
    },
    {
      label: "Extreme Data Streaming Ability",
      iconName: `${companyName}-Nodejs-EDSA`,
      content:
        "Data Streaming can be utilized to its fullest to create some of the best resource attributes like processing files at the time of uploading.",
    },
    {
      label: "Solves All Database Queries",
      iconName: `${companyName}-Nodejs-SADQ`,
      content:
        "This comes in as a well-received feature with database engineers as it perceptively handles queries on MongoDB and CouchDB without modulating the syntax differences.",
    },
    {
      label: "Dynamic Node Package Manager",
      iconName: `${companyName}-Nodejs-DNPM`,
      content:
        "Working with Node.js is highly productive and rewarding as it offers great edge to developers with its super steady and productive NPM.",
    },
    {
      iconName: `${companyName}-Nodejs-CD`,
      label: "Community Driven",
      content:
        "As a developer you get to receive latest updates, explore the innovative, and communicate with industry experts, being a part of the rich Node.js community.",
    },
  ],
};

export const typeNineBannerData = {
  heading: "What makes Node.js so popular",
  description:
    "HOSTABLE, LIGHTWEIGHT, BARRIERLESS, LINGUA FRANCA OF SERVER-SIDE PROGRAMMING",
  para1:
    "Node.js is steadily getting into the mainstreams of latest web development affairs. Credit goes to the fresh and sweeping code reference environment and the resourceful package ecosystem it puts up with. Next, it is well known to deliver hyper tasking abilities when it comes to building JavaScript based real-time applications. Which makes big difference to the way your Node.js application development project orients.",
  para2:
    "Further, as it uses an event-driven, non-blocking I/O model, it makes it highly smooth and virtuous to commit to your new-age development goals. Here, if you still face some glitches, wondering how to go about or how to best use your capabilities, you are further helped by the rich forum of developers who perpetually contribute to the Node.js environment.",
};

export const multiRowData = {
  text1: "LIGHTWEIGHT | SCALABLE | EFFICIENT",
  text2:
    "Hire Node.js Developer and avail highly proficient Node.js development Services",
  text3:
    "To be able to max-out with Node.js benefits you don’t just need the right people and resources to work with but also the right kind of engagement and pricing options that go well with your Node.js development needs. At Kdr, we have evolved over years to put up with optimum processes and programs to allow our clients get best of benefits through Node.js hiring services. Thus, you can trust on us to avail the best of Node.js development services, hiring dedicated Node.js experts with high employability grades, and empower yourself to make the right solution come your way in the best equations of cost and quality – all at great peace of mind!.",
  carouselData: [
    {
      iconName: `${companyName}-Nodejs-WAD`,
      label: "Web Application Development",
    },
    {
      iconName: `${companyName}-Nodejs-AD`,
      label: "API Development",
    },
    {
      iconName: `${companyName}-Nodejs-RTAS`,
      label: "Real-time App Solutions",
    },
    {
      iconName: `${companyName}-Nodejs-SPAD`,
      label: "Single page App Development",
    },
    {
      iconName: `${companyName}-Nodejs-UUD`,
      label: "UI/UX Design",
    },
    {
      iconName: `${companyName}-Nodejs-DD`,
      label: "Dashboard Development",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "hire Node JS Developers",
};

export const typeFourBannerData = {
  heading: "Why kdr for Node.js Web Application Development?",
  text1:
    "As a leading Node.js development company, kdr is known to deliver the finest of Node.js compliance and value, keeping modern business needs served with technically sound runtime JavaScript enablement. While we build Node.js solution for you we make it a point to keep your purpose of web pinned and tracked throughout, with the streamlined process of procurement, development and delivery. Our team of talented programmers offers you the finest of assistance in building your fully-featured data-intensive real-time web quite approvingly, allowing you to meet and acquire the best of value with your Node.js web development endeavor.",
  description:
    "RELISH SAVING EFFORT, TIME AND COST - WATCH OUR COMPETENT TEAM OF NODEJS DEVELOPERS",
};
