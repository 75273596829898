import React from 'react'
import { Link } from 'react-router-dom'
import {Icon} from '../../Icon/Icon'

export const CardTypeFive = ({data}) => {
    return (
        <div
        data-aos="slide-right"
          className="group flex justify-around items-center bg-white px-5 py-6 md:px-10 md:py-12 rounded-md cursor-pointer hover:bg-gradient-blue-green hover:shadow-xl transition transform hover:-translate-y-1 duration-200 hover:ease-in"
        >
          <Icon classes="transform group-hover:text-white group-hover:scale-150 duration-200 ease-in w-40"  iconName={data.logo}/>
          <div className="group-hover:text-white ml-6"> 
            <p className="text-md md:text-lg font-bold mb-3">{data.name}</p>
            <p className="text-md text-gray-600 group-hover:text-white mb-3">
              {data.description}
            </p>
            <Link
              to={data.name
                .toLowerCase()
                .replace(/[&,\\/]/g, "")
                .replace(/\s+/g, "-")}
              className="flex items-center"
            >
              <span className="text-xs uppercase font-semibold">
                View More
              </span>
              <svg
                className="h-5 w-5 transition transform group-hover:translate-x-2 duration-300 ease-linear"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" />
              </svg>
            </Link>
          </div>
        </div>
    )
}
