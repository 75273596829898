import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "java.png",
    imageText2: "We call it 'The Java Kingdom' because it's our territory",
    imageText3: "WE’RE A TOP-RANKED JAVA DEVELOPMENT COMPANY",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get Estimation for your Project",
    },
  },
};

export const multiRowData = {
  text1: "JAVA SERVICES FOR ALL",
  text2: "Java application development services robust, secure and effective",
  text3:
    "Globally acclaimed for our ground-breaking web solutions, we offer Java application development services that are rich, productive, swift, reliable and high-performing. Our Java web development caters to wide spectrum of industries.",
  text4: "some of the services include:",
  carouselData: [
    {
      iconName: `${companyName}-JAVA-EJD`,
      label: "Enterprise Java Development",
    },
    {
      iconName: `${companyName}-JAVA-CWS`,
      label: "Custom Web & Software",
    },
    {
      iconName: `${companyName}-JAVA-DMD`,
      label: "Dynamic Mobile Development",
    },
    {
      iconName: `${companyName}-JAVA-EJD`,
      label: "Java/J2EE Development",
    },
    {
      iconName: `${companyName}-JAVA-MS`,
      label: "Maintenance & Support",
    },
    {
      iconName: `${companyName}-JAVA-JC`,
      label: "Java CMS/Migration",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "let's Connect",
};

export const stackUsedData = {
  stackData: [
    {
      name: "FrameworKs",
      logo: `${companyName}-NET-F`,
      techUsed: [
        {
          techName: "Spring 4.3",
          techLogo: "spring.png",
        },
        {
          techName: "Hibernate 5.2.5",
          techLogo: "hibernate5.2.5.png",
        },
      ],
    },
    {
      name: "IDE",
      logo: `${companyName}-JAVA-I`,
      techUsed: [
        {
          techName: "Eclipse",
          techLogo: "eclipse.png",
        },
        {
          techName: "NetBeans",
          techLogo: "netbeans.png",
        },
      ],
    },
    {
      name: "ORMs & Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "MySQL",
          techLogo: "mysql.png",
        },
      ],
    },
    {
      name: "Libraries",
      logo: `${companyName}-JAVA-L`,
      techUsed: [
        {
          techName: "Java Database Connectivity (JDBC)",
          techLogo: "jdbc.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "dashboard.jpg",
};

export const typeFourBannerData = {
  heading:
    "We blend proven concepts with modern technology to produce smart Java solutions",
  text1:
    "Making it the top picked Java development company, our Java architects follow a unified approach keeping clients' expectations in mind to produce structurally stable, scalable and feature-rich products that carry the potential of leaving users awe-struck.",
  description: "JAVA WEB DEVELOPMENT SOLUTIONS THAT GUARANTEE SUCCESS",
};

export const typeThreeBannerData = {
  heading: "Here's why you need to give us a chance",
  description: "WE HAVE SERVED THE INDUSTRY FOR MORE THAN A DECADE",
  details: [
    {
      label: "Satisfied Clients",
      content:
        "Our in-house team is committed to providing utmost satisfaction to our clients with modern yet effective Java development services.",
      iconName: `${companyName}-JAVA-SC`,
    },
    {
      label: "Experienced Java Architects",
      content:
        "Workforce combining skills and experience is the best combination an agency can possess, thankfully we do.",
      iconName: `${companyName}-JAVA-EJA`,
    },
    {
      label: "High Quality Standards",
      content:
        "Quality of the project remains unharmed regardless of the project's complexity and deadlines.",
      iconName: `${companyName}-JAVA-HQS`,
    },
    {
      label: "Scalable & Robust Solutions",
      content:
        "We possess an excellent track record of producing Java web development solutions that are accepted on a global scale.",
      iconName: `${companyName}-JAVA-SRS`,
    },
    {
      label: "Inventive Procedures & Unwavering Commitments",
      content:
        "We follow an innovative approach to meet clients' expectations without failing on our commitments.",
      iconName: `${companyName}-JAVA-IPUC`,
    },
    {
      label: "Customer-first Approach",
      content:
        "We treat our customers with respect because we know 'happy customers' are the best.",
      iconName: `${companyName}-JAVA-CFA`,
    },
    {
      label: "Pocket-friendly Solutions",
      content:
        "We care about your budget and offer quality solutions at reasonable pricing.",
      iconName: `${companyName}-JAVA-PFS`,
    },
    {
      label: "Enhanced Security Procedures",
      content:
        "Our consulting conversations with clients remain private for life.",
      iconName: `${companyName}-JAVA-ESP`,
    },
  ],
};
