export const dominateData = {
  heading: "Social networking app that goes beyond boundaries",
  para:
    "The world of smartphones is growing where people like to connect, discuss, share, upload and comment. It’s time for you to build a community of explorers who wish to contribute and collaborate with the world, society, self or for that matter any trending topic on this planet. Rely on us for building a social platform that goes beyond boundaries. We listen, ask, share views and act.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Social media has revamped the entire networking sphere for the good. Since social media has been introduced, people have been able to connect, communicate, and learn across the world. Businesses have discovered a fresh medium for user interaction and communication, thereby aiding in superior user satisfaction and effective brand promotion.",
  text2:
    "As we are very aware of the critical nature of the social domain, we render our strategic and result-oriented services to our global clientele that matter to us the most. We believe in empowering our clients with groundbreaking technology, in-trend methodologies, and pocket-friendly social media app development services that not only guarantee enhanced user contentment but also form an everlasting bond between the client and their customers. We provide the ideal technology for specific social media platforms, so that our clients can utilize all the business benefits of social media. ",
};

export const challengesData = {
  heading: "Challenges in social networking",
  description: "CONNECTIONS DEMAND COMMITMENTS ",
  details: [
    {
      label: "Interactive And Intuitive Design",
      content:
        "An easy and intuitive app helps in stealing the minimal attention time of users to a great effect. With a thoughtful process and a competent manpower, this feat needs to be achieved in order to assure success.",
    },
    {
      label: "Nurturing Customer Relationship",
      content:
        "The market has become more customer-centric while the customers have become smarter in using gadgets like smartphones and tablets. A mind-boggling customer retention strategy needs to be drafted to achieve desired results and nurture relationships.",
    },
    {
      label: "Data Mining",
      content:
        "With each passing day, new data is being produced in form of feeds, images, videos, web pages, articles and more. Mining of this data is required to produce semantic information that can aid businesses to reach new heights by effecting targeting, strategical planning and customized marketing plans..",
    },
    {
      label: "Privacy",
      content:
        "For major social media channels, privacy is the main concern. Businesses need to overcome the challenge of maintaining integrity and security of the users over social media channels, which can thereby impact business goals and revenues.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers value?",
  text2:
    "At Kdr, we focus to deliver our clients with modern approaches for enhanced user targeting and reach. We understand the dos and don’ts of social media app development helping us to empower our clients with the sweetest state-of-mind.",
  details: [
    {
      label: "Tailored social media solutions",
      content:
        "We understand that every business is different and demand individual solution. Keeping the same in mind, we craft tailored social media solutions for each client independently for a profitable future. Our customer-driven approach helps us to identify goals efficiently and personalize their entire journey because we know social media connections need to be real and real things are hard to come by.",
    },
    {
      label: "Report analysis",
      content:
        "Figures and numbers are important but only when presented in a right format. Social networks are loaded with content that requires to be classified into graphs, charts and tables for better understanding and decision-making. Covering it well, we provide a competitive advantage to our clients by utilizing modern techniques for extracting the information 'that matters'.",
    },
    {
      label: "Privacy come first",
      content:
        "We ensure personal details of the users remain inside a cocoon that’s hard to burst. Delivering quality solutions from many years, we utilize top-notch techniques in achieving this feat so that information is kept safe and secure. We implement expert security procedures to assure app’s security as well as user’s privacy. Our portfolio speaks for our success. We have been catering the social world with tailor-made solutions helping clients to grow by leaps and bounds and make high profits at the end of every financial year.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Profile management",
        text2: "#2. Live chat",
        text3: "#3. Photo/Video sharing",
      },
      {
        text1: "#4. Online polling",
        text2: "#5. Instant Messaging",
        text3: "#6. Community building",
      },
      {
        text1: "#7. Content management",
        text2: "#8. Reporting and analytics",
        text3: "#9. Privacy management",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
