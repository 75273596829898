import { companyName } from "../config";

export const greetingsData = [
  "నమస్తే",
  "Vanakkam - വണക്കം",
  "ನಮಸ್ತೆ ",
  "Bonjour",
  "Salve",
  "Olá",
  "Goedendag",
  "Asalaam alaikum ",
  "Halo",
  "Namaskar",
  "Hallå",
  "今日は",
  "שלום",
  "Merhaba",
  "Cześć",
  "สวัสดี",
  "Terve",
  "안녕",
  "Привет",
  "Hej",
  "Ahoj",
  "你好",
  "Ciao",
  "Halló",
  "բարև",
  "¡Hola!",
  "مرحبا",
  "Hi",
];

export const setIntervalTime = 3000;

export const formRightSideData = [
  {
    iconName: `${companyName}-contact-F`,
    text: "If not Forms, brief us @",
    details: [
      {
        urlName: "info@kdrsoftsolutions.com",
        url: "mailto:info@kdrsoftsolutions.com",
      },
      {
        urlName: "+91-73 99 88 44 33",
        url: "tel:+917399884433",
      },
      {
        urlName: "+1-929-446-1414",
        url: "tel:+1-929-446-1414",
      },
    ],
  },
  {
    iconName: `${companyName}-contact-POT`,
    text: "Be a part of our team",
    details: [
      {
        urlName: "careers@kdrsoftsolutions.com",
        url: "mailto:careers@kdrsoftsolutions.com",
      },
      {
        urlName: "1800 889 85 89",
        url: "tel:18008898589",
      },
    ],
  },
  {
    iconName: `${companyName}-mis-W`,
    text: "Talk to us on WhatsApp",
    details: [
      {
        urlName: "+1-302-597-7591",
        url: "http://api.whatsapp.com/send?phone=+13025977591",
      },
    ],
  },
];

export const locationData = {
  India: {
    image: "contactbg-india",
    numberDetails: {
      icon: "call",
      number: {
        label: "+91-7399884433",
        url: "tel:+91-7399884433",
      },
    },
    mailDetails: {
      icon: "mail",
      mail: {
        label: "info@kdrsoftsolutions.com",
        url: "mailto:info@kdrsoftsolutions.com",
      },
    },
  },
};

export const contactInfo = {
  heading: "Our Offices",
  locations: {
    main: {
      name: "India",
      iconName: "india.png",
      locations: [
        {
          name: "Vijayawada",
          addressOne: "2-7-93/6, Vijayawada, Andhra Pradesh ,521108, IN",
        },
        {
          name: "Hyderabad",
          addressOne: "8-2, Rd Number 12, NBT Nagar,",
          addressTwo: "Banjara Hills, Hyderabad, Telangana, 500034, IN",

          phone: "+91 73 99 88 44 33",
        },
      ],
    },
    other: [
      {
        name: "INDIA",
        iconName: "india.png",
        locations: [
          {
            name: "Hyderabad",
            addressOne: "1-8-318/1, Patigadda ",
            addressTwo: "Begumpet, Hyderabad",
            addressThree: "Telangana, 500016, IN",
            phone: "1800 889 85 89",

          },
        ],
      },
      {
        name: "INDIA",
        iconName: "india.png",
        locations: [
          {
            name: "Bangalore",
            addressOne: "Canberra Block, UB City, ",
            addressTwo: "Vittal Mallya Road, Bangalore",
            addressThree: "Karnataka, 560001, IN",
            phone: "1800 889 85 89",

          },
        ],
      },
      {
        name: "UK",
        iconName: "uk.png",
        locations: [
          {
            name: "United Kingdom",
            addressOne: "71-75, Shelton Street, ",
            addressTwo: "Covent Garden, London, ",
            addressThree: "WC2H 9JQ United Kingdom", 
            phone: " +44 79 1561 0025 ",

          },
        ],
      },
      {
        name: "USA",
        iconName: "usa.png",
        locations: [
          {
            name: "Delaware",
            addressOne: "651 N. Broad St. Suite 206,",
            addressTwo: "Middletown, DE 19709 ",
            addressThree: "United States of America", 
            phone: " +1 929 446 1414 ",
          },
        ],
      },

           
      // {
      //   name: "UAE",
      //   iconName: "uae.png",
      //   locations: [
      //     {
      //       name: "Dubai",
      //       addressOne: "One Central, Dubai World Trade Centre",
      //       addressTwo: "Dubai, United Arab Emirates ",
      //       // phone: " +1-850-900-0535 ",

      //     },
      //   ],
      // },
    ],
  },
};
