import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar/Navbar";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import {
  imageSection,
  tabData,
  text1,
  text2,
  becomeAPartner,
  partnerShipProgram,
} from "./BecomePartnerData";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import { Footer } from "../Footer/Footer";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";
import { TypeThreeBanner } from "../../lib/TypeThreeBanner/TypeThreeBanner";

export const BecomeAPartner = () => {
  const [show, setShow] = useState("associate_partnership");

  useEffect(() => {
    tabData.map((el) => {
      document
        .querySelector(`${el.link.replace("-", "_")}`)
        .addEventListener("click", (e) => {
          setShow(e.target.id);
        });
      return "";
    });
  }, []);

  return (
    <div>
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        showButton={false}
        imageName={`${imageSection.imageName}.png`}
        folderName={imageSection.imageName}
        imageText1={imageSection.imageText1}
        imageText2={imageSection.imageText2}
        imageText3={imageSection.imageText3}
      />
      <TypeOneBanner
        id="partnership"
        tabData={tabData}
        text1={text1}
        text2={text2}
        buttonType="button"
        selectedId={show}
      />
      <div
        className="px-4 lg:px-0 py-16 w-full max-w-6xl mx-auto text-base bg-white"
        data-aos="fade-in"
      >
        <p className="my-4 text-sm lg:text-base">
          {require(`./BecomePartnerData`)[`${show}`]["text1"]}
        </p>
        {require(`./BecomePartnerData`)[`${show}`]["text2"] && (
          <p className="my-4 text-sm lg:text-base">
            {require(`./BecomePartnerData`)[`${show}`]["text1"]}
          </p>
        )}
        <div
          className=" grid grid-flow-row grid-cols-1 lg:grid-cols-3 mt-4 gap-6 transition-all duration-150 ease-linear"
          data-aos="flip-up"
        >
          {require(`./BecomePartnerData`)[`${show}`]["cards"].map((el, i) => (
            <div
              key={el.heading}
              className={`${
                i % 2 !== 0
                  ? "bg-blue-700 text-white"
                  : "bg-transparent border-2 border-gray-300"
              } transform cursor-pointer hover:-translate-y-1 hover:shadow-xl duration-150 ease-linear shadow-md rounded-lg py-8 pl-8 pr-4`}
            >
              <p
                className={`text-md lg:text-xl font-bold pb-2 border-b-2 ${
                  i % 2 !== 0 ? "border-blue-500" : "border-blue-700"
                }`}
              >
                {el.heading}
              </p>
              <ul>
                {el.content.map((el) => (
                  <li
                    className="list-disc list-inside mt-4 text-sm lg:text-base"
                    key={el}
                  >
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <a
          className="flex justify-center items-center w-full max-w-xs text-white mx-auto text-center bg-gradient-purple-blue px-4 font-bold rounded-lg shadow hover:shadow-lg py-4 uppercase mt-4"
          href={becomeAPartner.link}
        >
          {becomeAPartner.label}
        </a>
      </div>
      <TypeThreeBanner data={partnerShipProgram} />
      <WorkWithUs />
      <Footer />
    </div>
  );
};

export default BecomeAPartner;
