import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../components/Navbar/Navbar";

export const ComingSoon = () => {
  return (
    <>
      <Navbar otherPages={true} transparent={true} />
      <div className="bg-gray-800 h-screen flex items-center px-4 xl:px-0">
        <div className="container mx-auto grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-10">
          <div data-aos="fade-right" className="flex flex-col items-center justify-center text-center lg:text-left">
            <p className="text-4xl capitalize font-bold text-gray-400">
              <span className="text-5xl">P</span>age under development
            </p>
            <p className="text-xl text-gray-600">
              For any queries, connect us through contact form
            </p>
            <Link
              to="/contact-us"
              className="text-xl text-white text-center bg-orange-500 rounded-md p-4 mt-8"
            >
              Have queries ? Send a message to us{" "}
              <span role="img" aria-label="happy face">
                😄
              </span>
            </Link>
          </div>
          <img
          data-aos="fade-up"
            className="hidden lg:block"
            src={require("../../assets/Images/under-construction.svg")}
            alt="under construction"
          />
        </div>
      </div>
    </>
  );
};
