import React from "react";

export const TypeSevenBanner = ({ data }) => {
  return (
    <div className="py-16 px-4 xl:px-0">
      <div className="container mx-auto grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-6">
        <div data-aos="fade-right" className="flex flex-col">
          <h3
            className="text-3xl lg:text-4xl capitalize mt-4 mb-4 text-center lg:text-left"
            style={{ lineHeight: "54px" }}
          >
            {data.text1}
          </h3>
          <p className="mb-4 text-gray-700">{data.text2}</p>
          <p className="mb-4 text-gray-700">{data.text3}</p>
          <div className="text-lg mt-2 font-semibold">
            {data.points.map((el, i) => (
              <p className="mb-2" key={`${el}${i}`}>{`#${i}.  ${el}`}</p>
            ))}
          </div>
          <span>
            <a
              className="inline-block mt-4 text-base uppercase font-bold text-white rounded-md px-4 py-4 bg-orange-500"
              href={data.buttonLink}
            >
              {data.buttonText}
            </a>
          </span>
        </div>
        <div
          data-aos="fade-left"
          className="hidden lg:flex justify-center items-center h-full"
        >
          <img
            className="mx-auto"
            src={require(`../../assets/Images/services/emergingTech/${data.image}`)}
            alt="open source"
          />
        </div>
      </div>
    </div>
  );
};
