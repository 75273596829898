export const heroSectionTwoData = {
  // heading1:
  //   "Sharing our expertise and passion to build solutions that empower your business",
  heading2:
    "Award Winning Web & Mobile App Development Company",
  heading3:
    "Kdr provides turn-key software development services that add value to your business. Contact us today and transform your ideas into Solutions !!",
  anchorText: "START YOUR PROJECT",
  anchorLink: "#workWithUs",
};
