import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "uiux.png",
    imageText2: "We design it for 'People'Be it Web or Mobile",
    imageText3: "USABILITY DEFINES BUSINESS SUCCESS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "connect with our experts ",
    },
  },
};

export const typeFourBannerData = {
  heading: "Top on-demand technologies implemented",
  text1:
    "Embarking on a development journey since the start, we've created a highly competent and professional team of Android developers abreast of latest version releases and technology tools. If, you're looking for a no-compromise, top Android app, contact us. We follow the latest trends and include them in our Android app development services.",
  description:
    "EVERYTHING YOU NEED TO BUILD, DEPLOY AND LAUNCH AN AMAZING ANDROID APP",
};

export const multiRowData = {
  text1: "400 MILLION USERS INTERACT WITH OUR DESIGNS",
  text2: "Stellar design + strategic thinking lead to pixel perfect screens",
  text3:
    "Simply put, our core strengths lie in the simplicity of the design yet providing gutsy, avant-garde and stimulating designs and experiences to our client's customers. A perfect mix of functional resources and visual elements is what we attempt to achieve with our adept team of designers and developers.",
  text4: " UI/UX solutions we offer include:",
  carouselData: [
    {
      iconName: `${companyName}-UIUX-FED`,
      label: "Front-end Development",
    },
    {
      iconName: `${companyName}-UIUX-ID`,
      label: "Interface Design",
    },
    {
      iconName: `${companyName}-UIUX-ED`,
      label: "Experience Design",
    },
    {
      iconName: `${companyName}-UIUX-CWD`,
      label: "Custom Web Design",
    },
    {
      iconName: `${companyName}-UIUX-RL`,
      label: "Responsive Layout",
    },
    {
      iconName: `${companyName}-UIUX-MU`,
      label: "Mobile UI/UX",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Let's Connect",
};

export const stackUsedData = {
  stackData: [
    {
      name: "UI",
      logo: "coding",
      techUsed: [
        {
          techName: "Photoshop",
          techLogo: "photoshop.png",
        },
        {
          techName: "Illustrator",
          techLogo: "illustrator.png",
        },
        {
          techName: "InDesign",
          techLogo: "indesign.png",
        },
        {
          techName: "Sketch",
          techLogo: "sketch.png",
        },
        {
          techName: "Zeplin",
          techLogo: "Zeplin.png",
        },
        {
          techName: "Material Design",
          techLogo: "material_design.png",
        },
        {
          techName: "CorelDRAW",
          techLogo: "coreldrow.png",
        },
      ],
      showButton: false,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "UX",
      logo: "server",
      techUsed: [
        {
          techName: "HTML5",
          techLogo: "html.png",
        },
        {
          techName: "CSS3",
          techLogo: "css.png",
        },
        {
          techName: "JavaScript",
          techLogo: "js.png",
        },
        {
          techName: "SASS",
          techLogo: "saas.png",
        },
        {
          techName: "LESS",
          techLogo: "less.png",
        },
        {
          techName: "Bootstrap",
          techLogo: "bootstrap.png",
        },
        {
          techName: "Foundation",
          techLogo: "foundation.png",
        },
        {
          techName: "jQuery Mobile",
          techLogo: "jquerymobile.png",
        },
        {
          techName: "IONIC",
          techLogo: "ionic.png",
        },
        {
          techName: "Dreamweaver",
          techLogo: "dreamweaver.png",
        },
        {
          techName: "Photoshop",
          techLogo: "photoshop.png",
        },
        {
          techName: "Illustrator",
          techLogo: "illustrator.png",
        },
        {
          techName: "Sketch",
          techLogo: "sketch.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeThreeBannerData = {
  heading: "Best design partner for all your needs",
  description: "SEE HOW WE MAKE YOUR PRODUCT ENGAGING AND USEFUL",
  details: [
    {
      iconName: `${companyName}-UIUX-BCJ`,
      label: "Build Customer Journeys",
      content:
        "Keeping end-users and their expectations in mind, we build up user personas of all projects, discover and map product's customer journey.",
    },
    {
      iconName: `${companyName}-UIUX-RP`,
      label: "Rapid Prototyping",
      content:
        "With fast, cost-effective, easy-to-change and talk-about concepts, we specialize in graphical user interface design and dev.",
    },
    {
      iconName: `${companyName}-UIUX-TVDS`,
      label: "Top Visual Design Systems",
      content:
        "We have an extensive experience crafting remarkable visual interfaces using style guides, grid systems, typography, mood boards.",
    },
    {
      iconName: `${companyName}-UIUX-PUD`,
      label: "Perfect UI Development",
      content:
        "A team of some of the world's top front-end developers who code high-performance HTML, CSS and JS interfaces.",
    },
    {
      iconName: `${companyName}-UIUX-AT`,
      label: "Advanced Technology",
      content:
        "The tools, frameworks and technologies used for UI/UX design at kdr are latest and as per industry standards.",
    },
    {
      iconName: `${companyName}-UIUX-QA`,
      label: "Quality Assurance",
      content:
        "We have a dedicated quality assurance team who are experts in testing methodologies and protocols.",
    },
    {
      iconName: `${companyName}-UIUX-IAC`,
      label: "Iterative & Collaborative",
      content:
        "We include clients in every decision we make at each phase of development and work iteratively for the changes required.",
    },
    {
      iconName: `${companyName}-UIUX-UCD`,
      label: "User-Centric Delivery",
      content:
        "Whether it's design or experience, information architecture or navigation model, the final project delivery is always user-centered.",
    },
  ],
};

export const resultInfo = {
  text1: "Only results matter to us",
  text2:
    "Because they help us gain client satisfaction and let our clients enjoy maximum profits",
  content: [
    {
      label: "Apps Developed By KDR Team",
      value: "350+",
      color: "green",
    },
    {
      label: "Client Retention Rate",
      value: "64%",
      color: "indigo",
    },
    {
      label: "App Downloads on App Stores",
      value: "1M+",
      color: "red",
    },
  ],
};
