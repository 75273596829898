import React from "react";

export const Type16Banner = ({ data }) => {
  return (
    <div className="py-16 px-4 xl:px-0 ">
      {data.heading && (
        <div className="text-center font-bold capitalize text-xl lg:text-3xl">
          {data.heading}
        </div>
      )}
      {data.description && (
        <div className="container mx-auto mt-8 text-base lg:text-lg text-gray-600 text-center">
          {data.description}
        </div>
      )}
      <div className="max-w-5xl mx-auto mt-16">
        {data.details.map((el,i) => (
          <div data-aos="fade-up" data-aos-delay={`50 * ${i + 1}`} className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 bg-gray-100 px-4 py-8 mt-4 rounded-md" key={i}>
            <p className="inline-flex items-center font-bold text-2xl tracking-wider justify-center md:justify-start">{el.title}</p>
            <div className="mt-4 md:mt-0">
              {el.list.map((el,i) => (
                <p className="text-gray-600" key={`${el}${i}`}>
                  <span className="inline-block w-2 h-2 rounded-full bg-blue-600 mr-6"></span>
                  {el}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
