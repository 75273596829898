import React, { useRef, useEffect } from "react";
import { Icon } from "../../lib/Icon/Icon";

export const ScrollToTop = () => {
  const scrollButtonRef = useRef(null);

  const showButton = () => {
    if (window.scrollY > window.innerHeight / 2) {
      scrollButtonRef.current.style.display = "block";
    } else {
      scrollButtonRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", showButton);
    return () => {
      document.removeEventListener("scroll", showButton);
    };
  }, []);

  return (
    <button
      className="hidden bg-blue-900 rounded-full p-4"
      data-aos="fade-in"
      style={{
        position: "fixed",
        right: "10px",
        bottom: "80px",
        zIndex: "1000",
      }}
      ref={scrollButtonRef}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <Icon iconName="chevron-up" classes="text-white w-8 h-8" />
    </button>
  );
};

export default ScrollToTop;
