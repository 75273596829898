import React from "react";
export const Icon = ({ iconName, classes }) => {
  const hrefLink = `#${iconName}`;
  const icon = (
    <svg className={classes}>
      <use href={hrefLink}></use>
    </svg>
  );

  return icon;
};
