import React from "react";
import CardTypeSix from "../Card/CardTypeSix/CardTypeSix";

const Type18Banner = ({ data }) => {
  return (
    <div className="bg-white px-4 md:px-2">
      <div className="max-w-6xl mx-auto text-center py-8 md:py-16">
        {data.heading && <h2 className="font-bold text-2xl md:text-4xl my-6">{data.heading}</h2>}
        <div className="flex flex-col">
          {data.cardData &&
            data.cardData.map((data, index) => (
              <CardTypeSix key={index} data={data} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Type18Banner;
