import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "hireteam.png",
    imageText2:
      "Hire Dedicated Developers for Web, Mobile, Cloud, IoT and More",
    imageText3: "GREAT VISION COMES WITH GREAT WORKFORCE",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "HIRE DEDICATED RESOURCES NOW",
    },
  },
};

export const typeThreeBannerData = {
  heading: "Why should you hire Dedicated Developers from us?",
  description:
    "TOP COMPANIES RELY ON Kdr FOR IMPORTANT PROJECTS BECAUSE WE DELIVER",
  details: [
    {
      label: "Certified Professionals",
      iconName: `${companyName}-Hire-Developers-CP`,
      content:
        "Largest team of certified professionals and industry experts competent in their own disciplines.",
    },
    {
      label: "Infrastructure in Place",
      iconName: `${companyName}-Hire-Developers-IIP`,
      content:
        "A full-time in-house team working in web and mobile app development with all the hardware and software infrastructure in place.",
    },
    {
      label: "Daily Reporting, Direct Control",
      iconName: `${companyName}-Hire-Developers-DRDC`,
      content:
        "With a dedicated resource, the client can have direct and open communication with the developer.",
    },
    {
      label: "Secured Development Environment",
      iconName: `${companyName}-Hire-Developers-SDE`,
      content:
        "Clients are assured of complete protection of their intellectual property and can have a secured development environment.",
    },
    {
      label: "Significant Cost Savings",
      iconName: `${companyName}-Hire-Developers-SCS`,
      content:
        "While there is a strong leadership to manage a group of dedicated developers, clients need not take the pain and enjoy best pricing options with zero hidden overheads.",
    },

    {
      label: "Overall Flexibility",
      iconName: `${companyName}-Hire-Developers-OF`,
      content:
        "You can manage the resources as per your needs and have the flexibility to get changes done until you are satisfied.",
    },
    {
      label: "Online Project Management",
      iconName: `${companyName}-Hire-Developers-OPM`,
      content:
        "No headaches of offline follow up as clients can track the work progress of the dedicated resource and the project on online PM tools like JIRA, GitLab, Hubstaff.",
    },
    {
      label: "On-Demand Selection",
      iconName: `${companyName}-Hire-Developers-ODS`,
      content:
        "After handpicking the resumes as per their business needs, our clients take the full charge of interviewing the candidates and select only if they are satisfied.",
    },
  ],
};

export const stackEmployedData = {
  text1: "Infinite Possibilities with Top Open Source Web Development Tools",
  text2: "HANDS-ON EXPERIENCE WITH OPEN SOURCE TECHNOLOGIES",
  textUnderline: true,
  para:
    "Outdated tools and technologies can harm your business. At Kdr, we use latest, reliable tools and technologies to offer top-notch open source development services. Our developers are competent in CMS development services.",
  techData: [
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
    {
      logo: "html.png",
      name: "HTML",
    },
  ],
};

export const stackUsedData = {
  text1: "Passionate professionals in niche technologies",
  text2: "PICK A DEVELOPER SKILL YOU ARE LOOKING FOR!",
  stackData: [
    {
      name: "Mobile App Developers",
      logo: `${companyName}-Hire-Developers-MAD`,
      techUsedPara:
        "Have an App Idea like Uber or WhatsApp? As a leading mobile app development facility, we’re a home to top-rated mobile app developers who are skilled and proficient enough to allow you a complete solution – from creating a perceptive prototype to developing clean codes to deploying and maintaining your solution with great competency.",
      techUsed: [
        {
          techName: "iPhone",
          techLogo: "apple.png",
        },
        {
          techName: "Android",
          techLogo: "android.png",
        },
        {
          techName: "PhoneGap",
          techLogo: "phonegap.png",
        },
        {
          techName: "Xamarin",
          techLogo: "xamarian.png",
        },
      ],
    },
    {
      name: "Web App Developers",
      logo: `${companyName}-Hire-Developers-WAD`,
      techUsedPara:
        "Find and hire dedicated web developers working since 2003.Seasoned programmers with technical breadth and depth in a variety of industries ranging from large businesses to startups. We have a bunch of developers adroit in multiple technologies.",
      techUsed: [
        {
          techName: "PHP",
          techLogo: "php.png",
        },
        {
          techName: "ASP.NET",
          techLogo: "aspnet.png",
        },
        {
          techName: "AngularJS",
          techLogo: "angularjs.png",
        },
        {
          techName: "Node.js",
          techLogo: "nodejs.png",
        },
        {
          techName: "CakePHP",
          techLogo: "cake-php.png",
        },
      ],
    },
    {
      name: "Open Source Developers",
      logo: `${companyName}-Hire-Developers-OSD`,
      techUsedPara:
        "An extensive pool of hand-picked CMS Developers under one roof.Enhance your business efficiency with the talented and most knowledgeable technophiles skilled in open source and Microsoft technologies to provide custom CMS development services. We guarantee seamless communication and result-oriented project management.",
      techUsed: [
        {
          techName: "WordPress",
          techLogo: "wordPress.png",
        },
        {
          techName: "Joomla",
          techLogo: "joomla.png",
        },
        {
          techName: "Drupal",
          techLogo: "drupal.png",
        },
        {
          techName: "Magento",
          techLogo: "magento.png",
        },
      ],
    },
    {
      name: "UI & UX Designers",
      logo: `${companyName}-Hire-Developers-UUD`,
      techUsedPara:
        "Passionate UI and UX designers with strong attention-to-detail and an aesthetic acumen.Top user interface design professionals are a click away to deliver excellent work on time within budget. Well-equipped designs that are unique and resourceful created by bonafide UXers for exceptional user experiences.",
      techUsed: [
        {
          techName: "UX Designer",
          techLogo: "photoshop.png",
        },
        {
          techName: "UX Developer",
          techLogo: "html.png",
        },
      ],
    },
  ],
  showButton: true,
  buttonLink: "#workWithUs",
  buttonText: "HIRE A DEVELOPER",
};

export const hireDedicated = {
  text1: "Hire Dedicated Developers from an in-house team",
  text2: "GET A PERFECTLY-FITTING CANDIDATE FOR EACH PURPOSE",
  para:
    "Without the hassles of freelancers and continuous monitoring of the developer’s work, you can have peace of mind by hiring dedicated developer and designer from our team at a reasonable price, customized to your requirements. We offer world-class development skills with an in-house team- you can hire dedicated web developers and mobile app experts.",
  data: [
    {
      iconName: "hourglass",
      heading: "Full-time Hiring",
      details: {
        duration: "8/hours per day, 5 days/week",
        communication: "Email, Skype, Phone",
        billing: "Monthly",
        hiring_period: "Minimum 1 Month",
      },
    },
    {
      iconName: "stopwatch",
      heading: "Part Time Hiring",
      details: {
        duration: "4/hours per day, 5 days/week",
        communication: "Email, Skype, Phone",
        billing: "Monthly",
        hiring_period: "Minimum 1 Month",
      },
    },
    {
      iconName: "clock",
      heading: "Hourly Hiring",
      details: {
        communication: "Email, Skype, Phone",
        billing: "Monthly",
        hiring_period: "Minimum 1 Month",
      },
    },
  ],
  showButton: true,
  buttonLeftText1: "Want a custom solution?",
  buttonLeftText2: "HIRE DEDICATED DEVELOPERS",
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const howHiringWorks = {
  text1: "How the hiring works?",
  para:
    "A hiring solution that perfectly fits your business requirements, time and budget parameters.It’s a quick 5-step process where you can get the right match to fulfill your development needs.",
  process: [
    {
      iconName: "mail",
      heading: "Submit Your Request",
    },
    {
      iconName: "search",
      heading: "Screen Resumes",
    },
    {
      iconName: "user-add",
      heading: "Interview Selected Candidate",
    },
    {
      iconName: "dollar",
      heading: "Final Selection Process",
    },
    {
      iconName: "thumbs-up",
      heading: "Terms & Contract",
    },
  ],
};
