import React from "react";
import { Icon } from "../Icon/Icon";

export const ServiceOfferedTypeTwo = ({ data }) => {
  return (
    <div className="py-16 px-4 xl:px-0">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between">
          {data.heading && (
            <p className="lg:max-w-md text-3xl text-center lg:text-left lg:text-4xl font-bold">
              {data.heading}
            </p>
          )}
          {data.description && (
            <p className="lg:max-w-lg text-base text-center lg:text-left text-gray-700">
              {data.description}
            </p>
          )}
        </div>
        {data.data && (
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
            {data.data.map((el, i) => (
              <div className="flex items-center" key={`${el}${i}`}>
                <Icon
                  iconName="badge-tick"
                  classes="w-8 h-8  text-resource-rightGradient flex-shrink-0"
                />
                <p className="ml-4">{el}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
