import React from "react";
import { TypeTwoImageCard } from "../Image/TypeTwoImageCard/TypeTwoImageCard";

export const Type15Banner = ({ data }) => {
  return (
    <div className="pt-16">
      {data.heading && (
        <p className="text-4xl font-bold text-center capitalize">
          {data.heading}
        </p>
      )}
      {data.description && <p className="text-base text-gray-600 max-w-2xl mx-auto text-center mt-5">{data.description}</p>}
      {data.imageDetails && (
        <div
          className={`grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-${data.imageDetails.length} mt-5`}
        >
          {data.imageDetails.map((el, i) => (
            <TypeTwoImageCard key={i} data={el} id={i} />
          ))}
        </div>
      )}
    </div>
  );
};
