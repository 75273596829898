import React from "react";
import { CardTypeThree } from "../Card/CardTypeThree/CardTypeThree";
import { Icon } from "../Icon/Icon";

export const HireDedicated = ({ data, formatOne, formatTwo }) => {
  return (
    <div className="py-16 px-4 lg:px-0">
      <div className="container mx-auto">
        {data.text1 && (
          <p className="text-center text-3xl md:text-4xl capitalize">
            {data.text1}
          </p>
        )}
        {data.text2 && (
          <p className="text-center uppercase text-md md:text-lg text-gray-700 mt-4">
            {data.text2}
          </p>
        )}
        {data.para && (
          <p className="text-center my-10 text-gray-800 mt-4">{data.para}</p>
        )}
        {data.card && <CardTypeThree data={data.card} />}
        {formatOne && (
          <>
            <div className="grid grid-flow-row grid-cols-1  lg:grid-cols-3 gap-10">
              {data.data &&
                data.data.map((el) => (
                  <div
                    className="px-4 py-8 text-center md:text-left border-2 border-gray-200 hover:shadow-lg"
                    key={el.iconName}
                  >
                    {el.iconName && (
                      <Icon
                        iconName={el.iconName}
                        classes="w-20 h-20 inline-block"
                      />
                    )}
                    {el.heading && (
                      <p className="text-xl font-bold text-blue-700 my-4">
                        {el.heading}
                      </p>
                    )}
                    {el.para && <p>{el.para}</p>}
                    {el.details &&
                      Object.keys(el.details).map((el1) => (
                        <p className="mb-4" key={el1}>
                          <span className="capitalize font-bold text-base">
                            {el1.replace("_", " ")}
                          </span>
                          : {el.details[el1]}
                        </p>
                      ))}
                  </div>
                ))}
            </div>
            {data.showButton && (
              <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between items-center mt-10">
                <div>
                  <p className="my-2 text-2xl">{data.buttonLeftText1}</p>
                  <p className="text-xl uppercase text-gray-600">
                    {data.buttonLeftText2}
                  </p>
                </div>
                <div className="mt-6 md:mt-0">
                  <a
                    className="inline-block p-4 text-white font-bold text-lg uppercase rounded-lg bg-orange-500"
                    href={data.buttonLink}
                  >
                    {data.buttonText}
                  </a>
                </div>
              </div>
            )}
          </>
        )}
        {formatTwo && (
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {data.process.map((el, i) => (
              <div
                key={el.iconName}
                className="p-6 flex flex-col justify-center text-center border-2 border-gray-200 hover:shadow-md items-center"
              >
                <p className="text-gray-500  mb-5 font-semibold">
                  <span className="font-bold inline-block text-main-blue">
                    0{i + 1}.
                  </span>
                  /05
                </p>
                <Icon
                  iconName={el.iconName}
                  classes="text-orange-500 w-16 h-16"
                />
                <p className="mt-4 font-bold text-lg">{el.heading}</p>
              </div>
            ))}
          </div>
        )}
        {data.bottomCards &&
          data.bottomCards.map((el, i) => (
            <div key={`${el.iconName}${i}`} className="py-4 mt-4">
              <CardTypeThree data={el} />
            </div>
          ))}
      </div>
    </div>
  );
};
