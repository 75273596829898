export const whyPageComponents = {
  "press-media": {
    navbar: true,
    image_banner: true,
    request_quote: true,
    type_one: true,
    footer: true,
  },
  "quality-assurance": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    request_quote: true,
    work_with_us: true,
    footer: true,
  },
  "awards-memberships": {
    navbar: true,
    image_banner_icon: true,
    type_one_card: true,
    request_quote: true,
    footer: true,
  },
  "our-partners": {
    navbar: true,
    image_banner_icon: true,
    type_11: true,
    request_quote: true,
    footer: true,
  },
  "project-communication-strategy": {
    navbar: true,
    image_banner_icon: true,
    type_four: true,
    timeline_type_one: true,
    request_quote: true,
    footer: true,
  },
  "our-clients": {
    navbar: true,
    image_banner_icon: true,
    type_11: true,
    request_quote: true,
    footer: true,
  },
  "engagement-models": {
    navbar: true,
    image_banner_icon: true,
    type_four: true,
    points_section: true,
    how_hire_works: true,
    request_quote: true,
    footer: true,
  },
  "our-development-process": {
    navbar: true,
    image_banner_icon: true,
    type_four: true,
    timeline_type_two: true,
    request_quote_type_two: true,
    footer: true,
  },
  "our-team": {
    navbar: true,
    image_banner: true,
    type_three: true,
    quote_banner: true,
    image_card: true,
    rq_image: true,
    footer: true,
  },
  "about-us": {
    navbar: true,
    image_banner: true,
    type_three: true,
    quote_banner: true,
    circle_cards: true,
    quote_banner_bottom: true,
    team_banner: true,
    info_banner: true,
    timeline_three: true,
    type_14: false,
    request_quote: true,
    footer: true,
  },
};
