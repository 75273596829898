import React from "react";
import { Icon } from "../../Icon/Icon";
export const IconBanner = ({ data }) => {
  return (
    <div className="relative py-16 px-4 xl:px-0 text-center">
      {<p className="text-4xl capitalize font-bold">{data.heading}</p>}
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-16 container mx-auto">
        {data.details.map((el) => (
          <div className="flex flex-col justify-center items-center">
            <Icon iconName={el.iconName} classes="w-20 h-20 text-gray-500"/>
            <p className="uppercase mt-4 font-bold text-base">{el.iconDescription}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
