import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

export const TypeFiveBanner = ({ data, showButton, showKeySolution }) => {
  return (
    <div className="text-left text-white  bg-gray-900 py-16" data-aos="fadein">
      <div className="w-full max-w-xs sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:container mx-auto">
        <p className="text-4xl capitalize leading-10 text-center">
          {data.text1}
        </p>
        <p className="text-base text-gray-500 mt-4 text-center">{data.text2}</p>
        <ul className="mt-10">
          {data.details.map((el) => (
            <li
              key={el.label}
              className="list-disc mt-16 mr-4"
              id="enterpriseItem"
            >
              <div className="flex items-center">
              {el.iconName && <Icon
                classes="w-16 h-16 text-orange-500"
                iconName={el.iconName}
              />}
              <div className="ml-4">
                <p className="font-bold text-xl">{el.label}</p>
                <p className="w-full max-w-6xl mt-4 text-md text-gray-600">
                  {el.content}
                </p>
              </div>
              </div>
            </li>
          ))}
        </ul>
        {showKeySolution && (
          <div className="mt-10">
            <p className=" text-2xl">{data.keySolutionsData.heading}</p>
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-4 mt-5">
              {data.keySolutionsData.details.map((el) => (
                <div
                  className="md:border-l-2 border-blue-700 pl-6 text-sm text-gray-300"
                  key={el.text1}
                >
                  <p className="mb-4">{el.text1}</p>
                  <p className="mb-4">{el.text2}</p>
                  <p>{el.text3}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {showButton &&
          (data.buttonDetails?.buttonType === "button" ? (
            <Link to={data.buttonDetails.buttonLink}>
              <button className="uppercase px-4 py-4 flex justify-center items-center w-full max-w-sm mx-auto mt-10 rounded-lg text-sm md:text-base bg-orange-500 text-white">
                {data.buttonDetails.buttonText}
              </button>
            </Link>
          ) : (
            <a
              href={data.buttonDetails.buttonScrollToId}
              className="uppercase px-4 py-4 flex justify-center items-center w-full max-w-sm mt-10 rounded-lg text-sm md:text-base bg-orange-500 text-white"
            >
              {data.buttonDetails.buttonText}
            </a>
          ))}
      </div>
    </div>
  );
};
