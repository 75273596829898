import React from "react";

export const ImageTypeThreeBanner = ({ data }) => {
  return (
    <div
      className="h-screen lg:h-70vh"
      style={{
        background: `url(${require(`../../assets/Images/${data.folderName}/${data.imageName}`)}) center center / cover no-repeat`,
      }}
      data-aos="fade-in"
    >
      <div className="flex flex-col justify-center items-center md:justify-start md:items-start max-w-6xl mx-auto pt-48 text-white text-center md:text-left px-4 lg:px-2">
        {data.tag && (
          <span
            className="uppercase py-1 px-2 tracking-widest bg-main-blue text-sm font-semibold"
            data-aos="fade-in"
          >
            {data.tag}
          </span>
        )}
        {data.heading && (
          <p
            className="uppercase max-w-5xl text-xl md:text-3xl lg:text-5xl leading-tight mt-3 font-bold tracking-wider"
            data-aos="fade-in"
          >
            {data.heading}
          </p>
        )}
        {data.description && (
          <p className="max-w-4xl mt-6 text-sm md:text-lg" data-aos="fade-in">
            {data.description}
          </p>
        )}
      </div>
    </div>
  );
};
