export const dominateData = {
  heading: "Handling your supplies with great care!",
  para:
    "With our pro-technology solutions, we have helped businesses to form compliance with the urging customer demands and fast-shifting industry trends. No matter how difficult or specific your choices pertaining to the solutions are, we always strive to guide and serve you with the brightest and finest you can have – bringing in the best of value, quality and trait together. When it’s Kdr, look for nothing less than a flawless solution to work best with your idea of Transportation and Automotive – both on and off the road!",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "With our class-leading transport & automotive solutions we allow you to own eminent possibilities in your trait of business. Our proven solutions commit to serve you with streamlined processes, fluid communication and pioneering tools to attain quality and excellence with every slice of your offering. ",
  text2:
    "We bring you the most resourceful range of solution in your space of offering, covering every aspect of your business – ranging across product development, ERP and CRM, inventory management, enterprise mobility solutions, API bridging application, customer service and support solutions. Our advanced functionality driven solutions allow you to explore the most out of prevalent opportunities and trends.   Which combine with your resources to utilize perceptive digital roles and high-end functionality traits to bring you the best of your Transportation & Automotive endeavor.",
};

export const challengesData = {
  heading: "Challenges in Transport Industry",
  description: "TO BE DEALT WITH NIFTY HANDS",
  details: [
    {
      label: "Dependency On Traditional Tools",
      content:
        "Businesses in the Transport and Automotive industry are hardware driven. They largely depend on traditional tools and facilities to process their solutions. This makes it difficult for them to switch to different processes and plan their resources as per the changed requirements.",
    },
    {
      label: "Regulation Compliance",
      content:
        "There are different safety and environmental regulations imposed by government agencies that need to be convincingly attended and served by companies to get approved for quality and standards. If not done right and in time this could result in adverse outcomes.",
    },
    {
      label: "Competition",
      content:
        "The modern-day transportation businesses are operating amidst dynamic global competition. This makes it important for automotive ventures to come up with aggressive measures and arrange for sophisticated supplies to crack it to success.",
    },
    {
      label: "Isolated Groups",
      content:
        "Isolated systems and remote assemblies in the global transport ecosystem are difficult to identify and comprehend. This makes the process of decision making complicated as the data you are referring to fail to respond to queries and process requests – gravely dropping the level of quality and productivity.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "Be it logistics management, fleet assistance, in-vehicle connectivity, operational tracking, supply chain management, or just anything that adds value to your automotive venture – we are all equipped to serve you with the best of innovation and quality in your realm of business – with solutions that define quality and efficiency.",
  details: [
    {
      label: "Infusing Collaboration",
      content:
        "We design solutions to stimulate collaboration and streamline your processes to act and deliver optimally. To allow you significant business value in your transport business we infuse collaboration in your functional ecosystem – allowing you solutions that commit to scalable architecture and high-end engineering references.",
    },
    {
      label: "Quality Real-Time Results",
      content:
        "Our extensive experience working in the transportation industry allows us to identify and serve your information needs well. We combine domain experts and technology professionals to develop engrossed decision-making matrix and communication framework to allow you highly resourceful and relevant solution to process quality real-time results.",
    },
    {
      label: "Process-Controlled Development",
      content:
        "At Kdr, we follow strict engineering disciplines and implement fastidious techniques and practices to help us assure quality at all levels. Through our process-controlled approach in development we are able to deploy complete traceability and firmness in building value-driven solutions addressing your specific business needs.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. BI and Data Warehousing Solutions",
        text2: "#2. Dealer Management Systems (DMS)",
        text3: "#3. E-Commerce, CRM and ERP Solutions",
      },
      {
        text1: "#4. Supply Chain Management (SCM)",
        text2: "#5. Management Information Systems (MIS)",
        text3: "#6. Product Life Cycle Management (PLCM)",
      },
      {
        text1: "#7. Enterprise Mobility Solutions",
        text2: "#8. Software Maintenance and Support",
        text3: "#9. Logistics and Inventory Solutions",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
