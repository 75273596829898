export const dominateData = {
  heading: "Build smarter restaurants increase customer loyalty",
  para:
    "Our web and mobile ordering services are exactly what your restaurant needs. You can save a lot on employee labor costs, increase your sales and allow customers to place orders when they are on-the-go. We incorporate the top features in your online services including mobile payments, customized menu items, robust backend order management system, admin control over order listing and much more.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "A large part of food and restaurant industry is driven by millennials now. Their relationship with food, its packaging and its delivery is special, and they want to share their experiences with family, friends, colleagues and neighbors..",
  text2:
    "Development, design and project management teams at Kdr have helped various food businesses, startups, cafés, production houses and restaurants to cater to changing demands of food enthusiasts and people around the world, mainly in online food delivery, sharing restaurant reviews and rating, favorite food photo sharing as well as their internal systems like managing the inventory, logistics, loyalty, feedback management and efficient restaurant management across multiple locations.",
};

export const challengesData = {
  heading: "Challenges in food & restaurant industry",
  description: "STREAMLINE BUSINESS CONTROL TO INCREASE FOODIE’S DELIGHT",
  details: [
    {
      label: "Retaining Customers",
      content:
        "With multifarious brands and so much competition in the market, people have a lot of options to switch and they do that for a new taste and new preparation. People get bored easily and they keep experimenting so it’s difficult to retain customers.",
    },
    {
      label: "Food Standardization",
      content:
        "With multifarious brands and so much competition in the market, people have a lot of options to switch and they do that for a new taste and new preparation. People get bored easily and they keep experimenting so it’s difficult to retain customers.",
    },
    {
      label: "Managing Logistics",
      content:
        "Another big challenge the restaurant and food businesses have to face is of managing the inventory and logistics since food stuff are sensitive and exposed. Vendors must work on eliminating any concerns that the customers may have other than freshness and integrity in supply chain.",
    },
    {
      label: "Innovating The Food Space",
      content:
        "Startups emerging with innovative, technologically-advanced ideas each new day, it’s imperative for others to discover and implement ways to connect more with existing and attract new customers with digitally expressive, modern strategies.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "We've served 50 million hungry users at their convenience. Positioned among the leading app developers worldwide, our team has that digital acumen to take your food business to all types of audiences and expand your reach through the introduction of avant-garde technology features helping every food shop survive the competition and make an impactful brand in the market",

  details: [
    {
      label: "Online ordering, Easy payments",
      content:
        "Having skilled web and mobile teams who understand today’s business strategies and market scope, every company whether big or small can outlive the challenges caused in retaining customers as apart from developing a new app or website for your restaurant, we can renovate it with novel, user-centric components like mobile wallets, order on table, fastest checkout options for home delivery, seamless online ordering experiences and much more.",
    },
    {
      label: "Enterprise Mobility",
      content:
        "In order to manage and streamline the logistics, shipments and delivery process, we develop the best-in-class enterprise apps that can trace and track all internal activities assigned to individuals and staff members in the restaurant, across multiple locations (if it’s a chain). This simplifies much of the data analysis and data storage along with task management and evaluating employee’s performance on the basis of customer satisfaction level that can be judged by rating and reviews.",
    },
    {
      label: "Create a brand",
      content:
        "While each one of you are focusing on becoming an identifiable, a big brand, we help you in your journey in today’s food industry. With us, there are limitless possibilities to enter into the field as a startup or grow as an enterprise through a website or mobile app. Since we care about our client’s customers the most, every project we design and develop is well though-out and implemented with more viable and future-ready solutions, delivering customized user experiences, increasing profits and gains.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Web Development",
        text2: "#2. Restaurant App Development (Android & iPhone)",
        text3: "#3. Mobile (Digital) Ordering",
      },
      {
        text1: "#4. POS Software",
        text2: "#5. On-demand Delivery",
        text3: "#6. Cloud-based solutions",
      },
      {
        text1: "#7. Order Management System",
        text2: "#8. Inventory Management",
        text3: "#9. CRM Solutions",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
