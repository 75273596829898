import React from "react";

export const TypeOneBanner = ({
  text1,
  text2,
  tabData,
  id,
  buttonType,
  selectedId,
}) => {
  return (
    <div className="relative" data-aos="fade-in">
      <div className="absolute downArrow"></div>
      <section
        className="text-center px-5 pt-10 pb-5 bg-gradient-resource text-white"
        id={id}
      >
        <p className="text-xl md:text-2xl lg:text-3xl font-bold capitalize">
          {text1}
        </p>
        <p className="text-sm md:text-base mt-3 container text-gray-300 mx-auto">
          {text2}
        </p>
      </section>
      {tabData && (
        <section
          className={`${
            buttonType === "button"
              ? "relative"
              : "sticky top-88 md:top-103 z-10"
          } bg-gradient-resource flex items-center justify-center`}
        >
          <div
            id="tabData"
            className={`w-full md:max-w-6xl text-white md:py-4 grid grid-flow-row grid-cols-${
              tabData.length % 2 === 0 ? 2 : 1
            } row-gap-2 md:grid-cols-${tabData.length}`}
          >
            {tabData.map((el) =>
              buttonType === "button" ? (
                <button
                  key={el.label}
                  id={el.link.replace("#", "").replace("-", "_")}
                  className={`${
                    selectedId === el.link.replace("#", "").replace("-", "_")
                      ? "bg-white text-main-blue"
                      : "bg-transparent"
                  } tabLink text-sm md:text-base uppercase font-bold py-4 text-center`}
                >
                  {el.label}
                </button>
              ) : (
                <a
                  key={el.label}
                  href={el.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tabLink text-sm md:text-base uppercase font-bold py-4 text-center hover:bg-white hover:text-main-blue"
                >
                  {el.label}
                </a>
              )
            )}
          </div>
        </section>
      )}
    </div>
  );
};
