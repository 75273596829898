import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

export const TypeThreeBanner = ({
  data,
  backgroundColor,
  buttonLink,
  buttonText,
  showButton,
  buttonScrollToId,
  buttonType,
  itemsToShow,
  showBorder,
}) => {
  return (
    <div
      data-aos="fade-in"
      className={`py-16 ${
        backgroundColor ? `${backgroundColor} text-white` : "bg-gray-200"
      }`}
    >
      {data.semiHeading && (
        <p className="w-full container mx-auto text-sm md:text-base mt-2 mb-4 text-gray-600 text-center uppercase">
          {data.semiHeading}
        </p>
      )}
      <p className="w-full max-w-5xl capitalize mx-auto text-2xl md:text-3xl lg:text-4xl text-center">
        {data.heading}
      </p>
      {data.description && (
        <p className="w-full max-w-6xl mx-auto text-sm md:text-base mt-2 mb-4 text-gray-600 text-center uppercase">
          {data.description}
        </p>
      )}
      {data.para && (
        <p className="mt-8 mb-4 w-full max-w-5xl mx-auto text-center text-base text-gray-600">
          {data.para}
        </p>
      )}
      {data.para2 && (
        <p className="mt-8 mb-4 w-full max-w-5xl mx-auto text-center text-base text-gray-600">
          {data.para2}
        </p>
      )}
      {data.details && (
        <div
          className={`w-full max-w-6xl mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-${
            itemsToShow ? itemsToShow : 4
          } col-gap-6 row-gap-12 mt-12 text-center md:text-left`}
        >
          {data.details.map((el, i) => (
            <div
              data-aos="flip-down"
              key={`${el.label}${i}`}
              className={`px-4 ${
                showBorder
                  ? itemsToShow === 2
                    ? "xl:border-l-4 border-blue-700 py-2"
                    : "border-2 border-gray-400 py-6"
                  : "border-transparent"
              }`}
            >
              {el.iconName && <Icon classes="w-12 h-12 text-blue-400" iconName={el.iconName}/>}
              <p className="font-bold text-lg md:text-xl my-4">{el.label}</p>
              <p className="text-sm text-gray-600">{el.content}</p>
            </div>
          ))}
        </div>
      )}
      {showButton &&
        (buttonType === "button" ? (
          <Link to={buttonLink}>
            <button className="uppercase font-bold text-white px-4 rounded-md flex justify-center items-center w-full max-w-xs mt-10 mx-auto py-4 bg-orange-500">
              {buttonText}
            </button>
          </Link>
        ) : (
          <a
            href={buttonScrollToId}
            className="uppercase font-bold px-4 py-4 flex justify-center items-center w-full max-w-sm mt-10 mx-auto rounded-lg text-sm md:text-base bg-orange-500 text-white"
          >
            {buttonText}
          </a>
        ))}
    </div>
  );
};
