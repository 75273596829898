import React from "react";
import { CommonCarousel } from "../../lib/Carousel/CommonCarousel";
import { data } from "../HappyClients/happyData";
export const HappyClients = () => {
  return (
    <div
      className="relative"
      style={{
        background: `url(${require("../../assets/Images/people.jpg")})`,
        backgroundPosition: "center",
        backgroundRepeat: "repeat ",
        backgroundSize: "contain",
      }}
    >
      <img
        className="hidden xl:block absolute bottom-0"
        src={require("../../assets/Images/testimonial.png")}
        style={{
          left: "44px",
          transform: "rotate(32deg)",
          bottom: "60px",
        }}
        alt=""
      />
      <div className="flex flex-col justify-center text-center py-10 bg-gray-300 bg-opacity-90">
        <p className="italic text-base font-bold tracking-wide mt-2 uppercase">
          / CLIENTS SPEAK- STORIES FROM OUR CLIENTS /
        </p>
        <p className="font-bold text-2xl md:text-3xl mb-10 uppercase">
          Our happy clients have a lot to tell you
        </p>
        <CommonCarousel data={data} pauseTime={2000} slideSpeed={6000} />
      </div>
    </div>
  );
};
export default HappyClients;
