import React from "react";
import { useLocation } from "react-router-dom";
// import { AppCarousel } from "../../../lib/Carousel/AppCarousel";
import { MultiRowCarousel } from "../../../lib/Carousel/MultiRowCarousel";
import { HireDedicated } from "../../../lib/HireDedicated/HireDedicated";
import { ImageTypeOneBanner } from "../../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { ImageTypeTwoBanner } from "../../../lib/ImageTypeTwoBanner/ImageTypeTwoBanner";
import { ResultInfo } from "../../../lib/ResultInfo/ResultInfo";
import { ServicesOffered } from "../../../lib/ServicesOffered/ServicesOffered";
import { StackEmployed } from "../../../lib/StackEmployed/StackEmployed";
import { StackUsed } from "../../../lib/StackUsed/StackUsed";
import { TypeEightBanner } from "../../../lib/TypeEightBanner/TypeEightBanner";
import { TypeFourBanner } from "../../../lib/TypeFourBanner/TypeFourBanner";
import { TypeNineBanner } from "../../../lib/TypeNineBanner/TypeNineBanner";
import { TypeSevenBanner } from "../../../lib/TypeSevenBanner/TypeSevenBanner";
import { TypeSixBanner } from "../../../lib/TypeSixBanner/TypeSixBanner";
import { TypeThreeBanner } from "../../../lib/TypeThreeBanner/TypeThreeBanner";
import { Footer } from "../../Footer/Footer";
import { Navbar } from "../../Navbar/Navbar";
import { WorkWithUs } from "../../WorkWithUs/WorkWithUs";
import { webPageComponent } from "./WebPageComponent";

export const Web = ({ url }) => {
  const imageTypeTwoBannerData = require(`./${url}`)["imageTypeTwoBannerData"];
  const whichComponents = webPageComponent[url];
  const stackUsedData = require(`./${url}`)["stackUsedData"];
  const servicesOffered = require(`./${url}`)["servicesOffered"];
  // const AppCarouselData = require(`./${url}`)["AppCarouselData"];
  const typeSixBannerData = require(`./${url}`)["typeSixBannerData"];
  const userCentric = require(`./${url}`)["userCentric"];
  const multiRowData = require(`./${url}`)["multiRowData"];
  const stackEmployedData = require(`./${url}`)["stackEmployedData"];
  const typeFourBannerData = require(`./${url}`)["typeFourBannerData"];
  const typeThreeBannerData = require(`./${url}`)["typeThreeBannerData"];
  const imageTypeOneBannerData = require(`./${url}`)["imageTypeOneBannerData"];
  const resultInfo = require(`./${url}`)["resultInfo"];
  const openSourceData = require(`./${url}`)["openSourceData"];
  const typeEightBannerData = require(`./${url}`)["typeEightBannerData"];
  const topTypeThreeBannerData = require(`./${url}`)["topTypeThreeBannerData"];
  const typeNineBannerData = require(`./${url}`)["typeNineBannerData"];

  const location = useLocation().key;

  return (
    <div key={location}>
      {whichComponents.navbar && (
        <Navbar otherPages={true} transparent={true} />
      )}
      {whichComponents.image_banner && (
        <ImageTypeOneBanner
          imageName={imageTypeOneBannerData.imageSection.imageName}
          folderName={imageTypeOneBannerData.imageSection.folderName}
          imageText2={imageTypeOneBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeOneBannerData.imageSection.imageText3}
          buttonType={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonType
          }
          buttonLink={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonLink
          }
          buttonText={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonText
          }
        />
      )}
      {whichComponents.type_two_image_banner && (
        <ImageTypeTwoBanner
          imageName={imageTypeTwoBannerData.imageSection.imageName}
          folderName={imageTypeTwoBannerData.imageSection.folderName}
          imageText2={imageTypeTwoBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeTwoBannerData.imageSection.imageText3}
          points={imageTypeTwoBannerData.imageSection.points}
          buttonType={imageTypeTwoBannerData.imageSection.buttonType}
          buttonLink={imageTypeTwoBannerData.imageSection.buttonLink}
          buttonText={imageTypeTwoBannerData.imageSection.buttonText}
        />
      )}
      {whichComponents.top_type_three && (
        <TypeThreeBanner
          data={topTypeThreeBannerData}
          backgroundColor="bg-gray-900"
          showButton={false}
        />
      )}
      {whichComponents.type_seven && <TypeSevenBanner data={openSourceData} />}
      {whichComponents.multi_row && (
        <MultiRowCarousel data={multiRowData} showButton={true} />
      )}
      {whichComponents.type_six && <TypeSixBanner data={typeSixBannerData} />}
      {whichComponents.user_centric_solutions && (
        <HireDedicated data={userCentric} formatTwo={true} />
      )}
      {whichComponents.type_four && (
        <TypeFourBanner data={typeFourBannerData} showArrow={true} />
      )}
      {whichComponents.stack_used && <StackUsed data={stackUsedData} />}
      {whichComponents.stack_employed && (
        <StackEmployed
          data={stackEmployedData}
          background="bg-contacPageFormRightColor-darkBlue"
        />
      )}
      {whichComponents.service_offered && (
        <ServicesOffered data={servicesOffered} />
      )}
      {whichComponents.type_three && (
        <TypeThreeBanner data={typeThreeBannerData} />
      )}
      {whichComponents.result_info && <ResultInfo data={resultInfo} />}
      {whichComponents.type_eight && (
        <TypeEightBanner data={typeEightBannerData} />
      )}
      {whichComponents.type_nine && (
        <TypeNineBanner data={typeNineBannerData} />
      )}
      {/* {whichComponents.app_carousel && (
        <AppCarousel appData={AppCarouselData} showButtn={true} />
      )} */}
      {whichComponents.work_with_us && <WorkWithUs />}
      {whichComponents.footer && <Footer />}
    </div>
  );
};
