import { companyName } from "../../config";

export const imageTypeThreeBannerData = {
  tag: "Artificial Intelligence",
  heading: "ENTERPRISE ARTIFICIAL INTELLIGENCE SOFTWARE",
  description:
    "Use the immense computational capabilities of AI-based software to dramatically improve the bottom line of your business. Artificial Intelligence software solutions like machine learning models and artificial intelligence apps will help you automate the operations of any department, ensure fail-safe decision making with predictive models that analyse data and propose spot-on information, safeguard your business physically and digitally, and significantly increase the productivity of your employees by working alongside them.",
  folderName: "solutions/solution",
  imageName: "ai.png",
};

export const type17Data = {
  heading: "ARTIFICIAL BRAINPOWER FOR THE ENTERPRISE OF THE FUTURE",
  folderName: "why/Team",
  imageName: "ai.png",
  list: [
    {
      iconName: `${companyName}-AI-GAS`,
      heading: "GREATER AUTOMATION",
      description:
        "From sales to supply chain management to customer service, AI-based software solutions are unmatched in precise automation of any business operation.",
    },
    {
      iconName: `${companyName}-AI-GBSS`,
      heading: "GREATER BUSINESS SECURITY",
      description:
        "Employ AI-based technologies to protect your business digitally and physically.",
    },
    {
      iconName: `${companyName}-AI-GEES`,
      heading: "GREATER BUSINESS EFFICIENCY",
      description:
        "Leverage AI-based software solutions to immediately derive insights from your Big Data, and make fail-safe decisions at every turn, improving the overall business efficiency.",
    },
    {
      iconName: `${companyName}-AI-GPS`,
      heading: "GREATER PRODUCTIVITY ",
      description:
        "Delegate everyday tasks like reporting to AI-based systems, and increase the productivity and job satisfaction of your employees.",
    },
  ],
};

export const type18Data = {
  cardData: [
    {
      title: "GREATER AUTOMATION",
      iconName: `AI`,
      description:
        "Use custom machine learning models and other AI-based software solutions to automate the operations across all departments: sales, finance, marketing, human resources, customer service, production, or any other. Employ machine learning in sales to quickly single out prospects, automate the entire production chain, and let AI act as a headhunter in HR. AI-based solutions are characterised by computational accuracy and quick processing, allowing your business to operate faster and grow exponentially.",
      points: [
        "Artificial Intelligence chat bots and apps ",
        "Machine learning models ",
        "Neural networks and deep learning ",
        "Visual information processing ",
        "Cloud-based AI models",
      ],
    },
    {
      title: "GREATER ENTERPRISE EFFICIENCY ",
      iconName: `${companyName}-AI-GEE`,
      description:
        "Use a variety of AI-based software solutions to instantly derive insights from your Big Data. Build predictive machine learning models to determine how your company will grow after an investment. Use the predictive models to gain customer behaviour insights. AI-based technologies quickly analyse vast amounts of data and present information that ensures fail-safe decision making.",
      points: [
        "Predictive machine learning models",
        "Data generation and augmentation ",
        "Sentiment analysis ",
        "Data analysis management ",
        "Raw data management",
      ],
    },
    {
      title: "GREATER BUSINESS SECURITY",
      iconName: `${companyName}-AI-GBS`,
      description:
        "Leverage the virtual cryptocurrency within an app or a marketplace to create more value for its first users and ensure fast early network growth. Your early adopters will benefit from app token cost growth, stimulating rapid platform growth by attracting new users.",
      points: [
        "Custom machine learning models ",
        "Voice recognition ",
        "Video search ",
        "Neural networks ",
        "Face recognition",
      ],
    },
    {
      title: "GREATER PRODUCTIVITY",
      iconName: `${companyName}-AI-GP`,
      description:
        "Leverage AI-based solutions at your enterprise in a “human-augmented approach,” when your employees work alongside artificial intelligence software. Use AI-based speech-to-text on client meetings, and let it comprise a contract automatically. Create your own proactive AI assistant to help your employees on a daily basis. Use AI-enabled solutions to remove workflow impediments and single out actions that can be assisted and automated with AI-based solutions.",
      points: [
        "Language understanding ",
        "AI enterprise apps ",
        "Voice processing ",
        "Text-to-speech AI ",
        "Speech-to-text AI",
      ],
    },
  ],
};
