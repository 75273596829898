import React from "react";

export const LoadMore = ({apps, loadMoreText, initialArray, setInitialArray}) => {
  const appsLength = apps.length;
  let addItems = 8;

  const loadMoreHandler = () => {
    if (initialArray.length >= appsLength) {
      document.getElementById("loadMore").classList.add("cursor-not-allowed");
      document.getElementById("loadMore").innerText = "No more content";
    } else {
      setInitialArray((prevState) => [
        ...prevState,
        ...apps.slice(addItems, addItems + 8),
      ]);
      addItems = addItems + 8;
    }
  };

  return (
    <button
      onClick={loadMoreHandler}
      className="bg-gray-300 rounded-lg shadow-md hover:shadow-xl transition-all duration-200 ease-in-out mb-8 text-black py-4 px-2 md:max-w-xs mx-6 md:mx-auto"
      id="loadMore"
    >
      {loadMoreText}
    </button>
  );
};
