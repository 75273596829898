import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "wp.png",
    imageText2: "WordPress Web Development Solutions that Withstand",
    imageText3: "WE PLAN, WE CREATE AND WE DELIVER.",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get Estimation for your project",
    },
  },
};

export const multiRowData = {
  text1: "RANKED AS PREMIER WORDPESS DEVELOPMENT COMPANY",
  text2:
    "WordPress development services multi-feature, customized web solutions",
  text3:
    "With hands-on experience in delivering premium WordPress solutions to our global clientele, we, at Kdr Soft Solutions, commit to endeavor quality, stability and solidity. Our wordpress developers represent a perfect fusion of skill, expertise and talent, which helps us in achieving our targets within set time frames.",
  text4: "The various WordPress web development services we offer include:",
  carouselData: [
    {
      iconName: `${companyName}-wordpress-CD`,
      label: "CMS Development",
    },
    {
      iconName: `${companyName}-wordpress-WWD`,
      label: "WordPress Web Development",
    },
    {
      iconName: `${companyName}-wordpress-TD`,
      label: "Theme Development",
    },
    {
      iconName: `${companyName}-wordpress-CPD`,
      label: "Custom Plug-In Development",
    },
    {
      iconName: `${companyName}-wordpress-WM`,
      label: "WordPress Migration",
    },
    {
      iconName: `${companyName}-wordpress-MS`,
      label: "Maintenance & Support",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "let's Connect",
};

export const stackUsedData = {
  stackData: [
    {
      name: "Languages",
      logo: `${companyName}-wordpress-L`,
      techUsed: [
        {
          techName: "PHP",
          techLogo: "php.png",
        },
        {
          techName: "JavaScript",
          techLogo: "js.png",
        },
      ],
    },
    {
      name: "Databases",
      logo: `${companyName}-wordpress-D`,
      techUsed: [
        {
          techName: "MySQL",
          techLogo: "mysql.png",
        },
        {
          techName: "MongoDB",
          techLogo: "mongo_db.png",
        },
      ],
      showButton: false,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "dashboard.jpg",
};

export const typeFourBannerData = {
  heading:
    "Top WordPress solutions from top-performing WordPress development company.",
  text1:
    "Keeping up-to-date with latest market trends and technologies, our stellar developers produce customized awe-inspiring products that not only elevate your online presence & brand image but also help in achieving desired business objectives.",
  description: "CUSTOMIZED PROCEDURES THAT ELEVATE BUSINESS",
};

export const resultInfo = {
  text1: "Results motivate uss",
  text2:
    "Companies that have worked with us have achieved excellent ROI and conversions",
  content: [
    {
      label: "Website Developed By KDR Team",
      value: "350+",
      color: "green",
    },
    {
      label: "Client Satisfaction Rate",
      value: "90%",
      color: "indigo",
    },
    {
      label: "Total Countries Served",
      value: "5+",
      color: "red",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Rely on us for outstanding WordPress development services",
  description: "BECAUSE EXPERTS DON’T DISAPPOINT",
  details: [
    {
      label: "Expert Coders",
      iconName: `${companyName}-wordpress-EC`,
      content:
        "Our experts develop WordPress web development solutions with the highest degree of knowledge and expertise.",
    },
    {
      label: "Search Engine Friendly",
      iconName: `${companyName}-wordpress-SEF`,
      content:
        "We develop WordPress websites that aren't just user friendly and easy to navigate but are also search engine friendly.",
    },
    {
      label: "Extensible & Reliable Solutions",
      iconName: `${companyName}-wordpress-ERS`,
      content:
        "We take the two aspects, reliability and extensibility, very seriously during development assuring terrific response.",
    },
    {
      label: "Satisfaction Guaranteed",
      iconName: `${companyName}-wordpress-SG`,
      content:
        "At no cost, we compromise on our clients’ satisfaction as we strive to achieve it by all means.",
    },
    {
      label: "Tactical Approach",
      iconName: `${companyName}-wordpress-TA`,
      content:
        "We devise effective strategies and implement a tactical plan of action to achieve the invincible.",
    },
    {
      label: "Clients Matter",
      iconName: `${companyName}-wordpress-CM`,
      content:
        "We stand up for our clients to help them achieve the undiscovered heights.",
    },
    {
      label: "Budget-Friendly Solutions",
      iconName: `${companyName}-wordpress-BFS`,
      content:
        "Without compromising on quality and sophistication, we deliver products within your budget.",
    },
    {
      label: "Bug-Free Websites",
      iconName: `${companyName}-wordpress-BFW`,
      content:
        "No matter what, we ensure that there are no glitches in the product and it runs flawlessly.",
    },
  ],
};
