import React from "react";
import { Link } from "react-router-dom";

import { siteMapData } from "./siteMapData";
import { Navbar } from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";

const Sitemap = () => {
  return (
    <div className="max-h-full">
      <Navbar otherPages={true} transparent={false} />
      <div className="pt-40 pb-20 px-10 mx-auto max-w-6xl">
        <h1 className="text-lg md:text-2xl lg:text-4xl capitalize font-bold text-center">
          Sitemap
        </h1>
        <div className="mt-4">
          {siteMapData.map((eachLink) => {
            return (
              <div>
                <h2 className="text-left text-base font-bold uppercase">
                  {eachLink.sectionName}
                </h2>
                <ul className="list-disc list-inside my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-auto ">
                  {eachLink.links.map((link) => (
                    <li className="text-gray-600 hover:text-blue-600 my-2 hover:font-bold">
                      <Link to={`${link.link}`}>{link.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sitemap;
