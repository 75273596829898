import React from "react";
import { Icon } from "../Icon/Icon";

export const TypeNineBanner = ({ data }) => {
  return (
    <div
      className="text-white py-16"
      style={{
        background: `url(${require("../../assets/Images/services/Web/web.png")})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container mx-auto text-center">
        {data.heading && (
          <p className="text-4xl mb-5 capitalize">{data.heading}</p>
        )}
        {data.description && (
          <p className="text-base mb-5 uppercase text-gray-400">
            {data.description}
          </p>
        )}
        {data.para1 && <p className="mt-5">{data.para1}</p>}
        {data.para2 && <p className="mt-5">{data.para2}</p>}
        {data.list && (
          <>
            <p className="my-5">{data.list.heading}</p>
            <div className="grid grid-flow-row grid-cols-2 gap-16 mt-8 max-w-6xl mx-auto">
              {data.list.points.map((el, i) => (
                <div className="relative bg-gray-700 bg-opacity-75 py-8 pl-16 pr-2 max-w-xl rounded-md text-left shadow-lg" key={i}>
                  <span
                    className="absolute bg-gradient-blue-green inline-block p-4 rounded-md shadow-2xl"
                    style={{
                      top: "50%",
                      left: 0,
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Icon iconName={el.iconName} classes="w-12 h-12" />
                  </span>
                  <p className="font-bold">{el.title}</p>
                  <p>{el.description}</p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
