export const copyrightContentData = `© Copyright 2021, All Rights Reserved, Kdr Soft® Solutions. `;

export const footerConnectWithUsSocialIcons = {
  label: "connect with us",
  socialProfiles: [
    {
      socialLabel: "facebook",
      socialURL: "https://facebook.com/kdrsoftsolutions",
    },
    {
      socialLabel: "twitter",
      socialURL: "https://twitter.com/kdrsoftsolution",
    },
    {
      socialLabel: "linkedin",
      socialURL: "https://linkedin.com/company/kdrsoftsolutions",
    },
    {
      socialLabel: "instagram",
      socialURL: "https://instagram.com/kdrsoftsolutions",
    },
    {
      socialLabel: "whatsapp",
      socialURL: "https://api.whatsapp.com/send?phone=917399884433",
    },
    
  ],
};

export const footerNewsLetterData = {
  label: "newsletter",
  urlText: "Get the latest industry news and insights",
};

export const footerClientLogos = ['footer-client-logo5','ms','googlepartner','facebook'];

export const footerCareersData = {
  label: "careers",
  jobLabel: "We are hiring!",
};
