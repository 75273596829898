export const disclaimerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "disclaimer.png",
    imageText2: "KDR SOFT SOLUTIONS LEGAL DISCLAIMER",
  },
  typeFourBannerData: {
    heading: "Disclaimer",
    
  },
  policyData: `<p style="text-align: justify;"><strong>LEGAL DISCLAIMER<br /><br /></strong></p>
  <p style="text-align: justify;"><strong>Kdr Soft Solutions Private Ltd.</strong> ("KDR") makes available information and materials (the "Information") on <strong>www.kdrsoftsolutions.com</strong>, subject to the&nbsp;<a href="https://www.kdrsoftsolutions.com/terms-conditions">Terms of Use</a>&nbsp;and this&nbsp;<a href="https://www.kdrsoftsolutions.com/disclaimer">Disclaimer</a>. This Disclaimer governs the use of our Website; by accessing this Website, you agree to the terms and conditions as outlined in the&nbsp;<a href="https://www.kdrsoftsolutions.com/terms-conditions">Terms of Use</a>&nbsp;and this&nbsp;<a href="https://www.kdrsoftsolutions.com/disclaimer">Disclaimer</a>. If you disagree, please do not access this Website. </br></br> We reserve the right to modify the&nbsp;<a href="https://www.hcltech.com/terms-use">Terms of Use</a>&nbsp;and/or&nbsp;<a href="https://www.hcltech.com/disclaimer">Disclaimer</a>&nbsp;from time to time without any notice and at our sole discretion by posting amended&nbsp;<a href="https://www.kdrsoftsolutions.com/terms-conditions">Terms of Use</a>&nbsp;and <a href="https://www.kdrsoftsolutions.com/disclaimer">Disclaimer</a>&nbsp;on this Website. By using this Website after the changes / modifications have been posted you agree to accept those changes / modifications, whether or not you have reviewed the changes / modifications.<br /><br /></p>
  <p style="text-align: justify;"><strong>COPYRIGHT<br /><br /></strong></p>
  <p style="text-align: justify;">Kdr Soft Solutions Private Ltd. 2021. All Rights Reserved.<br /><br /></p>
  <p style="text-align: justify;">Except as specifically permitted herein, no portion of the Information on this Website may be reproduced in any form or by any means without the prior written permission from Kdr.<br /><br /></p>
  <p style="text-align: justify;"><strong>TRADEMARKS<br /><br /></strong></p>
  <p style="text-align: justify;">Kdr, and other kdr graphics, logos, and service names including "kdrsoft" are trademarks of KDR. Except as specifically permitted herein, these Trademarks may not be used without the prior written permission from KDR. All other trademarks not owned by KDRthat appear on this website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by KDR.<br /><br /></p>
  <p style="text-align: justify;"><strong>USE OF WEBSITE INFORMATION<br /><br /></strong></p>
  <p style="text-align: justify;">Subject to the terms and conditions set forth in the&nbsp;<span style="color: #000000;">Terms of Use&nbsp;</span>and this&nbsp;<span style="color: #000000;">Disclaimer</span>, KDR grants you a non-transferable, non-exclusive, restricted and limited right to access, use and display this Website along with the contents. You agree and acknowledge that you will not interrupt or attempt to inter-rupt the functioning of this Website in any manner whatsoever and shall use &amp; access this Website in accordance with the terms and conditions stated in&nbsp;<span style="color: #000000;">Terms of Use&nbsp;</span>and this&nbsp;<span style="color: #000000;">Disclaimer</span>.<br /><br /></p>
  <p style="text-align: justify;">Except as otherwise indicated elsewhere on this Website, you may view, copy, print, and download the Information available on this Website subject to the following conditions:<br /><br /></p>
  <ul style="text-align: justify;">
  <li>The Information may be used solely for personal and informational purposes<br /><br /></li>
  <li>The Information may not be modified or altered in any way<br /><br /></li>
  <li>Any copy of the Information or portion thereof must include the copyright notice above and this permission notice.&nbsp; You must contribute all content taken by you from this Website to KDR<br /><br /></li>
  <li>The Information shall not be disclosed to any other person(s)/entity(ies) unless they are given notice of, and they agree to accept, the obligations arising under the Terms of Use<br /><br /></li>
  <li>You shall also agree to abide by all additional restrictions displayed on this Website and as may be updated from time to time<br /><br /></li>
  <li>KDR reserves the right to revoke such authorization at any time, and any such use shall be discontinued immediately upon written notice from KDR<br /><br /></li>
  <li>Information specified above does not include the design or layout of this Website. Further, you agree to comply with all copyright and other intellectual property laws worldwide in your use of this Website and to prevent any unauthorized copying of the Information&nbsp;<br /><br /></li>
  <li>By sharing any contribution (including without limitation any text, photographs, graphics, video or audio) with KDR or this Website you agree to grant KDR / Website, free of charge, permission to use the material in any way KDR wishes (including modifying or deleting it). In order that KDR / Website can use your contribution, you confirm that your contribution is your own original work, is not defamatory and does not infringe/ violate any relevant laws (including but not limited to laws relating to communal harmony, sedition etc.) and that you have the right to give KDR / Website permission to use it for the purposes specified above.<br /><br /></li>
  </ul>
  <p style="text-align: justify;"><strong>LINKS TO THIRD PARTY WEBSITES<br /><br /></strong></p>
  <p style="text-align: justify;">This Website may contain links to third party websites, which are not under the control of KDR. KDR has no responsibility or liability for and makes no representations whatsoever about any other website that you may have access to through this Website. These linked sites are only for your convenience, and therefore, you access them at your own risk. The inclusion of such links does not imply that KDR endorses or accepts any responsibility for the content or uses of such websites. Nonetheless, KDR seeks to protect the integrity of its website and the links placed upon it, and therefore requests any feedback on not only its own site, but also third party links as well.<br /><br /></p>
  <p style="text-align: justify;"><strong>INDEMNIFICATION<br /><br /></strong></p>
  <p style="text-align: justify;">By accepting this Disclaimer, you agree to indemnify, defend and otherwise hold harmless KDR, its officers, employees, agents, subsidiaries, affiliates and other partners from any direct, indirect, incidental, special, consequential or exemplary damages resulting from:<br /><br /></p>
  <ol style="text-align: justify;">
  <li>Your use of this Website<br /><br /></li>
  <li>Your breach of the<span style="color: #000000;">&nbsp;Terms <span style="color: #000000;">of</span> Use,&nbsp;Disclaimer<a style="color: #000000; text-decoration: underline;" href="https://www.hcltech.com/disclaimer">&nbsp;</a>a</span>nd policies or documents they incorporates by reference, or Your violation of any law, rules or regulation<br /><br /></li>
  <li>Any other matter relating to this Website. Any business transactions which may arise between the Users/Members from their use of this Website are the sole responsibility of the Users/Members involved.<br /><br /></li>
  </ol>
  <p style="text-align: justify;"><strong>WARRANTIES AND DISCLAIMERS<br /><br /></strong></p>
  <p style="text-align: justify;">Kdr Does Not Warrant The Information On This Website, Which Is Provided "As Is" Without Warranty Of Any Kind, Including, Without Limitation, Any Warranty Of Design, Merchantability Or Fitness For A Particular Purpose, And Without Warranty As To Non-Infringement Or The Performance Or Results You May Obtain By Using The Information. The Entire Risk As To The Results And Performance Obtained From Using The Information On This Website Is Assumed By You. Kdr Does Not Assume Responsibility For Any Errors Or Omissions In The Information Or Documents Which Are Referenced By Or Linked To This Website.<br /><br /></p>
  <p style="text-align: justify;"><strong>KDR MAKES NO WARRANTY THAT:<br /><br /></strong></p>
  <ol style="text-align: justify;">
  <li>This Website Will Meet Your Requirements,<br /><br /></li>
  <li>The Content/Material On This Website Will Be Uninterrupted, Timely, Secure Or Error Free,<br /><br /></li>
  <li>The Results That May Be Obtained From Use Of This Website Will Be Accurate Or Reliable,&nbsp;<br /><br /></li>
  <li>The Quality Of Any Content/Material Or Other Information Obtained By You Through This Website Will Meet Your Expectations, And<br /><br /></li>
  <li> Any Errors In The Software Will Be Corrected.</li>
  </ol> </br>
  <p style="text-align: justify;">Any Material Downloaded Or Otherwise Obtained Through The Use Of This Website Is Done At Your Own Discretion And Risk And That You Will Be Solely Responsible For Any Damage To Your Computer System Or Loss Of Data That Results From The Download Of Any Such Material.<br /><br /></p>
  <p style="text-align: justify;">No Advice Or Information, Whether Oral Or Written, Obtained By You From Kdr Or Through Or From This Website Shall Create Any Warranty Not Expressly Stated In This Disclaimer.<br /><br /></p>
  <p style="text-align: justify;">References To Companies And Information About Them And Their Services And Products Are Provided "As Is" Without Warranty Of Any Kind, Either Express Or Implied. In No Event Shall Kdr Be Liable To You Or Any Third Party For Any Incidental, Consequential, Indirect, Special, Or Exemplary Damages, Including, Without Limitation, Loss Of Business, Lost Profits, Business Interruption, Loss Of Business Information Or Any Pecuniary Loss, Arising Out Of, In Connection With, Or Relating To The Use Or Performance Of The Information Referenced By Or Linked To This Website, Even If Kdr Has Been Advised Of The Possibility Of Such Damages. Because Some States Do Not Allow The Exclusion Or Limitation Of Liability For Consequential Or Incidental Damages, The Above Limitation May Not Apply To You.<br /><br /></p>
  <p style="text-align: justify;">This Website And The Information Contained Therein Could Include Technical Or Other Inaccuracies Or Typographical Errors. Kdr Periodically Changes The Information Herein Which Will Be Incorporated Into New Additions Of The Website. Kdr May Make Improvements And/Or Changes Information Described In This Website At Any Time And Without Prior Notice.<br /><br /></p>
  <p style="text-align: justify;"><strong>GOVERNING LAW<br /><br /></strong></p>
  <p style="text-align: justify;">By accessing this Website, you agree that the foregoing as well as any dispute raised regarding this Website or KDR (in relation to this Website) is subject to the laws of the Republic of India and the <strong> High Court Of Andhra Pradesh at Amaravati </strong>, India shall have the exclusive jurisdiction on any dispute that may arise out of the use of this Website.<br /><br /></p>
  <p style="text-align: justify;"><strong>GENERAL<br /><br /></strong></p>
  <p style="text-align: justify;">KDR, KDR SOFT, its mark and logo and other logos and names are Trademarks of KDR. You understand and agree not to display or use these Trademarks in any manner without KDR&rsquo;s prior written permission. The section titles of this Disclaimer are displayed for convenience only and have no legal effect.&nbsp; Please send any questions or comments, or report violations of the with regard to this Website to&nbsp;<a href="mailto:mail@kdrsoftsolutions.com"><strong>mail@kdrsoftsolutions.com</strong></a>.<br /><br /></p>
  <p style="text-align: justify;">&nbsp;</p>`,
};
