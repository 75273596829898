import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { TeamBanner } from "../../lib/Banner/TeamBanner/TeamBanner";
import { MultiRowCarousel } from "../../lib/Carousel/MultiRowCarousel";
import { HireDedicated } from "../../lib/HireDedicated/HireDedicated";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { InfoBanner } from "../../lib/InfoBanner/InfoBanner";
import { PointsSection } from "../../lib/PointsSection/PointsSection";
import { TypeOneQuote } from "../../lib/Quotes/TypeOneQuote/TypeOneQuote";
import { RequestQuoteBanner } from "../../lib/RequestQuoteBanner/RequestQuoteBanner";
import { RequestQuoteBannerTypeTwo } from "../../lib/RequestQuoteBannerTypeTwo/RequestQuoteBannerTypeTwo";
import { RQImageType } from "../../lib/RQImageType/RQImageType";
import { StackUsed } from "../../lib/StackUsed/StackUsed";
import { TimeLineTypeOne } from "../../lib/TimeLine/TimeLineTypeOne/TimeLineTypeOne";
import { TimeLineTypeThree } from "../../lib/TimeLine/TimeLineTypeThree/TimeLineTypeThree";
import { TimeLineTypeTwo } from "../../lib/TimeLine/TimeLineTypeTwo/TimeLineTypeTwo";
import { Type11Banner } from "../../lib/Type11Banner/Type11Banner";
import { Type12Banner } from "../../lib/Type12Banner/Type12Banner";
import { Type13Banner } from "../../lib/Type13Banner/Type13Banner";
import { Type14Banner } from "../../lib/Type14Banner/Type14Banner";
import { TypeFourBanner } from "../../lib/TypeFourBanner/TypeFourBanner";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";
import { TypeTenBanner } from "../../lib/TypeTenBanner/TypeTenBanner";
import { TypeThreeBanner } from "../../lib/TypeThreeBanner/TypeThreeBanner";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import { whyPageComponents } from "./whyPageComponent";

export const Why = ({ url }) => {
  const imageTypeOneBannerData = require(`./${url}`)["imageTypeOneBannerData"];
  const typeOneBannerData = require(`./${url}`)["typeOneBannerData"];
  const typeOneCardData = require(`./${url}`)["typeOneCardData"];
  const typeOneTabData = require(`./${url}`)["typeOneTabData"];
  const multiRowData = require(`./${url}`)["multiRowData"];
  const typeFourBannerData = require(`./${url}`)["typeFourBannerData"];
  const stackUsedData = require(`./${url}`)["stackUsedData"];
  const typeThreeBannerData = require(`./${url}`)["typeThreeBannerData"];
  const timeLineTypeOne = require(`./${url}`)["timeLineTypeOne"];
  const hireDedicated = require(`./${url}`)["hireDedicated"];
  const pointsSectionData = require(`./${url}`)["pointsSectionData"];
  const circleCardData = require(`./${url}`)["circleCardData"];
  const requestQuoteBannerTypeTwo = require(`./${url}`)[
    "requestQuoteBannerTypeTwo"
  ];
  const timeLineTypeTwoData = require(`./${url}`)["timeLineTypeTwoData"];
  const typeOneQuoteData = require(`./${url}`)["typeOneQuoteData"];
  const typeTwelveData = require(`./${url}`)["typeTwelveData"];
  const rqImageTypeData = require(`./${url}`)["rqImageTypeData"];
  const bottomTypeOneQuoteData = require(`./${url}`)["bottomTypeOneQuoteData"];
  const infoBannerTypeData = require(`./${url}`)["infoBannerTypeData"];
  const teamBannerData = require(`./${url}`)["teamBannerData"];
  const timeLineTypeThreeData = require(`./${url}`)["timeLineTypeThreeData"];
  const type14BannerData = require(`./${url}`)["type14BannerData"];
  const whichComponents = whyPageComponents[url];
  const location = useLocation().key;

  return (
    <div key={location}>
      {whichComponents.navbar && (
        <Navbar otherPages={true} transparent={true} />
      )}
      {whichComponents.image_banner && (
        <ImageTypeOneBanner
          imageName={imageTypeOneBannerData.imageSection.imageName}
          folderName={imageTypeOneBannerData.imageSection.folderName}
          imageText2={imageTypeOneBannerData.imageSection.imageText2}
          showButton={true}
          imageText3={imageTypeOneBannerData.imageSection.imageText3}
          buttonType={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonType
          }
          buttonLink={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonLink
          }
          buttonText={
            imageTypeOneBannerData.imageSection.buttonDetails.buttonText
          }
        />
      )}
      {whichComponents.image_banner_icon && (
        <ImageTypeOneBanner
          imageName={imageTypeOneBannerData.imageSection.imageName}
          folderName={imageTypeOneBannerData.imageSection.folderName}
          imageText2={imageTypeOneBannerData.imageSection.imageText2}
          showButton={imageTypeOneBannerData.showButton}
          showIcon={imageTypeOneBannerData.showIcon}
          imageText3={imageTypeOneBannerData.imageSection.imageText3}
          iconName={imageTypeOneBannerData.iconName}
        />
      )}
      {whichComponents.multi_row && (
        <MultiRowCarousel data={multiRowData} showButton={true} />
      )}
      {whichComponents.type_four && (
        <TypeFourBanner data={typeFourBannerData} showArrow={true} />
      )}
      {whichComponents.timeline_type_one && (
        <TimeLineTypeOne data={timeLineTypeOne} />
      )}
      {whichComponents.timeline_type_two && (
        <TimeLineTypeTwo data={timeLineTypeTwoData} />
      )}
      {whichComponents.type_one && (
        <TypeOneBanner
          text1={typeOneBannerData.text1}
          text2={typeOneBannerData.text2}
          tabData={typeOneBannerData.tabData}
        />
      )}
      {whichComponents.stack_used && <StackUsed data={stackUsedData} />}
      {whichComponents.type_three && (
        <TypeThreeBanner data={typeThreeBannerData} />
      )}
      {whichComponents.quote_banner && <TypeOneQuote data={typeOneQuoteData} />}
      {whichComponents.circle_cards && <Type13Banner data={circleCardData} />}
      {whichComponents.quote_banner_bottom && (
        <TypeOneQuote data={bottomTypeOneQuoteData} />
      )}
      {whichComponents.team_banner && <TeamBanner data={teamBannerData} />}
      {whichComponents.info_banner && <InfoBanner data={infoBannerTypeData} />}
      {whichComponents.image_card && <Type12Banner data={typeTwelveData} />}
      {whichComponents.type_one_card && (
        <TypeTenBanner data={typeOneCardData} noData={true} />
      )}
      {whichComponents.type_11 && <Type11Banner data={typeOneTabData} />}
      {whichComponents.points_section && (
        <PointsSection data={pointsSectionData} />
      )}
      {whichComponents.how_hire_works && (
        <HireDedicated data={hireDedicated} formatOne={true} />
      )}
      {whichComponents.timeline_three && (
        <TimeLineTypeThree data={timeLineTypeThreeData} />
      )}
      {whichComponents.type_14 && <Type14Banner data={type14BannerData} />}
      {whichComponents.request_quote && <RequestQuoteBanner />}
      {whichComponents.rq_image && <RQImageType data={rqImageTypeData} />}
      {whichComponents.request_quote_type_two && (
        <RequestQuoteBannerTypeTwo data={requestQuoteBannerTypeTwo} />
      )}
      {whichComponents.work_with_us && <WorkWithUs />}
      {whichComponents.footer && <Footer />}
    </div>
  );
};
