import { companyName } from "../config";
export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "qa.jpg",
    imageText2:
      "Develop right the first time ensuring highest quality standards.",
    imageText3: "COMMITTED TO DEPLOY AND DELIVER WITH ZERO BUGS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "START YOUR PROJECT",
    },
  },
};

export const multiRowData = {
  text1: "TESTING YOUR APPS ON ALL DEVICES",
  text2: "Independent quality assurance & testing for desktop, web and mobile",
  text3:
    "We perform extensive testing throughout the entire development lifecycle and strive to continually decrease any non-conformance in software, apps and websites using product reports as monitored at each management review. Some of our most reliable QA services at each phase of design and development include:",
  carouselData: [
    {
      iconName: `${companyName}-QA-WAT`,
      label: "Web Application Testing",
    },
    {
      iconName: `${companyName}-QA-DMAT`,
      label: "Desktop/Mobile App Testing",
    },
    {
      iconName: `${companyName}-QA-PT`,
      label: "Prototype Testing",
    },
    {
      iconName: `${companyName}-QA-TA`,
      label: "Test Automation",
    },
    {
      iconName: `${companyName}-QA-PFT`,
      label: "Performance Testing",
    },
    {
      iconName: `${companyName}-QA-QAC`,
      label: "QA Consulting",
    },
    {
      iconName: `${companyName}-QA-FUT`,
      label: "Functionality & Usability Testing",
    },
    {
      iconName: `${companyName}-QA-IST`,
      label: "Integrated Systems Testing",
    },
    {
      iconName: `${companyName}-QA-DC`,
      label: "Device Compatibility",
    },
    {
      iconName: `${companyName}-QA-CB`,
      label: "Cross-browser Testing",
    },
    {
      iconName: `${companyName}-QA-CBT`,
      label: "Cloud-based Testing",
    },
    {
      iconName: `${companyName}-QA-ST`,
      label: "Security Testing",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const typeFourBannerData = {
  heading: "Bug-free, functional services that deliver maximum value",
  text1:
    "With our integrated approach to quality assurance services, we leverage diverse testing types including Unit, Manual, UI Automation, Parallel to increase end-user satisfaction and loyalty for our clients. To offer value-added services that meet standards, specifications, code of practice and customer’s requirements, our quality experts use a range of steadfast technologies, tools and strategies.",
  description: "MODERN TOOLS FOR AUTOMATION TESTING",
};

export const stackUsedData = {
  stackData: [
    {
      name: "Performance Tools",
      logo:  `${companyName}-QA-P`,
      techUsed: [
        {
          techName: "Web Stress",
          techLogo: "web_stress.png",
        },
        {
          techName: "JMeter",
          techLogo: "jmeter.png",
        },
      ],
    },
    {
      name: "Functional Testing Tools",
      logo: `${companyName}-QA-FT`,
      techUsed: [
        {
          techName: "Selenium for Web",
          techLogo: "selenium_forweb.png",
        },
        {
          techName: "Selenium with Appium",
          techLogo: "selenium_with_appium.png",
        },
        {
          techName: "AutoIt",
          techLogo: "Autoit.png",
        },
      ],
    },
    {
      name: "Bug Tracking Tools",
      logo: `${companyName}-QA-BT`,
      techUsed: [
        {
          techName: "Bugzilla",
          techLogo: "bugzilla.png",
        },
        {
          techName: "JIRA",
          techLogo: "jira.png",
        },
        {
          techName: "Mantis",
          techLogo: "mantis.png",
        },
        {
          techName: "Zoho",
          techLogo: "zoho.png",
        },
      ],
    },
  ],
};

export const typeThreeBannerData = {
  heading: "How we stand out in delivering quality assurance",
  description: "WE ONLY SEND OUT ONCE IT WORKS PERFECTLY",
  details: [
    {
      label: "Certified quality analysts",
      iconName: `${companyName}-QA-CQ`,
      content:
        "Our dedicated team of quality analysts have acquired various ISTQB testing certifications and accreditations.",
    },
    {
      iconName: `${companyName}-QA-F`,
      label: "Focus and attention-to-detail",
      content:
        "With a keen eye for detail, our QA team attentively removes bugs from the code, design and usability of every project we deliver.",
    },
    {
      iconName: `${companyName}-QA-WEI`,
      label: "Well-equipped infrastructure",
      content:
        "With a well-equipped infrastructure in place, we own advanced facility and latest devices to test Android, iOS, IoT, Apple Watch and AR/VR apps.",
    },
    {
      iconName: `${companyName}-QA-FCT`,
      label: "Full-cycle testing",
      content:
        "We perform quality assurance and testing processes along the whole development lifecycle- during prototyping, design, development and pre-release.",
    },
    {
      iconName: `${companyName}-QA-QC`,
      label: "Iterative quality checks",
      content:
        "Through our iterative quality checks, we push your project until it goes live and beyond to make sure it can live up to its potential.",
    },
    {
      iconName: `${companyName}-QA-FPS`,
      label: "Future-proof setup",
      content:
        "We’re able to test every aspect of your product from API interactions, to business logic to how it looks on all the latest devices.",
    },
    {
      iconName: `${companyName}-QA-RTTM`,
      label: "Reduced time-to-market",
      content:
        "For each project we work on, our aim is to not just deliver in minimum time but to ensure zero errors and reduce the overall time-to-market.",
    },
    {
      iconName: `${companyName}-QA-MAT`,
      label: "Major automation tools",
      content:
        "Having in-depth expertise in testing across various devices, we also employ expertise across all major automation and performance tools.",
    },
  ],
};
