export const dominateData = {
  heading: "Technically Engrossed All-Star Media Solutions",
  para:
    "At Kdr, we don’t leave a chance to conceptualize and design the most advanced solutions to help you reach your end-purpose. We align our advanced technical ability and extensive resource capacity to help you attain the most out of your Media & Entertainment endeavor. And all that with flawless process landscape and seamless communication lineup – to deliver on-time high-quality solutions to serve every whit of our client expectation right!",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Our years of experience working in the space of Media & Entertainment helps us craft superlative M&E solutions. With our team of experts incessantly working on latest technology and perceptive tactical choices – we allow you to get the most out of your piece of idea in your tangent of business.",
  text2:
    "We cover it all from consulting to development and maintenance - helping businesses to own distinctively impactful presence with end-to-end high-perspective solutions. No matter how specific or particular your needs are, we can bring you the most delightful match of a solution to your M&E project. Whether it’s production, distribution, broadcasting, building delivery platforms, or simply remodeling entertainment offerings – you can count on us as your technology partner.",
};

export const challengesData = {
  heading: "Challenges in Media & Entertainment",
  description: "TREATED WITH THE RIGHT SOLUTION",
  details: [
    {
      label: "Integration Across Multiple Platforms",
      content:
        "The modern-day media consumers are not devoted to a single platform. They are instead looking to buy ever-newer services to go with diversified choices and varied experiences. This requires businesses to explore a range of platforms and devices to access diversified media dimensions.",
    },
    {
      label: "Monetization Of Content",
      content:
        "In order to fetch the desired response from visitors and convert them into qualified leads, you need to pitch your services right. This can be effectively done with the help of adverts and revenue generation resources through monetization of content.",
    },
    {
      label: "User Experience",
      content:
        "User Experience Design decides how well your product will work with your audiences. To get it right you need to appoint it well with the fine scheme of transition, responsiveness, navigation, and other functional properties that allow you to connect with your users effectively.",
    },
    {
      label: "Retaining Customers",
      content:
        "You need to continuously aim at retaining existing customers while brewing new relationships. And that is possible by introducing inventive new media concepts into their solution. With this, you can foster relations with active brand-loyal users and claim higher retention.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "We always strive to serve our clients with everything innovation-driven and trend-focused with our top-of-the-line Media & Entertainment solutions. Allowing businesses to meet their potential with technically advanced applications that know their purpose well, we make them reach the best of exposure and value they desire and deserve.",
  details: [
    {
      label: "Process excellence",
      content:
        "Our digitally enhanced processes and advanced facilities let us design solutions that accelerate time-to-market, boost productivity and minimize costs. This lets us build highly efficient solutions leveraged by competent practices to help you to attain best of business value proposition – be it for launching new distribution channels or introducing new business models.",
    },
    {
      label: "Secure Infrastructure",
      content:
        "With our fool-proof infrastructural capacity build on highly secured and well-managed technology and performance architecture – we are able to allow you highly dependable solutions to work with your ranging utility and application needs. Be it Android or iOS or any other technology, you have nothing to worry when it is served and sourced through our sound development facility.",
    },
    {
      label: "Multi-Channel Approach",
      content:
        "That’s something that sets us apart from other media solution providers. We put together with an excellent multi-channel approach in developing media solutions to allow you immersive revenue-generating options. This includes everything from HTML5 application development for Android and iOS, to B2C and B2B e-commerce solutions and full-cycle marketing campaigns.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Distribution Management",
        text2: "#2. Workflow Management Solutions",
        text3: "#3. Mobile Applications",
      },
      {
        text1: "#4. Web Applications / Portals",
        text2: "#5. Salesforce Automation",
        text3: "#6. Full-Cycle ERP /CRM",
      },
      {
        text1: "#7. Inventory Management",
        text2: "#8. Product Development / Digital SCM",
        text3: "#9. Legacy Migration",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
