import React from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";

export const PageNotFound = () => {
  const location = useLocation();

  return (
    <div className="max-h-full">
      
      <Navbar otherPages={true} navBackground="#262626" />
      <div className="pt-32 text-center text-2xl flex flex-col items-center justify-center">
        <p>Page not found : <code className="font-bold">{location.pathname}</code></p>
        <img className="w-full max-w-lg" src={require('../../assets/Images/pageNotFound/404.svg')} alt="not found" />
      </div>
      <Footer/>
    </div>
  );
};