import React, { useEffect, useState } from "react";
import { CardTypeOne } from "../Card/CardTypeOne/CardTypeOne";
import { CardTypeTwo } from "../Card/CardTypeTwo/CardTypeTwo";

export const TypeTenBanner = ({ data, noData }) => {
  const [firstItem, secondItem] = Object.keys(data.cardData);
  const [cardDataItem, setCardDataItem] = useState(firstItem);
  const [firstTab, setFirstTab] = useState(true);

  const clickHandler = (element) => {
    Array.from(document.getElementsByClassName("tabLink")).map((el) => {
      el.classList.remove("bg-white", "text-gray-900");
      return null;
    });
    document
      .getElementById(element.target.id)
      .classList.add("bg-white", "text-gray-900");
    if (element.target.id === "press_releases") {
      setFirstTab(true);
      setCardDataItem(firstItem);
    } else {
      setFirstTab(false);
      setCardDataItem(secondItem);
    }
  };

  useEffect(() => {
    setCardDataItem(firstItem);
    document
      .getElementById("press_releases")
      .classList.add("bg-white", "text-gray-900");
  }, [firstItem]);

  return (
    <>
      <section>
        <div className="relative">
          <div className="absolute downArrow"></div>
          <section className="text-center pt-10 bg-gradient-resource text-white  px-4 xl:px-0">
            <p className="text-xl md:text-2xl lg:text-3xl font-bold capitalize">
              {data.text1}
            </p>
            <p className="text-sm md:text-base mt-3 w-full max-w-6xl text-gray-300 mx-auto">
              {data.text2}
            </p>
          </section>
          {data.tabData && (
            <section
              className={`relative bg-gradient-resource flex items-center justify-center  px-4 xl:px-0`}
            >
              <div
                id="tabData"
                className={`w-full md:max-w-6xl text-white py-4 grid grid-flow-row grid-cols-${
                  data.tabData.length % 2 === 0 ? 2 : 1
                } row-gap-2 md:grid-cols-${data.tabData.length}`}
              >
                {data.tabData.map((el) => (
                  <button
                    onClick={clickHandler}
                    key={el.label}
                    id={el.label.toLowerCase().replace(" ", "_")}
                    className={`tabLink text-sm md:text-base uppercase font-bold py-4 text-center`}
                  >
                    {el.label}
                  </button>
                ))}
              </div>
            </section>
          )}
        </div>
      </section>
      {!noData &&
        (firstTab ? (
          <section id="awardCardData">
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {data["cardData"][`${cardDataItem}`].map((el) => (
                <CardTypeOne data={el} key={el.id} />
              ))}
            </div>
          </section>
        ) : (
          <section
            id="memberShipCardData"
            className="container mx-auto py-16  px-4 xl:px-0"
          >
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
              {data["cardData"][`${cardDataItem}`].map((el) => (
                <CardTypeTwo data={el} key={el.id} />
              ))}
            </div>
          </section>
        ))}
    </>
  );
};
