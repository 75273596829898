export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "qatesting.jpg",
    imageText2: "Smart Meaningful Wearable Apps for Android Wear & iWatch",
    imageText3: "WE DRIVE INNOVATION FOR FUTURE WEARABLE TECHNOLOGIES",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeOneBannerData = {
  text1: "Top on-demand technologies implemented",
  text2:
    "For the best service deliverables, each on-demand project at Kdr is built with a technical edge using all modern and prime tools and frameworks.",
};

export const imageTilesData = {
  heading: "Why devops as a service works",
  description:
    "When you turn to kdrsoft, you get decade-long experience in QA and software testing. We leverage the latest tech, practices, and standards to help you create high quality software within the required timeframe.",
  imageDetails: [
    {
      imageUrl:
        "https://drive.google.com/uc?id=1RRMnreU63eNiD-wVoAWI3DRn-VP8dLBY",
      title: "A DECADE OF EXPERIENCE IN QA SERVICES",
      description:
        "Over the years, our QA testers have ensured trouble-free operation of countless software solutions. Our experts have tested dozens of mobile applications, complex enterprise software for Fortune 500 companies, IoT ecosystems, a blockchain marketplace, and more. As a result, you get fail-safe software your company can rely on.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1kTd1-jdVSaP0Z69rnSoNA5EKNT-jxWCY",
      title: "ESTABLISHED IN-HOUSE STANDARDS",
      description:
        "We deliver QA services according a set of procedures, guidelines, and projects documents we developed based on the best industry practices and standards. Our QA testers prevent defects from occurring in the first place, as well as facilitate the high quality and timely delivery of every software solution.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1PgzmVqvuu_rYLxwo9JB1n00vew5H18Ys",
      title: "ISO 9001:2015 Сertified",
      description:
        "ISO 9001:2015 is the most widely known as the international standard that specifies requirements for a quality management system (QMS). Organizations use the standard to demonstrate the ability to consistently provide products and services that meet customer and regulatory requirements.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1-vy39zSLN6SRlZ1SxM3B4Y2QJr7n36W2",
      title: "AUTOMATION & AGILITY",
      description:
        "We support multiple clients in meeting their software needs. We use tested DevOps engineering practices, consistent approaches to program management, and talent retention strategies that keep our software engineers motivated.",
    },
  ],
};

export const topFaqBannerData = {
  heading: "OUR QA AND SOFTWARE TESTING SERVICES",
  description:
    "To ensure fast delivery of your software without losing in quality, we employ a selected approach to automated QA. We use automated Unit testing on every project, while automated Acceptance and other types of testing rely on your enterprise software architecture, tech stack, project scale, and other factors. Our DevOps engineers and QA testers work side by side to allow for seamless and continuous agile delivery.",
  buttonLabel: "buttonTop",
  questionLabel: "questionTop",
  two_cols: true,
  faqDetails: [
    {
      question: "WHAT OUR QA SERVICES MANAGE",
      answer: `<ul class="list-inside list-disc p-0 m-0">
          <li>Raw materials quality </li>
          <li>Assemblies</li>
          <li>Products and components</li>
          <li>Production-related services</li>
          <li>Production and inspection processes</li>
        </ul>`,
    },
    {
      question: "TEST DESIGN TECHNIQUES",
      answer: `<ul class="list-inside list-disc p-0 m-0">
      <li>Equivalence partitioning</li>
      <li>Boundary value analysis</li>
      <li>Cause/effect analysis</li>
      <li>Bottleneck/issue assumption</li>
      <li>Exhaustive testing</li>
      </ul>`,
    },
    {
      question: "LEVELS OF OUR SOFTWARE TESTING SERVICES",
      answer: `<ul class="list-inside list-disc p-0 m-0">
          <li>Automated QA: Unit testing</li>
          <li>API testing</li>
          <li>Integration testing</li>
          <li>System testing</li>
          <li>Acceptance testing</li>
        </ul>`,
    },
    
    {
      question: "NON-FUNCTIONAL TESTING",
      answer: `<ul class="list-inside list-disc p-0 m-0">
          <li>Performance testing</li>
          <li>Usability testing</li>
          <li>Installation testing</li>
          <li>Configuration testing</li>
          <li>Security testing</li>
        </ul>`,
    },
    {
      question: "OTHER QA SERVICES",
      answer: `<ul class="list-inside list-disc p-0 m-0">
          <li>Environment analyzing</li>
          <li>Requirements testing</li>
          <li>Defect analyzing</li>
          <li>Story mapping</li>
          <li>Risk management</li>
          <li>Quality measurement</li>
          <li>Code review</li>
          <li>Design review</li>
          <li>Alpha testing</li>
          </ul>`,
    },
    {
      question: " TYPES OF OUR SOFTWARE TESTING SERVICES ",
      answer: `<ul class="list-inside list-disc p-0 m-0">
          <li>Functional testing (all levels)</li>
          <li>Functional automation testing</li>
          <li>Feature testing (positive and negative scenarios)</li>
          <li>End-to-end testing</li>
          <li>Changes or improvements software QA services</li>
        </ul>`,
    },
  ],
};

export const connectBannerData = {
  text:
    "ENSURE TROUBLE-PROOF SOFTWARE WITH OUR DEPENDABLE QA AND SOFTWARE TESTING SERVICES.",
  showButton: true,
  buttonText: "Talk to us",
  buttonType: "anchor",
  buttonLink: "#workWithUs",
};

export const type16BannerData = {
  heading: "OUR QA SERVICES LIFECYCLE",
  description:
    "Our QA testers join project activities at early stages of product development. This allows us to ensure that nothing in your software is overlooked, and that your product quality complies with the acceptance criteria that were agreed on.",
  details: [
    {
      title: "PLANNING",
      list: ["Project documentation review", "Test plan creation"],
    },
    {
      title: "DESIGN",
      list: ["Test cases design","Automated testing implementation (if you require it)"],
    },
    {
      title: "IMPLEMENTATION",
      list: ["Test execution and defects reporting","Test cases and scripts updates","Interim tests results reporting"],
    },
    {
      title: "STABILIZATION",
      list: ["Verification of fixed defects","Regression testing"],
    },
    {
      title: "DELIVERY",
      list: ["Acceptance testing","Scope verification","Deliverables check","Final tests results reporting"],
    },
  ],
};

export const faqBannerData = {
  heading: "F.A.Q.",
  description: "Do you have additional questions?",
  buttonLabel: "buttonBottom",
  questionLabel: "questionBottom",
  faqDetails: [
    {
      question: "How QA Testing Company Can Help My Business?",
      answer:
        "QA testing companies like kdrsoft help businesses build a positive reputation, meet their clients’ demands and expectations. Making sure that you provide high-quality and consistent services will build trust with your customers, thus giving you a chance to stay competitive on the market. Our QA team joins the project at its early stages to make sure that the final result suits your expectations, requirements, and runs smoothly. Every company aims to create good-looking and bug-free high-quality software to keep their customer retention. kdrsoft’s QA team makes sure that no bugs will come up once the product is launched. In addition to this, QA testing at the early stages of product development is cost-effective as it is much cheaper than fixing bugs after the release. It is essential to eliminate all the bugs and issues before the user meets your software to maintain a high level of trust and loyalty to your services.",
    },
    {
      question: "How Do You Provide QA Testing Services?",
      answer:
        "kdrsoft aims to satisfy the needs of its customers and suit their expectations. We have been in the industry for over a decade and have a team of QA experts that makes sure the final product is bug-free and stands to the highest standards. Our team provides QA and software testing services at every stage of product development. The reason for this is that we want to deliver an excellent product to our customers and minimize errors. That’s why we conduct a thorough analysis of your systems, build solutions on how to implement them, and create a test plan at the planning stage. It’s a part of our strategy that allows us to stay within budget and time limits stated by our customers. This strategy shows excellent results in practice as we think of the possible issues beforehand and think of the solutions to quickly overcome hurdles on our way to bug-free product.",
    },
    {
      question: "Why Choose kdrsoft Quality Assurance Testing Services?",
      answer:
        "Quality assurance and testing services are a part of our service portfolio. kdrsoft has over 10 years of experience in the industry, skilled team, and the latest tech tools that help us deliver high-quality services to our customers. QA software testing services granted by our company let customers monitor product development at all stages and demonstrate product quality information. It helps to stay on the same page with the customers and involves them in the process. We use the latest tech tools and solutions to build efficient products and help companies of any size to perform a smooth digital transformation. Our team works according to the plan that allows us to continue the agile delivery of the product. The team of experts conducts the following levels of software testing: Automated QA: Unit testing, API testing, Integration testing, System testing, Acceptance testing. QA specialists perform different types of software testing (functional, end-to-end, feature testing, etc.) to ensure trouble-proof software for our customers.",
    },
  ],
};
