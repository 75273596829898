import React, { useEffect } from "react";
import "./carousel.css";
import avatar from "../../assets/Images/avatar-bg.png";

export const CommonCarousel = ({ data, slideSpeed, pauseTime }) => {
  let slideIndex = 0;
  let clear;
  const showSlides = (n = 0) => {
    let i;
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");

    if (n > slides.length) {
      slideIndex = 1;
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    n === 0 && slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    clear = setTimeout(showSlides, slideSpeed);
  };

  useEffect(() => {
    showSlides();
    return () => clearTimeout(clear);
  });

  const goToSlide = (n) => {
    clearTimeout(clear);
    showSlides((slideIndex = n));
  };

  return (
    <div className="relative flex flex-col justify-center mx-auto mt-4 w-full md:max-w-2xl lg:max-w-4xl">
      <img
        id="carouselImg"
        className="absolute w-24 h-24"
        style={{
          top: "-40px",
          left: "-25px",
        }}
        alt=""
        src={avatar}
      />
      <div
        className="text-white px-4 py-12 md:px-24 cursor-pointer"
        id="mainSlide"
        style={{
          borderBottomLeftRadius: "100px",
          borderTopRightRadius: "100px",
          backgroundColor: "#362f2d",
        }}
      >
        {data.map((el, i) => (
          <div className="mySlides text-left fade" key={`${el.logo}${i}`}>
            <img
              id="singleQuote"
              src={require("../../assets/Images/singleQuote.svg")}
              className="absolute w-16 h-16 md:w-32 md:h-32"
              style={{
                right: "50px",
                bottom: "60px",
              }}
              alt="single quote"
            />
            <span
              id="logoName"
              className="absolute text-3xl text-gray-900 font-bold"
              style={{
                top: "-20px",
                left: "1px",
                padding: "5px 10px",
                borderRadius: "50%",
              }}
            >
              {el.logo}
            </span>
            <p className="text-base md:text-xl">{`“${el.comment}”`}</p>
            <p className="mt-6 text-md md:text-lg text-orange-500 font-bold">
              {el.name}
            </p>
            <p className="text-xs text-gray-500 font-bold">{el.position}</p>
            <p className="text-xs md:text-base text-gray-300 uppercase font-bold">
              {el.companyName}
            </p>
          </div>
        ))}
      </div>
      <div className="self-end mt-4 pr-4">
        {data.map((el, i) => (
          <span
            className="dot cursor-pointer"
            onClick={() => goToSlide(i + 1)}
            key={`${el.logo}_${i}`}
          ></span>
        ))}
      </div>
    </div>
  );
};
