import React from "react";
import { Icon } from "../../Icon/Icon";

export const TimeLineTypeThree = ({ data }) => {
  const dataLength = data.data.length;
  return (
    <div className="bg-darkBackground py-16 px-4 xl:px-0 text-white">
      <div className="container mx-auto">
        {data.topTagLine && (
          <p data-aos="fade-down" className="text-sm uppercase">
            {data.topTagLine}
          </p>
        )}
        {data.heading && (
          <p data-aos="fade-down" className="text-4xl ">
            {data.heading}
          </p>
        )}
        {data.data && (
          <div
            id="timeLineGrid"
            className="relative grid grid-flow-row grid-cols-1 lg:grid-cols-2 mt-10 row-gap-6"
          >
            {data.data.map((el, i) => (
              <div
                data-aos="fade-in"
                data-aos-delay={50 * `${i + 1}`}
                className="max-w-lg"
                key={i}
              >
                <div className="timeline-card relative flex items-center pl-10">
                  {el.iconName && (
                    <Icon
                      iconName={el.iconName}
                      classes="w-16 h-16 text-orange-500"
                    />
                  )}
                  <div className="text-xl ml-4">
                    <p className="text-gray-400">
                      <span>0{i + 1}</span>/0{dataLength}
                    </p>
                    {el.text && <p className="capitalize">{el.text}</p>}
                  </div>
                </div>
                {el.description && (
                  <div className="text-sm text-gray-600 mt-6 pl-8">
                    {el.description}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
