import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Icon } from "../Icon/Icon";
import "./appCarousel.css";
export const MultiRowCarousel = ({ data, showButton, noOfCards = 6, noOfItemsForCarousel = 12 }) => {
  var settings = {
    infinite: true,
    speed: 500,
    rows: 2,
    slidesToShow: noOfCards,
    slidesPerRow: 1,
    arrows: false,
    // autoplay: !!(data.carouselData.length > 8),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesPerRow: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesPerRow: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  const slide = useRef(null);

  const goNext = () => {
    slide.current.slickNext();
  };
  const goPrev = () => {
    slide.current.slickPrev();
  };
  return (
    <div className="bg-gray-200 py-16 px-4 xl:px-0">
      <div className="container mx-auto text-center px-2 md:px-0">
        {data.text1 && (
          <p className="uppercase text-base text-gray-600">{data.text1}</p>
        )}
        {data.text2 && (
          <p className="text-2xl md:text-3xl lg:text-4xl my-4 capitalize">{data.text2}</p>
        )}
        {data.text3 && (
          <p className="text-base text-gray-600 mb-2">{data.text3}</p>
        )}
        {data.text4 && (
          <p className="text-base text-gray-600 mb-2">{data.text4}</p>
        )}
        {data.carouselData.length > noOfItemsForCarousel ? (
          <>
            <Slider ref={slide} {...settings}>
              {data.carouselData.map((el,i) => (
                <div
                  key={`${el.iconName}${i}`}
                  className="flex flex-col h-full items-center justify-center p-4 bg-white"
                >
                  {el.iconName && <Icon
                    iconName={el.iconName}
                    classes="w-12 h-12 inline-block items-center text-orange-500"
                  />}
                  {
                    el.imageName && <img src={require(`../../assets/Images/${el.imageFolder}/${el.image}`)} className="w-24 h-20 mb-5 items-center justify-center mx-auto" alt={el.imageName}/>
                  }
                  <p className="mt-auto">{el.label}</p>
                </div>
              ))}
            </Slider>
            <div className="flex items-center justify-center mt-2">
              <button
                className="transform duration-200 hover:-translate-x-2 hover:text-blue-600"
                onClick={goPrev}
              >
                <Icon iconName="chevron-left" classes="w-8 h-8" />
              </button>
              <button
                className="transform duration-200 hover:translate-x-2 hover:text-blue-600"
                onClick={goNext}
              >
                <Icon iconName="chevron-right" classes="w-8 h-8" />
              </button>
            </div>
          </>
        ) : (
          <div className={`grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-${noOfCards} col-gap-6 row-gap-1 lg:gap-10`}>
            {data.carouselData.map((el,i) => (
              <div
                key={`${el.iconName}${i}`}
                className="flex flex-col justify-center items-center my-4 p-4 bg-white"
              >
                <Icon
                  iconName={el.iconName}
                  classes="w-12 h-12 inline-block items-center text-orange-500"
                />
                <p className="mt-3">{el.label}</p>
              </div>
            ))}
          </div>
        )}
        {showButton && (
          <a
            className="uppercase bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition-all duration-150 shadow-md ease-in font-bold px-4 py-4 mt-6 inline-block"
            href={data.buttonLink}
          >
            {data.buttonText}
          </a>
        )}
      </div>
    </div>
  );
};
