import { companyName } from "../../config";

export const typeOneBannerData = {
  text1: "Top-notch Technology Stack",
  text2:
    "For developing advanced IoT apps and helping your business reach out to the modern millennials, we use current technologies and tools that can deliver a seamlessly connected experience to your customers.",
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "iot.png",
    imageText2:
      "Next-generation internet solutions.connecting people & devices.",
    imageText3:
      "OFFERING THE HOTTEST CONNECTIVITY SERVICES VIA IOT APPLICATION DEVELOPMENT",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "connect with our experts",
    },
  },
};

export const multiRowData = {
  text1: "RANKED AS TOP IOT APPLICATION DEVELOPMENT COMPANY",
  text2: "IoT apps for startups and businesses",
  text3:
    "For the startups and other businesses we work with, we provide complete development services that utilize smart software/mobile solutions and can provide benefits to increase efficiency in the customers' and employees' lives, along with building a smart and connected home, workplace, and industry by using environmental data gathered by sensors that control devices through the internet. There are endless opportunities we offer with our mobile apps across different markets like automotive, health, smart homes, smart cities, retail, entertainment, and manufacturing that connect different IoT devices with mobile apps.",
  text4: "Some of our IoT app solutions include:",
  carouselData: [
    {
      iconName: `${companyName}-IOT-Development-MEUA`,
      label: "Mobile End-user Apps",
    },
    {
      iconName: `${companyName}-IOT-Development-BAD`,
      label: "Bluetooth App Development",
    },
    {
      iconName: `${companyName}-IOT-Development-BAAD`,
      label: "Backend & API Development",
    },
    {
      iconName: `${companyName}-IOT-Development-IAD`,
      label: "iBeacon App Development",
    },
    {
      iconName: `${companyName}-IOT-Development-ASC`,
      label: "App Security Consulting",
    },
    {
      iconName: `${companyName}-IOT-Development-BDA`,
      label: "Big Data Analytics",
    },
    {
      iconName: `${companyName}-IOT-Development-MC`,
      label: "M2M Communication",
    },
    {
      iconName: `${companyName}-IOT-Development-WAWFS`,
      label: "Wired and Wi-Fi Solutions",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Let's Get Started",
};

export const typeThreeBannerData = {
  heading: "Why choose kdr as your IoT App Development Partner?",
  description: "SENSOR-BASED ANDROID AND IOS APP SOLUTIONS",
  details: [
    {
      label: "Business Value Across Markets",
      iconName: `${companyName}-IOT-Development-BVAM`,
      content:
        "Discovering the true potential behind collecting and analyzing data from connected devices, we serve the widest industry base with endless innovative ideas.",
    },
    {
      label: "Strategic Consulting",
      iconName: `${companyName}-IOT-Development-SC`,
      content:
        "Before starting work, we suggest ideas for IoT based automation systems or converging them with existing processes.",
    },
    {
      label: "Earliest Adopters",
      iconName: `${companyName}-IOT-Development-EA`,
      content:
        "Passionate about new-fangled technology, we started our innovation and design-focused services for IoT devices since its inception.",
    },
    {
      label: "Highly Motivated & Innovative",
      iconName: `${companyName}-IOT-Development-HMI`,
      content:
        "To provide the best tech IoT, our team comprises highly creative and quick minds to provide hands-free connectivity to users for different operations.",
    },
    {
      label: "Disciplined Infrastructure",
      iconName: `${companyName}-IOT-Development-DI`,
      content:
        "Unlike screwing up with freelance developers due to hardware and software infrastructure problems, our company has everything arranged in place.",
    },
    {
      label: "Seamless IoT Integration",
      iconName: `${companyName}-IOT-Development-SII`,
      content:
        "To make IoT data available easily across the enterprise, we connect the IoT solutions with your ERP, CRM, EHR and more.",
    },
    {
      label: "Perfect Execution",
      iconName: `${companyName}-IOT-Development-PE`,
      content:
        "Our strategy encompasses all the five major layers of an IoT solution - Device, Communication, Cloud Services, Applications and Security.",
    },
    {
      label: "Faster Delivery, Reduced Costs",
      iconName: `${companyName}-IOT-Development-FDRC`,
      content:
        "We value our client’s time and money and thus, ensure to deliver every project on time and within the promised costs.",
    },
  ],
};

export const stackUsedData = {
  stackData: [
    {
      name: "Supported Protocols",
      logo: `${companyName}-IOT-Development-SP`,
      techUsed: [
        {
          techName: "MQTT",
          techLogo: "mqtt.png",
        },
        {
          techName: "XMPP",
          techLogo: "xmpp.png",
        },
        {
          techName: "AMQP",
          techLogo: "amqp.png",
        },
        {
          techName: "CoAP",
          techLogo: "coap.png",
        },
      ],
    },
    {
      name: "Device Connectivity",
      logo: `${companyName}-IOT-Development-DC`,
      techUsed: [
        {
          techName: "Wireless",
          techLogo: "wireless.png",
        },
        {
          techName: "Ethernet",
          techLogo: "ethernet.png",
        },
        {
          techName: "Bluetooth",
          techLogo: "bluetooth.png",
        },
        {
          techName: "4G LTE",
          techLogo: "4glte.png",
        },
        {
          techName: "GSM Network (2G/3G and SMS)",
          techLogo: "gsm_network.png",
        },
      ],
    },
    {
      name: "Cloud Platform",
      logo: `${companyName}-IOT-Development-CP`,
      techUsed: [
        {
          techName: "AWS",
          techLogo: "aws.png",
        },
      ],
    },
    {
      name: "Driving Forces For IoT",
      logo: `${companyName}-IOT-Development-DFFI`,
      techUsed: [
        {
          techName: "Sensors",
          techLogo: "sensors.png",
        },
        {
          techName: "Cloud Sync",
          techLogo: "cloudsync.png",
        },
      ],
    },
    {
      name: "Web Service",
      logo: `${companyName}-IOT-Development-WS`,
      techUsed: [
        {
          techName: "REST",
          techLogo: "rest.png",
        },
        {
          techName: "SOAP",
          techLogo: "soap.png",
        },
      ],
    },
    {
      name: "Operating System",
      logo: `${companyName}-IOT-Development-OS`,
      techUsed: [
        {
          techName: "Android",
          techLogo: "android1.png",
        },
        {
          techName: "iOS",
          techLogo: "ios.png",
        },
      ],
    },
  ],
};
