import React, { useEffect } from "react";
import startup from "../../assets/Images/clutch.png";
import sme from "../../assets/Images/gfaward.png";

import {
  copyrightContentData,
  footerConnectWithUsSocialIcons,
  footerClientLogos,
  footerCareersData,
} from "./footerData";
import { Link } from "react-router-dom";
import { Icon } from "../../lib/Icon/Icon";
export const Footer = () => {
  useEffect(() => {
    const removeSlash = window.location.pathname.slice(
      window.location.pathname.indexOf("/") + 1
    );
    const path = removeSlash || "Top Software Development Company India, USA";
    document.title = `Kdr Soft Solutions | ${path
      .replace(/-/g, " ")
      .replace(
        /\w\S*/g,
        (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
      )}`;
  }, []);

  return (
    <div className="bg-gray-900 py-12 flex flex-col lg:flex-row justify-evenly items-center text-white">
      <div className="flex flex-col justify-center items-center mb-10 lg:mb-0 lg:items-start lg:justify-center">
        <div className="flex items-center justify-center flex-col md:items-start md:justify-start">
          <p className="uppercase text-sm text-gray-500 mb-1 ml-1">
            {footerCareersData.label}
          </p>
          <div className="flex items-center justify-center">
            <svg
              fill="none"
              className="w-6 h-6 stroke-current text-gray-600"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <Link to="/careers" className="text-white text-sm capitalize ml-2">
              {footerCareersData.jobLabel}
            </Link>
          </div>
        </div>
        <div className="flex items-center mt-5">
          <img src={startup} className="w-24 h-24" alt="Startup award" />
          <img src={sme} className="w-24 h-24 ml-4" alt="SME award" />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end">
          {/* Enable below when news letter is needed */}
          {/* <div className="flex flex-col items-center mb-5 lg:mb-0 lg:items-start justify-center">
            <p className="uppercase text-sm tracking-wide text-gray-500 font-bold">
              {footerNewsLetterData.label}
            </p>
            <button
              onClick={() => console.log("Implement news letter")}
              className="font-bold text-blue-500 text-sm"
            >
              {footerNewsLetterData.urlText}
            </button>
          </div> */}
          <div className="flex flex-col items-center justify-center lg:items-end">
            <p className="uppercase text-sm tracking-wide text-gray-500 font-bold mb-2">
              {footerConnectWithUsSocialIcons.label}
            </p>
            <ul className="flex">
              {footerConnectWithUsSocialIcons["socialProfiles"].map((el) => (
                <li
                  className="px-2 py-1 hover:bg-gray-700 hover:bg-opacity-50 bg-gray-800 bg-opacity-50 cursor-pointer"
                  key={el.socialLabel}
                >
                  <a
                    href={el.socialURL}
                    className="inline-block align-middle"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      iconName={el.socialLabel}
                      classes="w-4 h-4 text-gray-500"
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="self-center grid grid-flow-row grid-cols-2 md:grid-cols-4 col-gap-10 mt-8">
          {footerClientLogos.map((el) => (
            <img
              className="fill-current text-white"
              src={require(`../../assets/Images/footerClientLogos/${el}.png`)}
              alt={el}
              key={el}
            />
          ))}
        </div>
        <p className="mt-6 text-sm text-center text-gray-600 px-4 md:px-0">
          {copyrightContentData}
          <a
            href="/privacy-policy"
            className="hover:text-gray-200 hover:underline"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            href="terms-conditions"
            className="hover:text-gray-200 hover:underline"
          >
            Terms & Conditions
          </a>{" "}
          |{" "}
          <a href="disclaimer" className="hover:text-gray-200 hover:underline">
            Disclaimer
          </a>{" "}
          |{" "}
          <a href="/sitemap" className="hover:text-gray-200 hover:underline">
            Sitemap
          </a>{" "}
          {/* |{" "} */}
          {/* <a href="/privacy-policy" className="hover:text-gray-200 hover:underline">
            Refund Policy 
          </a> */}
        </p>
        <p className="mt-2 px-4 md:px-0 text-xs text-gray-700 text-center md:text-right capitalize">
          All images are Copyrighted by their respective owners.
        </p>
      </div>
    </div>
  );
};
