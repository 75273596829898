import React, { useState } from "react";
import { FormInputFields } from "./FormInputFields";
import ReCAPTCHA from "react-google-recaptcha";
import FormSelectFields from "./FormSelectFields";
import {
  interestedInData,
  budgetData,
  cCodeData,
  reCaptchaKey,
  categoryData,
  allowedFormats,
} from "./contactFormData";
import { FormTextAreaField } from "./FormTextAreaField";
import { sendMail } from "../utils/sendMail";
import { toast } from "react-toast";

export const Contact = ({
  userNameField,
  emailField,
  codeField,
  phoneNumberField,
  interestedInField,
  budgetField,
  socialField,
  messageField,
  reCaptchaField,
  category,
  sendButtonLabel,
  disclosure,
  uploadButton,
  uploadDisclosure,
}) => {
  let [selectedFile, setSelectedFile] = useState("");
  const [NDA, setNDA] = useState(false);

  const captchaRef = React.createRef();
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();
    if (!!captchaValue) {
      const formData = document.getElementById("contactFormData");
      const data = new FormData(formData);
      data.delete("g-recaptcha-response");
      data.delete("NDA");
      data.delete("file");
      data.set("sendNDA", NDA);
      data.set("attachment", selectedFile);
      const res = await sendMail(data);
      if (res.success) {
        captchaRef.current.reset();
        formData.reset();
        toast.success(res.message, {
          backgroundColor: "green",
          color: "#fff",
        });
      } else {
        toast.error(res.message, {
          backgroundColor: "red",
          color: "#ffff",
        });
      }
    } else {
      toast.error("Please fill the captcha field.", {
        backgroundColor: "red",
        color: "#ffff",
      });
    }
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    const extension = file?.name?.split(".")?.[1];
    if (file?.size && Math.round(file.size / 1024) > 2048) {
      toast.warn("File should be less than 2mb of size", {
        backgroundColor: "#dd6b20",
      });
    } else if (allowedFormats.includes(extension)) {
      setSelectedFile(file);
    } else {
      toast.error(
        `${extension} is not allowed. please upload ${allowedFormats.join(
          " or "
        )}`,
        {
          backgroundColor: "red",
        }
      );
    }
  };

  const checkBoxHandler = (e) => {
    setNDA(e.target.checked);
  };

  const userNameSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-4 h-4">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  );
  const emailSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
  );

  const phoneIconSVG = (
    <svg viewBox="0 0 20 20" fill="#486e90" className="w-5 h-5">
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
    </svg>
  );

  const socialIconSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
      />
    </svg>
  );

  const budgetIconSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  const interestedInIconSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
      />
    </svg>
  );

  const messageIconSVG = (
    <svg fill="none" viewBox="0 0 24 24" stroke="#486e90" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
      />
    </svg>
  );

  return (
    <form
      onSubmit={onSubmitHandler}
      id="contactFormData"
      autoComplete="on"
      className="bg-white"
      name="contact-form"
    >
      <div className="py-4 px-6">
        <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 transition-all duration-200 ease-linear">
          {userNameField && (
            <FormInputFields
              labelName="Name"
              fieldId="name"
              type="text"
              inputFieldName="username"
              inputFiledId="formName"
              required={true}
              svg={userNameSVG}
            />
          )}
          {emailField && (
            <FormInputFields
              labelName="Email Address"
              fieldId="email"
              type="email"
              inputFieldName="email"
              inputFiledId="formEmail"
              required={true}
              svg={emailSVG}
            />
          )}
        </div>
        <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 grid-cols-1">
          <div className="grid grid-flow-col grid-rows-1 col-gap-5">
            {codeField && (
              <FormSelectFields
                svg={phoneIconSVG}
                labelName="Code"
                selectCodeField="formCodeSelect"
                data={cCodeData}
                required={true}
                name="code"
              />
            )}
            {phoneNumberField && (
              <FormInputFields
                labelName="Phone Number"
                fieldId="number"
                type="number"
                inputFieldName="number"
                inputFiledId="formNumber"
                required={true}
              />
            )}
          </div>
          {interestedInField && (
            <FormSelectFields
              svg={interestedInIconSVG}
              labelName={category ? "Category" : "Interested In"}
              data={category ? categoryData : interestedInData}
              selectCodeField="formInterestedIn"
              required={true}
              name="interestedIn"
            />
          )}
        </div>
        <div className="grid grid-flow-row md:grid-cols-2 md:col-gap-10 grid-cols-1">
          {budgetField && (
            <FormSelectFields
              svg={budgetIconSVG}
              labelName="Your Budget"
              selectCodeField="formBudget"
              data={budgetData}
              required={true}
              name="budget"
            />
          )}
          {socialField && (
            <FormInputFields
              labelName="Skype ID/Whatsapp No."
              fieldId="socialNumber"
              type="text"
              inputFieldName="socialNumber"
              inputFiledId="formSocialNumbers"
              required={true}
              svg={socialIconSVG}
            />
          )}
        </div>
        {messageField && (
          <FormTextAreaField
            textAreaFieldId="formTextArea"
            svg={messageIconSVG}
            labelName="Message"
            required={true}
            name="description"
          />
        )}
        {uploadDisclosure && (
          <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
            {uploadButton && (
              <div className="flex">
                <label
                  htmlFor="file"
                  className="bg-gradient-purple-blue text-white flex justify-center items-center px-4 py-2 cursor-pointer shadow-sm hover:shadow-md rounded-lg"
                >
                  <svg
                    viewBox="0 0 20 20"
                    className="w-8 h-8 fill-current text-white"
                    fill="currentColor"
                  >
                    <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
                  </svg>
                  <span>Add File</span>
                </label>
                <input
                  className="hidden"
                  name="file"
                  onChange={fileHandler}
                  type="file"
                  id="file"
                />
              </div>
            )}
            {disclosure && (
              <div className="flex justify-center items-center">
                <input
                  type="checkbox"
                  id="NDA"
                  onChange={checkBoxHandler}
                  name="NDA"
                />
                <label
                  htmlFor="NDA"
                  className="ml-2 text-sm cursor-pointer font-extrabold"
                  style={{
                    color: "#486e90",
                  }}
                >
                  Yes, send me a Mutual NDA (Non-Disclosure Agreement)
                </label>
              </div>
            )}
          </div>
        )}
        {selectedFile !== "" && (
          <p className="text-center font-bold">{`You have selected: ${selectedFile.name}`}</p>
        )}
        <div className="flex md:flex-row flex-col justify-evenly items-center mt-4">
          {reCaptchaField && (
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onExpired={() => {
                captchaRef.current.reset();
                toast.warn("Captcha selection expired please verify again", {
                  backgroundColor: "#dd6b20",
                });
              }}
              ref={captchaRef}
              size="compact"
            />
          )}
          <button
            type="submit"
            className="px-4 py-3 mt-4 md:mt-0 w-full hover:bg-gradient-purple-blue hover:text-white md:max-w-lg text-blue-700 font-bold rounded-md uppercase text-sm border-2 border-main-blue transition-all duration-200 ease-linear"
          >
            {sendButtonLabel}
          </button>
        </div>
      </div>
    </form>
  );
};
