import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CardTypeOne } from "../Card/CardTypeOne/CardTypeOne";
import { MultiRowCarousel } from "../Carousel/MultiRowCarousel";

export const Type11Banner = ({ data }) => {
  const multiRowLength = data.multiRow && Object.keys(data.multiRow).length;
  useEffect(() => {
    document
      .getElementById("tabData").firstChild
      .classList.add("bg-white", "text-gray-900");
  });
  return (
    <>
      <section>
        <div className="relative">
          <div className="absolute downArrow"></div>
          <section className="text-center pt-10 bg-gradient-resource text-white  px-4 xl:px-0">
            <p className="text-xl md:text-2xl lg:text-3xl font-bold capitalize">
              {data.text1}
            </p>
            <p className="text-sm md:text-base mt-3 w-full max-w-6xl text-gray-300 mx-auto">
              {data.text2}
            </p>
          </section>
          {data.tabData && (
            <section
              className={`relative bg-gradient-resource flex items-center justify-center  px-4 xl:px-0`}
            >
              <div
                id="tabData"
                className={`w-full md:max-w-6xl text-white py-4 grid grid-flow-row grid-cols-${
                  data.tabData.length % 2 === 0 ? 2 : 1
                } row-gap-2 md:grid-cols-${data.tabData.length}`}
              >
                {data.tabData.map((el) =>
                  el.buttonBehavior !== "new" ? (
                    <button
                      key={el.label}
                      id={el.label.toLowerCase().replace(" ", "_")}
                      className={`tabLink text-sm md:text-base uppercase font-bold py-4 text-center`}
                    >
                      {el.label}
                    </button>
                  ) : (
                    <Link
                      to={el.link}
                      key={el.label}
                      id={el.label.toLowerCase().replace(" ", "_")}
                      className={`tabLink text-sm md:text-base uppercase font-bold py-4 text-center`}
                    >
                      {el.label}
                    </Link>
                  )
                )}
              </div>
            </section>
          )}
        </div>
      </section>
      {data.cardFormat ? (
        <section id="awardCardData">
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {data["cardData"].map((el) => (
              <CardTypeOne data={el} key={el.id} />
            ))}
          </div>
        </section>
      ) : (
        <section className="bg-gray-200 w-full flex flex-col">
          {data.multiRow &&
            Object.keys(data.multiRow).map((el, i) => (
              <div
                className={`${
                    multiRowLength !== i + 1 && "border-b-2 border-gray-300"
                } container mx-auto`}
                key={i}
              >
                <MultiRowCarousel
                  data={data.multiRow[`${el}`]}
                  noOfCards={4}
                  noOfItemsForCarousel={8}
                />
              </div>
            ))}
          {data.disclaimerText && (
            <span className="text-sm px-2 py-1 mx-4 md:mx-auto my-4 rounded-md bg-gray-400 tet-gray-800">
              {data.disclaimerText}
            </span>
          )}
        </section>
      )}
    </>
  );
};
