import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "pwa.png",
    imageText2: "PWA Development Company",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeThreeBannerData = {
  heading: "Why hire Kdr for Progressive Web Application Development?",
  description:
    "Kdr Soft Solutions is a pioneer PWA Development Company that creates modern style web applications that are highly secure, functional, optimized, responsive, reliable and speedy with relevant content. We ensure that our Progressive web app development services follow industry standards and guidelines of Progressive Web App Development. Major Reasons To Hire Us:",
  details: [
    {
      iconName: `${companyName}-MDA-option-BM`,
      label: "Flexible Engagement Model",
      content:
        "We offer flexible engagement models for our global clients and prospects.",
    },
    {
      iconName: `${companyName}-PWA-AC`,
      label: "App Customization",
      content:
        "We develop customized apps that are business centric and will suit to your needs.",
    },
    {
      iconName: `${companyName}-PWA-LCM`,
      label: "Lifecycle Management",
      content: "Every project goes via app development life cycle.",
    },
    {
      iconName: `${companyName}-PWA-SO`,
      label: "Security/IP Ownership",
      content:
        "We follow rules guided by NDA to protect the source code. We strictly adhere to privacy of data.",
    },
    {
      iconName: `${companyName}-PWA-RD`,
      label: "Responsive",
      content:
        "We build web apps that can fit into screens of any size – either desktop, mobile, tablet etc.",
    },
    {
      iconName: `${companyName}-PWA-MBS`,
      label: "Multiple Browser Support",
      content: "Our apps work on virtually every browser, even on old browsers",
    },
    {
      iconName: `${companyName}-PWA-OS`,
      label: "Offline Support",
      content:
        "Our apps can work offline as they are enriched by service workers and also capable of working on low-quality networks",
    },
    {
      iconName: `${companyName}-PWA-AS`,
      label: "App Shell",
      content:
        "Our apps make use of app shell model to provide app-style navigation and interactions",
    },
    {
      iconName: `${companyName}-PWA-TS`,
      label: "TLS Support",
      content:
        "Our apps are served via TLS to prevent snooping and it is ensured that the content has not been tampered with",
    },
    {
      iconName: `${companyName}-PWA-D`,
      label: "Discoverable",
      content:
        "Our apps are easily discoverable by search engine due to W3C manifests and service worker registration.",
    },
    {
      iconName: `${companyName}-PWA-PN`,
      label: "Push Notifications",
      content:
        "Our apps contain features like push notifications make re-engagement easy.",
    },
    {
      iconName: `${companyName}-PWA-NIR`,
      label: "No Installation Required",
      content:
        "These do not require any complex installation and can be shared via link",
    },
    {
      iconName: `${companyName}-PWA-CPS`,
      label: "Cross-platform support (Write once, use anywhere)",
      content:
        "A single codebase can be used for all platforms (Web, Android, and iOS)",
    },
    {
      iconName: `${companyName}-PWA-UIE`,
      label: "Updating is Easy",
      content:
        "The app update process can be easily controlled by the developer and there is no requirement of the app store",
    },
  ],
  showButton: true,
  buttonText: "Hire PWA Developer",
  buttonType: "button",
  buttonLink: "/request-quote",
};

export const topTypeThreeBannerData = {
  heading: "Basic Principles to Progressive Web Application Development",
  details: [
    {
      iconName: `${companyName}-PWA-SSR`,
      content: "Short and simple registration",
    },
    {
      iconName: `${companyName}-PWA-ID`,
      content: "Improved design",
    },
    {
      iconName: `${companyName}-PWA-FOKF`,
      content: "Focusing on specific features",
    },
    {
      iconName: `${companyName}-PWA-CCF`,
      content: "Collecting customer feedback",
    },
    {
      iconName: `${companyName}-PWA-UAP`,
      content: "Updating the application periodically",
    },
    {
      iconName: `${companyName}-PWA-EAP`,
      content:
        "Ensure that the app is profitable but not at expense of user experience",
    },
  ],
};

export const topSecondTypeThreeBannerData = {
  semiHeading:
    "HIRE PWA DEVELOPER AND GET HIGHLY PERFORMANT WEB APPLICATIONS THAT LOOK JUST LIKE NATIVE APPS",
  heading: "Progressive Web Application Development",
  para:
    "Progressive web applications (PWA’s) combine the best features of a web as well as native applications. But with limited access to device’ features like camera, GPS etc. They can work even on old browser versions. They make use of modern web capabilities to deliver an app-like experience. They develop from web browser pages. Such apps are built in Angular, React or any other framework that runs on the desktop, Mobile and all other platforms with advanced support like Android, iOS, Windows etc. PWA’s are capable of tapping the native capabilities of the device. Service Workers work closely as an engine to give native powers to normal web apps in their OS and browsers.",
};

export const appInfographics = {
  heading: "Why Progressive Web Applications?",
  description:
    "Get mobile-app-like experiences, to deliver fast, engaging and reliable experiences, reachable and reliable websites",
  infoContent: [
    {
      heading: "Sharable and Linkable",
      description:
        "PWA does not require any installation. It can be easily shared via links",
      imageFolder: "pwa",
      imageName: "sharable.svg",
    },
    {
      heading: "Offline Support",
      description:
        "PWA’s are capable of working even in poor internet connectivity",
      imageFolder: "pwa",
      imageName: "offline-support.svg",
    },
    {
      heading: "Increased Conversion Rate",
      description:
        "Frictionless navigation improves conversion and user retention rates.",
      imageFolder: "pwa",
      imageName: "retention.svg",
    },
    {
      heading: "User Friendly",
      description:
        "PWA captures wider audience as they cater to almost all browsers, all platforms and even work offline when required. They are secure, fast and reliable.",
      imageFolder: "pwa",
      imageName: "user-friendly.svg",
    },
    {
      heading: "Progressive",
      description:
        "If the apps have a fully responsive website that performs the same way where is the need to waste time, money, space and internet on smartphones by installing the native apps? It works on multiple platforms and multiple browsers and enhances the existing web technology.",
      imageFolder: "pwa",
      imageName: "progressive.svg",
    },
  ],
};

export const serviceOfferedTypeTwo = {
  heading: "Progressive Web Apps Services by Kdr Soft Solutions",
  description:
    "We ensure that Progressive Web Applications built by us will be identical to native mobile applications and performance will never be an issue. These run faster across all platforms along with integrating features like offline navigation, push notifications, data analysis and easy distribution channels.",
  data: [
    "Progressive App Design, Development and testing customized to your needs",
    "Application Shell Architecture",
    "Quality Responsive Design",
    "Application Shell Architecture",
  ],
};
