export const webPageComponent = {
  "web-development": {
    navbar: true,
    type_two_image_banner: true,
    type_six: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "e-commerce-cms": {
    navbar: true,
    type_two_image_banner: true,
    type_seven: true,
    stack_used: true,
    type_eight: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  php: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    result_info: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  wordpress: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    result_info: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  dotnet: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  java: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  magento: {
    navbar: true,
    image_banner: true,
    multi_row: false,
    top_type_three: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  angularjs: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    type_nine: true,
    work_with_us: true,
    footer: true,
  },
  reactjs: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    type_nine: true,
    work_with_us: true,
    footer: true,
  },
  nodejs: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    app_carousel: true,
    type_nine: true,
    work_with_us: true,
    footer: true,
  },
};
