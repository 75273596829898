import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

export const RequestQuoteBannerTypeTwo = ({ data }) => {
  return (
    <div className="bg-gradient-resource py-10 px-4 xl:px-0">
      <div className="container mx-auto text-white flex flex-col text-center justify-center items-center">
        <Icon iconName="smiley-face" classes="w-16 h-16" />
        {data.text1 && <p className="text-2xl md:text-3xl lg:text-4xl font-bold mt-2">{data.text1}</p>}
        {data.text2 && <p className="text-lg font-bold">{data.text2}</p>}
        {data.showButton && <Link to={data.buttonLink} className="px-6 py-4 border-2 mt-8 text-sm md:text-base uppercase border-gray-200 rounded-md font-bold hover:bg-white hover:text-gray-800">
            {data.buttonText}
        </Link>}
      </div>
    </div>
  );
};
