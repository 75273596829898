export const flow = [
  {
    logo: "know-how-UC",
    name: "UNDERSTAND OUR CLIENTS",
    cardDetail: ["Call or Meeting", "NDA (Non-Disclosure)"],
  },
  {
    logo: "know-how-PE",
    name: "PROJECT ESTIMATES",
    cardDetail: ["Gather Requirements", "Perfect Proposal"],
  },
  {
    logo: "know-how-SP",
    name: "STRATEGIC PARTNERSHIP",
    cardDetail: ["T&C Communicated", "Project Agreement"],
  },
];
