import { companyName } from "../../config";

export const imageTypeTwoBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "opensource.png",
    imageText2: "Open Source web development powering millions of websites",
    imageText3:
      "WE BUILD OUTSTANDING WEB SOLUTIONS TO EXPEDITE BUSINESS GROWTH USING WORDPRESS, JOOMLA, DRUPAL",
    points: [
      "Top-notch WordPress solutions",
      "Tailor-made professional solutions",
      "Magento certified developers",
      "Advanced resources and tools",
      "Responsive web solutions",
      "Integrated workflow",
    ],
    buttonType: "button",
    buttonLink: "portfolios",
    buttonText: "View our latest OPENSOURCE WEB Portfolio",
  },
};

export const typeThreeBannerData = {
  heading:
    "Development benefits offered by Kdr that are customized to your needs",
  description: "WE TAKE RESPONSIBILITY. YOU RECEIVE BENEFITS",
  details: [
    {
      label: "Innovative & Unique Solutions",
      iconName: `${companyName}-OCMS-IUS`,
      content:
        "Our team never compromises with creativity- be it a small web product or an enterprise level app.s.",
    },
    {
      iconName: `${companyName}-OCMS-DC`,
      label: "Dependable Code",
      content:
        "Everything written in the code is logical without any unnecessary lines that confuse and let you suffer.",
    },
    {
      iconName: `${companyName}-OCMS-UF`,
      label: "Unparalleled Flexibility",
      content:
        "With open source web development, clients have full freedom to modify and customize their solutions any time before deployment.",
    },
    {
      iconName: `${companyName}-OCMS-FE`,
      label: "Featured Expertise",
      content:
        "In each of the open source technology, we have expert programmers to assist you with the right open source web development services and solutions.",
    },
    {
      iconName: `${companyName}-OCMS-MS`,
      label: "Maintenance & Support",
      content:
        "Our in-house support team offers full-scale support services to clients for each product we deliver.",
    },
    {
      iconName: `${companyName}-OCMS-PM`,
      label: "Performance Monitoring",
      content:
        "Each of the assigned designers and developers are continuously monitored by a Senior-level Manager.",
    },
    {
      iconName: `${companyName}-OCMS-ZCO`,
      label: "Zero Cost Overheads",
      content:
        "There are no operational overheads, no extra office space, hardware or software and any hidden costs involved.",
    },
    {
      iconName: `${companyName}-OCMS-FTTM`,
      label: "Fast time-to-market",
      content:
        "Each of the open source development services offered will be market-ready and no extra time will be wasted in improvements and debugging.",
    },
  ],
};

export const stackEmployedData = {
  text1: "Infinite Possibilities with Top Open Source Web Development Tools",
  text2: "HANDS-ON EXPERIENCE WITH OPEN SOURCE TECHNOLOGIES",
  textUnderline: true,
  para:
    "Outdated tools and technologies can harm your business. At Kdr, we use latest, reliable tools and technologies to offer top-notch open source development services. Our developers are competent in CMS development services.",
  techData: [
    {
      logo: "joomla.png",
      name: "Joomla",
    },
    {
      logo: "drupal.png",
      name: "Drupal",
    },
    {
      logo: "wordPress.png",
      name: "WordPress",
    },
    {
      logo: "magento.png",
      name: "Magento",
    },
  ],
};

export const openSourceData = {
  text1: "Offering Open Source web development services",
  text2:
    "To make sure our clients receive the most out of our open source development services, we follow a time-bound and cost-effective development philosophy, which our developers use to craft any web idea for your online business website into a reality. To accomplish this, we use state-of-the-art open source web development technologies like WordPress, Joomla, Drupal, Magento, and more. We stand second to none when it comes to maximizing business outputs with optimized web solutions for our valued customers.",
  text3: "The various services we provide are:",
  points: [
    "CMS Development Services",
    "Magento E-commerce",
    "Custom Module Development",
    "Web Store Development",
    "Website Support & Maintenance",
    "Migrations & Upgradations",
    "Responsive Web",
  ],
  buttonText: "Talk to our expert developers",
  buttonLink: "#workWithUs",
  image: "open-source.png",
};
