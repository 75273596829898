import React from "react";
import { Icon } from "../../Icon/Icon";

export const TimeLineTypeOne = ({ data }) => {
  return (
    <div className="pt-16 container mx-auto">
      <p className="text-center text-4xl font-bold capitalize">
        {data.heading}
      </p>
      <p className="text-center uppercase text-xl text-gray-700 mb-10">
        {data.description}
      </p>
      <div className="relative timeline-one grid grid-flow-row grid-cols-1 md:grid-cols-2 col-gap-10 row-gap-0 h-full container mx-auto">
        {data.details.map((el, i) => (
          <div className={`relative`} key={i}>
            <div
              className={`hidden md:block ${
                i % 2 === 0 ? "curve-line-left" : "curve-line-right"
              }`}
            ></div>
            <div
              className={`flex justify-center border-2 py-4 mb-4 md:border-transparent hover:shadow-md md:hover:shadow-none border-gray-300 rounded-lg ${
                i % 2 !== 0
                  ? "md:justify-start md:py-16"
                  : "md:justify-end md:py-2"
              }`}
            >
              <div
                data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                className={`max-w-lg flex flex-col ${
                  i % 2 === 0
                    ? "md:order-1 md:items-end md:text-right"
                    : "md:order-2 md:items-start md:text-left"
                } px-4 order-2`}
              >
                <Icon
                  iconName={el.iconName}
                  classes="w-16 h-16 text-blue-500"
                />
                <p className="mt-6 text-2xl">{el.label}</p>
                <p className="text-base text-gray-600 mt-2">{el.content}</p>
              </div>
              <div
                data-aos="fade-out"
                className={`${
                  i % 2 === 0 ? "md:order-2" : "md:order-1"
                } order-1 px-4`}
              >
                <span className="inline-flex justify-center items-center w-16 h-16 rounded-full font-bold text-base text-white bg-blue-700">
                  0{i + 1}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
