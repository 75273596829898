import React from "react";
import { Contact } from "../../lib/Contact/Contact";
import { Icon } from "../../lib/Icon/Icon";

export const WorkWithUs = () => {
  return (
    <div
      data-aos="fade-in"
      className="flex w-full flex-col px-4 xl:px-0 lg:flex-row justify-center items-center py-12"
      style={{ background: "#162d42" }}
      id="workWithUs"
    >
      <div className="relative flex flex-col items-center text-white lg:pr-12">
        <div className=" text-center lg:text-left mb-10 ">
          <p className="text-3xl md:text-4xl font-bold mb-1">
            Interested in working with us?
          </p>
          <p className="text-lg md:text-xl tracking-wide text-gray-500">
            LET’S TALK AND GET STARTED
          </p>
        </div>
        <div className="flex flex-col justify-center items-stretch md:justify-start lg:flex-col">
          <a
            className="flex items-center px-3 py-2 rounded-md bg-workwithus-button hover:bg-workwithus-buttonHovered transition duration-200 ease-linear"
            href="mailto:sales@kdrsoftsolutions.com"
          >
            <svg
              className="hidden md:block w-8 h-8"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <div className="flex flex-col text-center md:text-left md:pl-4 md:border-l-2 md:ml-4 border-gray-700">
              <p className="uppercase text-workwithus-buttonTopTextColor text-xs md:text-sm tracking-widest">
                mail to our sales department
              </p>
              <p className="font-bold text-xl md:text-2xl leading-9">
                sales@kdrsoftsolutions.com
              </p>
            </div>
          </a>
          <a
            className="flex items-center px-3 py-2 rounded-md mt-4 lg:mt-4 lg:ml-0 bg-workwithus-button hover:bg-workwithus-buttonHovered transition duration-200 ease-linear"
            href="https://wa.me/+13025977591"
          >
            <Icon
              classes="hidden md:block fill-current w-8 h-8 text-white"
              iconName="KDR-mis-W"
            />
            <div className="flex flex-col text-center md:text-left md:pl-4 md:border-l-2 md:ml-4 border-gray-700">
              <p className="uppercase text-workwithus-buttonTopTextColor text-xs md:text-sm tracking-widest">
                Our WhatsApp Number
              </p>
              <p className="font-bold text-xl md:text-2xl leading-9">
              +1 302 597 7591
              </p>
            </div>
          </a>
          <a
            className="flex items-center px-3 py-2 rounded-md mt-4 lg:mt-4 lg:ml-0 bg-workwithus-button hover:bg-workwithus-buttonHovered transition duration-200 ease-linear"
            href="tel:18008898589

            "
          >
            <Icon
              classes="hidden md:block fill-current w-8 h-8 text-white"
              iconName="KDR-call"
            />
            <div className="flex flex-col text-center md:text-left md:pl-4 md:border-l-2 md:ml-4 border-gray-700">
              <p className="uppercase text-workwithus-buttonTopTextColor text-xs md:text-sm tracking-widest">
                Our Phone Number
              </p>
              <p className="font-bold text-xl md:text-2xl leading-9">
            1800 889 85 89

              </p>
            </div>
          </a>
        </div>
        <div className="self-center lg:self-start">
          <p className="text-2xl mt-10">& What you will get:</p>
          <p className="flex items-center mt-5 mb-2 text-base text-gray-500">
            <span className="inline-block rounded-full w-2 h-2 mr-6 bg-blue-600"></span>
            <span> On Call Inquiry Assistance</span>
          </p>
          <p className="flex items-center mb-2 text-base text-gray-500">
            <span className="inline-block rounded-full w-2 h-2 mr-6 bg-blue-600"></span>
            <span>Project Consulting By Experts</span>
          </p>
          <p className="flex items-center text-base text-gray-500">
            <span className="inline-block rounded-full w-2 h-2 mr-6 bg-blue-600"></span>
            <span>Detailed Project Estimation</span>
          </p>
        </div>
        <div
          className="absolute hidden lg:block"
          style={{
            width: "1px",
            height: "100%",
            background: "rgba(255,255,255,.1)",
            right: "15px",
            top: "0",
            textAlign: "center",
          }}
        >
          <span
            className="absolute uppercase w-16 rounded-full"
            style={{
              height: "64px",
              left: "-31px",
              fontSize: "16px",
              color: "#c5d1dd",
              letterSpacing: ".6px",
              lineHeight: "64px",
              background: "#162d42",
              top: " 50%",
              marginTop: "-32px",
            }}
          >
            OR
          </span>
        </div>
      </div>
      <div className="mt-6 max-w-2xl lg:ml-6 bg-white rounded-lg">
        <Contact
          userNameField={true}
          emailField={true}
          budgetField={true}
          codeField={true}
          interestedInField={true}
          messageField={true}
          phoneNumberField={true}
          reCaptchaField={true}
          socialField={true}
          category={false}
          sendButtonLabel="Send your Inquiry"
          disclosure={true}
          uploadDisclosure={true}
          uploadButton={true}
        />
      </div>
    </div>
  );
};
export default WorkWithUs;
