import React from "react";

import { Navbar } from "../Navbar/Navbar";
import { Footer } from "../Footer/Footer";
import { TypeFourBanner } from "../../lib/TypeFourBanner/TypeFourBanner";
import { DynamicallyInsertHtml } from "../../lib/DynamicallyInsertHtml/DynamicallyInsertHtml";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { disclaimerData } from "./disclaimerData";

export const Disclaimer = () => {
  const { imageSection, typeFourBannerData, policyData } = disclaimerData;
  return (
    <div className="max-h-full">
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        imageName={imageSection.imageName}
        folderName={imageSection.folderName}
        imageText2={imageSection.imageText2}
        showButton={false}
        imageText3={imageSection.imageText3}
      />
      <TypeFourBanner data={typeFourBannerData} showArrow={true} />
      <DynamicallyInsertHtml html={policyData} />
      <Footer />
    </div>
  );
};
