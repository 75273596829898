const firstColumn = [
  "About Us",
  "Our Team",
  "Our Development Process",
  "Engagement Models",
  "Project Communication Strategy",
  "Quality Assurance",
];

const secondColumn = [
  "Client Reviews",
  "Our Clients",
  "Our Partners",
  "Awards & Memberships",
  "Our Blog",
];

const thirdColumn = ["Press & Media", "Contact Us"];

export const names = {
  firstColumn,
  secondColumn,
  thirdColumn,
};
