import { companyName } from "../../config";

export const imageTypeTwoBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "webdev.png",
    imageText2: "Web development services",
    imageText3:
      "WE ARE BEST-IN-CLASS WEB DEVELOPMENT AGENCY THAT STANDS OUT AMONG THOUSANDS OF COMPETITORS",
    points: [
      "Qualified Web Resources",
      "State-of-the-art Facility",
      "Standardized Approach",
      "User-centric Solutions",
      "Proven Track Record",
      "Authorized Credentials",
    ],
    buttonType: "button",
    buttonLink: "request-quote",
    buttonText: "View our latest Web app portfolio",
  },
};

export const stackUsedData = {
  text1: "Innovation & excellence powered by latest technology",
  text2: "THE POSSIBILITIES ARE INFINITE",
  stackData: [
    {
      name: "PHP",
      logo: `${companyName}-web-development-P`,
      techUsedPara:
        "Feature-packed and high-performance websites from experienced PHP developers. With the help of best PHP frameworks available, our team makes web development easy and enjoyable so that you can focus on building your business.",
      techUsed: [
        {
          techName: "CakePHP",
          techLogo: "cake-php.png",
        },
        {
          techName: "Yii",
          techLogo: "yii.png",
        },
        {
          techName: "Zend", 
          techLogo: "zend.png",
        },
        {
          techName: "Codeigniter", 
          techLogo: "codeigniter.png",
        },
        {
          techName: "Symfony", 
          techLogo: "symfony.png",
        },
        {
          techName: "Laravel", 
          techLogo: "laravel.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Asp.net",
      logo: `${companyName}-web-development-AN`,
      techUsedPara:
        "Dynamic and scalable web pages with server-side application framework. Our developers make the best use of just-in-time compilation, native optimization and caching services. Enterprise-class websites and web apps are well built by ASP.NET.",
      techUsed: [
        {
          techName: "Visual Studio 2015",
          techLogo: "visual_studio_2015.png",
        },
        {
          techName: ".NET Core",
          techLogo: "dot_net_core.png",
        },
        {
          techName: ".NET 4.5",
          techLogo: "dot_net_yet.png",
        },
        {
          techName: "Visual Studio Code",
          techLogo: "visual_studio.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "HTML5 & CSS3",
      logo: `${companyName}-web-development-HC`,
      techUsedPara:
        "Powerful web applications with new HTML elements, behaviors and attributes. Using CSS3, we create advanced layout with shadows, gradients, transitions, animation, flexible grids to add design to our clients' products. Plus, we encourage (through CSS) literate, stable coding that uses programming constructs like real variables, nesting blocks and mix-ins.",
      techUsed: [
        {
          techName: "HTML5",
          techLogo: "html.png",
        },
        {
          techName: "CSS3",
          techLogo: "css.png",
        },
        {
          techName: "Bootstrap",
          techLogo: "bootstrap.png",
        },
        {
          techName: "Foundation 3",
          techLogo: "foundation.png",
        },
        {
          techName: "Grid Systems",
          techLogo: "gridsystems.png",
        },
        {
          techName: "HTML5 Boilerplate",
          techLogo: "html5_boilerplarte.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "JAVA",
      logo: `${companyName}-web-development-JL`,
      techUsedPara:
        "The #1 Programming language for developing all-purpose websites. Because of Java’s better scalability, stability and reliable online technical support, it is used in a variety of computing platforms from embedded devices and mobile phones to enterprise servers and super computers.",
      techUsed: [
        {
          techName: "Spring",
          techLogo: "spring.png",
        },
        {
          techName: "Maven",
          techLogo: "maven.png",
        },
        {
          techName: "Java EE 7",
          techLogo: "Java_ee7.png",
        },
        {
          techName: "Git",
          techLogo: "git.png",
        },
        {
          techName: "IntelliJ",
          techLogo: "intelliJ.png",
        },
        {
          techName: "Jenkins",
          techLogo: "jenkins.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "OPEN SOURCE",
      logo: `${companyName}-web-development-OS`,
      techUsedPara:
        "To stand out as a business website, we use Open-source technologies, tools and frameworks as they power much of your web presence. Since the design is publicly accessible, anyone from your team can modify it with their creative minds and have a more customizable, more secure and more flexible website.",
      techUsed: [
        {
          techName: "Magento",
          techLogo: "magento.png",
        },
        {
          techName: "WordPress",
          techLogo: "wordPress.png",
        },
        {
          techName: "Joomla",
          techLogo: "joomla.png",
        },
        {
          techName: "Drupal",
          techLogo: "drupal.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "JAVA SCRIPT",
      logo: `${companyName}-web-development-J`,
      techUsedPara:
        "Using the top programming language with a number of repositories, we are pioneers in JavaScript as we can create highly responsive interfaces to improve your customer's experience and provide them with dynamic functionality. These include a search box with suggested results, periodic loading of information, and animated elements on the page.",
      techUsed: [
        {
          techName: "Kendo",
          techLogo: "kendo.png",
        },
        {
          techName: "Node.js",
          techLogo: "nodejs.png",
        },
        {
          techName: "jQuery",
          techLogo: "jQuery.png",
        },
        {
          techName: "AngularJS",
          techLogo: "angularjs.png",
        },
        {
          techName: "Ember",
          techLogo: "ember.png",
        },
        {
          techName: "ReactJS",
          techLogo: "react_native.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
  ],
  showButton: false,
};

export const typeSixBannerData = {
  text1: "Completely customizable high-quality robust websites",
  text2:
    "It's not just the website, we create a seamless user experience that keeps them coming back. We are recognized among leading custom web development companies. Our web development services include:",
  left: {
    heading: "WEB DEVELOPMENT COMPANY",
    para1:
      "Kdr is a premier website development company. We’re in the business of awesome web development because we only rely on premier, modern and most worthy technologies to build web projects. Focusing on process-driven development, each of us at Kdr aims at providing impressive website development services, quick product-to-market delivery so that users (client’s customers) don’t look any further as they can find everything they need on the websites we build.",
    para2:
      "Kdr is a premier website development company. We’re in the business of awesome web development because we only rely on premier, modern and most worthy technologies to build web projects. Focusing on process-driven development, each of us at Kdr aims at providing impressive website development services, quick product-to-market delivery so that users (client’s customers) don’t look any further as they can find everything they need on the websites we build.",
    para3:
      "Our team comprises Magento certified developers, skilled professionals in HTML5, CSS3, Joomla, WordPress, PHP, JavaScript, ASP.NET, Drupal. They will be worth your time and dollars.",
  },
  right: {
    points: [
      {
        iconName: `${companyName}-web-development-CWD`,
        label: "Custom Web Development",
      },
      {
        iconName: `${companyName}-web-development-CRM`,
        label: "Customer Relationship Management (CRM)",
      },
      {
        iconName: `${companyName}-web-development-CS`,
        label: "Cloud Solutions",
      },
      {
        iconName: `${companyName}-web-development-TPI`,
        label: "Third-Party Integrations (CRM and more)",
      },
      {
        iconName: `${companyName}-web-development-WM`,
        label: "WorkFlow Management",
      },
      {
        iconName: `${companyName}-web-development-EWD`,
        label: "Enterprise Web Development",
      },
      {
        iconName: `${companyName}-web-development-ECBB`,
        label: "E-Commerce, B2B & B2C",
        link: "/e-commerce-cms",
      },
      {
        iconName: `${companyName}-web-development-ACBA`,
        label: "Any custom business application",
      },
    ],
    buttonText: "let's discuss your project",
    buttonLink: "#workWithUs",
  },
};

export const typeThreeBannerData = {
  heading:
    "Performance-driven website development company that delivers value to your precise business needs",
  description: "AN EFFECTIVE RANGE OF WEB APP SOLUTIONS FOR EVERY BUSINESS",
  details: [
    {
      label: "Advanced Web Development",
      content:
        "We are skilled in cutting-edge technologies like HTML5, CSS3, CakePHP 3.X for creative technical execution.",
      iconName: `${companyName}-web-development-AWD`,
    },
    {
      label: "Cloud-based Dev",
      content:
        "Maximally leveraging cloud services to accelerate the development process.",
      iconName: `${companyName}-web-development-CBD`,
    },
    {
      label: "Dedicated web teams",
      content:
        "Our professionals are certified in the various technologies they work for, so our customers can get the most out of our service.",
      iconName: `${companyName}-web-development-DWT`,
    },
    {
      label: "Full-cycle Development",
      content:
        "Includes project planning, requirement analysis, system design, implementation, testing and deployment.",
      iconName: `${companyName}-web-development-FCD`,
    },
    {
      label: "Quality Assurance",
      content:
        "Quality matters to us the most- each website we develop undergoes a scrupulous bug fixing process.",
      iconName: `${companyName}-web-development-QA`,
    },
    {
      label: "Consolidation & Integration",
      content:
        "We integrate potential tools, innovative technologies and good quality designs.",
      iconName: `${companyName}-web-development-CI`,
    },
    {
      label: "Agile & Adaptive",
      content:
        "Ranked among top web development agencies, we have a proven track-record of quality working with our agile model.",
      iconName: `${companyName}-web-development-AA`,
    },
    {
      label: "Maintenance & Support",
      content: "We are here whenever you need us after your site goes live.",
      iconName: `${companyName}-web-development-MS`,
    },
  ],
};
