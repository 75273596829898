import React from "react";
import { partnershipData } from "./partnershipData";
import { Link } from "react-router-dom";
import { Icon } from "../../lib/Icon/Icon";

export const Partnership = () => {
  return (
    <div
      data-aos="fade-up"
      className="bg-gray-200 flex flex-col justify-center text-center py-16"
    >
      <p className="italic text-base font-bold tracking-wide my-2 uppercase">
        / CUSTOM DEVELOPMENT- CHOOSE YOUR WORKING MODEL /
      </p>
      <p className="font-bold text-2xl md:text-3xl mb-5 uppercase">
        PARTNERSHIP MODELS
      </p>
      <p className="text-lg md:text-xl text-gray-800 px-10 md:px-16 lg:w-full lg:max-w-6xl mx-auto">
        A relation tailored to your needs. From an individual developer to a
        dedicated project team, we have all you need. Our three effective
        partnership models are designed to adapt to individual business
        requirements and multiply profits for our clients.
      </p>
      <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-3 mx-5 xl:mx-auto gap-8 lg:gap-10  mt-12">
        {partnershipData.map((eachKey, i) => (
          <div
            data-aos="flip-up"
            key={eachKey.logo}
            className="flex flex-col justify-center items-center w-full max-w-lg mx-auto xl:max-w-xs bg-white rounded-lg py-4 px-6 transform hover:-translate-y-1 transition-all duration-150 ease-linear cursor-pointer shadow-md hover:shadow-lg"
          >
            <Icon classes="w-24 h-24" iconName={eachKey.logo} />
            <p className="mt-8 text-xl font-bold uppercase">
              {eachKey.heading}
            </p>
            <p className="mt-2 text-md text-gray-700">{eachKey.description}</p>
          </div>
        ))}
      </div>
      <Link
        to="/request-quote"
        className="bg-gradient-purple-blue p-4 text-white rounded-md mx-auto mt-8 text-base uppercase font-bold cursor-pointer hover:shadow-lg transform hover:-translate-y-1 transition-all duration-150 ease-linear"
      >
        let’s discuss your project
      </Link>
    </div>
  );
};
export default Partnership;
