import React from "react";

export const ToolsUsed = ({ data }) => {
  return (
    <div className="py-16">
      <div data-aos="fade-in" className="container mx-auto text-center">
        {data.heading && <p className="text-3xl font-bold uppercase">{data.heading}</p>}
        {data.description && <p className="text-lg text-gray-700 mt-3">{data.description}</p>}
        {data.data && <div className="grid grid-cols-2 lg:grid-cols-4 gap-1 grid-flow-row mt-5">
          {data.data.map((el, i) => (
            <div
              data-aos="fade-up-right"
              data-aos-delay={100 * `${i + 1}`}
              key={i}
              className={`col-span-${el.cols} bg-blue-300 bg-opacity-25 p-6 rounded-md`}
            >
              <p className="text-left text-blue-700 uppercase font-bold">
                {el.name}
              </p>
              <div className="mt-6 text-left">
                {el.used.map((el,i) => (
                  <span key={i} className="inline-block capitalize text-gray-700 p-1 rounded-lg border-2 border-gray-400 mb-2 mr-2">
                    {el}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>}
      </div>
    </div>
  );
};
