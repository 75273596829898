import React from "react";
import { Link } from "react-router-dom";

export const ConnectBanner = ({ data }) => {
  return (
    <div className="bg-gradient-to-r from-blue-700 to-blue-600 py-16 px-4 xl:px-0 text-center lg:text-left">
      <div
        data-aos="fade-in"
        className="container mx-auto flex flex-col justify-center lg:flex-row lg:justify-evenly items-center text-white"
      >
        {data.text && (
          <p className="max-w-xl text-2xl font-bold capitalize break-words">
            {data.text}
          </p>
        )}
        {data.showButton &&
          (data.buttonType === "button" ? (
            <Link
              to={data.buttonLink}
              className="inline-block p-4 mt-4 lg:md-0 rounded-md bg-gray-800 uppercase"
            >
              {data.buttonText}
            </Link>
          ) : (
            <a
              className="inline-block p-4 mt-4 lg:md-0 rounded-md bg-gray-800 uppercase"
              href={data.buttonLink}
            >
              {data.buttonText}
            </a>
          ))}
      </div>
    </div>
  );
};
