import React from "react";
import { Icon } from "../../lib/Icon/Icon";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";
import { TypeTwoBanner } from "../../lib/TypeTwoBanner/TypeTwoBanner";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import {
  hiringProcess,
  imageSection,
  notHiring,
  notHiringButtonText,
  notHiringDescription,
  ourCulture,
  skills,
  tabData,
  text1,
  text2,
  whyJoinUs,
} from "./careersData.js";

export const Careers = () => {
  const {
    paragraphOne,
    paragraphTwo,
    paragraphThree,
    cards,
    buttonText,
  } = ourCulture;

  const { data, description, title } = whyJoinUs;

  return (
    <div>
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        folderName="Career"
        buttonScrollToId="#opportunities"
        buttonText={imageSection.buttonText}
        imageName={imageSection.imageName}
        imageText1={imageSection.imageText1}
        imageText2={imageSection.imageText2}
        imageText3={imageSection.imageText3}
        showButton={true}
      />
      <TypeOneBanner
        id="opportunities"
        text1={text1}
        text2={text2}
        tabData={tabData}
        buttonType="a"
      />
      {skills.length === 0 ? (
        <TypeTwoBanner
          mainId="current-openings"
          text1={notHiring}
          text2={notHiringDescription}
          buttonText={notHiringButtonText}
          scrollTo="#workWithUs"
        />
      ) : (
        <p>Implement Later</p>
      )}
      <section
        className="text-white flex flex-col text-center"
        style={{ background: "rgb(22, 45, 66)" }}
        id="our-culture"
        data-aos="fade-in"
      >
        <p className="mt-16 mb-3 text-2xl md:text-3xl lg:text-5xl leading-10 w-full max-w-sm md:max-w-5xl mx-auto">
          {paragraphOne}
        </p>
        <p className="text-sm md:text-base mb-6 text-gray-500 w-full max-w-sm md:max-w-5xl mx-auto ">
          {paragraphTwo}
        </p>
        <p className="text-sm md:text-base my-5 w-full max-w-sm md:max-w-5xl mx-auto ">
          {paragraphThree}
        </p>
        <div className="pt-6 w-full max-w-6xl mx-auto grid grid-flow-row grid-cols-1 row-gap-10 lg:grid-cols-3 lg:col-gap-20 lg:row-gap-0">
          {cards.map((el, i) => (
            <div
              className="p-4 flex flex-col justify-center border-gray-700 hover:border-gray-500 rounded-lg cursor-pointer border-2 group"
              key={el.name}
              data-aos="flip-up"
            >
              <Icon
                classes="w-24 h-24 text-blue-500 block mx-auto"
                iconName={el.logo}
              />
              <p className="mt-5 mb-2 text-2xl md:text-3xl group-hover:text-gray-600 ">
                {el.name}
              </p>
              <p className="text-base pb-10 leading-7 text-gray-600 group-hover:text-white">
                {el.content}
              </p>
            </div>
          ))}
        </div>
        <a
          className="my-10 py-4 px-2 bg-orange-500 hover:bg-orange-400 cursor-pointer rounded-lg uppercase font-bold text-base md:text-lg mx-auto w-full max-w-xs "
          href={buttonText.link}
        >
          {buttonText.name}
        </a>
      </section>
      {/* Why u should join us  */}
      <section
        id="why-join-kdr"
        className="py-12 text-black"
        data-aos="fade-in"
      >
        <p className="text-2xl md:text-3xl lg:text-5xl font-bold mt-4 w-full max-w-5xl mx-auto text-center">
          {title}
        </p>
        <p className="text-sm md:text-base leading-6 text-gray-700 mb-2 w-full max-w-5xl mx-auto text-center">
          {description}
        </p>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full max-w-6xl mx-auto gap-10 mt-6">
          {data.map((el) => (
            <div
              className="border-2 border-gray-100 group-hover:ease-linear duration-200 transform shadow-sm group hover:shadow-lg hover:bg-gray-800 cursor-pointer rounded-lg px-4 py-6 text-center md:text-left"
              key={el.title}
              data-aos="flip-down"
            >
              <Icon classes="w-16 h-16 text-blue-500" iconName={el.logo} />
              <p className="text-lg md:text-xl font-bold group-hover:text-gray-300 my-4">
                {el.title}
              </p>
              <p className="text-base text-gray-500">{el.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="py-8 md:py-16 text-center" id="hiring-process">
        <p className="text-2xl md:text-3xl lg:text-5xl font-semibold w-full max-w-6xl mx-auto">
          {hiringProcess.para1}
        </p>
        <p className="text-sm md:text-base w-full max-w-6xl mx-auto">
          {hiringProcess.para2}
        </p>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 w-full max-w-6xl mx-auto py-10">
          {hiringProcess.process.map((el) => (
            <div
              className="flex flex-col items-center justify-center"
              key={el.label}
            >
              <div className="border-4 w-40 h-40 px-4 rounded-full border-main-blue flex flex-col justify-center items-center">
                <p className="text-base mb-1">
                  <span className="text-blue-700 font-bold">0{el.number}</span>
                  <span className="font-bold text-4xl text-blue-700">.</span>
                  <span className="text-gray-600">/04</span>
                </p>
                <p className="font-bold text-md">{el.label}</p>
              </div>
              <p className="mt-4 text-base text-gray-600">{el.content}</p>
            </div>
          ))}
        </div>
      </section>
      <WorkWithUs />
      <Footer />
    </div>
  );
};
