export const happyClientsImageSection = {
  text1: "The Highest Standards. The Happiest Clients.",
  text2: "WE LOVE OUR CLIENTS AND THEY LOVE US BACK. HEAR WHAT THEY’RE SAYING…",
  ratings: [
    {
      logo: "schedule",
      name: "schedule",
      rating: 4.9,
    },
    {
      logo: "quality",
      name: "Quality",
      rating: 5,
    },
    {
      name: "Cost",
      logo: "cost",
      rating: 4.9,
    },
    {
      logo: "refer",
      name: "Willing to Refer",
      rating: 5,
    },
  ],
  para:
    "*Ratings base a claim on observations by various global IT research firms.*",
};

export const trustData = {
  text1:
    "Trusted by startups, enterprises & businesses in every industry, across the world",
  text2:
    "We have impressed millions of our respected customers with our project management, designs, development, solutions, services, professionals, technologies, culture, quality, communication, leadership, and business acumen.",
};

export const appsTestimonial = [
  {
    companyName: "ED Technologies",
    personName: "Murali T - CEO",
    ratings: 4.6,
    review:
      " I first reached out to Kdr Soft Solutions three years ago to build a MVP for our startup. They had a solution that was close enough to what we wanted to build that we used that as a base, and they customized it to meet our needs. When any development effort, there will be changes, and Kdr Soft was able to handle all change requests we made in a very efficient manner. After four months of development, we had a working MVP that we used as a POC to raise our first round of seed money. ",
  },
  {
    companyName: "MJ Mediaworks",
    ratings: 5,
    personName: "Manish Jain - MD",
    review:
      " The team at Kdr Soft Solutions is patient and has a client-centric approach. They remain polite and professional under pressure, going above and beyond to solve issues.",
  },
  {
    companyName: "DB Software ",
    personName: "Deepti - VP, Sales",
    ratings: 4.5,
    review:
      " I would like to thank the team who worked on my project, it was a very nice experience, I learned a lot from the team. I was expecting lots of challenges throughout the project because it needed a good team to take care of all details, but Jagadish who I would like to thank personally because of the flexibility, dedication, and support he gives every time I asked for. Thank you, Kdr Soft Solutions and all your team members.",
  },

  {
    companyName: "The 9 Coins",
    ratings: 4.2,
    personName: "Dhanasree - CTO",
    review:
      " We have worked with Kdr Soft Solutions for almost a year now. We have been very satisfied with the results and overall service attitude of Kdr Soft and our contact person Jagadish. I have two more projects in mind for the future and I will use this company for those as well.",
  },
  {
    companyName: "Startup Labs",
    ratings: 4.7,
    personName: "Sai Kiran K - Managing Director",
    review:
      " I have been associated with Kdr Soft Solutions from the last few months and my experience has been excellent. Their website development team is very creative and supportive. Their have best of the tech technology and give best possible service at the most reasonable cost. I was able to get more hits on through my website and was able to generate more lead. This helped me gain more strength in the market.",
  },
  {
    companyName: "Gourmet Foods",
    ratings: 4.8,
    personName: "Mahesh - Co Founder",
    review:
      " Kdr Soft Solutions worked untiringly to meet my goals for App development. I noticed a rapid boost in my business through the app. Highly Recommended!.",
  },
  {
    companyName: "My First Data",
    ratings: 4,
    personName: "Pradeep D - Chief Technology Officer",
    review:
      "Kdr Soft Solutions keeps doing what comes naturally to them which is building teams with their clients, partners and creating exceptional software development and support. Job well done. I have used them for offshore development. Congratulations to all your teams.",
  },
  {
    companyName: "AH Tech",
    ratings: 4,
    personName: "Amer Hussain - Founder",
    review:
      " Collaborating throughout the project with the internal staff, the team added value through their willingness to accommodate the company's preferred development pace and address all requests for changes. Their patience and devotion to the project yielded a high-quality, customized output.",
  },
  {
    companyName: "M Enterprises",
    ratings: 5,
    personName: "Mamata - Founder",
    review:
      "Kdr Soft Solutions created a seamless, visually-appealing website. Although sales have been slow, the UX of the website is good. They are a thoughtful team, and they possess strong project management skills. The team responds to requests in a timely manager and maintains great communication. ",
  },
];
