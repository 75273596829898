import { companyName } from "../../config";

export const AppCarouselData = {
  text1: "/ OUR PORTFOLIO - SHOWCASING OUR WORK /",
  text2: "FEW OF OUR RECENT FAVORITE PROJECTS",
  button1Text: "View More Client Review",
  button1Link: "testimonials",
  button2Text: "Browser Complete portfolio",
  button2Link: "portfolios",
  apps: [
    {
      appId: 1,
      logo: "kdr.png",
      shortName: "kdr",
      background: "#24367f",
      name: "KDR",
      category: "IT Solutions",
      screenshot: "testScreenShot.png",
      publishedDate: "October, 2018",
      shortDescription:
        "Spicy jalapeno bacon ipsum dolor amet drumstick sirloin chuck shankle. Flank ribeye pancetta andouille ham hock. ",
      tags: {
        website: {
          url: "https://thereportinn.com",
          name: "web",
        },
        android: {
          url: "https://thereportinn.com",
          name: "android",
        },
      },
      workedOn: [
        "backend",
        "database",
        "frontend",
        "software testing",
        "strategy",
        "ux/ui",
      ],
      image1URL: "kdr/4",
      image2URL: "kdr/2",
      image3URL: "kdr/1",
      image4URL: "kdr/3",
      image5URL: "kdr/5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      clientTestimonals: {
        name: "Jagadish",
        designation: "Founder",
        comment:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
      },
    },
    {
      appId: 2,
      logo: "kdr.png",
      shortName: "kdr",
      background: "#12b0e5",
      name: "The Report Inn",
      category: "IT Solutions",
      screenshot: "testScreenShot.png",
      publishedDate: "October, 2018",
      shortDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tags: {
        website: {
          url: "https://thereportin.com",
          name: "web",
        },
        iphone: {
          url: "https://thereportin.com",
          name: "ios",
        },
        android: {
          url: "https://thereportin.com",
          name: "android",
        },
      },
      workedOn: ["backend", "database", "frontend"],
      image1URL: "kdr/4",
      image2URL: "kdr/2",
      image3URL: "kdr/1",
      image4URL: "kdr/3",
      image5URL: "kdr/5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      clientTestimonals: {
        name: "Jagadish",
        designation: "Founder",
        comment:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
      },
    },
    {
      appId: 3,
      shortName: "kdr",
      background: "#24367f",
      logo: "kdr.png",
      name: "KDR SOFT SOLUTIONS",
      category: "IT Solutions",
      screenshot: "testScreenShot.png",
      publishedDate: "October, 2018",
      shortDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      tags: {
        website: {
          url: "https://thereportin.com",
          name: "web",
        },
        iphone: {
          url: "https://thereportin.com",
          name: "ios",
        },
        android: {
          url: "https://thereportin.com",
          name: "android",
        },
      },
      workedOn: [
        "backend",
        "database",
        "frontend",
        "software testing",
        "strategy",
        "ux/ui",
      ],
      image1URL: "kdr/4",
      image2URL: "kdr/2",
      image3URL: "kdr/1",
      image4URL: "kdr/3",
      image5URL: "kdr/5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      clientTestimonals: {
        name: "Jagadish",
        designation: "Founder",
        comment:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
      },
    },
    {
      appId: 4,
      shortName: "kdr",
      background: "#24367f",
      logo: "kdr.png",
      name: "KDR SOFT SOLUTIONS",
      category: "IT Solutions",
      screenshot: "testScreenShot.png",
      publishedDate: "October, 2018",
      shortDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tags: {
        website: {
          url: "https://thereportin.com",
          name: "web",
        },
        iphone: {
          url: "https://thereportin.com",
          name: "ios",
        },
      },
      workedOn: [
        "backend",
        "database",
        "frontend",
        "software testing",
        "strategy",
        "ux/ui",
      ],
      image1URL: "kdr/4",
      image2URL: "kdr/2",
      image3URL: "kdr/1",
      image4URL: "kdr/3",
      image5URL: "kdr/5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      clientTestimonals: {
        name: "Jagadish",
        designation: "Founder",
        comment:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
      },
    },
    {
      appId: 5,
      shortName: "kdr",
      background: "#24367f",
      logo: "kdr.png",
      name: "KDR SOFT SOLUTIONS",
      category: "IT Solutions",
      screenshot: "testScreenShot.png",
      publishedDate: "October, 2018",
      shortDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      tags: {
        website: {
          url: "https://thereportin.com",
          name: "web",
        },
        android: {
          url: "https://thereportin.com",
          name: "android",
        },
      },
      workedOn: [
        "backend",
        "database",
        "frontend",
        "software testing",
        "strategy",
        "ux/ui",
      ],
      image1URL: "kdr/4",
      image2URL: "kdr/2",
      image3URL: "kdr/1",
      image4URL: "kdr/3",
      image5URL: "kdr/5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      clientTestimonals: {
        name: "Jagadish",
        designation: "Founder",
        comment:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
      },
    },
  ],
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "angular.png",
    imageText2:
      "AngularJS development services, meant to deliver amazing data-binding possibilities with great web substance!",
    imageText3:
      "DEVELOPING CUSTOMIZED WEB APPLICATIONS WITH ANGULARJS TO HELP YOU BUILD YOUR WEB PRESENCE WITH REFINED ENGAGEMENT AND GREAT AESTHETIC VALUE",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const stackUsedData = {
  stackData: [
    {
      name: "Partnership",
      logo: `${companyName}-Angular-P`,
      heading: "Partnering with us gets you an extra edge",
      description:
        "Over 100+ projects delivered across 60+ application verticals since 2017 ",
    },
    {
      name: "Global",
      logo: `${companyName}-Angular-G`,
      heading: "Globally acclaimed company",
      description:
        "We’ve received accreditations from renowned international agencies",
    },
    {
      name: "Customer satisfaction",
      logo: `${companyName}-Angular-CSTP`,
      heading: "Customer satisfaction - our top priority",
      description:
        "Client and user satisfaction is best reward for us and we strive to deliver it with great dedication",
    },
    {
      name: "Experts",
      logo: `${companyName}-Angular-E`,
      heading: "Experienced AngularJS developers",
      description:
        "Certified and experienced AngularJS developers capable of creating superlative web solutions",
    },
    {
      name: "Trust",
      logo: `${companyName}-Angular-T`,
      heading: "Trusted delivery methods",
      description:
        "Our delivery methods and approaches are designed to match your goals, timeline and budget",
    },
    {
      name: "Transparency",
      logo: `${companyName}-Angular-CC`,
      heading: "Collaborative communication",
      description:
        "Constant and complete project visibility from day one via phone, email, skype chat, video call",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Why AngularJS for application development",
  description:
    "IT’S ABOUT CREATING DYNAMIC, MVC ENABLED, SINGLE PAGE APPS. GO TEAM!",
  details: [
    {
      label: "A Complete Client-Side Solution",
      iconName: `${companyName}-Angular-ACCSS`,
      content:
        "Get extensive support to build an all-encompassing client-side solution with featvures like form validation, dependencies and data binding",
    },
    {
      label: "Captivating Support Services",
      iconName: `${companyName}-Angular-CSS`,
      content:
        "Our application development efforts are designed as visual beacon to enable the clients to follow us.",
    },
    {
      label: "Features to Get Excited About",
      iconName: `${companyName}-Angular-FTGEA`,
      content:
        "We offer AngularJS development, migration and upgrade, customization, maintenance and support services.",
    },
    {
      label: "Great MVC Support",
      iconName: `${companyName}-Angular-GMS`,
      content:
        "Unlike other frameworks, AngularJS strings MVC components together which saves a lot of time and efforts.",
    },
    {
      label: "Two-Way Data Binding",
      iconName: `${companyName}-Angular-TWDB`,
      content:
        "By handling the synchronization between the DOM and the model, the performance is improved greatly.",
    },
    {
      label: "Trouble-Free Testing",
      iconName: `${companyName}-Angular-TFT`,
      content:
        "AngularJS comes with an end-to-end unit testing setup, which extensively promotes the procedure of multiple testing.",
    },
  ],
};

export const typeNineBannerData = {
  heading: "What makes AngularJS so popular",
  description:
    "CONTROL YOUR WEB APPLICATION LIKE A PRO SO THAT IT CAN BE OBSERVED, MODIFIED AND IMPROVED",
  para1:
    "AngularJS is host to a large gathering of notables like scalability, flexibility and MVC implementation. Powered by Google, this is currently most powerful open-source JavaScript based framework that is used to create Rich Internet Applications (RIA) in mobile, browser and web. Further, it enables creating client-side, cross browser compliant applications, in a clean MVC way.",
  list: {
    heading: "Angular JS development revolves around 6 core features:",
    points: [
      {
        iconName: `${companyName}-Angular-TWDB`,
        title: "2-way Data Binding",
        description: "That synchronizes data between model and view components",
      },
      {
        iconName: `${companyName}-Angular-EAS`,
        title: "Expressive and Simple",
        description:
          "With presence of controllers, services, filters, directives(that can be used to build custom widgets), templates, modules, expressions, dependency injection, providers, validators",
      },
      {
        iconName: `${companyName}-Angular-DB`,
        title: "Data Binding",
        description:
          "It’s so much easy to bind your app UI by creating a text property on a model. It further makes the application rich and responsive.",
      },
      {
        iconName: `${companyName}-Angular-GMS`,
        title: "Simplified MVC pattern",
        description:
          "Application is bifurcated into model, view and controller, its closer to MVVM.",
      },
      {
        iconName: `${companyName}-Angular-CP`,
        title: "Cross-Platform",
        description:
          "Offering re-usable HTML components, it complies across platforms",
      },
      {
        iconName: `${companyName}-Angular-SAP`,
        title: "Speed and Performance",
        description:
          "Allows you to quicken up development by writing less code and enhance functionality.",
      },
    ],
  },
};

export const multiRowData = {
  text1: "BEST FRAMEWORK ACROSS PLATFORMS",
  text2:
    "Hire Angular developer and get the best AngularJS development services",
  text3:
    "At kdr, we provide AngularJS development services keeping your idea at the top of it all. While we attempvt to deliver the solution, we get into the depth of purpose and value that can best serve your business. This helps us reaching the right design to implement a strong web machinery to run it well at both ends. Once we are done with the development we reinforce to offer you with highly powerful, scalable and valuable product with our advanced app testing methods for achieving the best results with AngularJS development.",
  carouselData: [
    {
      iconName: `${companyName}-Angular-DC`,
      label: "Development & Consulting",
    },
    {
      iconName: `${companyName}-Angular-ED`,
      label: "Enterprise Development",
    },
    {
      iconName: `${companyName}-Angular-CS`,
      label: "Custom Solutions",
    },
    {
      iconName: `${companyName}-Angular-ECD`,
      label: "eCommerce Development",
    },
    {
      iconName: `${companyName}-Angular-HAS`,
      label: "HTML5 App Solutions",
    },
    {
      iconName: `${companyName}-Angular-MAS`,
      label: "Maintenance and Support",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Hire Angular JS Developers",
};

export const typeFourBannerData = {
  heading: "Why kdr for AngularJS Application Development?",
  text1:
    "As a leading AngularJS development company, kdr strives to translate maximum benefits of AngularJS through our resourceful web development services. Our expert developers follow streamlined processes and proficient approaches to bring forth a solution that’s loved by your audience. We build high-end web applications using the extreme flexibility that AngularJS offers- for build competent single page websites (SPA) as well as large websites. We are all-equipped with latest resources and advanced tools that help us offer AngularJS development solutions and services with great authority, agility and ease.",
  description: "CREATE BEST OFFSHORE MOBILE APPS THAT OUTPERFORM RIVALS",
};
