export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "Team.jpg",
    imageText2:
      "It’s about the thoughts and souls that work behind KDR SOFT SOLUTIONS",
    imageText3: "WELCOME TO A CONSTANT STATE OF INNOVATION",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "contact-us",
      buttonText: "connect with our Team",
    },
  },
};

export const typeThreeBannerData = {
  semiHeading: "BEST PEOPLE. BEST RESULTS",
  heading: "Enthusiastic, Professional & Qualified",
  description:
    "WE’RE A TEAM OF 20+ PEOPLE WHO ALL ARE MASTERS OF WHAT THEY DO.",
  para:
    "We love what we do, and we love who we do it with. Half of us work on core development, designing and quality analysis, and the other half manage the project on the whole. The people who work at kdr share the vision and values of our community.",
};

export const typeOneQuoteData = {
  quote: `<p>"Meet Our Team of Lateral Thinkers.</p>We Rise by Lifting Our Fellows, Our Partners and Our Clients."`,
};

export const typeTwelveData = {
  heading: "senior Management",
  imageCardData: [
    {
      name: "Korla Jagadish Babu",
      role: "FOUNDER & CEO",
      quote: "Don’t dream about success, Work for it",
      image:
        "https://lh3.googleusercontent.com/d/1SR2q3i-VhGyb0lGh-dYuzGSOLSDlVTuw=s220?authuser=0",
      socialProfiles: [
        {
          iconName: "linkedin",
          link: "https://www.linkedin.com/in/jag910",
          
        },
        
      ],
      about:
        "Jagadish is the Founder & CEO of Kdr Soft Solutions. Having the genes of marketing, project management, internal administration, research and development, he is responsible for leading KDR's burgeoning strategy discipline and sharpening the overall strategic vision and approach of the agency. Jagadish has an understanding of what it takes to build a company and develop new products. He is skilled in leading teams of people, honing strategy and focused execution. He is also an expert in cyber security and enterprise data. Jagadish has managed large teams (both technical and QA team) spread across multiple geographical locations – India, USA, UK and APAC regions.",
    },
    {
      name: "Dharma Rau",
      quote: "Play by the rules, but be ferocious.",
      role: "Chairman",
      image:
        "https://drive.google.com/uc?export=view&id=1DSpUgbfCBLlNYCcjeDVwOCYfzeWoS2ob",
      socialProfiles: [
        {
          link: "https://www.linkedin.com/company/kdrsoftsolutions",
          iconName: "linkedin",
        },
      ],
      about:
        "Dharma is the Chairman of Kdr Soft Solutions. Having an extensive experience in enterprise application product management, Dharma takes the complete responsibility for the entire company's portfolio including business strategy, design, research and operations. He is responsible for the company’s external presence, co-running key initiatives such as supporting diversity in the company, developing Kdr's social footprint, and implementing sustainable growth strategies. Dharma is a technology enthusiast, investor, and connector. He has an ability to understand customers’ points of view, goals and needs and helps guide projects to exceed expectations.",
    },
    {
      name: "Korla Yashwanth",
      quote:
        "It’s not about how you do it,it’s about how well you accomplish it!",
      role: "CO-FOUNDER, MANAGING DIRECTOR",
      image:
        "https://drive.google.com/uc?export=view&id=1IaMn8iMOh9JSVCQbClOKNgNmGgsDB5hz",
      socialProfiles: [
        {
          link: "https://www.linkedin.com/company/kdrsoftsolutions",
          iconName: "linkedin",
        },
      ],
      about:
        "Yashwanth is the Co-Founder and business development leader of Kdr Soft Solutions. Evolving quickly along with newer technologies and simultaneously gaining quick expertise in business domains is how Yashwanth is related with. He is a highly sought after technical expert because of his command over utilization of various technologies in resolving complex technical and business problems. He is an advocate of heterogeneous solutions to get best out of multiple technologies. He likes to share and learn from his peers and technology stalwarts. He has an excellent record of using path breaking techniques to create value to his clients and people connected with him.",
    },
  ],
};

export const rqImageTypeData = {
  folderName: "why/Team",
  imageName: "ourteam.png",
  heading: "Ready for more than just another job?",
  description:
    "WE'RE ALWAYS INTERESTED IN MEETING NEW PEOPLE WITH A SIMILAR PASSION AND EXPERIENCE OF WORKING WITH TECHNOLOGY AND TURNING OUR CLIENT’S VISION INTO A PROFITABLE BUSINESS OPPORTUNITY. GREAT CARRERS BEGIN HERE!",
  showButton: true,
  buttonText: "Join our team",
  buttonLink: "/careers",
};
