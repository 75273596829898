import React from "react";

export const ResultInfo = ({ data }) => {
  return (
    <div className="w-full py-16">
      <div data-aos="fade-in" className="container mx-auto">
        {data.text1 && <p className="text-center text-xl md:text-2xl lg:text-4xl mb-3">{data.text1}</p>}
        {data.text2 && <p className="max-w-lg mx-auto text-center text-lg md:text-xl text-gray-600">{data.text2}</p>}
        {data.content && (
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-10 mt-10">
            {data.content.map((el,i) => (
              <div className="flex flex-col md:flex-row items-center justify-center font-bold" key={`${el}${i}`}>
                <span className="text-right text-lg md:pl-12">{el.label}</span>
                <span className={`text-5xl ml-4 text-${el.color}-400 font-bold`}>{el.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
