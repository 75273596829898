import React from "react";
// import { AppCarousel } from "../../lib/Carousel/AppCarousel";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { TypeThreeBanner } from "../../lib/TypeThreeBanner/TypeThreeBanner";
import { TypeFiveBanner } from "../../lib/TypeFiveBanner/TypeFiveBanner";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import {
  appDevelopmentData,
  // enterpriseAppData,
  enterpriseImageSection,
  enterpriseSolutions,
  promisingRelationShipData,
} from "./enterpriseData";

export const Enterprise = () => {
  return (
    <div>
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        folderName={enterpriseImageSection.folderName}
        imageName={enterpriseImageSection.imageName}
        imageText2={enterpriseImageSection.imageText2}
        imageText3={enterpriseImageSection.imageText3}
        showButton={true}
        buttonText={enterpriseImageSection.buttonText}
        buttonType="button"
        buttonLink={enterpriseImageSection.buttonLink}
      />
      <TypeThreeBanner
        data={appDevelopmentData}
        itemsToShow={3}
        showBorder={true}
      />
      <TypeFiveBanner data={enterpriseSolutions} showButton={true} />
      <TypeThreeBanner
        data={promisingRelationShipData}
        buttonScrollToId={
          promisingRelationShipData.buttonDetails.buttonScrollId
        }
        buttonText={promisingRelationShipData.buttonDetails.buttonText}
        showButton={true}
      />
      {/* <AppCarousel appData={enterpriseAppData} showButtn={true} /> */}
      <WorkWithUs />
      <Footer />
    </div>
  );
};
