import React, { useCallback, useEffect, useRef } from "react";

export const ProgressBar = () => {

    const divRef = useRef(null)

  const modifyProgressBar = useCallback(() => {
      const ProgressBar = document.body.scrollTop || document.documentElement.scrollTop
      const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
      const scroll = (ProgressBar/totalHeight) * 100
      divRef.current.style.width = `${scroll}%`
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", modifyProgressBar);
    return () => {
      document.removeEventListener("scroll", modifyProgressBar);
    };
  }, [modifyProgressBar]);

  return (
    <div
    id="progressBar"
    ref={divRef}
      className="fixed top-0 bg-red-400"
      style={{
        zIndex: 9999,
        height: "5px",
      }}
    ></div>
  );
};
