import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

export const ImageTypeOneBanner = ({
  imageName,
  imageText1,
  imageText2,
  imageText3,
  buttonText,
  buttonScrollToId,
  showButton,
  folderName,
  buttonLink,
  buttonType,
  showIcon,
  iconName,
}) => {
  return (
    <section
      className="relative h-screen"
      style={{
        background: `url(${require(`../../assets/Images/${folderName}/${imageName}`)}) center center / cover no-repeat`,
        height: "70vh",
      }}
    >
      <div
        className="absolute text-white flex w-full max-w-5xl mx-auto flex-col items-center justify-center text-center"
        style={{
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -60%)",
        }}
      >
        {showIcon && <Icon iconName={iconName} classes="w-16 lg:w-32" />}
        {imageText1 && <p data-aos="fade-up" className="text-sm md:text-base">{imageText1}</p>}
        {imageText2 && (
          <p data-aos="fade-up" className="text-2xl lg:text-4xl font-extrabold tracking-wider mt-2 capitalize">
            {imageText2}
          </p>
        )}
        {imageText3 && (
          <p data-aos="fade-up" className="text-sm md:text-base w-full max-w-4xl text-gray-300 mt-2">
            {imageText3}
          </p>
        )}
        {showButton &&
          (buttonType === "button" ? (
            <Link to={buttonLink}>
              <button data-aos="fade-up" className="uppercase px-4 py-4 rounded-lg mt-8 bg-gray-900 text-sm md:text-base">
                {buttonText}
              </button>
            </Link>
          ) : (
            <a
              href={buttonScrollToId}
              data-aos="fade-up"
              className="uppercase px-4 py-4 border-white border-2 rounded-lg mt-8 text-sm md:text-base hover:border-transparent hover:bg-orange-500"
            >
              {buttonText}
            </a>
          ))}
      </div>
    </section>
  );
};
