export const dominateData = {
  heading: "Dominate your local market with optimized real estate solutions",
  para:
    "We help you thrive while others fail to keep up. This is the best time to take this opportunity to maximize your online reach in the real estate and property market. Leave the rest to us. We will analyze, customize and optimize your campaigns with cutting-edge techniques and technology for the highest ROI.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "The commercial landscape has changed with the emergence of ecommerce and mcommerce. The real estate sector is not left out of this category. Most real estate and property businesses have shifted their process to advertise and reach millions of potential clients who are in search of their dream home, a nice office, and an ideal business space. We have adopted the next big tech trends in real estate, and offered the most sought-after solutions to all property owners and real estate builders.",
  text2:
    "Consumer behavior is changing when it comes to searching for a home. Digital landscape is becoming more important in the property-hunting process. We play a significant role in dealing with all commercial real estate challenges across the globe.",
};

export const challengesData = {
  heading: "Challenges in online travel & hospitality",
  description: "MOBILE FUELS THE ONLINE TRAVEL AGENCIES",
  details: [
    {
      label: "Talent Shortage",
      content:
        "With fewer qualified buyers in the online real estate market, customers don’t have the trust and assurance of getting the right property that they dream of. They are dubious about buying online and they hardly find a good UX while browsing through the websites.",
    },
    {
      label: "More Informed Buyers",
      content:
        "Consumers want a less stressful experience and want to skip queues to book a flat. They want to pre-register and want to be more informed before they go and buy the property. A quick selling experience is what everyone looks for in the industry but with top-quality results.",
    },
    {
      label: "New Technology Transformations",
      content:
        "With everything becoming smart these days, customers want to have the real feel of the house or land they see online on the website. These demographic shifts and preferences coupled with technological advances are affecting the online real estate.",
    },
    {
      label: "Escalating Operating Costs",
      content:
        "Government regulations have an unintended effect on the real estate sector with more conditions and limitations for lenders, appraisers, agents and developers to adhere to augment consumer service.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "Kdr aims at utilizing the potential opportunities of a large inventory of property sitting in the market and displaying it online in the most attractive views, rendering a fluid experience to the eager buyers. With our development services, our sole purpose is to cater to the global audiences through top-notch technologies used in developing a web or mobile solution that offers convenience to view, choose and buy from any device they own.",
  details: [
    {
      label: "Holistic Approach",
      content:
        "Helping the real estate companies that have multiple offices including wholly owned subsidiary offices, licensee locations or franchisees, we build compelling web and mobile application solutions to have a local platform that delivers great business results. We opt for holistic approach to real estate site design that supports multiple locations.",
    },
    {
      label: "Powerful Solutions",
      content:
        "To guide the real estate owners with the right skills for selling their properties to millennials seeking a delightful and satisfied online service, our team is capable of designing first-rate user interface that makes it easy for visitor to sort through your online listings and move ahead to become your engaged prospects.",
    },
    {
      label: "Cutting-edge Technology",
      content:
        "Introducing the concepts of VR and 3D technology, we are stepping in to create stellar websites with advanced views of the property being sold. A full-slate of services for real estate agencies interested in taking their online presence to the next level, we have a comprehensive solution to handle any type of property. From the initial-level consulting to complete design/redesign, you will have all the required tools and expertise for a smashing success in online real estate business.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Auction/Property Web Development",
        text2: "#2. Property Listing Selection",
        text3: "#3. Mobile Optimized/Fully responsive",
      },
      {
        text1: "#4. Real Estate Mobile App Development",
        text2: "#5. ERP/CRM Solutions",
        text3: "#6. IDX Update & Management",
      },
      {
        text1: "#7. Content Management System",
        text2: "#8. Existing Website Redevelopment",
        text3: "#9. Custom Page Development",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
