import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "magento.png",
    imageText2: "Magento Development Services Magento 1.x to Magento 2.x",
    imageText3: "ALL MAGENTO SOLUTIONS THAT YOUR ECOMMERCE STORE NEEDS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Get Estimation for your Project",
    },
  },
};

export const stackUsedData = {
  stackData: [
    {
      name: "Frameworks",
      logo: `${companyName}-Magento-F`,
      techUsed: [
        {
          techName: "Magento 2.x",
          techLogo: "magento-logo1.png",
        },
      ],
    },
    {
      name: "Languages",
      logo: `${companyName}-Magento-L`,
      techUsed: [
        {
          techName: "PHP",
          techLogo: "php.png",
        },
      ],
    },
    {
      name: "ORMs & Database",
      logo: `${companyName}-Magento-OAD`,
      techUsed: [
        {
          techName: "MySQL",
          techLogo: "mysql.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "dashboard.jpg",
};

export const typeFourBannerData = {
  heading: "Top on-demand technologies implemented",
  text1:
    "Embarking on a development journey since the start, we've created a highly competent and professional team of Android developers abreast of latest version releases and technology tools. If, you're looking for a no-compromise, top Android app, contact us. We follow the latest trends and include them in our Android app development services.",
  description:
    "EVERYTHING YOU NEED TO BUILD, DEPLOY AND LAUNCH AN AMAZING ANDROID APP",
};

export const resultInfo = {
  text1: "Only results matter to us",
  text2:
    "Because they help us gain client satisfaction and let our clients enjoy maximum profits",
  content: [
    {
      label: "iOS Apps Developed By KDR Team",
      value: "350+",
      color: "green",
    },
    {
      label: "Client Retention Rate",
      value: "64%",
      color: "indigo",
    },
    {
      label: "App Downloads on App Stores",
      value: "10M+",
      color: "red",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Early adopters of Magento eCommerce development",
  description: "DELIVERED 350+ SUCCESSFUL PROJECTS",
  details: [
    {
      label: "10+ Magento Certified Developers",
      iconName: `${companyName}-Magento-MCD`,
      content:
        "Experienced, Skilled and Reliable Magento Experts to develop your online store.",
    },
    {
      label: "Magento 2.x Migration & Upgrade",
      iconName: `${companyName}-Magento-MMU`,
      content:
        "Flawless migration and extension development solutions for Magento 2.x.",
    },
    {
      label: "Bespoke Development",
      iconName: `${companyName}-Magento-BD`,
      content:
        "Our priority is to provide the best- remarkable quality of Magento development services.",
    },
    {
      label: "Magento development since 2008",
      iconName: `${companyName}-Magento-MD`,
      content:
        "We’ve been developing Magento web and mobile since its release.",
    },
    {
      label: "Dedicated Project Manager",
      iconName: `${companyName}-Magento-DPM`,
      content:
        "A dedicated project manager assigned, always available to talk and update.",
    },
    {
      label: "Proven Magento Record",
      iconName: `${companyName}-Magento-PMR`,
      content:
        "Our portfolio comprises 300+ Magento projects. All delivered successfully!",
    },
    {
      label: "On time, within scope",
      iconName: `${companyName}-Magento-OTWS`,
      content:
        "We are well-known for adhering to timelines and business requirements.",
    },
    {
      label: "Critical Support",
      iconName: `${companyName}-Magento-CS`,
      content:
        "We are well-known for adhering to timelines and business requirements.",
    },
  ],
};

export const topTypeThreeBannerData = {
  heading: "Offering Magento ecommerce development services",
  para:
    "Counted as a Magento web development leader worldwide by Clutch, we manage every project-website, mobile app, market integration or B2B solution with same careful attention to business strategy and a breakthrough approach for startups. Our Magento development service offerings include following three models.",
  details: [
    {
      label: "Magento Custom Development",
      iconName: `${companyName}-Magento-MCWD`,
      content:
        "At any stage, you may want to integrate your own functionality or module in your existing project, you are free to do that. We help online merchants transform Magento into the best fit for their business.",
    },
    {
      label: "Magento Commerce Development",
      iconName: `${companyName}-Magento-MED`,
      content:
        "Our team excels in providing the much-desired unprecedented flexibility & control over the content through Magento Commerce along with performance optimization and implementation.",
    },
    {
      label: "Magento Migration (1.x to 2.x)",
      iconName: `${companyName}-Magento-MM`,
      content:
        "We provide robust data migration service solutions by either moving your existing online store to Magento or migrating it from Magneto 1.x to Magento 2.x.",
    },
    {
      label: "Magento Extension Development",
      iconName: `${companyName}-Magento-ED`,
      content:
        "Developed and submitted Magento 1.x and Magento 2.x Extensions to Magento Connect. We provide related services to expand your business and brand name.",
    },
  ],
};
