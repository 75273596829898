import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "erp.png",
    imageText2: "ENTERPRISE SOFTWARE DEVELOPMENT",
    imageText3:
      "Leverage our 13-year expertise in enterprise software development, API integration, modernising legacy systems, and consolidating app portfolios. We help large organizations become a lasting success in the global arena by re-engineering solutions of different complexity.",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Talk to Us",
    },
  },
};

export const topTypeThreeBannerData = {
  heading: "ENTERPRISE SOFTWARE DEVELOPMENT SERVICES",
  details: [
    {
      label: "Custom Enterprise Software Development",
      iconName: `${companyName}-Product-Engineering-CESD`,
      content:
        "Support your business infrastructure with scalable software that improves key facets of your enterprise. Get access to our industry-specific knowledge to design, build, and scale your new enterprise software solution.",
    },
    {
      label: "Enterprise Mobility Services",
      iconName: `${companyName}-Product-Engineering-EMS`,
      content:
        "Our end-to-end enterprise mobility solutions allow organizations to safely and efficiently manage the extensive use of mobile devices that can support a large number of users in their business environment.",
    },
    {
      label: "Digital Transformation Services",
      iconName: `${companyName}-Product-Engineering-DTS`,
      content:
        "Kdr is your reliable digital transformation partner. Leverage our expertise in using digital technologies to create new or modify existing business processes to meet changing market requirements.",
    },
    {
      label: "Data Management",
      iconName: `${companyName}-Product-Engineering-DM`,
      content:
        "Stay ahead with effective data management strategy, predictive analytics, and intelligent automation using our in-depth knowledge of emerging technologies such as Big Data, Data Science, Cognitive Computing, Blockchain and more.",
    },
    {
      label: "Software Integration",
      iconName: `${companyName}-Product-Engineering-SI`,
      content:
        "Improve your software infrastructure with well-built microservices, reliable API, and data integration. Launch your business strategy with our comprehensive enterprise application development, or use our enterprise app integration solutions.",
    },
    {
      label: "Legacy Application Modernization",
      iconName: `${companyName}-Product-Engineering-LAM`,
      content:
        "Outdated software comes with much bigger risks and can be expensive to maintain. Hire our top minds to perform an in-depth feature and technical analysis of your legacy solution and improve it with the latest tools and technologies.",
    },
  ],
  showButton: false,
};

export const stackUsedData = {
  stackData: [
    {
      name: "Artificial Intelligence",
      logo: `${companyName}-Product-Engineering-AI`,
      heading:
        "Use AI-based algorithms like a machine and deep learning to automate any operation, gain insights from Big Data, ensure fail-safe decisions of your employees, and more.",
    },
    {
      name: "Internet of Things",
      logo: `${companyName}-Product-Engineering-IOT`,
      heading:
        "Use IoT devices to monitor your operations in a secure, well-built infrastructure continually. They will gather Big Data, helping you to streamline these operations, gain spot-on insights, and improve employee decision-making.",
    },
    {
      name: "Blockchain",
      logo: `${companyName}-Product-Engineering-BC`,
      heading:
        "Use a private blockchain to make your business data more secure, automate key operations with Smart Contracts, create a blockchain-based marketplace, and more.",
    },
    {
      name: "Cloud Computing",
      logo: `${companyName}-Product-Engineering-CC`,
      heading:
        "Use our Microsoft Azure and AWS cloud solutions to make your business environment flexible, scalable, and connected. Use our expertise in enterprise applications development to move your mobile strategy to the cloud.",
    },
    {
      name: "Big Data",
      logo: `${companyName}-Product-Engineering-BD`,
      heading:
        "Create a scalable way to consistently boost your business intelligence, develop a reliable way to analyze your data, and give your employees a convenient way to visualize and understand your customers’ preferences and the market’s trends.",
    },
    {
      name: "Augmented Reality",
      logo: `${companyName}-Product-Engineering-AR`,
      heading:
        "Build 3D prototypes in AR, implement AR enterprise training software, hold meetings in AR - the technology will elevate the efficiency of your operations, and help you cut costs.",
    },
  ],
};

export const topFaqBannerData = {
  heading: "SOFTWARE DEVELOPMENT LIFECYCLE",
  description:
    "We employ best practices and development methodologies for building effective enterprise software development process and solutions in a structured and systematic way.",
  showImage: true,
  imageName: "sdlc.png",
  imageFolder: "services/emergingTech",
  buttonLabel: "buttonTop",
  questionLabel: "questionTop",
  faqDetails: [
    {
      iconName: `${companyName}-Product-Engineering-P`,
      question: "Planning",
      answer:
        "We collect all the relevant information from the customer to develop custom software development solutions as per their expectation.",
    },
    {
      iconName: `${companyName}-Product-Engineering-D`,
      question: "Designing",
      answer:
        "We collect all the relevant information from the customer to develop custom software development solutions as per their expectation.",
    },
    {
      iconName: `${companyName}-Product-Engineering-DF`,
      question: "Defining",
      answer:
        "The system and documents are prepared as per the requirement specifications. This helps us define overall system architecture and technology stack.",
    },
    {
      iconName: `${companyName}-Product-Engineering-B`,
      question: "Building",
      answer:
        "Developers start to build the entire system by writing code using the chosen programming language, techniques, and methodologies.",
    },
    {
      iconName: `${companyName}-Product-Engineering-T`,
      question: "Testing",
      answer:
        "Evaluating the quality of software with the aim of finding and fixing defects.",
    },
    {
      iconName: `${companyName}-Product-Engineering-DP`,
      question: "Deployment",
      answer:
        "The final software is released and checked for deployment issues, if any.",
    },
    {
      iconName: `${companyName}-Product-Engineering-M`,
      question: "Maintainance",
      answer:
        "According to the service level agreement, we ensure that needs continue to be met and that the system continues to perform as per the specification mentioned in the first phase.",
    },
  ],
};

export const faqBannerData = {
  heading: "F.A.Q.",
  description: "Do you have additional questions?",
  buttonLabel: "buttonBottom",
  questionLabel: "questionBottom",
  faqDetails: [
    {
      question: "What Is the Enterprise Application Development Company?",
      answer:
        "Our company helps different types of businesses to use software solutions effectively to automate and enhance the level of productivity. We have been on the market for over 13 years and know the subtleties that can help you grow. The usage of the latest technologies benefits enterprises in numerous ways. Our team tailors the existing ones according to your needs and aims. We help enterprises to optimize and automate inner-company processes, enhance communication within the company’s system between employees and customers, and ensure seamless enterprise mobile applications development. Automation is a key notion in today’s fast-developing world, and the quicker enterprises react to the changes — the better. When it comes to digitalization and personalized customer experience, we are ready to offer our services as we are a team of professional and experienced developers who can come up with excellent solutions for your enterprise. Well-developed and structured software is an essence of modern business, and you have to keep up with the trends in order to compete and survive.",
    },
    {
      question: "What Is the Enterprise Application Development Process?",
      answer:
        "The process of enterprise app development is divided into three stages. The kick-off stage is the first one in which you present your requirements. We go through them, thoroughly analyze, and build up a team. We choose the experts who can quickly and effectively complete the project within stated time frames. Then, the team works on the requirements, prepares technical documentation, and creates a basic design of the app. Customers are 100% involved in the process of development, so we take into account your opinion and build the application that suits your expectations. The second stage is when the actual app is developed. Our team uses Scrum methodology, so you get updates every two weeks. Next, the app goes through User Acceptance Testing, and if they show great results, the enterprise mobile application development is over. The third stage involves constant monitoring of the application after implementation. There is a team of experts who fixes bugs, if any, and provides general customer support.",
    },
    {
      question: "What Enterprise Software Solution Do You Offer?",
      answer:
        "Our company has vast experience in the development of software for enterprises and ranks among the leaders within the field. It takes a professional team, on-hands experience, the latest tools, and technologies, as well as the ability to think outside the box to reach this level of proficiency. Kdr’s team members provide our customers with reliable and up-to-date solutions that can support enterprise operations in numerous ways. We help software development enterprise to automate operations and enhance their performance level. Experts analyze your requirements and come up with the best solutions that solve certain issues. We not only create custom software but also integrate it into the system of the enterprise and improve its infrastructure. Outdated software decreases the overall level of enterprise productivity. Our team provides in-depth technical analyses and offers improvements that should be made to keep the enterprise competitive.",
    },
    {
      question: "How Do Enterprise Software Developers Work for a Company?",
      answer:
        "The company hires and works with highly professional and qualified employees. We are proud to have one of the best teams of developers who have vast experience in mobile app development for enterprises. Our enterprise software developers are skilled experts in the field and are ready to tackle projects of any level of complexity to deliver cutting-edge enterprise software solutions. Our company has been in the field of enterprise software development for over a decade. Back then, the app industry only started to emerge, and our developers have gone through all the stages of its development. They know all the subtleties involved in the process; they are capable of coming up with the best solutions for your enterprise and are ready to acquire and adjust to the fast-emerging changes. Our team of experts knows the bases on which the enterprise app market is built, has the best tech tools to develop high-quality products, and can foresee the upcoming trends, so your software stays relevant throughout the years.",
    },
  ],
};

export const connectBannerData = {
  text:
    "ENHANCE YOUR BUSINESS EFFICIENCY WITH Kdr INNOVATION-LED SOLUTIONS.",
  showButton: true,
  buttonText: "Talk to us",
  buttonType: "anchor",
  buttonLink: "#workWithUs",
};

export const toolsUsedData = {
  heading: "TOOLS & TECHNOLOGIES WE USE",
  description:
    "Kdr has grown into an international contestant in enterprise application development services with wide-ranging expertise in all areas needed for reliable software development.",
  data: [
    {
      name: "LANGUAGES",
      cols: 2,
      used: [
        "Java",
        "C#",
        "C/C++",
        "Objective C",
        "Python",
        "Groovy",
        "Swift",
        "Kotlin",
        "PHP",
        "Rust",
        "Scala",
      ],
    },
    {
      name: "FRAMEWORKS",
      cols: 2,
      used: [
        "JDBC / JPA ",
        "JMS ",
        "Hibernate ",
        ".NET ",
        "EJB ",
        "Apache Camel ",
        "Nodejs ",
        "Firebase ",
        "LDAP / Active Directory ",
        "Reactive (Akka, RxJava, Reactor)",
      ],
    },
    {
      name: "MOBILE",
      cols: 1,
      used: [
        "iOS ",
        " Android ",
        "Hibernate ",
        "HTML5",
        " React",
        "Xamarin",
        "JavaScript",
      ],
    },
    {
      name: "WEB",
      cols: 1,
      used: ["Vue", "Sass", "Coffee", "WebGL", "Angular"],
    },
    {
      name: "DATABASE MANAGEMENT",
      cols: 1,
      used: ["NoSQL", "MySQL", "Oracle SQL", "PostgreSQL", "Microsoft SQL"],
    },
    {
      name: "CLOUD",
      cols: 1,
      used: [
        "Amazon Web Services (AWS)",
        "Google Cloud",
        "Oracle Cloud",
        "IBM Cloud",
        "Microsoft Azure",
      ],
    },
  ],
};
