export const partnershipData = [
  {
    logo: "FPR",
    heading: "FIXED PRICE PROJECT",
    description:
      "We provide time estimates and assign skilled resources to work with you on your project, on a fixed-price basis.",
  },
  {
    logo: "HTE",
    heading: "HOSTED TEAM EXTENSION",
    description:
      "You can improve the operational efficiency of your project by adding our skilled professionals to work remotely with your team.",
  },
  {
    logo: "DTM",
    heading: "DEDICATED TEAM MODEL",
    description:
      "We provide a dedicated team of designers, developers, project manager and QA for an estimated time (minimum 3 months) to complete your project.",
  },
];
