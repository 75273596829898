export const mapURLToData = {
  "real-estate-property": "REP",
  "social-networking": "SN",
  "food-restaurant": "FNB",
  "banking-finance-insurance":"BFSI",
  "education-elearning":"EL",
  "ecommerce-retail-b2b":"ECOMM",
  "travel-hospitality":"TH",
  "media-entertainment":"MEDIA",
  "healthcare-fitness":"HEALTH",
  "transport-automotive":"TRANS",
  "gaming-leisure":"GAMING",
  "event-tickets":"EVENT"

};

export const REP = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "REP.png",
    imageText2: "Real Estate & Property mapping and data solutions",
    imageText3: "SELLING SOLUTIONS, NOT PROMISES",
  },
};
export const SN = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "sn.png",
    imageText2: "Social Networking because people matter",
    imageText3: "CONNECT WITH US TO CONNECT THE WORLD  ",
  },
};

export const FNB = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "fnb.png",
    imageText2: "Food & Restaurants blending in digital",
    imageText3: "DELIVERING CUSTOMER CONVENIENCE AND SYNCHED OPERATIONS ",
  },
};

export const BFSI = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "bfsi.png",
    imageText2: "Banking, Finance & Insurance secure and profitable solutions",
    imageText3: "BANKING FOR A STRONGER CUSTOMER RELATIONSHIP",
  },
};

export const EL = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "el.png",
    imageText2: "Education & Learning is going global",
    imageText3: "WE'LL HELP YOU TO MOVE AHEAD ",
  },
};
  
export const ECOMM = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "ecomm.png",
    imageText2: "E-commerce & Retail are converging",
    imageText3: "THROUGH WEB, MOBILE & CLOUD",
  },
};

export const TH = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "th.png",
    imageText2: "Travel & Hospitality is moving fast",
    imageText3: "CHOOSE US TO STAY AHEAD ",
  },
};

export const MEDIA = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "media.png",
    imageText2: "End-to-end solutions for Media & Entertainment businesses",
    imageText3: "INTERACTIVELY-EQUIPPED TO SERVE YOU WITH EXCELLENCE",
  },
};

export const HEALTH = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "health.png",
    imageText2: "Tech innovation in Health & Fitness",
    imageText3: "BRIDGING THE GAP BETWEEN PRACTITIONERS, PATIENTS AND MEDICAL COMPANIES",
  },
};

export const TRANS = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "trans.png",
    imageText2: "Transportation & Automotive solutions to get you going",
    imageText3: "FINE-TUNING YOUR ABILITY TO PERFORM AND DELIVER",
  },
};
export const GAMING = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "gaming.png",
    imageText2: "Gaming & Leisure for most zealous fanatics",
    imageText3: "WE'VE REVOLUTIONIZED THE DIGITAL WORLD",
  },
};
export const EVENT = {
  imageSection: {
    folderName: "solutions/industries",
    imageName: "evnt.png",
    imageText2: "Transforming the future of live event ticketing",
    imageText3: "INCREASING ONLINE TICKET SALES TO MUSIC CONCERTS, SPORTS EVENTS, THEATRE AND MORE",
  },
};