export const dominateData = {
  heading: "Lifelike character design and striking game effects",
  para:
    "Our developers employ suitable game sounds, creative inputs every time they build a gaming app. To keep the game frenzy alike, we include stunning background, unique game level designs and awesome graphics. We know the best technology to build lightweight leisure, casino, and thrilling sports and racing apps that can promote the business potentially.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Our developers employ suitable game sounds, creative inputs every time they build a gaming app. To keep the game frenzy alike, we include stunning background, unique game level designs and awesome graphics. We know the best technology to build lightweight leisure, casino, and thrilling sports and racing apps that can promote the business potentially.",
  text2:
    "Our developers employ suitable game sounds, creative inputs every time they build a gaming app. To keep the game frenzy alike, we include stunning background, unique game level designs and awesome graphics. We know the best technology to build lightweight leisure, casino, and thrilling sports and racing apps that can promote the business potentially.",
};

export const challengesData = {
  heading: "Challenges in Gaming and Leisure",
  details: [
    {
      label: "Diverse Skill Sets",
      content:
        "Networking, graphics, clustered computing, databases- online games may require a diverse skill set in developers. Realizing the ramification in game rules, a single change may require agile and conceptual expertise to make the required changes effectively and ensure correctness of execution. Tried and tested approaches to the virtual world are a challenge.",
    },
    {
      label: "Billion Dollar Industry",
      content:
        "Customers are increasingly sophisticated and smart in their use of technology to research and select a particular brand. As a result, the development and marketing approach need to be adopted and updated to effectively reach the customer.",
    },
    {
      label: "Minimal Standardization",
      content:
        "No two gaming apps are alike, neither they look similar nor do they function the same. It’s all about the developer’s creativity and imagination. There is minimum standardization at the content and design level. Online game play and content are provided on a per-platform basis. Inability to apply standardization in this industry is a challenge for vendors and business user.",
    },
    {
      label: "Regulatory Pressure",
      content:
        "With the advent of online and social gaming platforms, regulatory bodies are tightening their own local regulatory approach. They are looking to license, regulate and tax online gaming between providers and players within their frontiers, without allowing for cross-border activity. To comply with the latest trends with minimal operational impact is a concern for many service providers.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "With the advent of online and social gaming platforms, regulatory bodies are tightening their own local regulatory approach. They are looking to license, regulate and tax online gaming between providers and players within their frontiers, without allowing for cross-border activity. To comply with the latest trends with minimal operational impact is a concern for many service providers.",
  details: [
    {
      label: "Cost-effective solutions",
      content:
        "To unburden app owners from the investment anxieties, we offer the best, competitive price in the industry. We charge genuine and cost-effective, with everything said upfront, before the development starts. For any cost calculations, in between the process, we communicate it clearly to the client without hiding any development costs.",
    },
    {
      label: "User Acquisition and Engagement",
      content:
        "We focus on creating highly engaging, addictive gaming and leisure applications because they are top-grossing. By implementing smart referral and viral features, we ensure higher user acquisition to help businesses stay ahead of their competitors.",
    },
    {
      label: "Online Casino and Massively multiplayer",
      content:
        "Developing powerful and user-friendly online real-time multiplayer games is what we are adept at. For multiplayer games, our developers ensure that the backend is absolutely rock solid. Scalable architecture and proper server-side logic is implemented in each product we develop.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Web & Mobile Gaming Apps",
        text2: "#2. Multiplayer Online Games",
        text3: "#3. Real-time turn-based games",
      },
      {
        text1: "#4. Sports and Racing Gaming Apps",
        text2: "#5. Casino and Cards Game Development",
        text3: "#6. HTML5 Game Development",
      },
      {
        text1: "#7. 2D & 3D Game Development",
        text2: "#8. AR/VR Games",
        text3: "#9. Facebook Game Development",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
