import React from "react";

export const TypeFourBanner = ({ data, showArrow }) => {
  return (
    <section
      className="relative text-center px-5 py-10 bg-gradient-resource text-white"
      id="typeFour"
    >
      {showArrow && <div className="absolute downArrow"></div>}
      {data.heading && (
        <p className="text-xl md:text-2xl lg:text-3xl font-bold capitalize">
          {data.heading}
        </p>
      )}
      {data.description && (
        <p className="text-sm mt-3 w-full container text-gray-300 mx-auto uppercase">
          {data.description}
        </p>
      )}
      <p className="text-sm md:text-base mb-3 w-full container text-gray-300 mx-auto mt-3">
        {data.text1}
      </p>
      <p className="text-sm md:text-base mt-3 w-full container text-gray-300 mx-auto">
        {data.text2}
      </p>
    </section>
  );
};
