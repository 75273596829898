import { companyName } from "../../config";

export const openSourceData = {
  text1: "Full-service eCommerce web development company- B2B & B2C",
  text2:
    "There is an increase of purchases being made on computers and mobile devices whether it's for B2C or B2B. To reach the customer, we offer the best personalization methods and top-notch eCommerce web development services.",
  text3: "The various services we provide are:",
  points: [
    "eCommerce Website Development",
    "Responsive (Desktop, Tablet, Mobile)",
    "Mobile eCommerce Applications",
    "eCommerce Integration",
    "eCommerce Enterprise Development",
    "CRM Solutions Production",
    "eCommerce Performance Optimization",
    "Any Custom eCommerce Development",
  ],
  buttonText: "Talk to our experts",
  buttonLink: "#workWithUs",
  image: "webecomm.png",
};

export const imageTypeTwoBannerData = {
  imageSection: {
    folderName: "services/Web",
    imageName: "ecomm.png",
    imageText2:
      "Top eCommerce development company offering best-in-class eCommerce solutions",
    imageText3:
      "FULLY CUSTOMIZED B2B AND B2C STOREFRONTS DESIGNED TO YOUR BUSINESS PRIORITIES",
    points: [
      "350+ successful projects",
      "Ultimate shopping experiences",
      "Proven expertise in M-commerce",
      "100% Responsive websites",
      "Magento certified developers",
      "Interactive user interfaces",
    ],
    buttonType: "button",
    buttonLink: "request-quote",
    buttonText: "View our latest Ecommerce portfolio",
  },
};

export const stackUsedData = {
  text1: "World's most reliable eCommerce development services",
  text2: "EXPERT ATTENTION & MODERN TECHNOLOGY",
  stackData: [
    {
      name: "Magento",
      logo: `${companyName}-EWD-M`,
      techUsedPara:
        "World's #1 and most flexible commerce platform with more than 250,000 merchants across the globe, Magento boosts your online sales and maximizes the gross margins remarkably. If you're looking for a Magento development company that delivers best results, you’ve come to the right place as our Magento specialized services include:",
      techUsed: [
        {
          techName: "Magento 2.0 Development",
          techLogo: "magento-logo1.png",
        },
        {
          techName: "Magento Enterprise Development",
          techLogo: "magento-logo2.png",
        },
        {
          techName: "Migration to Magento",
          techLogo: "magento-logo3.png",
        },
        {
          techName: "PSD to Magento Customization",
          techLogo: "magento-logo4.png",
        },
        {
          techName: "Module Customization",
          techLogo: "magento-logo10.png",
        },
        {
          techName: "Magento Plugin Customization",
          techLogo: "magento-logo6.png",
        },
        {
          techName: "Magento 1.0 to 2.0",
          techLogo: "magento-logo7.png",
        },
        {
          techName: "Magento Theme Integration",
          techLogo: "magento-logo8.png",
        },
        {
          techName: "Magento Responsive Design",
          techLogo: "magento-logo9.png",
        },
        {
          techName: "CRM, ERP & PIM Integration",
          techLogo: "magento-logo5.png",
        },
        {
          techName: "Commerce Order Management on Magento",
          techLogo: "magento-logo11.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Shopify",
      logo: `${companyName}-EWD-S`,
      techUsedPara:
        "Shopify gives you full control over your website- you can innovate anything from marketing and payments to secure checkout and shipping. Our team uses the best themes and contemporary sales channels offered by Shopify to impress your customers with a wonderful UX/UI and over 70 international payment gateways. We offer a wide range of Shopify services.",
      techUsed: [
        {
          techName: "Shopify Point of Sale",
          techLogo: "shoppify.png",
        },
        {
          techName: "Theme Customization",
          techLogo: "theme_customization.png",
        },
        {
          techName: "Migration to Shopify",
          techLogo: "migration_to_shopify.png",
        },
        {
          techName: "API Development",
          techLogo: "api_development.png",
        },
        {
          techName: "Mobile Commerce",
          techLogo: "mobile_commerce.png",
        },
        {
          techName: "Payment Gateway Integration",
          techLogo: "payment_gateway_integration.png",
        },
        {
          techName: "Online Shopping Cart Management",
          techLogo: "online_shopping_cart_management.png",
        },
        {
          techName: "CMS Solution Integration",
          techLogo: "cms_solution_integration.png",
        },
        {
          techName: "Multi-Store, Multi-lingual Setup",
          techLogo: "multi_store_multi_lingual_setup.png",
        },
        {
          techName: "Conversion Optimization",
          techLogo: "conversion_optimization.png",
        },
        {
          techName: "Custom Page Development",
          techLogo: "custom_page_development.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "OpenCart",
      logo: `${companyName}-EWD-O`,
      techUsedPara:
        "Using OpenCart, Kdr’s team puts the best efforts to scale and run your online business. With a powerful store management, OpenCart powers over 320,000 eCommerce entrepreneurs worldwide. We’ve chosen it to develop seamless websites with best-in-class service integrations, payment providers, shipping methods, sales and accounting, analytics and social selling. Our eCommerce web developers use all the powers of OpneCart and offer infinite services.",
      techUsed: [
        {
          techName: "Powerful Store Management",
          techLogo: "powerful_store_management.png",
        },
        {
          techName: "Extensions & Themes",
          techLogo: "extensionp_plugin_integration.png",
        },
        {
          techName: "OpenCart-based Shopping Cart Development",
          techLogo: "Opencart.png",
        },
        {
          techName: "Payment, Shipping & Tax Integration",
          techLogo: "payment_gateway_integration.png",
        },
        {
          techName: "OpenCart Module Integration",
          techLogo: "opencart-module-integration.png",
        },
        {
          techName: "OpenCart Customized Template Design",
          techLogo: "opnecart_customized_template_design.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "WooCommerce",
      logo: `${companyName}-EWD-W`,
      techUsedPara:
        "An extendable, adaptable, open-source commerce platform by WordPress. For delivering a simple, beautiful and enjoyable shopping cart experience, our developers use WooCommerce. Combining the powers of different plug-ins into a single standalone eCommerce platform. we provide the following WooCommerce services.",
      techUsed: [
        {
          techName: "WooCommerce Plugin Customization",
          techLogo: "woocommerce_theme_customization.png",
        },
        {
          techName: "Complete WooCommerce WordPress Development",
          techLogo: "wordPress.png",
        },
        {
          techName: "Extension/Plugin Integration",
          techLogo: "extensionp_plugin_integration.png",
        },
        {
          techName: "Omni-channel Planning",
          techLogo: "omni_channel_planning.png",
        },
        {
          techName: "CMS Solution Integration",
          techLogo: "cms_solution_integration.png",
        },
        {
          techName: "Conversion Optimization",
          techLogo: "conversion_optimization.png",
        },
        {
          techName: "WooCommerce Theme Customization",
          techLogo: "woocommerce_theme_customization.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Custom",
      logo: `${companyName}-EWD-C`,
      techUsedPara:
        "Building a custom eCommerce website, we, at Kdr, provide all the features you need, better third-party integrations, better integrations with other systems and operations, faster page loading and much more to better represent your brand. Best of technologies with best of frameworks and tools provide amazing eCommerce web development services.",
      techUsed: [
        {
          techName: "Customized eCommerce Site Development",
          techLogo: "customized_eCommerce-_site_development.png",
        },
        {
          techName: "Desired Payment Gateway Options",
          techLogo: "desired_payment_gateway_options.png",
        },
        {
          techName: "Comprehensive Order Management",
          techLogo: "comprehensive_order_management.png",
        },
        {
          techName: "Third-Party Integrations",
          techLogo: "third-party_integrations.png",
        },
        {
          techName: "Personalized Storefront",
          techLogo: "personalizedstorefront.png",
        },
        {
          techName: "Scalable Application",
          techLogo: "scalable_application.png",
        },
        {
          techName: "Mobile Commerce",
          techLogo: "mobile_commerce.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
  ],
  showButton: false,
};

export const typeEightBannerData = {
  text1:
    "Next generation of eCommerce solution-oriented eCommerce website development company",
  text2: "FOCUSED ON COMMERCE SCALABILITY & PERFORMANCE",
  imageFolder: "services/Web",
  imageName: "Dashboard.png",
};
