import React from "react";

export const CardTypeTwo = ({data}) => {
  return (
    <div
    data-aos="fade-in"
      className="flex flex-col justify-center items-center p-6 border-2 border-gray-300 hover:shadow-md rounded-md"
    >
      <p className="text-base">{data.description}</p>
      <p className="text-xl font-bold mt-2 mb-10">{data.title}</p>
      <img
        className="pt-4 border-t-2 border-gray-300"
        src={require(`../../../assets/Images/${data.folderName}/${data.imageName}`)}
        alt={data.name}
      />
    </div>
  );
};
