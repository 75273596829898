import React, { useEffect } from "react";
import { Contact } from "../../lib/Contact/Contact";
import { Navbar } from "../Navbar/Navbar";
import "./contactPage.css";
import {
  greetingsData,
  setIntervalTime,
  formRightSideData,
  contactInfo,
} from "./contactPageData";
import { Footer } from "../Footer/Footer";
import { Icon } from "../../lib/Icon/Icon";

export const ContactPage = () => {
  // let [map] = useState("India");

  useEffect(() => {
    const animatedText = document.getElementById("animatedText");
    let itemCount = 0;
    const dataLength = greetingsData.length;
    const timeInterval = setInterval(() => {
      if (itemCount === dataLength) {
        itemCount = 0;
      }
      animatedText.innerHTML = `<p class="animatedContent">${greetingsData[itemCount]}</p>`;
      itemCount++;
    }, setIntervalTime);
    return () => clearInterval(timeInterval);
  });

  return (
    <div className="w-full" id="contactPage">
      <Navbar otherPages={true} transparent={true} />
      <div
        className="bg-gray-800 h-full text-center pt"
        style={{
          background: `url(${require("../../assets/Images/contactPage/contact-banner.jpg")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pt-48 text-white tracking-wide text-xl">
          <div className="text-5xl md:text-6xl font-bold" id="animatedText">
            <p className="animatedContent">Hi</p>
          </div>
          <p className="">
            Talk to us for <strong>project development</strong>,{" "}
            <strong>building business partnerships</strong>,{" "}
            <strong>staffing requirements</strong> and anything{" "}
            <strong>technology.</strong>
          </p>
          <p>We’d love to share our expertise with you.</p>
          <p className="mt-4">
            Fill in the below form to get along with us! It becomes a lot more{" "}
            <strong>amazing</strong> when <strong>we get together.</strong>
          </p>
          {/* <p className="mt-4">
            <strong>Regd Off : </strong>TF-2 -7-93/6 , Sai Bhavan, Palavari Street, SER Center, Prasadampadu, Vijayawada, Andhra Pradesh, India - 521108
          </p> */}
        </div>
        <div className="w-11/12 md:w-full md:max-w-6xl mx-auto flex flex-col md:flex-row rounded-lg mt-10 pb-20">
          <div className="bg-white rounded-tl-lg rounded-bl-lg w-full mx-auto md:w-5/6">
            {/* <p className="text-sm mt-6">*We value your privacy</p> */}
            <Contact
              emailField={true}
              userNameField={true}
              codeField={true}
              phoneNumberField={true}
              interestedInField={true}
              messageField={true}
              reCaptchaField={true}
              category={true}
              sendButtonLabel="Submit"
              uploadDisclosure={false}
            />
          </div>
          <div className="bg-contacPageFormRightColor-darkBlue text-white w-full md:w-2/6 flex flex-col justify-center items-center">
            {formRightSideData.map((el) => (
              <div
                className="rightLinks px-8 md:px-0 py-4 my-4 md:my-0 group-hover:text-white"
                key={el.iconName}
              >
                <Icon
                  iconName={el.iconName}
                  classes="flex align-center mx-auto w-12 h-16 text-gray-600 mb-4 hover:text-white"
                />
                <p className="text-base md:text-lg">{el.text}</p>
                <div
                  id="rightDetails"
                  className="flex flex-col text-sm md:text-base"
                >
                  {el.details.map((element) => (
                    <a
                      className="text-sm"
                      style={{
                        color: "#0084ff",
                      }}
                      href={element.url}
                      key={element.urlName}
                    >
                      {element.urlName}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Contacts with image as the background */}
      {/* <div
        className="object-cover"
        style={{
          background: `url(${require(`../../assets/Images/contactPage/${map}.png`)}) no-repeat 45% 50%`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "70vh",
        }}
      ></div> */}
      <div className="bg-blue-700 px-4 lg:px-8 py-8 lg:py-16 text-white">
        <h2 className="text-center font-bold text-lg capitalize">
          {contactInfo.heading}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 row-gap-10 lg:col-gap-10 mt-4 lg:mt-8">
          <div className="bg-opacity-30 bg-black p-4 rounded-md col-span-1">
            <div className="flex justify-start items-center">
              <img
                src={require(`../../assets/Images/india.png`)}
                className="w-10 h-10 rounded-full bg-white"
                alt={contactInfo.locations.main.name}
              />
              <h3 className="font-bold uppercase ml-4 text-lg tracking-wider">
                {contactInfo.locations.main.name}
              </h3>
            </div>
            <div className="flex flex-col items-start mt-2">
              {contactInfo.locations.main.locations.map((singleLocation, i) => {
                return (
                  <div className="text-left mb-4" key={i}>
                    <h4 className="font-bold capitalize">
                      {singleLocation.name}
                    </h4>
                    {singleLocation.addressOne && (
                      <p className="mt-2">{singleLocation.addressOne}</p>
                    )}
                    {singleLocation.addressTwo && (
                      <p className="mt-2">{singleLocation.addressTwo}</p>
                    )}
                    {singleLocation.addressThree && (
                      <p className="mt-2">{singleLocation.addressThree}</p>
                    )}
                    {singleLocation.phone && (
                      <p className="mt-2">
                        Tel :{" "}
                        <a
                          href={`tel:${singleLocation.phone}`}
                          className="text-blue-300 hover:underline"
                        >
                          {singleLocation.phone}
                        </a>
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`col-span-2 ${
              contactInfo.locations.other > 3
                ? `xl:grid-cols-4`
                : `xl:grid-cols-${contactInfo.locations.other.length}`
            } grid grid-cols-1 md:grid-cols-2 px-4 lg:px-0 my-auto`}
          >
            {contactInfo.locations.other.map((singleOtherLocation, i) => {
              return (
                <div className="flex flex-col items-start" key={i}>
                  <div className="flex justify-start items-center">
                    <img
                      src={require(`../../assets/Images/${singleOtherLocation.iconName}`)}
                      className="w-10 h-10 rounded-full bg-white"
                      alt={singleOtherLocation.name}
                    />
                    <h3 className="font-bold uppercase ml-4 text-lg tracking-wider">
                      {singleOtherLocation.name}
                    </h3>
                  </div>
                  <div className="mt-4">
                    {singleOtherLocation.locations.map((singleLocation, i) => {
                      return (
                        <div className="text-left mb-8 lg:mb-4" key={i}>
                          <h4 className="font-bold capitalize">
                            {singleLocation.name}
                          </h4>
                          {singleLocation.addressOne && (
                            <p className="mt-2">{singleLocation.addressOne}</p>
                          )}
                          {singleLocation.addressTwo && (
                            <p className="mt-2">{singleLocation.addressTwo}</p>
                          )}
                          {singleLocation.addressThree && (
                            <p className="mt-2">
                              {singleLocation.addressThree}
                            </p>
                          )}
                          {singleLocation.phone && (
                            <p className="mt-2">
                              Tel :{" "}
                              {/* <a
                                href={`tel:${singleLocation.phone}`}
                                className="text-blue-300 hover:underline"
                              > */}
                              {singleLocation.phone}
                              {/* </a> */}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
