import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "cloudcom.png",
    imageText2:
      "Powering Cloud Application Development Spanning Diverse Industries",
    imageText3:
      "COMMOVING CLOUD COMPUTING SOLUTIONS TO MATCH YOUR CLOUD STRATEGY",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Talk to our experts",
    },
  },
};

export const multiRowData = {
  text1: "MIGRATE TO CLOUD WITH kdr",
  text2: "Private, public or hybrid all cloud computing services",
  text3:
    "Our experts develop all types of cloud-based automated solutions that provide safe, seamless and rapid migration of your business, enterprise and customer-centric applications to the cloud. We also help you migrate your servers, databases and apps to cloud environments, ensuring scalability, flexibility and cost savings.",
  text4:
    "Some of the key cloud computing services and solutions that we offer include",
  carouselData: [
    {
      iconName: `${companyName}-cloud-computing-CAD`,
      label: "Cloud App Development",
    },
    {
      iconName: `${companyName}-cloud-computing-CC`,
      label: "Cloud Configuration",
    },
    {
      iconName: `${companyName}-cloud-computing-MTC`,
      label: "Migration to Cloud",
    },
    {
      iconName: `${companyName}-cloud-computing-IAC`,
      label: "Integration and Consolidation",
    },
    {
      iconName: `${companyName}-cloud-computing-CSS`,
      label: "Cloud Server Solutions",
    },
    {
      iconName: `${companyName}-cloud-computing-CS`,
      label: "Cloud Security",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Let's Get Started",
};

export const typeThreeBannerData = {
  heading: "Why choose kdr for cloud computing solutions",
  description:
    "ACCELERATE ENTERPRISE-GRADE CONNECTIVITY WITH AGILE CLOUD SERVICES",
  details: [
    {
      label: "Quality of code",
      iconName: `${companyName}-cloud-computing-QOC`,
      content:
        "All our code is peer-reviewed and nothing but the highest standards are accepted. We run automated code-review software.",
    },
    {
      iconName: `${companyName}-cloud-computing-OUA`,
      label: "Our unique abilities",
      content:
        "We have a unique combination of talent and skills under one roof and have an outstanding proven track record.",
    },
    {
      iconName: `${companyName}-cloud-computing-PFT`,
      label: "Passion for technology",
      content:
        "We bring only those consultants and engineers on board that have a genuine passion for technology to accelerate enterprise connectivity.",
    },
    {
      iconName: `${companyName}-cloud-computing-ODS`,
      label: "Our dedicated support",
      content:
        "We are not a flash-in-the-pan company but are always connected to solve your queries and suggest you best methods.",
    },
    {
      iconName: `${companyName}-cloud-computing-TNI`,
      label: "Top-notch infrastructure",
      content:
        "Our IT infrastructure is managed so well that our clients never face any related issues and can enjoy the best connectivity with our teams.",
    },
    {
      iconName: `${companyName}-cloud-computing-BPM`,
      label: "Best project management",
      content:
        "We encourage and employ time-tested project management built to maximize ROI on schedule and on budget.",
    },
    {
      iconName: `${companyName}-cloud-computing-TC`,
      label: "Technology competence",
      content:
        "Working with a number of latest, advanced technologies, we deliver robust, strategic and fully-integrated solutions across all platforms.",
    },
    {
      iconName: `${companyName}-cloud-computing-CCS`,
      label: "Complete customized solutions",
      content:
        "We cater to different demands of different clients across different industries through custom solution that cater to their requirements.",
    },
  ],
};

export const typeFiveBannerData = {
  text1:
    "Provide anywhere, anytime access with secured cloud application development services",
  text2:
    "We have a distinctive aptitude for developing cloud-based applications through an integrated approach that combines strategic business acumen with proven enterprise cloud technologies. This way, our clients streamline their business processes for effective and efficient use of innovative cloud service technologies. We offer secured, scalable and on-demand cloud computing solutions through our AWS enterprise level cloud services. The cloud computing services models that we are adept in:",
  details: [
    {
      iconName: `${companyName}-cloud-computing-SAAS`,
      label: "Software-as-a-Service (SaaS)",
      content:
        "We offer enterprise mobility in the cloud through mobile SaaS software as well as on-demand software platform for building, deploying and managing applications in the cloud.",
    },
    {
      iconName: `${companyName}-cloud-computing-PAAS`,
      label: "Platform-as-a-Service (PaaS)",
      content:
        "Generating new opportunities for software development, we deliver multi-tenant applications independent of any specific platform.",
    },
  ],
  buttonDetails: {
    buttonText: "Talk to our experts",
    buttonScrollId: "#workWithUs",
  },
};
