import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "devop.png",
    imageText2: "IMPACTFUL DEVOPS",
    imageText3:
      "Need to ensure fast, continuous delivery and a consistently high level of software quality? An approach to software that establishes close collaboration between software engineers and IT operations employees, DevOps will help you achieve this by automating and optimising your IT processes. Our expert DevOps engineers will help you attune the delivery, deployment, development, security, and support of any high-load, fail-safe system with microservices architecture — so your business strategy can always rely on high-quality software.",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const typeOneBannerData = {
  text1: "Top on-demand technologies implemented",
  text2:
    "For the best service deliverables, each on-demand project at Kdr is built with a technical edge using all modern and prime tools and frameworks.",
};

export const topTypeThreeBannerData = {
  heading: "OUR DEVOPS IMPLEMENTATION PROCESS",
  description:
    "Our DevOps process help you automate and optimise your IT operations in four steps:",
  details: [
    {
      label: "ANALYSE",
      iconName: `${companyName}-Devops-A`,
      content:
        "Our DevOps team performs an end-to-end analysis of your IT processes and software infrastructure to find every stumbling block that prevents the successаful execution of your software strategy.",
    },
    {
      label: "CHOOSE A SOLUTION",
      iconName: `${companyName}-Devops-CAS`,
      content:
        "We present possible DevOps solutions to your IT issues, giving you the prospect on the team size, budget, and timeframe before the implementation of the solution.",
    },
    {
      label: "EXECUTE",
      iconName: `${companyName}-Devops-E`,
      content:
        "Our DevOps engineers can tackle any problem related to your software infrastructure, set up a continuous delivery in line with your business objectives and corporate culture, organize a clear release management process, and more.",
    },
    {
      label: "SUPPORT",
      iconName: `${companyName}-Devops-S`,
      content:
        "The implementation of a DevOps solution is a continuously complex challenge. We provide support until your software delivery works like clockwork, defining the timeframe for support before we get started.",
    },
  ],
  showButton: false,
};

export const imageTilesData = {
  heading: "Why devops as a service works",
  imageDetails: [
    {
      imageUrl:
        "https://drive.google.com/uc?id=1Idz9QBCrO0cUyqQmqmGJZPkKhJIpIP5y",
      title: "EXTENSIVE EXPERIENCE",
      description:
        "We support multiple clients in meeting their software needs. We use tested DevOps engineering practices, consistent approaches to program management, and talent retention strategies that keep our software engineers motivated.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1BUK0Ub80VPp4EIwwVj-xXlifJqfl7Plb",
      title: "NICHE SKILLS",
      description:
        "Eastern Europe is where some of the best tech problem solvers are available at scale. Our DevOps engineers have extensive command of the languages, technologies, and hard and soft skills required to deliver you an impactful DevOps solution.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1TcxIASxFM6PTMlMqAjsDh5yOuq_CbMaH",
      title: "GREAT FLEXIBILITY",
      description:
        "The scale of the local talent market and quality of our regulatory framework allows impressive flexibility for your DevOps team and solution. Our hiring process and pricing methods can be tailored to your requirements in a changing environment.",
    },
    {
      imageUrl:
        "https://drive.google.com/uc?id=1eSv9GROGURXcs16G3PaYfBfnSdPaUHc5",
      title: "COMPREHENSIVE APPROACH",
      description:
        "We look at your IT operations from all sides to present a DevOps solution that fits your business needs, and then gradually implement it.",
    },
  ],
};

export const connectBannerData = {
  text: "START DELIVERING QUALITY SOFTWARE ON TIME WITH DEVOPS AS A SERVICE.",
  showButton: true,
  buttonText: "Talk to us",
  buttonType: "anchor",
  buttonLink: "#workWithUs",
};

export const iconBannerData = {
  heading: "what devops as a service gives",
  details: [
    {
       iconName: `${companyName}-Devops-HSDR`,
      iconDescription: "HIGHER SOFTWARE DELIVERY RATE",
    },
    {
       iconName: `${companyName}-Devops-FTTM`,
      iconDescription: "FASTER TIME TO MARKET",
    },
    {
       iconName: `${companyName}-Devops-HSQ`,
      iconDescription: "HIGHER SOFTWARE QUALITY",
    },
    {
       iconName: `${companyName}-Devops-BC`,
      iconDescription: "BETTER COLLABORATION",
    },
  ],
};
