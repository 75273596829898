import React from "react";
import { images } from "./logoNames";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./whoWeAre.css";
// import styles from './whoWeAre.module.css'

export const Whoweare = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="bg-white px-0 lg:px-4">
      <div
        data-aos="fade-in"
        className="flex flex-col justify-center items-center max-w-5xl mx-10 md:m-auto py-12"
      >
        <p className="uppercase text-2xl md:text-4xl font-bold">WHO WE ARE</p>
        {/* <p className="pt-4 text-gray-600 text-md md:text-lg text-justify">
          We are a growing and ambitious team of professionals who have come
          together after many years of solo careers in consulting, design &
          development of enterprise-class IT solutions. We at{" "}
          <b>KDR SOFT SOLUTIONS </b>
          believe in providing innovative solutions at affordable prices. We
          intertwine knowledge from various domains to design key answers to
          daily chores. We are a bunch of IT and business folks with a
          collective experience of more than 50 years in the fields of
          information technology & business management. We pride ourselves on
          developing long-term relationships with our clients, partners, and
          employees.
        </p> */}
        <div className="text-gray-700 text-justify">
          <p className="pt-4">
            Kdr Soft Solutions is a boutique digital transformation Company, We
            provide cutting-edge engineering solutions for global and Fortune
            500 companies with the mission to help enterprises accelerate the
            adoption of new technologies. Among a plethora of services, web
            design and development, tailor-made applications, ERPs, CRMs,
            e-commerce solutions, business-to-business applications,
            business-to-client applications, Game Application Development
            managed hosting and internet portal management are few that we
            offer. Satisfied clients around the globe bear testimony to the
            quality of our work. As a leader in technology exploring, KDR is
            committed to exporting quality software worldwide. Kdr Soft
            maintains its advanced domain expertise through five dedicated labs:
            Cognitive Computing, Decentralized Applications (i.e., Blockchain),
            Internet-of-Things (IoT), Premier Customer Experience (CX). Being
            recognized experts in cutting-edge tech, Kdr Soft’s professionals
            engineer secure, dynamic, and intelligent solutions, and seamlessly
            integrate them into the existing IT infrastructure of clients.{" "}
          </p>

          <p className="pt-4">
            Whether it is a consumer-oriented app or a transformative
            enterprise-class solution, the company leads the process from
            ideation and concept to delivery and provides ongoing support
            through its IS360 framework. The core operating belief is that any
            modern customer-centric organization starts its digital
            transformation with the customer journey in mind, no matter how
            ambitious it is, which is why a highly personalized approach and
            superb user experience is Kdr Soft’s dominant focus.{" "}
          </p>

          <p className="pt-4">
            Kdr Soft has three distinct offerings: for tech startups, for small
            and medium-size businesses, and for large enterprises - each
            customer cluster is unique in its needs, scale, budget, and legacy
            architecture.{" "}
          </p>

          <p className="pt-4">
            The Company’s services portfolio offers strategic IT consulting, CX
            design, development, and full lifecycle management of custom
            products and applications, and system integration.{" "}
          </p>
        </div>
        {/* <div className="grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-8 lg:gap-16 xl:gap-20 md:gap-16 mt-10 w-full">
          {images.map((el, i) => (
            <div key={`${el}${i}`} className="w-full h-24">
              <img
                data-aos="fade-up"
                className="mx-auto h-24"
                src={require(`../../assets/Images/whoweare/${el}`)}
                alt={el}
              />
            </div>
          ))}
        </div> */}
        <div className="max-w-6xl w-full">
          <Slider {...settings} className="pt-12">
            {images.map((el, i) => (
              <div key={`${el}_${i}`}>
                <img
                  className="w-24 h-24 mx-auto"
                  src={require(`../../assets/Images/whoweare/${el}`)}
                  alt={el.split(".")[0]}
                />
              </div>
            ))}
          </Slider>
        </div>
        <a
          href="#workWithUs"
          className="flex justify-center items-center uppercase py-4 px-4 bg-gradient-purple-blue mt-8 w-4/5 md:w-auto text-sm text-white font-medium rounded-md cursor-pointer shadow-md hover:shadow-lg transform hover:-translate-y-1 transition-all duration-150 ease-linear"
        >
          <span>Talk to our team</span>
          <svg
            className="w-5 h-5 fill-current text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" />
          </svg>
        </a>
      </div>
    </div>
  );
};
export default Whoweare;
