export const imageTypeOneBannerData = {
    imageSection: {
      folderName: "services/emergingTech",
      imageName: "projectmanagement.jpg",
      imageText2: "Project Communication Strategy",
      imageText3: "TIMELY AND INFORMED COMMUNICATION IS THE KEY TO SUCCESSFUL PROJECT DELIVERY",
    },
    showButton: false,
    showIcon: false,
  };
  
  export const typeFourBannerData = {
    text1:
      "Each one of us at Kdr believes that communication builds the trust required to form a strong and successful relationship, be it with clients, with partners or among teammates. That said, we create a high-level communication management plan that includes being able to effectively listen to client’s requirements, act on feedback, present the issues and foster harmony on the whole.",
  };

  export const timeLineTypeOne = {
    heading: "Information Flow in the Right Direction + Keeping all concerned in the loop = Effective Project Management",
    description: "SEE HOW WE REINFORCE PROJECT COMMUNICATION AT EACH STAGE",
    details: [
      {
        iconName: "PCA-PA",
        label: "Project Analysis",
        content:
          "We carefully listen to the client’s project requirements and plan and then move ahead for initial development. We share the project SRS with our website development team for detailed analysis, research, fact findings, and design workflow design to understand the requirement and scope of the project.",
      },
      {
        iconName: "PCA-PKM",
        label: "Project Kick Off Meetings",
        content:
          "After the project agreement, kickoff meetings between teams and clients are scheduled to discuss key project deliverables, the procedure to be followed, project scope, time involvement, resource billing and payment procedures. Our Business Analyst Expert will arrange a client meeting with the dedicated Project manager and team members who are going to be involved throughout.",
      },
      {
        iconName: "PCA-CCC",
        label: "Clear, Correct & Concise Information",
        content:
          "From day 1, our team members maintain clarity, conciseness and correctness of the information and conversation they exchange with the client and uphold that among the concerned project teammates, managers and leaders.",
      },
      {
        iconName: "PCA-SPC",
        label: "Single Point of Contact",
        content:
          "To avoid complexities and confusions, we maintain a single point of communication and ensure clear and effective interaction. The data and information are centralized and easily accessed. However, in certain cases, a client can contact any individual team member to handle any specific issues.",
      },
      {
        iconName: "PCA-MCC",
        label: "Multiple Communication Channels",
        content:
          "Communication is key so we practice conversation on multiple channels. We use Skype, Email, Messengers, and Phone calls to interact with our national and international clients. We encourage live meetings, video and audio conference calls, instant messaging and emails. Both written and verbal communication methods are used appropriately as and when required.",
      },
      {
        iconName: "PCA-PWA",
        label: "Project Workflow Accessibility",
        content:
          "Once drawn up, we clearly and concisely document the project detail, and make it easily accessible for everyone's reference on shared folders or project communication tools like Jira so that all people involved in the project gain insights in the present state of the project workflow.",
      },
      {
        iconName: "PCA-PS",
        label: "Privacy & Security",
        content:
          "We understand that project details and client information are extremely sensitive. All team members working at Kdr Soft Solutions are bound to keep the information within the office, not breaching any privacy policy contract.",
      },
      {
        iconName: "PCA-MPMT",
        label: "Modern Project Management Tools",
        content:
          "We use modern project management tools like Jira, GitLab and Hubstaff to increase the work efficiency and accessibility among all involved- client, project manager, team leaders and team members.",
      },
      {
        iconName: "PCA-PU",
        label: "Proactive & Upfront",
        content:
          "Project management is not all roses all the time. We also face challenges and issues during the development phase, but our team’s best component is that we immediately inform to the Project Manager and client. We work on finding the solution, without wasting any time in shying away and hiring the problems.",
      },
      {
        iconName: "PCA-R",
        label: "Weekly/ Fortnightly Reviews",
        content:
          "We believe that regular review of work is important to maintain the quality. According to client's convenience and project requirement, we schedule meetings on weekly or fortnightly basis.",
      },
      {
        iconName: "PCA-CD",
        label: "Complete Documentation",
        content:
          "To refer to a conversation discussed two weeks back or so, we always believe in documenting everything. Our documentation process includes procedures, feedbacks, and consolidation of all resources to ensure easy sharing and references. Even the emails and skype chats are preserved for any future communication.",
      },
      {
        iconName: "PCA-SR",
        label: "Status Reports",
        content:
          "After each development phase, we send a project status report to the client to share what has been developed and what’s pending and to know the feedback and any changes if required in between.",
      },
      {
        iconName: "PCA-RF",
        label: "Respect & Flexibility",
        content:
          "We value client’s suggestions, opinions and contributions in the project development and give them the freedom to communicate any changes they think are necessary for the betterment of the overall project.",
      },
    ],
  };