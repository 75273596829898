import React from "react";

export const CardTypeOne = ({ data }) => {
  return (
    <div data-aos="fade-in" className="flex flex-col justify-start items-center border-b-2 border-r-2 border-gray-300 hover:shadow-2xl">
      <p className="bg-gray-100 uppercase text-gray-500 w-full py-6 flex items-center justify-center">
        <span className="inline-block w-2 h-2 mr-2 bg-main-blue rounded-full"></span>
        {data.name}
      </p>
      <div className="flex flex-col justify-center items-center pt-4 pb-20 px-6 text-center">
        <img
          src={require(`../../../assets/Images/${data.folderName}/${data.imageName}`)}
          alt={data.name}
        />
        <p className="text-xl font-bold mt-4 capitalize">{data.title}</p>
        <p className="text-base mt-4 lowercase">{data.description}</p>
      </div>
    </div>
  );
};
