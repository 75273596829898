import { companyName } from "../../config";

export const imageTypeThreeBannerData = {
  tag: "AUGMENTED REALITY",
  heading: "CUSTOM AUGMENTED REALITY SOFTWARE",
  description:
    "Futuristic but practical in use, augmented reality adds a new digital dimension to your business. Use AR for pitch-perfect prototyping without the usual costs. Surprise your customers in retail with AR installations in your customer journeys. Augmented reality is the next step in the digital evolution that allows your enterprise to do more than it ever could and spend less simultaneously.",
  folderName: "solutions/solution",
  imageName: "ar.png",
};

export const type17Data = {
  heading: "A NEW DIMENSION OF YOUR BUSINESS",
  folderName: "why/Team",
  imageName: "augr.jpg",
  list: [
    {
      iconName: `${companyName}-augmented-ANDFOS`,
      heading: "A NEW DIMENSION OF OPERATIONS",
      description:
        "From healthcare to construction, use AR to literally walk through the smallest details of your operations, and make them more efficient and accurate.",
    },
    {
      iconName: `${companyName}-augmented-ANDOUES`,
      heading: "A NEW DIMENSION OF USER EXPERIENCE",
      description:
        "Bring emotion-invoking AR experiences to your customers, and make your brand linger for a very long time.",
    },
    {
      iconName: `${companyName}-MDA-option-AVA`,
      heading: "A NEW DIMENSION OF MANUFACTURING",
      description:
        "AR allows more creativity, better collaboration, and utmost precision compared to the traditional manufacturing process.",
    },
    {
      iconName: `${companyName}-augmented-ANDOPS`,
      heading: "A NEW DIMENSION OF PRODUCTIVITY",
      description:
        "Refresh the enterprise experience for your employees with AR training, monitoring, and meetings, improving the overall enterprise productivity.",
    },
  ],
};

export const imageTypeFourBannerData = {
  imgName: "augmented-reality-hololens.png",
  heading: "LEVERAGE OUR AR DEVELOPMENT CAPABILITIES",
  points: [
    "Object tracking",
    "Object and pattern recognition",
    "Localization and mapping",
    "Face detection, tracking, and recognition",
    "Style transfer in photos and video",
  ],
  sideBar: {
    heading: "enabling hardware",
    points: [
      {
        iconName: `${companyName}-MDA-option-AVA`,
        name: "HOLOLENS",
      },
      {
        iconName: `${companyName}-web-development-ACBA`,
        name: "MOBILE APP",
      },
      {
        iconName: `${companyName}-web-development-AWD`,
        name: "COMPUTER",
      },
      {
        iconName: `${companyName}-augmented-MC`,
        name: "MOTION CONTROLLERS",
      },
    ],
  },
};

export const type18Data = {
  // heading: "A NEW DIMENSION FOR OPERATIONS",
  cardData: [
    {
      title: "A NEW DIMENSION FOR OPERATIONS",
      iconName: `${companyName}-augmented-ANDFOS`,
      description:
        "Employ AR to get insightful perspectives into your operations and significantly increase their accuracy and efficiency. Use AR in healthcare to analyse high resolution image scans and 3D models in greater detail. Review and browse construction models in real scale as you walk through them for the most spot-on results.",
      points: [
        "3D modelling in AR",
        "3D image visualisation in AR",
        "Enterprise augmented reality apps",
        "Model and image AR reviews",
      ],
    },
    {
      title: "A NEW DIMENSION FOR MANUFACTURING",
      iconName: `${companyName}-MDA-option-AVA`,
      description:
        "Use AR to transform your manufacturing process. Elevate engineering efficiency and precision, encourage creative solutions, and cut manufacturing costs by allowing your engineers to develop prototypes in mixed reality. Let them leave comments and put other context in AR, so their colleagues could review them later. Use the headsets in successive production stages for more spot-on adjustments — until your creation is perfected.",
      points: [
        "3D prototyping in Mixed Reality",
        "BIM model visualisation in AR",
        "Prototype reviews in AR",
        "Engineer training in AR",
      ],
    },
    {
      title: "A NEW DIMENSION FOR CUSTOMER EXPERIENCE",
      iconName: `${companyName}-augmented-ANDOUES`,
      description:
        "Animate your customer experience with AR, giving new emotions and a brand new level of comfort to your customers. Guide your real estate clients through real scale interactive properties to save their time. Build breathtaking AR installations in your marketing initiatives to let your brand linger in the customer's’ memory and increase engagement. AR will improve your customer experience even on the smallest levels, as you can add features like AR effects and custom camera lenses to your mobile app.",
      points: [
        "Marketing-focused 3D models",
        "Augmented reality installations",
        "Custom AR app features",
        "AR games and apps",
      ],
    },
    {
      title: "A NEW DIMENSION FOR PRODUCTIVITY",
      iconName: `${companyName}-augmented-ANDOPS`,
      description:
        "Implement AR solutions in your enterprise environment to increase enterprise productivity and boost employee commitment through gamification. Create AR training software that simulates work and office scenarios to give a better perspective of the job position and the office code of conduct. Arrange AR meetings with employees from business locations all over the world. Use AR in educational establishments and initiatives to let your students experience any remote location. Leverage augmented reality in sports for real-time visualisation of in-game performance.",
      points: [
        "AR enterprise training software",
        "AR video meetings",
        "Education-focused AR experiences",
        "Real-time athlete performance visualization",
      ],
    },
  ],
};
