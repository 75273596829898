import React from "react";
import { useLocation } from "react-router-dom";
// import { AppCarousel } from "../../../lib/Carousel/AppCarousel";
import { ImageTypeOneBanner } from "../../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { PageNotFound } from "../../../lib/PageNotFound/PageNotFound";
import { RequestQuoteBanner } from "../../../lib/RequestQuoteBanner/RequestQuoteBanner";
import { ServicesOffered } from "../../../lib/ServicesOffered/ServicesOffered";
import { TypeFiveBanner } from "../../../lib/TypeFiveBanner/TypeFiveBanner";
import { TypeFourBanner } from "../../../lib/TypeFourBanner/TypeFourBanner";
import { TypeThreeBanner } from "../../../lib/TypeThreeBanner/TypeThreeBanner";
import { Footer } from "../../Footer/Footer";
import { Navbar } from "../../Navbar/Navbar";
import { mapURLToData } from "./industriesSectionData";
import { servicesOffered } from "./servicesOffered";
export const IndustriesWeServe = ({ url }) => {
  const uCode = mapURLToData[url];
  // const appData = require(`./${url}`)[`${uCode}Data`];
  const singleData = require("./industriesSectionData")[uCode];
  const typeThreeBannerData = require(`./${url}`)["dominateData"];
  const typeFourBannerData = require(`./${url}`)["typeFourBannerData"];
  const challengesData = require(`./${url}`)["challengesData"];
  const howKdr = require(`./${url}`)["howKdr"];

  const location = useLocation().key;

  return singleData ? (
    <div key={location}>
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        folderName={singleData.imageSection.folderName}
        imageName={singleData.imageSection.imageName}
        imageText2={singleData.imageSection.imageText2}
        imageText3={singleData.imageSection.imageText3}
      />
      <TypeFourBanner data={typeFourBannerData} />
      <TypeThreeBanner
        data={challengesData}
        itemsToShow={2}
        showBorder={true}
      />
      <TypeFiveBanner data={howKdr} showButton={true} showKeySolution={true} />
      <ServicesOffered data={servicesOffered} />
      <TypeThreeBanner
        data={typeThreeBannerData}
        buttonLink={typeThreeBannerData.buttonDetails.buttonLink}
        buttonType={typeThreeBannerData.buttonDetails.buttonType}
        buttonText={typeThreeBannerData.buttonDetails.buttonText}
        showButton={true}
      />
      {/* <AppCarousel appData={appData} showButtn={true} /> */}
      <RequestQuoteBanner />
      <Footer />
    </div>
  ) : (
    <PageNotFound />
  );
};
