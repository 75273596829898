export const privacyPolicy = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "privacypolicy.png",
    imageText2: "We Value Your Privacy",
    imageText3: "KDR SOFT SOLUTIONS ONLINE PRIVACY POLICY STATEMENT HIGHLIGHTS",
  },
  typeFourBannerData: {
    heading: "Privacy Policy",
    text1:
      "PROTECTING PERSONAL INFORMATION IS A RESPONSIBILITY THAT WE TAKE VERY SERIOUSLY",
    text2:
      "And we also expect the same from you. Kdr Soft Solutions is committed to transparency and choice regarding protecting the privacy of our client’ s personal information as well as that of all visitors to our website.",
  },
  policyData: `<p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif; font-size: 14px;">This privacy policy outlines what data <strong>Kdr Soft Solutions Pvt. Ltd</strong>. (herein referred to as &quot;<strong>Kdr</strong>&quot;, &quot;<strong>Kdr</strong><strong>Soft</strong></span><span style="font-family: Verdana, Geneva, sans-serif; font-size: 10px;"><strong><sup>TM</sup></strong></span><span style="font-family: Verdana, Geneva, sans-serif; font-size: 14px;">&quot;, &quot;<strong>Kdr&nbsp;</strong><strong>Soft&nbsp;</strong><strong>Solutions</strong>&quot;, &quot;We&quot;, &quot;Our&quot;, or &quot;Us&quot;) collects, stores, and process about you. It also explains your rights to your data and how you can contact us if you have any questions. We reserve the right to alter this privacy statement at our discretion and encourage you to check this section from time to time to be aware of any changes.</span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>To whom this policy applies</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">This privacy statement applies to individuals (&quot;users&quot; or &quot;you&quot;) who interact with our website, subscribe to any of our products, request information, or use the chat support (herein collectively referred to as &quot;services&quot;).</span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>How we collect data</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Depending on services you use, we collect data from one or more of these three sources:</span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>1. Data we request:</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">When you request any of our services, we may ask, store, and process any/all of these data categories:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your Name</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your Country</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your Email Id</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your Skype Id</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your Phone Number</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Other information that you choose to share</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>2. Cookies:</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Cookies are small files placed on your computer when you first visit our website that helps analyze web traffic and tracks you across the web. The collection of this data is based on &ldquo;implied consent&rdquo;, i.e we assume you agree to our terms of usage unless you explicitly opt out. You can opt out by changing your browser settings to not accept cookies, browsing in anonymous mode, or by not using our website.</span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">We also have on our website links to many third-party services and use third-party tools, the privacy policy of which may be different from us and we assume no responsibility for their compliance. The third-party tools we use cookies to collect data are:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>Google&nbsp;</strong><strong>Analytics&nbsp;</strong>- It collects data related to the device/browser, IP address and on-site activities to measure and report statistics about your interactions on the web. It is used to enhance user experience and we do not access your personally identifiable information. To understand how Google Analytics handles your data, read their privacy policy.</span></span><br><br></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>Zoho Desk</strong>- We use Zoho Desk to provide customer support on our website. While they don&rsquo;t have access to private data you share with our representatives, they may collect additional data that is enforced by their privacy policy.</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>3. External tools:</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">We also use some tools to collect data about your usage and behavior beyond our website that include:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>MailChimp&nbsp;</strong>- Promotional emails we send are tracked to know much traffic they bring on our website. We may also track if an individual email is opened by any recipient to calculate click rates and improve engagement. We use MailChimp as our email marketing tool and they are governed by their own privacy policy.</span></span><br><br></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>Social&nbsp;</strong><strong>plugins&nbsp;</strong>- We have on our website links to many social media platforms for more engagement and visibility. Once you click on any of those links to visit any of those platforms, they collect, use, and share your data as per their own privacy policies, of which we assume no responsibility. We encourage you to check out the privacy policies of the social platform we use: Facebook, Twitter, Google Plus, LinkedIn, YouTube, Behance, Dribble.</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>Why we collect these data</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">The data we collect from these cookies can be personally identifiable or anonymous. We collect this data to:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Offer personalized service</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Create a secure platform</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Improve performance</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Statistical analysis</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>How we secure your data</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">The security of your personal data is of utmost importance to us and we take all appropriate measures to protect it. There are requisite technical, administrative, and physical barriers in place to ensure your data is accessible only to authorized persons or systems. In case we discover a data breach, we immediately (within 72 hours) inform the local authorities and affected users.</span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>How we use your personal data</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">We store and process the data we collect for our legitimate interests and service delivery fully under the ambit of law. We use your data:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To provide and maintain our services.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To improve our services and website experience.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To communicate with you via email, notification, or any other contact information that you have provided.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To provide any third-party requisite data that helps enable our services.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">For internal research and management.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">For fraud detection.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To enforce our terms and conditions.</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>How do we protect your information?</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">We have strict security procedures covering the storage of your information in order to prevent unauthorised access . This means that sometimes we may ask you for proof of identity or for other personal information before we can process your call or enquiry further</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Your explicit consent is obtained.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Mandated by law.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Third-party is a collaborator or a part (subsidiary, parent/sister company) of Kdr Soft Solutions.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">To provide any third-party requisite data that helps enable our services.</span></span></li>
  </ul>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;"><strong>What you can do</strong></span></span></p>
  <p style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">You have full control over what information we store and its subsequent usage. You can choose to:</span></span></p>
  <ul>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">Unsubscribe from the mailing list and notification preferences to stop receiving such communication.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">You can choose to not share your information by not filling up the form and not using the chat support.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-family: Verdana, Geneva, sans-serif;"><span style="font-size: 14px;">You can at anytime change your consent earlier provided for any specific use of your data.</span></span></li>
      <li style="text-align: justify; line-height: 1.5;"><span style="font-size: 14px; font-family: Verdana, Geneva, sans-serif;">If you have any question regarding our privacy policy or data handling practices, we would be glad to hear from you at <strong><span style="color: rgb(85, 57, 130);"><a href="mailto:mail@kdrsoftsolutions.com</span></strong></span></li>
  </ul>`
};
