import React from 'react'
import Ratings from '../../Ratings/Ratings'

const ReviewCard = ({
    data
}) => {
    return (
        <div className="transition-shadow shadow-md hover:shadow-xl rounded-md bg-white flex flex-col items-center px-6 pt-8 pb-2">
            <p className="text-4xl font-bold capitalize">{data.companyName}</p>
            <p className="text-sm text-gray-700 font-bold capitalize">{data.personName}</p>
            <Ratings rating={data.ratings}/>
            <p className="my-8 h-24 overflow-auto text-center">"{data.review}"</p>
        </div>
    )
}

export default ReviewCard
