import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../Icon/Icon";

export const TypeOneQuote = ({ data }) => {
  return (
    <div className="py-12" data-aos="fade-right" data-aos-duration="1000">
      <div className="flex flex-col container mx-auto items-center text-center">
        <div className="border-b-4 border-blue-600 pb-1">
          <Icon iconName="left-chevron" classes="w-8 h-8 text-blue-600" />
        </div>
        {data.quote && (
          <p
            className="mt-4 text-2xl text-center"
            dangerouslySetInnerHTML={{ __html: data.quote }}
          ></p>
        )}
        {data.heading && (
          <p className="text-3xl font-bold my-4">{data.heading}</p>
        )}
        {data.description && (
          <p className="text-lg leading-8 max-w-4xl text-gray-700">
            {data.description}
          </p>
        )}
        {data.showButton && (
          <Link
            className="inline-block p-4 uppercase rounded-md bg-orange-500 mt-4 text-white text-lgs"
            to={data.buttonLink}
          >
            {data.buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};
