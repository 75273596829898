import React from "react";
import { CircleCard } from "../Card/CircleCard/CircleCard";

export const Type13Banner = ({ data }) => {
  return (
    <div className="bg-gradient-to-bl from-blue-800 to-blue-500 py-16 px-4 xl:px-0">
      <div className="container mx-auto">
        {data.text && <p className="text-4xl text-white font-bold text-left max-w-4xl" data-aos="fade-right">{data.text}</p>}
        {data.circleCards && <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 items-center">
          {data.circleCards.map((el, i) => (
            <CircleCard data={el} key={i} id={i} />
          ))}
        </div>}
      </div>
    </div>
  );
};
