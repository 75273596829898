import React from "react";
import { Icon } from "../../Icon/Icon";

export const FAQBanner = ({ data }) => {
  const toggleHandler = (i) => {
    const lastChild = document.getElementById(`${data.buttonLabel}_${i}`);
    const questionDiv = document.getElementById(`${data.questionLabel}_${i}`)
      .lastElementChild;
    const hidden = lastChild.classList.contains("hidden");
    questionDiv.children[1].children[0].setAttribute(
      "href",
      `${!hidden ? "#chevron-down" : "#chevron-up"}`
    );
    if (hidden) {
      lastChild.classList.remove("hidden");
      lastChild.classList.add("block");
    } else {
      lastChild.classList.remove("block");
      lastChild.classList.add("hidden");
    }
  };

  return (
    <div className="py-16 bg-gray-100 px-4 xl:px-0">
      <div data-aos="fade-in" className="container mx-auto text-center">
        {data.heading && (
          <p className="text-2xl lg:text-4xl font-bold mb-5 uppercase">
            {data.heading}
          </p>
        )}
        {data.description && (
          <p className="text-lg text-gray-600 capitalize">{data.description}</p>
        )}
        <div
          className={`grid grid-flow-row grid-cols-1 ${
            data.showImage ? "md:grid-cols-2" : "md:grid-cols-1"
          }`}
        >
          <div>
            {data.faqDetails && (
              <div className={`${data.two_cols ? 'grid grid-flow-row grid-cols-2 col-gap-10 container' : 'flex flex-col max-w-3xl'} pt-12 mx-auto`}>
                {data.faqDetails.map((el, i) => (
                  <button
                    data-aos="fade-up"
                    key={i}
                    className="faq-button border-b-2 border-gray-300 hover:text-blue-600 focus:outline-none"
                    onClick={() => toggleHandler(i)}
                  >
                    <div
                      id={`${data.questionLabel}_${i}`}
                      className="flex justify-start items-center py-5 max-w-3xl mx-auto"
                    >
                      {el.iconName && (
                        <Icon iconName={el.iconName} classes="w-8 h-8 mr-4" />
                      )}
                      <div className="flex justify-between items-center w-full">
                        <p className="font-bold">{el.question}</p>
                        <Icon iconName="chevron-down" classes="w-6 h-6" />
                      </div>
                    </div>
                    <p
                      id={`${data.buttonLabel}_${i}`}
                      className="hidden text-left text-gray-600 py-4 pl-4"
                      dangerouslySetInnerHTML={{ __html: el.answer }}
                    >
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
          {data.showImage && (
            <div className="flex items-center justify-center">
              <img
                className="hidden lg:block"
                src={require(`../../../assets/Images/${data.imageFolder}/${data.imageName}`)}
                alt={data.imageName}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
