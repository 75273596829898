export const termsCondition = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "aboutus.jpg",
    imageText2: "Know Your Rights",
    imageText3: "STANDARD KDR TERMS OF USE OF WORKING WITH KDR",
  },
  typeFourBannerData: {
    heading: "Terms & Conditions",
    text1:
      "COPYRIGHT © 2021 KDR SOFT SOLUTIONS AND/OR ITS AFFILIATES. ALL RIGHTS RESERVED.",
    text2:
      "The following are terms of a legal agreement between you and Kdr Soft™ Solutions. Here, we clearly define aspects of our business relationship with you.",
  },
  policyData: `<p style="text-align: justify;"><strong>General Work Terms and Client Responsibilities &amp; Liabilities<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">All site content (text and multimedia) will be the sole responsibility of the client to provide to Kdr Soft. Such should be provided prior to commencing the work.<br /><br /></li>
    <li style="text-align: justify;">Client is solely responsible to take proper back-up of all content on their site prior to letting Kdr Soft undertake the required course of action towards meeting the contract. Any loss or damage to existing data shall not be a responsibility of Kdr Soft under any circumstances.<br /><br /></li>
    <li style="text-align: justify;">The Contract does not hold Kdr Soft responsible for any data entry, web hosting or custom artwork/graphics related work/tasks unless otherwise specifically mentioned, paid for, and agreed to by both the parties towards such. Any artwork, images, or text supplied and/or designed by Kdr Soft on behalf of the client, will remain the property of Kdr Soft and/or its suppliers unless otherwise agreed.<br /><br /></li>
    <li style="text-align: justify;">Email address<br /><br /></li>
    <li style="text-align: justify;">While Kdr Soft will do its best to achieve all deliveries within the estimated time, there may, at times, be the need to extend or adjust time in cases of any unavoidable and non-forecasted situations like those of deployment issues, dependencies, 3rd-Party support, development bottlenecks, resource unavailability due to emergency, communication delays and the like.<br /><br /></li>
    <li style="text-align: justify;">While Kdr Soft will do its best to achieve all deliveries within the estimated time, there may, at times, be the need to extend or adjust time in cases of any unavoidable and non-forecasted situations like those of deployment issues, dependencies, 3rd-Party support, development bottlenecks, resource unavailability due to emergency, communication delays and the like.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft will provide the Client an opportunity to review the appearance and content of the Web site during the design and once they are completed. Kdr Soft shall wait for a period of 7 days to hear any feedback on such shared work/outputs from the client. In the event of client not replying within this period, such material will have deemed to have been automatically accepted and approved by the Client.<br /><br /></li>
    <li style="text-align: justify;">The Client retains the copyright to data, files and graphic logos provided by the Client and grants Kdr Soft the rights to publish and use such material. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting Kdr Soft permission and rights for use of the same and agrees to indemnify and hold harmless Kdr Soft from all claims resulting from the Client's negligence or inability to obtain proper copyright permissions. A contract for Web site design and/or placement shall be regarded as a guarantee by the Client to Kdr Soft that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft will not accept responsibility for any alterations caused by the Client or a third party occurring to the Client's pages/website once installed/deployed. Such alterations include, but are not limited to additions, modifications, or deletions. Kdr Soft may require a one-off Web Development charge before resolving any issues that may arise.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Payments<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">Kdr Soft accepts payments by cheque, Cash or Bank Transfers (although we reserve the right to decline payment in any of these forms without notice). Without limitation, Kdr Soft reserves the right to withdraw any payment methods at any time and to vary its prices without prior notice.<br /><br /></li>
    <li style="text-align: justify;">A cancellation fee may be charged if the Customer cancels the Service prior to completion. The fee will be equal to the amount of work completed at the point of cancellation.<br /><br /></li>
    <li style="text-align: justify;">A non-payment of cancellation fee and/or over-due amount will result in legal action upon necessity.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Support and 3rd-Party<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">As the site launches, we offer free support for the first month. After one month of free service, we charge according to our various price packages best-suited to client's requirement. We also provide attractive discounts if the client chooses a higher price package. The scope of support only includes any bug fixing and email support and excludes any issues related to the site architecture, rule changes and add-ons/enhancements.<br /><br /></li>
    <li style="text-align: justify;">Any 3rd-Party support, product and/or service being used/ integrated into the site which requires licensing, payment, copyright, etc. shall be the sole responsibility and liability of and be provided by the client or will be procured by Kdr Soft on behalf of the client on pre-payment for the cost of such procurement. The fee charged by Kdr Soft is exclusive of out-of-pocket expenses and expense claims filed by third party products/services involved.<br /><br /></li>
    <li style="text-align: justify;">No guarantees or warranties shall be provided by Kdr Soft for the accuracy or performance of such 3rd-Party product/service.<br /><br /></li>
    <li style="text-align: justify;">Any upgrade in the 3rd-Party product/service being used in the project shall not be part of scope at Kdr Soft. Such shall be addressed per feasibility and revision of price and time may be called for by Kdr Soft.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Re-work, Enhancements/Add-ons, and Billing<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">Any additional features not envisaged in the scope of work would be entertained through a Change Management process and be additional billed. Scope Creeps after wireframe sign off would be billed as additional and timelines and cost for delivery would increase.<br /><br /></li>
    <li style="text-align: justify;">Most tweaks such as minor changes/re-work are normally just completed, however, if we feel this is being abused, we will build a list of works found being the grounds of abuse and bill such additionally per the total time efforts involved and undertaken to achieve the work/tasks at business rates ranging from US $ 15 to 20 per hour.<br /><br /></li>
    <li style="text-align: justify;">Whilst we try our best to cover most changes within the budget of the site, some changes are classified as enhancements/add-ons to the system and become chargeable; we will advise you before we start work of any such items.<br /><br /></li>
    <li style="text-align: justify;">Any re-work change or tweak request by the client post approval and/or furthering to the subsequent stage of project process shall be treated as add-on work and be additionally billed.<br /><br /></li>
    <li style="text-align: justify;">The client must pay the fee charged by Kdr Soft without any deductions, discounts, or debt settlement by the agreed due dates.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Limitations of Liability<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">Kdr Soft will use reasonable skill and care in providing the Service. However, we make no representation and exclude any warranty, express or implied, as to the availability, quality, accuracy, timeliness, completeness, performance, or fitness of the Service.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft hereby excludes itself, its Employees and or Agents from: all and any liability for loss or damage caused by any inaccuracy; omission; delay or error, whether the result of negligence or other cause in the production of the website; All and any liability for loss or damage to clients' artwork/photos, data/content supplied for the site. This is whether the loss or damage results from negligence or otherwise.<br /><br /></li>
    <li style="text-align: justify;">Except in the event of death or personal injury caused by our negligent acts or omissions, we shall not be liable in any way for any damages arising in contract, tort or otherwise in respect of loss or damage arising out of or in connection with this Agreement or operation of the Service. In no event will we be liable for any direct, indirect, or consequential damages in contract or tort, including loss of profit, loss or damage to property or relating to claims made by third parties.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft cannot make guarantees of service on behalf of third-party organizations and will not be held liable for the failure in any service provided by third parties.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Approvals and Delivery<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">The project will involve various stages and the work for the next stage will only start after receiving the sign off on and relevant payments for the previous stage as agreed.<br /><br /></li>
    <li style="text-align: justify;">On completion of the Service (Website design and/or website development), the website will be uploaded to the Customer area of Kdr Soft server for approval. Upon approval by the Client, the website will be uploaded to the destination server where the site shall stay. Kdr Soft reserves the right to delay uploading of the website until full payment has been received.<br /><br /></li>
    <li style="text-align: justify;">All code and material developed will be transferred post completion of project and after sign offs. The code ownership will reside with the client after final payments.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft holds the Right to publish and use the completed work and/or even the deployed final produce/website for references to other potential clients. In circumstances such is required to be withheld, client shall notify Kdr Soft well in advance and request prior and proper approvals towards the same.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Timescale<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">Kdr Soft will aim to complete all services within the agreed timescale. The timescale will commence on receipt of both the agreed % deposit (ranging from 30% to 50% of the project price as mutually discussed and agreed prior to contract finalization) as acceptance and all website content from the Customer.<br /><br /></li>
    <li style="text-align: justify;">Kdr Soft may need to extend any timescales due to circumstances beyond its control.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Jurisdiction<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">These terms shall be governed by and interpreted in accordance with Indian Law. The parties irrevocably agree that the courts of India shall have exclusive jurisdiction to settle any dispute which may arise out of, under, or in connection with these Terms and Conditions. The placing of an order will confirm acceptance of these conditions which are attached to the Order.<br /><br /></li>
    </ul>
    <p style="text-align: justify;"><strong>Severability<br /><br /></strong></p>
    <ul>
    <li style="text-align: justify;">In the event any one or more of the provisions of this Agreement and/or Order shall be held to be invalid, illegal, or unenforceable, the remaining provisions of this Agreement and/or Order shall be unimpaired and the Agreement and/or Order shall not be void for this reason alone. Such invalid, illegal or unenforceable provision shall be replaced by a mutually acceptable valid, legal, and enforceable provision, which comes closest to the intention of the parties underlying the invalid, illegal or unenforceable provision.<br /><br /></li>
    </ul>
    <div>
    <div><strong>Trademarks&nbsp;<br /><br /></strong></div>
    <ul>
    <li>'<strong>kdr</strong>', and&nbsp;'<strong>kdr soft</strong> '&nbsp;are&nbsp;registered&nbsp;trademarks&nbsp;of Kdr Soft Solutions Private Limited. All Other marks, trade names used may be trademarks and/or registered trademarks fo their respective owners and not associated with Kdr Soft Solutions unless otherwise mentioned.</li>
    </ul>
    </div><br />
    
    <h1><span style="text-decoration: underline;"><strong>Refund Policy</strong></span></h1> <br/>
    
    <p style="text-align: justify;>When a payment is made to Kdr Soft Solutions Private Limited, the amount paid is retained by Kdr Soft Solutions Private Limited in a client account. Kdr Soft Solutions Private Limited will earn the payment upon delivering on the scope of services for the client.</p><br />
<p style="text-align: justify;>During an engagement, Kdr Soft Solutions Private Limited receives payment at different rates and different times depending on the completion of various milestones.<br /><br /></p>
<p style="text-align: justify;>Where Kdr Soft Solutions Private Limited offers non-tangible irrevocable services we do not issue refunds once the service is delivered. For example: refund cannot be provided for resources and man hours because resources and man hours spent on delivering the service are non-returnable in nature. Under any circumstance, Kdr Soft Solutions Private Limited shall be liable to refund only upto the amount paid by the client.<br /><br /></p>
<p style="text-align: justify;>As a customer you are responsible for understanding this refund policy upon purchasing any of our products or services at our website. However, we realize that exceptional circumstances can take place with regard to the character of the product or services we provide.<br /><br /></p>
<p><strong>Therefore we do honor requests for refund where the following reasons apply:<br /><br /></strong></p>
<ol class="order-list">
<li>Non-delivery of the product or service such as (but not limited to), for example, due to some mailing issues you do not receive a delivery e-mail from us or problems while downloading the product or receiving a service.<br /><br /></li>
<li>Irreparable defects or grave difficulties with the product or service: Although all the products and services are thoroughly tested before release, unexpected errors may occur. This reason should be submitted to our team for approval.<br /><br /></li>
<li>Product or services not-as-described: A request based on this reason is satisfied on a case by case basis with 7Kdr Soft Solutions Private Limited reserving full discretion to make the determination whether a refund is appropriate.<br /><br /></li>
</ol>
<p>Notice of refund must be made via e-mail to <a href="mailto:payments@kdrsoftsolutions.com"><strong>payments@kdrsoftsolutions.com</strong></a>.&nbsp;A refund acknowledgement will be sent within 5-7 working days and amount will be refunded within 90 working days, subject to this Refund Policy.<br /><br /></p> 
<h3><span style="text-decoration: underline;"><strong>Kdr Soft Solution&rsquo;s Cancellation Policy<br /></strong></span>&nbsp;</h3>
<ul>
<li>After all agreement completion, we get start the work on the project and then if within a week, you ask us to cancel the project and refund the Upfront milestone payment then we will be refunding only 50% of upfront milestone amount.<br /><br /></li>
<li>Once we start work on project, there will no full refund of project amount in any case for paid milestones.<br />&nbsp;</li>
<li>If we have completed the project and then you want to cancel the project, then in that case we will not refund any already paid amount and you will also be liable to pay the remaining amount of the project.<br />&nbsp;</li>
<li>If you have paid for the milestone &amp; we have also delivered the work for that milestone and now you want to cancel the project, then we will not charge you for further milestones and project will be cancelled.<br />&nbsp;</li>
<li>If you have cancelled the project in between and now facing some error or bug then we are not liable to solve them and it will have separate estimation for solving those bugs &amp; errors.<br />&nbsp;</li>
<li>We will not be including any new work in between the pre-defined milestones. We will be covering the new work or milestones after completing all the existing milestones of the project on which agreed earlier.<br />&nbsp;</li>
<li>If you still have any query, please write to us at <a href="mailto:info@kdrsoftsolutions.com"><strong>info@kdrsoftsolutions.com</strong><br /></a><strong><a href="mailto:info@xtreemsolution.com"><br /></a></strong></li>
</ul>`,
};
