export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "aboutus.jpg",
    imageText2: "We Don't Just Build IT Solutions We Build Possibilities for You",
    imageText3: "A GLOBAL TECHNOLOGY PROVIDER OF OUTSTANDING IT SOLUTIONS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "contact-us",
      buttonText: "Let's Get Started",
    },
  },
};

export const typeThreeBannerData = {
  semiHeading: "STRIVE TO LEAD",
  heading: "We are committed to building solutions that create rewarding results",
  description: "BE IT WEB, MOBILE OR ANYTHING DIGITAL",
};

export const typeOneQuoteData = {
  heading: "Mission statement",
  description:
    "At KDR , we strive to excel in the invention and development of the industry’s most advanced technologies including mobile apps, web, eCommerce, mCommerce, IoT, Wearables, AR/VR, cloud integration, cross-platform, on-demand, enterprise mobility and alike. We translate these technologies into value for our clients’ customers giving them professional and best-in-class services; thereby helping businesses throughout the world harness their full potential.",
};

export const bottomTypeOneQuoteData = {
  heading: "Vision",
  description:
    "To become the most trusted and preferred offshore IT solutions partner for Startups, SMBs and Enterprises through innovation and technology leadership",
  showButton: true,
  buttonText: "Connect with us",
  buttonLink: "/contact-us",
};

export const infoBannerTypeData = [
  {
    iconName: "about-us-OIE",
    amount: "50+ Years",
    text: "OF INDUSTRY EXPERIENCE",
  },
  {
    iconName: "about-us-RR",
    amount: "65%",
    text: "RETENTION RATE",
  },
  {
    iconName: "about-us-DC",
    amount: "100+",
    text: "DELIGHTED CLIENTS",
  },
  {
    iconName: "about-us-E",
    amount: "20+ ",
    text: "EMPLOYEES",
  },
  {
    iconName: "about-us-SES",
    amount: "80%",
    text: "SENIOR & EXPERT STAFF",
  },
];

export const circleCardData = {
  text:
    "Amazing things happen to your business when we connect those dots of utility and value",
  circleCards: [
    {
      iconName: "about-us-C",
      heading: "Creative",
      para1: "Create the BEST.",
      para2: "Be Creative",
    },
    {
      iconName: "about-us-I",
      heading: "Innovative",
      para1: "Innovate the NEXT.",
      para2: "Be Innovative.",
    },
    {
      iconName: "about-us-F",
      heading: "Functional",
      para1: "Refine the REST.",
      para2: "Be Functional."
    },
  ],
};

export const teamBannerData = {
  leftPart: {
    text1: "BEST PEOPLE. BEST RESULTS",
    text2: "Our Team",
    text3: "We’re a team of 20+ people who are all masters of what we do.",
    text4:
      "We love what we do and the people we work with. Our team is half-split between developers, designers, quality analysts and project managers. All of us who work at KDR share the vision and values of our community. We are driven by the idea that the best work is born when diligence mixes with fun and creativity mixes with professionalism, which makes our process unique.",
    showButton: true,
    buttonText: "Meet our team",
    buttonLink: "/our-team",
  },
  rightPart: {
    images: [
      "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1267&q=80",
      "https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
      "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
      "https://images.unsplash.com/photo-1577412647305-991150c7d163?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
      "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
    ],
  },
};

export const timeLineTypeThreeData = {
  topTagLine: "OUR VALUES",
  heading: "Our core purpose is to serve customers well",
  data: [
    {
      iconName: "about-us-BR",
      text: "Be Real",
      description:
        "We believe in maintaining integrity and transparency forever with our clients and our colleagues. We run our company with unquestionable ethics.",
    },
    {
      iconName: "about-us-BA",
      text: "Be Accountable",
      description:
        "We take responsibility of whatever we promise to our clients and live up to their expectations. We include them in the decision process.",
    },
    {
      iconName: "about-us-PI",
      text: "Passionate & Innovative",
      description:
        "Passion and innovation are a part of our DNA. We strive to serve businesses with solutions built with the right mix of these qualities.",
    },
    {
      iconName: "about-us-MD",
      text: "Making a Difference",
      description:
        "With a freedom of thought and team diversity, we work towards exclusivity and changing the way products and services reach to the market.",
    },
    {
      iconName: "about-us-QL",
      text: "Quest to Learn",
      description:
        "We exist to learn new technologies, latest trends to help our clients stay ahead of competitors and gain profitable results.",
    },
    {
      iconName: "about-us-CW",
      text: "Collaborative Working",
      description:
        "We work as a team, supporting each other to make the best use of our potential. We value this association and come together to collaborate efforts and create valuable solutions.",
    },
    {
      iconName: "about-us-RM",
      text: "Results Matter",
      description:
        "With a ‘can do’ attitude, our team work towards attaining client’s goals and provide them a value proposition, which bring them back to us for their next projects.",
    },
    {
      iconName: "about-us-AC",
      text: "Always Connected",
      description:
        "Be it technology, people, partners or customers- we believe in building connections, in relationships across boundaries.",
    },
  ],
};

export const type14BannerData = {
  heading: "We lead the global rankings",
  description:
    "OUR UNMATCHED QUALITY SERVICES ARE RECOGNIZED BY WORLD'S TOP RESEARCH & REVIEW COMPANIES",
  cardDetails: [
    {
      imageURL:
        "https://www.appfutura.com/uploads/blog/2016/04/AppFuturaLogo.png",
      name: "abc",
      text1: "Ranked Among Top 10 Mobile App Developers",
      text2: "BY APPFUTURA.COM - 2019",
    },
    {
      imageURL:
        "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png",
      name: "abc",
      text1: "Ranked Among Top iOS App Development Agencies",
      text2: "BY BUSINESSOFAPPS.COM - 2020",
    },
    {
      imageURL:
        "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png",
      name: "abc",
      text1: "Ranked Among Top 5 App Developers & Development",
      text2: "COMPANIES IN INDIA - 2020",
    },
    {
      imageURL:
        "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png",
      name: "abc",
      text1: "#1 Magento Web Development Company",
      text2: "BY EXTRACT.CO - 2019",
    },
    {
      imageURL:
        "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png",
      name: "abc",
      text1: "Ranked Among Top 5 App Developers & Development Companies in India - 2020",
      text2: "",
    },
    {
      imageURL:
        "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png",
      name: "abc",
      text1: "Top 5 Mobile App Development Companies",
      text2: "BY SILICONINDIA - 2018",
    },
  ],
};
