export const sendMail = (formObj) => {
  return fetch("https://ddmewien4l.execute-api.us-east-2.amazonaws.com/api/sendMail", {
    method: "POST",
    body: formObj,
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached,
  })
    .then((data) => {
      return data.json()
    })
    .then((data) => {
      return {
        success: true,
        message: data.message,
      };
    })
    .catch((error) => {
      return {
        success: false,
        message: "Something went wrong",
      };
    });
};
