import { companyName } from "../../config";

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "ios.png",
    imageText2: "iPhone app development services",
    imageText3:
      "KDR IS A TOP IOS APP DEVELOPMENT COMPANY WITH THE BEST TEAM OF OBJECTIVE-C & SWIFT CODERS",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "GET ESTIMATION FOR YOUR PROJECT",
    },
  },
};

export const typeFourBannerData = {
  heading: "We adopt modern, cutting-edge technologies",
  text1:
    "iOS market has unmatched potential, and as one of the top iphone app development companies, we adopt leading-edge technologies that help your business achieve the best of traffic and conversions.",
  description: "TECHNOLOGIES THAT FUEL OUR IOS APPLICATIONS",
};

export const multiRowData = {
  text1: "FOR STARTUPS, ENTERPRISES & CONSUMERS",
  text2: "Scalable, interactive & custom iOS solutions on your way",
  text3:
    "We are ranked as top iPhone app development company worldwide by Clutch, GoodFirms, AppFutura. We specialize in creating end-to-end tailor-made mobile solutions using latest iOS SDKs. Serving in multiple segments, our core iOS development services include:",
  carouselData: [
    {
      iconName: `${companyName}-MDA-option-MA`,
      label: "Multimedia Apps",
    },
    {
      iconName: `${companyName}-MDA-option-VA`,
      label: "VoIP Apps",
    },
    {
      iconName: `${companyName}-MDA-option-DS`,
      label: "Data Synchronization",
    },
    {
      iconName: `${companyName}-MDA-option-EA`,
      label: "Enterprise Apps",
    },
    {
      iconName: `${companyName}-MDA-option-SN`,
      label: "Social Networking",
    },
    {
      iconName: `${companyName}-MDA-option-MG`,
      label: "Mobile Gaming",
    },
    {
      iconName: `${companyName}-MDA-option-LA`,
      label: "Location-based Apps",
    },
    {
      iconName: `${companyName}-MDA-option-WA`,
      label: "Wearable/IoT Apps",
    },
    {
      iconName: `${companyName}-MDA-option-AVA`,
      label: "AR/VR Apps",
    },
    {
      iconName: `${companyName}-MDA-option-BA`,
      label: "iBeacon Applications",
    },
    {
      iconName: `${companyName}-MDA-option-ECA`,
      label: "E-commerce Apps",
    },
    {
      iconName: `${companyName}-MDA-option-UA`,
      label: "Utility Apps",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Let's Connect",
};

export const stackUsedData = {
  stackData: [
    {
      name: "Languages",
      logo: "coding",
      techUsed: [
        {
          techName: "Swift",
          techLogo: "swift.png",
        },
        {
          techName: "OBJECTIVE-C",
          techLogo: "objective-c.png",
        },
      ],
      showButton: false,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Frameworks",
      logo: "server",
      techUsed: [
        {
          techName: "Cocoa Touch",
          techLogo: "cocoa-touch.png",
        },
        {
          techName: "3D Touch ",
          techLogo: "apple-3d-touch.png",
        },
        {
          techName: "Push Notifications",
          techLogo: "push_notifications.png",
        },
        {
          techName: "In-App Purchases (IAP)",
          techLogo: "in-app-purchase.png",
        },
        {
          techName: "Core Data",
          techLogo: "core_data.png",
        },
        {
          techName: "HealthKit",
          techLogo: "apple-healthkit.png",
        },
        {
          techName: "CallKit",
          techLogo: "apple-callkit.png",
        },
        {
          techName: "CocoaPods",
          techLogo: "cocoapods.png",
        },
        {
          techName: "AFNetworking",
          techLogo: "anfetworking.png",
        },
        {
          techName: "SiriKit",
          techLogo: "sirikit.png",
        },
      ],
    },
    {
      name: "ORMs And Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "SQLite",
          techLogo: "sqlite.png",
        },
        {
          techName: "Core Data",
          techLogo: "core_data.png",
        },
        {
          techName: "Realm Mobile Database",
          techLogo: "realm.png",
        },
      ],
    },
  ],
  showButton: false,
};

export const typeThreeBannerData = {
  heading: "Why choose KDR as your iOS app development partner?",
  description: "WE ARE MULTI-ASSET AND SERVE ACROSS MULTIPLE INDUSTRIES",
  details: [
    {
      iconName: `${companyName}-MDA-option-IPFIA`,
      label: "Impressive Portfolio for iOS apps",
      content:
        "More than 10M+ downloads of our apps, over 350+ successful iOS projects.",
    },
    {
      label: "iOS Expert Developers",
      iconName: `${companyName}-MDA-option-IE`,
      content:
        "Passionate and pro workforce with innovative minds and specialized skills.",
    },
    {
      label: "High quality development",
      iconName: `${companyName}-MDA-option-HQD`,
      content:
        "90% client satisfaction with Total Quality Management in design, development & delivery.",
    },
    {
      label: "Confidentiality & Security",
      iconName: `${companyName}-MDA-option-CS`,
      content:
        "100% confidential consultation and project privacy maintained for lifetime.",
    },
    {
      label: "On time and in budget",
      iconName: `${companyName}-MDA-option-OTIB`,
      content:
        "98% of projects are delivered on time and within the estimated budget.",
    },
    {
      label: "Complemented User Experience",
      iconName: `${companyName}-MDA-option-CUE`,
      content:
        "We work with human-centric design policy delivering a smooth and polished flow.",
    },
    {
      label: "Custom Development",
      iconName: `${companyName}-MDA-option-CD`,
      content:
        "Deliver results in accordance with the real purpose of individual businesses.",
    },
    {
      label: "Adaptive to new versions and technologies",
      iconName: `${companyName}-MDA-option-ANVAT`,
      content:
        "All-tuned with the latest iOS versions, updates and releases by Apple Inc.",
    },
  ],
};

export const resultInfo = {
  text1: "Only results matter to us",
  text2:
    "Because they help us gain client satisfaction and let our clients enjoy maximum profits",
  content: [
    {
      label: "iOS Apps Developed By KDR Team",
      value: "100+",
      color: "green",
    },
    {
      label: "Client Retention Rate",
      value: "64%",
      color: "indigo",
    },
    {
      label: "App Downloads on App Stores",
      value: "1M+",
      color: "red",
    },
  ],
};
