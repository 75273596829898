import React from "react";
import { Link } from "react-router-dom";

export const RQImageType = ({ data }) => {
  return (
    <div
      className="py-20 flex items-center justify-center"
      style={{
        background: `url(${require(`../../assets/Images/${data.folderName}/${data.imageName}`)})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        data-aos="fade-up"
        className="container mx-auto text-white text-center "
      >
        {data.heading && <p className="text-4xl font-bold">{data.heading}</p>}
        {data.description && (
          <p className="uppercase mt-3">{data.description}</p>
        )}
        {data.showButton && (
          <Link
            className="bg-gradient-blue-green rounded-md text-base uppercase font-bold mt-6 p-4 inline-block"
            to={data.buttonLink}
          >
            {data.buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};
