import React from "react";

export const TypeTwoBanner = ({
  text1,
  text2,
  buttonText,
  scrollTo,
  mainId,
}) => {
  return (
    <div
      className="py-16 flex flex-col bg-gray-300 justify-center items-center text-center"
      id={mainId}
      data-aos="fade-in"
    >
      <p className="text-2xl lg:text-3xl font-bold">{text1}</p>
      <p className="text-sm md:text-base mt-2">{text2}</p>
      <a
        href={scrollTo}
        className="uppercase bg-blue-700 text-white text-sm rounded-lg mt-6 hover:bg-blue-600 transition-all duration-150 shadow-md ease-in font-bold"
        style={{
          letterSpacing: "1px",
          padding: " 20px 30px 18px 30px",
        }}
      >
        {buttonText}
      </a>
    </div>
  );
};
