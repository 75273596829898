export const dominateData = {
  heading: "Empowering finance across all platforms ",
  para:
    "To solve various problems faced in the industry, we have a quick-witted business analyst team to ideate the best solutions that define business objectives of every finance and insurance company. Certified designers, developers and quality analysts develop an integrated product that can run across all smartphones, tablets and wearable devices.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Our banking and finance solutions add value to financial organizations, banking institutions and insurance companies with online payment systems, transaction processing and mobile banking and help them stay ahead of a changing marketplace. Our team of tech veterans can simplify an often-convoluted process into a connected, real-time development solution.",
  text2:
    "We have gained the expertise in helping privately-owned, government and public-sector financial and insurance institutions and banks with the right strategies for increased operational efficiency, increased revenue and profit generation, reduced costs, maintaining constant cost leadership and transforming the internal and customer-focused processes to match the market trends.",
};

export const challengesData = {
  heading: "Challenges in banking, finance & insurance",
  description: "INTEGRATING REAL-TIME DATA TO MAKE INFORMED DECISIONS",
  details: [
    {
      label: "Managing Customer Data",
      content:
        "Today, a lot of analytics insight and business intelligence is required to analyze, manage and store the large amounts of customer data generated.",
    },
    {
      label: "Accessibility & Customer Service",
      content:
        "Smartphones are remodeling our relationship with money. Safer access to data and information related to their finances has become a major concern for many informed customers. Millennials choose a more personalized and convenient way to manage their bank accounts and financing documents.",
    },
    {
      label: "Regulation",
      content:
        "Most bankers cite tightening regulatory requirements as costly, ineffective and excessive. The rising regulatory requirements impact the innovation and diversity that bankers strive to implement to cater to escalating demands. Being compliant is tougher as it forces the companies to compromise with creativity in the industry.",
    },
    {
      label: "Technology Risk",
      content:
        "Yet another big challenge that banking and financing groups face is the failure to invest rightly in secure, agile systems, that can enhance digital systems and mobile banking due to the risk for cyber-attacks. At the same time, they face from a host of disruptive innovators who are able to provide seamless and more affordable options across multiple channels.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "We’ve helped many financial institutions in serving millions of customers through a reliable, bug-free, future-ready web, mobile or software system. Seeing things from the eyes of customers, we know what the customers expect from your products and services and we give them exactly what they want. Using latest technology tools and frameworks, we help in building customer’s trust in our client’s services and converting them in loyal consumers.",
  details: [
    {
      label: "Cashing in with Banking & Finance Apps",
      content:
        "A successful combination of technical expertise and skilled developers allows us to build solution-driven strategies that gratifies customer’s expectations and provides them luxury to perform various banking activities via mobile phones tablets, wearables and other devices, without taking pains of leaving home and work. Our app solutions encourage concepts of branchless banking.",
    },
    {
      label: "Financial Enterprise Management",
      content:
        "Allowing the staff to work remotely and manage the consumer’s data from anywhere, anytime, our solutions are aimed at simplifying the rigid workplace systems and provide more secure consuemerized software and enterprise mobility services.",
    },
    {
      label: "Effective Data Utilization",
      content:
        "Facilitating intuitive and convenient ways of managing consumer data, our financial tech solutions are integrated with various big data tools and frameworks. With enhanced security in place and above the horizon, you can assure your customers by providing top-notch experiences in transferring money, generating a request and managing financing activities.",
    },
    {
      label: "Continuous Support",
      content:
        "Our work doesn't stop once we deliver a product (a website, mobile app or enterprise software), we’ll guide its transition in the marketplace and continuously upgrade it to stay at par with the competitors and other brands in the industry.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Web Apps for Internet Banking",
        text2: "#2. Mobile Banking",
        text3: "#3. Transaction (backend) Management",
      },
      {
        text1: "#4. Automated Workflow",
        text2: "#5. ERP/CRM",
        text3: "#6. Cash Management System",
      },
      {
        text1: "#7. Comprehensive Reporting",
        text2: "#8. Financial Planning Software",
        text3: "#9. App Security Consulting",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
