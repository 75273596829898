export const mobilePageComponent = {
  "mobile-application-development": {
    navbar: true,
    type_two_image_banner: true,
    service_offered: true,
    type_six: true,
    user_centric_solutions: true,
    stack_used: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "cross-platform-app-development": {
    navbar: true,
    type_two_image_banner: true,
    multi_row: true,
    type_four: true,
    stack_employed: true,
    type_three: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  ios: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    result_info: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  android: {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    result_info: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "ui-ux-designing": {
    navbar: true,
    image_banner: true,
    multi_row: true,
    type_four: true,
    stack_used: true,
    type_three: true,
    result_info: true,
    app_carousel: true,
    work_with_us: true,
    footer: true,
  },
  "react-native": {
    navbar: true,
    image_banner: true,
    top_type_three: true,
    type_three: true,
    app_infographics: true,
    type_two_service_offered: true,
    work_with_us: true,
    footer: true,
  },
  "pwa": {
    navbar: true,
    image_banner: true,
    top_second_type_three: true,
    top_type_three: true,
    type_three: true,
    app_infographics: true,
    type_two_service_offered: true,
    work_with_us: true,
    footer: true,
  },
  flutter: {
    navbar: true,
    image_banner: true,
    top_second_type_three: true,
    top_type_three: true,
    type_three: true,
    app_infographics: true,
    type_two_service_offered: true,
    work_with_us: true,
    footer: true,
  },
  ionic: {
    navbar: true,
    image_banner: true,
    type_three: true,
    top_second_type_three: true,
    app_infographics: true,
    type_two_service_offered: true,
    work_with_us: true,
    footer: true,
  },
};
