import React from "react";
import { ImageTypeOneBanner } from "../../lib/ImageTypeOneBanner/ImageTypeOneBanner";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import { WorkWithUs } from "../WorkWithUs/WorkWithUs";
import {
  data,
  startUpImageSection,
  text1,
  text2,
  successfulData,
  growth,
  // appData,
} from "./startupData";
import { TypeOneBanner } from "../../lib/TypeOneBanner/TypeOneBanner";
import { TypeThreeBanner } from "../../lib/TypeThreeBanner/TypeThreeBanner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { AppCarousel } from "../../lib/Carousel/AppCarousel";

export const StartUp = () => {
  const location = useLocation().key;

  return (
    <div key={location}>
      <Navbar otherPages={true} transparent={true} />
      <ImageTypeOneBanner
        folderName="startup"
        imageName={startUpImageSection.imageName}
        imageText2={startUpImageSection.imageText2}
        imageText3={startUpImageSection.imageText3}
        showButton={true}
        buttonText={startUpImageSection.buttonText}
        buttonScrollToId="#workWithUs"
      />
      <TypeOneBanner text1={text1} text2={text2} />
      <TypeThreeBanner data={data} />
      <TypeThreeBanner
        data={successfulData}
        backgroundColor="bg-gray-900"
        showButton={true}
        buttonType="button"
        buttonLink={successfulData.buttonDetails.buttonLink}
        buttonText={successfulData.buttonDetails.buttonText}
      />
      <TypeThreeBanner
        data={growth}
        showButton={true}
        buttonScrollToId="#workWithUs"
        buttonText={growth.buttonDetails.buttonText}
      />
      {/* <AppCarousel appData={appData} showButtn={true} /> */}
      <WorkWithUs />
      <Footer />
    </div>
  );
};
