import React from "react";
import ClampLines from "react-clamp-lines";
import { useHistory } from "react-router-dom";

export const AppCard = ({
  appId,
  name,
  category,
  publishedDate,
  shortDescription,
  tags,
  screenshot,
  shortName,
  logo,
}) => {
  const history = useHistory();
  return (
    <div
      key={appId}
      className="card group rounded-lg cursor-pointer transition-all transform duration-200 ease-linear hover:shadow-xl hover:-translate-y-2 flex flex-col"
      onClick={() => {
        history.push(`/portfolios/app/${appId}`);
      }}
    >
      <div
        className="flex flex-col items-center justify-center rounded-t-lg group-hover:bg-gray-300  bg-gray-200 h-full"
        style={{
          padding: "10px 15px 0",
        }}
      >
        <img
          className="w-16 h-16 mt-8"
          src={require(`../../assets/Images/portfolios/${shortName}/${logo}`)}
          alt={name}
        />
        <p className="uppercase text-center font-bold mt-3 text-3xl">{name}</p>
        <p className="uppercase text-gray-600 text-base my-4">{category}</p>
        <div
          style={{
            marginTop: "auto",
          }}
        >
          <img
            src={require(`../../assets/Images/portfolios/${screenshot}`)}
            alt={name}
          />
        </div>
      </div>
      <div
        className="bg-white p-4 rounded-b-lg"
        style={{
          boxShadow: "0 0 30px rgba(21,21,21,.12)",
          marginTop: "auto",
        }}
      >
        <p className="text-sm capitalize text-gray-700 mb-1">{publishedDate}</p>
        <ClampLines
          className="font-bold text-lg leading-6 capitalize"
          moreText=""
          text={shortDescription}
          id="default"
          lines={2}
          buttons={false}
        />
        <div className="flex mt-4 justify-start">
          {tags &&
            Object.keys(tags).map((el) => (
              <p
                className="text-xs ml-4 font-bold py-2 px-2 border-2 rounded-full capitalize border-gray-400 hover:bg-gray-800 hover:text-white hover:border-transparent"
                key={el}
              >
                {el}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};
