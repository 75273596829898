import React from "react";
import { Link } from "react-router-dom";

export const ImageTypeTwoBanner = ({
  imageName,
  imageText1,
  imageText2,
  imageText3,
  buttonText,
  buttonScrollToId,
  showButton,
  folderName,
  buttonLink,
  buttonType,
  points,
}) => {
  return (
    <section
      className="relative flex justify-center items-center h-100vh lg:h-70vh"
      style={{
        background: `url(${require(`../../assets/Images/${folderName}/${imageName}`)}) center center / cover no-repeat`,
      }}
    >
      <div data-aos="fade-up" className="absolute text-white flex w-full  mx-auto flex-col items-center justify-center text-center">
        <p className="text-sm md:text-base">{imageText1}</p>
        <p className="text-2xl lg:text-4xl max-w-6xl font-bold tracking-wider mt-2 capitalize">
          {imageText2}
        </p>
        <p className="text-sm  md:text-base w-full max-w-6xl text-gray-300 mt-2">
          {imageText3}
        </p>
        <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 col-gap-4 row-gap-2 mt-6 font-bold text-gray-400">
          {points.map((el, i) => (
            <p key={`${el}${i}`}>#{el}</p>
          ))}
        </div>
        {showButton &&
          (buttonType === "button" ? (
            <Link to={buttonLink}>
              <button className="uppxercase px-4 py-4 rounded-lg mt-8 bg-gray-400 text-blue-900 uppercase font-extrabold bg-opacity-85 text-sm md:text-base hover:bg-gray-900 hover:text-white hover:underline">
                {buttonText}
              </button>
            </Link>
          ) : (
            <a
              href={buttonScrollToId}
              className="uppercase px-4 py-4 border-white border-2 rounded-lg mt-8 text-sm md:text-base hover:border-transparent hover:bg-orange-500"
            >
              {buttonText}
            </a>
          ))}
      </div>
    </section>
  );
};
