import React from "react";
import { useParams } from "react-router-dom";
import { PageNotFound } from "../../lib/PageNotFound/PageNotFound";
import { componentMapping, urlData } from "./redirectData";
/**
 * Take the url as the input and return the jsx element corresponding to that url
 * @param {string} url The url of the accessed page.
 * @returns {JSX.Element}
 */
export const RedirectComponent = () => {
  const { url } = useParams();
  const component = url in urlData && urlData[url];

  if (component) {
    return React.createElement(componentMapping[component], {
      url: url,
    });
  } else {
    return <PageNotFound />;
  }
};
