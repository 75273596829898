export const tabData = [
  {
    label: "technology blogs",
    link: "#",
  },
  {
    label: "white papers",
    link: "#",
  },
  {
    label: "infographics",
    link: "#",
  },
  {
    label: "case studies",
    link: "#",
  },
];

export const text1 =
  "An extensive collection of technology information specific to your needs";
export const text2 =
  "Powered by our own proprietary technology solutions, we provide a go-to source for tech, digital culture and cutting-edge technology content for our dedicated and influential audience around the globe.";
