export const dominateData = {
  heading:
    "Transforming healthcare towards the future for better clinical decision making",
  para:
    "Our solutions cater to all type of audiences in this industry and we develop software and apps for empowering physicians, for patient education/engagement, for driving the millennials through latest wearable and fitness bands, for overall hospital workflow management, for enhancing patient-physician communication and for increasing efficiency and revenue for medical organizations.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Our solutions cater to all type of audiences in this industry and we develop software and apps for empowering physicians, for patient education/engagement, for driving the millennials through latest wearable and fitness bands, for overall hospital workflow management, for enhancing patient-physician communication and for increasing efficiency and revenue for medical organizations.",
  text2:
    "Now that patients around the world have grown more comfortable using digital networks and services, even for complex and sensitive issues like healthcare, we believe the time has come for healthcare systems, payers, and providers to go all in on their digital strategies.There has been an increase in fitness fanatics who are becoming more tech-oriented with their workouts and diets. People will log the foods that they eat and the exercises they have accomplished each day to keep them on track with their fitness goals.The question is, where should they start? And the answer is no other place better than Kdr !",
};

export const challengesData = {
  heading: "Challenges in healthcare and pharmaceuticals industry",
  description: "CHANNELIZED INTO GREAT OPPORTUNITIES",
  details: [
    {
      label: "Managing The Big Data",
      content:
        "To treat each patient on mobile or desktop and to suggest them the right diet, medication and exercise, huge data has to be stored, managed and processed by IT tools and technologies across multiple devices. Collecting high volumes of data at speed is a challenge because each detail has to be stored privately and securely.",
    },
    {
      label: "The Trust Gap",
      content:
        "The healthcare industry, and pharma in particular, doesn't have the best reputation thanks in part to controversies over subjects like drug pricing. That has created a trust gap in which consumers as well as physicians are less likely to trust ads and information that come from healthcare marketers.",
    },
    {
      label: "Online Reputation Management",
      content:
        "Traditional measures of treating patients and providing nursing and care are inadequate in today’s digital health marketplace. Patient experience (PX) is the dominant driver for technology investments and innovative programs. Most health systems do not have effective means to capture patient feedback.",
    },
    {
      label: "Patients Want The Innovative",
      content:
        "The core features patients expect from their health system are surprisingly mundane. To gratify the audiences of all ages is a challenge because in this industry, patients are more interested in efficiency, integration with other channels, value-based care than just appealing interfaces.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "With our customer-centric, agile and unique solutions connecting the patients with right doctors and the right medical facilitator at ease and convenience, you can reimagine your healthcare business to promote health and connectivity of each individual, while adapting to individual’s needs. Be it web, mobile app, cloud, wearable or IoT, we have touched every trending technology in the industry.",
  details: [
    {
      label: "Data collection and data privacy",
      content:
        "Implementing the most effective big data analytics solutions, we can easily map the data to the right patient so that it goes to the therapist and comes back with effective solutions. Our big data services that help in managing appointments, provide secure access to health records and correspond with clinical.",
    },
    {
      label: "Wearable tech & Cloud",
      content:
        "For providing a more personalized experience across all devices, we’ve now developed an array of fitness apps along with on-demand medicine services and patient care through web and mobile. When it comes to creating digital solutions for a delicate industry like healthcare and fitness, we don’t take any chances but aim to use the latest, cutting-edge technologies in our processes.",
    },
    {
      label: "Targeting patients personally",
      content:
        "While patients look for a more innovative and real-time experience, we provide complete personalization for each of your patients and leads. We include features like sending personalized campaigns to each of them based on medical history, consistent healthcare plan, allergies and other stored data to build loyalty and keep them coming back.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Mobile Hospital Management Solutions",
        text2: "#2. Fitness Tracking Apps",
        text3: "#3. Wearable App Development",
      },
      {
        text1: "#4. Web and Mobile Healthcare Solutions",
        text2: "#5. Pharma IT Software Solutions",
        text3: "#6. Electronic Medical Records (EMR) Software",
      },
      {
        text1: "#7. Electronic Health Records (EHR) Software",
        text2: "#8. Health Insurance Apps",
        text3: "#9. Clinical Management System Software",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
