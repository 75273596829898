export const dominateData = {
  heading: "One App. Same Event. Any Device.Top online ticketing solutions",
  para:
    "Some of our clients have sold tickets on their Instagram accounts and Facebook pages. We’ve developed such potential and stimulating UI/UX. Once you work with us, you get infinite possibilities for event ticketing management. Our development solutions give you lots of flexibility.",
  buttonDetails: {
    buttonType: "button",
    buttonLink: "/request-quote",
    buttonText: "Talk to our experts",
  },
};

export const typeFourBannerData = {
  text1:
    "Online booking through mobile, desktop, tablet has encouraged the most convenient and quickest way of buying tickets across the globe, and we do that phenomenally.",
  text2:
    "We have developed great projects in the industry that cater to a large audience and provide a seamless booking experience to web and mobile users. Our developers have been able to create holistic approaches to targeting, storing, managing and communicating over secure and convenient digital platforms.",
};

export const challengesData = {
  heading: "Challenges in Event and Ticketing industry",
  details: [
    {
      label: "Payment Risks And Cancellations",
      content:
        "As per a recent report, victims of online ticket fraud have lost more than £1.2 million to ticket fraud from June to December of 2015 with nearly 3.000 cases reported to Action Fraud between May and October. Convincing people by giving them a comfort and convenience they are expecting at the cost of booking online is a challenge for many merchants.",
    },
    {
      label: "Better Data Collection",
      content:
        "While booking tickets through mobile phones, there are a lot of data generated by each user, and with each completed transaction, the service provider gets to know consumer-based information including age, interests, purchase histories and place and manner of tickets purchases. To manage this data and personalize each ticket booking is a challenge for most providers.",
    },
    {
      label: "Wearable Technology",
      content:
        "In the online event ticket sales industry, the wearable technology has brought significant challenges when it comes to adapting digital platforms for easier use by wearable technology. Most customers are interested in having their event ticket scanned from their smartwatch. So, online event ticket sellers have to plan ahead when to have the ticket code on their wearable.",
    },
    {
      label: "Customer Loyalty",
      content:
        "To convert first-time customers into loyal customers, we need a well-implemented strategy with an edge. People quickly switch to different websites and apps if they find smoother transactions, more authenticity and better discounts and coupons. The goal of having mobile ticketing merchants assist in customer retention and efficient ways in engaging consumers in the future.",
    },
  ],
};

export const howKdr = {
  text1: "How KDR delivers business value?",
  text2:
    "Mobile option, wearable tech and other cutting-edge technologies provide a great option for managing the ticketing software and cater to the unique needs of customers. At Kdr, our experience in developing going-out event apps has been extensive, even before these apps were mainstream.",
  details: [
    {
      label: "Covering wider audiences",
      content:
        "From offering ticket purchasing functionality to a narrowed, local target audience to providing out-of-the-box features to travelers who prefer to hang out in chosen spots, we’ve developed apps for all. To gain customer’s interest and satisfaction, our team of developers ensure real-time booking experience where users can see the real-time seat availability, distance from the screen, multiple shows across various regions and alike.",
    },
    {
      label: "Modern technology implemented",
      content:
        "You talk about mobile, wearable, IoT, Cloud, we have a solution for each of them. For a delightful and connected experience, we know user choices and preferences. They want to have the same experience across all devices. In all our event ticketing apps, we suggest clients to introduce mobile and smartwatch ticket scanning at the venue.",
    },
    {
      label: "Feature-packed software",
      content:
        "For admin to handle and manage customer information and bookings, we implement the best technologies and tools like BrainTree, Stripe and others required for the service. Managing bookings, integrating payments, securing customer information, personalizing experiences are significant tasks for the service provider. We incorporate the best big data solutions, payment gateway integration, point of sale services in our applications.",
    },
  ],
  keySolutionsData: {
    heading: "Some of the key solutions we provide are:",
    details: [
      {
        text1: "#1. Ticket Management System",
        text2: "#2. Event Web Applications",
        text3: "#3. eTicketing Solutions",
      },
      {
        text1: "#4. Web & Mobile Booking",
        text2: "#5. Online Registration System",
        text3: "#6. CRM Application Development",
      },
      {
        text1: "#7. POS Software",
        text2: "#8. Venue/ Coupon/ Category Management",
        text3: "#9. Real-time Reporting & Tracking",
      },
    ],
  },
  buttonDetails: {
    buttonText: "Lets get in touch",
    buttonType: "button",
    buttonLink: "/contact-us",
  },
};
