import { companyName } from "../../config";

export const typeOneBannerData = {
  text1: "For a connected world",
  text2:
    "We have developed smartphone apps for several years and have now extended our technology stack to tomorrow’s best-selling gadgets. For each of them, we provide customized, user-centric and business-aligned solution.",
};

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/emergingTech",
    imageName: "wearable.png",
    imageText2: "Smart Meaningful Wearable Apps for Android Wear & iWatch",
    imageText3: "WE DRIVE INNOVATION FOR FUTURE WEARABLE TECHNOLOGIES",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "Let's connect",
    },
  },
};

export const multiRowData = {
  text1: "WEARABLE TECHNOLOGY ACROSS INDUSTRIES",
  text2:
    "Next generation of wearable services for frontline, innovative business groups",
  text3:
    "Need to cope up with the advancing market and changing consumer demands?.",
  text4:
    "We're here to enhance your services with a completely new customer-engagement story that will be relished by generations to come. We offer ground-breaking wearable development services to fulfill various business, customer and enterprise purposes.",
  carouselData: [
    {
      iconName: `${companyName}-wearable-SWA`,
      label: "Smart Watch Apps",
    },
    {
      iconName: `${companyName}-wearable-FTA`,
      label: "Fitness Tracking Apps",
    },
    {
      iconName: `${companyName}-wearable-WP`,
      label: "Wireless Payments",
    },
    {
      iconName: `${companyName}-wearable-IOT`,
      label: "Internet of Things",
    },
    {
      iconName: `${companyName}-wearable-CA`,
      label: "Customized Apps",
    },
    {
      iconName: `${companyName}-wearable-VRA`,
      label: "Virtual Reality Apps",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our experts",
};

export const stackEmployedData = {
  techData: [
    {
      logo: "google_glassiWatch.png",
      name: "Google Glass",
    },
    {
      logo: "anndroid_wear.png",
      name: "Android Wear",
    },
    {
      logo: "apple-watch1.png",
      name: "Apple Watch Series 2",
    },
    {
      logo: "samsung_gear.png",
      name: "Samsung Gear",
    },
    {
      logo: "gps_watch.png",
      name: "GPS Watch",
    },
    {
      logo: "augmented_reality.png",
      name: "Augmented Reality",
    },
    {
      logo: "fitbit.png",
      name: "Fitbit",
    },
  ],
};

export const typeThreeBannerData = {
  heading: "Why choose KDR as your wearable app development partner?",
  description: "TOP-CLASS EXPERIENCES FOR MOBILE AND WEARABLE DEVICES",
  details: [
    {
      label: "Earliest Adopters",
      iconName: `${companyName}-wearable-EA`,
      content:
        "Passionate about new-flanged technology, we started our innovation and design-focused services for wearable devices since its inception.",
    },
    {
      label: "Domain Expertise",
      iconName: `${companyName}-wearable-DE`,
      content:
        "We’ve reached a sweet spot in wearable application development and formed an expert team of over 30 developers to provide the best wearable tech.",
    },
    {
      label: "Functional & Fluid",
      iconName: `${companyName}-wearable-FF`,
      content:
        "We'll make your custom wearable app a star on all devices and connected screens as everything we develop is fully functional and smooth.",
    },
    {
      label: "Finest Upgradations",
      iconName: `${companyName}-wearable-FU`,
      content:
        "For all those businesses having a mobile app and seeking to make it a wearable compatible app, we can be your best partners.",
    },
    {
      label: "Bespoke Displays",
      iconName: `${companyName}-wearable-BD`,
      content:
        "Committed to think digital and create amazing user experience, we never skip our focus from crafting a delightful visual display of the app.",
    },
    {
      label: "All Bases Covered",
      iconName: `${companyName}-wearable-ABC`,
      content:
        "From reliable healthcare to manufacturing to enterprise-level, we can develop real-life business applications across all industries.",
    },
    {
      label: "On Time, In Budget",
      iconName: `${companyName}-wearable-OTIB`,
      content:
        "We value our client’s time and money and thus, ensure to deliver every project on time and within the promised costs.",
    },
    {
      label: "Disciplined Infrastructure",
      iconName: `${companyName}-wearable-DI`,
      content:
        "Unlike screwing up with freelance developers due to hardware and software infrastructure problems, our company has everything arranged in place.",
    },
  ],
};
