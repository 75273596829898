export const tabData = [
  {
    label: "current openings",
    link: "#current-openings",
  },
  {
    label: "our culture",
    link: "#our-culture",
  },
  {
    label: "why join kdr",
    link: "#why-join-kdr",
  },
  {
    label: "hiring process",
    link: "#hiring-process",
  },
];

export const text1 = "At KDR, a job isn’t a job, it’s Who You Are";
export const text2 =
  "Lead by technology and find your Happy Place in this high-tech world of web designing and mobile app development. We believe in building enduring relationships- with clients, with employees";

export const skills = [];

export const notHiring = "Currently we're not hiring!";

export const notHiringDescription =
  "Though we are not hiring, You can reach out to us to join our KDR family";

export const notHiringButtonText = "Be part of our team";

export const hiring = "We’re Hiring! Your skills are valued";

export const hiringDescription =
  "CHOOSE YOUR AREA OF INTEREST AND CREATE SOMETHING PEOPLE LOVE TO USE EVERY DAY";

export const imageSection = {
  imageName: "Career.jpg",
  imageText1: "TURN YOUR TECHNICAL OBSESSION INTO",
  imageText2: "A Promising Career",
  imageText3:
    "PEOPLE JUST LIKE YOU WORK HERE. IT’S AMAZING WHAT WE ACCOMPLISH WHEN WE’RE HERE TOGETHER!",
  buttonText: "See Current opportunities",
};

export const ourCulture = {
  paragraphOne: "Our Values Shape Our Culture",
  paragraphTwo: "WE GIVE EMPLOYEES THE FREEDOM TO BE AWESOME",
  paragraphThree:
    "Like a lot of startups and enterprises, Kdr is filled with highly motivated and thought-driven individuals who believe that our values are the essence of who we are and the way we work day in, day out. We inspire human connections- be it with employees, clients and partners. Our actions are rooted in three core values:",
  cards: [
    {
      logo: "career-IN",
      name: "Integrity",
      content:
        "Trust has to be at the center of what we do. If it’s not, it won’t show up to our clients and prospects, to our partners who want to build deep relations with us. Yes, we have targets that we want to hit, but we take actions that build trust with colleagues and external",
    },
    {
      logo: "career-PA",
      name: "Passion",
      content:
        "We go that extra distance in all our activities and stand up for what we believe in. Each member at Kdr steadfastly pushes oneself and fellows for results, actively encourages, supports and motivates others. We take pride in doing a good job and exceeding expectations.",
    },
    {
      logo: "career-AC",
      name: "Accountability",
      content:
        "A work culture that values responsibility and accountability is what we encourage within our teams. Everyone here is willing to take responsibility of their own actions. Because ultimately when team members consistently demonstrate ownership, trust is formed.",
    },
  ],
  buttonText: {
    name: "Join Our Team",
    link: "mailto:careers@kdrsoftsolutions.com",
  },
};

export const whyJoinUs = {
  title: "Why Should You Join Us",
  description: "ALL THINGS @KDR, ALL IN SINGLE BEST PLACE",
  data: [
    {
      logo: "career-EF",
      title: "Employee First",
      description: "You are not an ordinary employee but an asset",
    },
    {
      logo: "career-AR",
      title: "Advanced Resources",
      description: "Mac Screens and Android or iOS devices for the team",
    },
    {
      logo: "career-PE",

      title: "Positive Environment",
      description: "As bright and spirited as it gets to work and learn",
    },
    {
      logo: "career-GP",

      title: "Groundbreaking Projects",
      description: "Grateful opportunities for your career",
    },
    {
      logo: "career-TOC",

      title: "Transparent & Open Communication",
      description: "Open discussions during retreats and meetings",
    },
    {
      logo: "career-TD",

      title: "Training & Development",
      description: "Programs designed to amplify your ability and skills",
    },
    {
      logo: "career-RB",

      title: "Rewards & Benefits",
      description: "To make you feel approved and recognized",
    },
    {
      logo: "career-FC",

      title: "Fun Connect",
      description:
        "Annual picnics, festival celebrations,Friday fun and Connect activities",
    },
  ],
};

export const hiringProcess = {
  para1: "Our Tech Hiring Process",
  para2: "IF IT’S YOUR CHANCE, GIVE YOUR BEST SHOT.",
  process: [
    {
      number: 1,
      label: "Initial Interaction",
      content:
        "A one on one personal interview with the HR Manager is the first step of the selection process.",
    },
    {
      number: 2,
      label: "Technical Interview",
      content:
        "It’s time to exhibit your technical skills before the Tech Expert judged on subject knowledge.",
    },
    {
      number: 3,
      label: "Real-time Assignment",
      content:
        "For practical technical assessment, you may have to answer a range of puzzles and out-of-the-box problems.",
    },
    {
      number: 4,
      label: "Final HR Discussion",
      content:
        "Once you pass the technical round, final discussions on salary, workplace ethics take place.",
    },
  ],
};
