import { companyName } from "../../config";

// export const AppCarouselData = {
//   text1: "/ OUR PORTFOLIO - SHOWCASING OUR WORK /",
//   text2: "FEW OF OUR RECENT FAVORITE PROJECTS",
//   button1Text: "View More Client Review",
//   button1Link: "testimonials",
//   button2Text: "Browser Complete portfolio",
//   button2Link: "portfolios",
//   apps: [
//     {
//       appId: 1,
//       logo: "kdr.png",
//       shortName: "kdr",
//       background: "#24367f",
//       name: "KDR",
//       category: "IT Solutions",
//       screenshot: "testScreenShot.png",
//       publishedDate: "October, 2018",
//       shortDescription:
//         "Spicy jalapeno bacon ipsum dolor amet drumstick sirloin chuck shankle. Flank ribeye pancetta andouille ham hock. ",
//       tags: {
//         website: {
//           url: "https://thereportinn.com",
//           name: "web",
//         },
//         android: {
//           url: "https://thereportinn.com",
//           name: "android",
//         },
//       },
//       workedOn: [
//         "backend",
//         "database",
//         "frontend",
//         "software testing",
//         "strategy",
//         "ux/ui",
//       ],
//       image1URL: "kdr/4",
//       image2URL: "kdr/2",
//       image3URL: "kdr/1",
//       image4URL: "kdr/3",
//       image5URL: "kdr/5",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       clientTestimonals: {
//         name: "Jagadish",
//         designation: "Founder",
//         comment:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
//       },
//     },
//     {
//       appId: 2,
//       logo: "kdr.png",
//       shortName: "kdr",
//       background: "#12b0e5",
//       name: "The Report Inn",
//       category: "IT Solutions",
//       screenshot: "testScreenShot.png",
//       publishedDate: "October, 2018",
//       shortDescription:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//       tags: {
//         website: {
//           url: "https://thereportin.com",
//           name: "web",
//         },
//         iphone: {
//           url: "https://thereportin.com",
//           name: "ios",
//         },
//         android: {
//           url: "https://thereportin.com",
//           name: "android",
//         },
//       },
//       workedOn: ["backend", "database", "frontend"],
//       image1URL: "kdr/4",
//       image2URL: "kdr/2",
//       image3URL: "kdr/1",
//       image4URL: "kdr/3",
//       image5URL: "kdr/5",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       clientTestimonals: {
//         name: "Jagadish",
//         designation: "Founder",
//         comment:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
//       },
//     },
//     {
//       appId: 3,
//       shortName: "kdr",
//       background: "#24367f",
//       logo: "kdr.png",
//       name: "KDR SOFT SOLUTIONS",
//       category: "IT Solutions",
//       screenshot: "testScreenShot.png",
//       publishedDate: "October, 2018",
//       shortDescription:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
//       tags: {
//         website: {
//           url: "https://thereportin.com",
//           name: "web",
//         },
//         iphone: {
//           url: "https://thereportin.com",
//           name: "ios",
//         },
//         android: {
//           url: "https://thereportin.com",
//           name: "android",
//         },
//       },
//       workedOn: [
//         "backend",
//         "database",
//         "frontend",
//         "software testing",
//         "strategy",
//         "ux/ui",
//       ],
//       image1URL: "kdr/4",
//       image2URL: "kdr/2",
//       image3URL: "kdr/1",
//       image4URL: "kdr/3",
//       image5URL: "kdr/5",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
//       clientTestimonals: {
//         name: "Jagadish",
//         designation: "Founder",
//         comment:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
//       },
//     },
//     {
//       appId: 4,
//       shortName: "kdr",
//       background: "#24367f",
//       logo: "kdr.png",
//       name: "KDR SOFT SOLUTIONS",
//       category: "IT Solutions",
//       screenshot: "testScreenShot.png",
//       publishedDate: "October, 2018",
//       shortDescription:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//       tags: {
//         website: {
//           url: "https://thereportin.com",
//           name: "web",
//         },
//         iphone: {
//           url: "https://thereportin.com",
//           name: "ios",
//         },
//       },
//       workedOn: [
//         "backend",
//         "database",
//         "frontend",
//         "software testing",
//         "strategy",
//         "ux/ui",
//       ],
//       image1URL: "kdr/4",
//       image2URL: "kdr/2",
//       image3URL: "kdr/1",
//       image4URL: "kdr/3",
//       image5URL: "kdr/5",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
//       clientTestimonals: {
//         name: "Jagadish",
//         designation: "Founder",
//         comment:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
//       },
//     },
//     {
//       appId: 5,
//       shortName: "kdr",
//       background: "#24367f",
//       logo: "kdr.png",
//       name: "KDR SOFT SOLUTIONS",
//       category: "IT Solutions",
//       screenshot: "testScreenShot.png",
//       publishedDate: "October, 2018",
//       shortDescription:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
//       tags: {
//         website: {
//           url: "https://thereportin.com",
//           name: "web",
//         },
//         android: {
//           url: "https://thereportin.com",
//           name: "android",
//         },
//       },
//       workedOn: [
//         "backend",
//         "database",
//         "frontend",
//         "software testing",
//         "strategy",
//         "ux/ui",
//       ],
//       image1URL: "kdr/4",
//       image2URL: "kdr/2",
//       image3URL: "kdr/1",
//       image4URL: "kdr/3",
//       image5URL: "kdr/5",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       clientTestimonals: {
//         name: "Jagadish",
//         designation: "Founder",
//         comment:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
//       },
//     },
//   ],
// };

export const imageTypeOneBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "android.png",
    imageText2: "Android App Development Services",
    imageText3:
      "TOP ANDROID APP DEVELOPMENT COMPANY FOR STARTUPS, MEDIUM & LARGE ENTERPRISES",
    buttonDetails: {
      buttonType: "button",
      buttonLink: "request-quote",
      buttonText: "GET ESTIMATION FOR YOUR PROJECT",
    },
  },
};

export const typeFourBannerData = {
  heading: "Ranked among top Android app development companies",
  text1:
    "Embarking on a development journey since the start, we've created a highly competent and professional team of Android developers abreast of latest version releases and technology tools. If, you're looking for a no-compromise, top Android app, contact us. We follow the latest trends and include them in our Android app development services.",
  description:
    "EVERYTHING YOU NEED TO BUILD, DEPLOY AND LAUNCH AN AMAZING ANDROID APP",
};

export const multiRowData = {
  text1: "HIGHEST TECHNOLOGY STANDARDS FOR ANDROID APPLICATIONS",
  text2:
    "From Consumer to Startup to Enterprise we've developed Android apps for all",
  text3:
    "At Kdr, we put up with optimized code, interactive applications and result-driven development approach for Android. We have vast experience in delivering services with seemingly limitless possibilities across various Android devices and all its versions.",
  text4: "Different Android app solutions that we provide are:",
  carouselData: [
    {
      iconName: `${companyName}-MDA-option-BA`,
      label: "Business Applications",
    },
    {
      iconName: `${companyName}-MDA-option-CA`,
      label: "Chat Application",
    },
    {
      iconName: `${companyName}-MDA-option-AVA`,
      label: "AR/VR Apps",
    },
    {
      iconName: `${companyName}-MDA-option-MA`,
      label: "Multimedia Applications",
    },
    {
      iconName: `${companyName}-MDA-option-VA`,
      label: "VoIP Applications",
    },
    {
      iconName: `${companyName}-MDA-option-NA`,
      label: "News Applications",
    },
    {
      iconName: `${companyName}-MDA-option-LA`,
      label: "Geo-Location Applications",
    },
    {
      iconName: `${companyName}-MDA-option-MG`,
      label: "Gaming Apps",
    },
    {
      iconName: `${companyName}-MDA-option-WA`,
      label: "Weather Applications",
    },
    {
      iconName: `${companyName}-MDA-option-HAFA`,
      label: "Health & Fitness Applications",
    },
    {
      iconName: `${companyName}-MDA-option-UA`,
      label: "Utility Applications",
    },
    {
      iconName: `${companyName}-MDA-option-N`,
      label: "NFC Applications",
    },
  ],
  buttonLink: "#workWithUs",
  buttonText: "Talk to our android experts",
};

export const stackUsedData = {
  stackData: [
    {
      name: "Languages",
      logo: "coding",
      techUsed: [
        {
          techName: "Java",
          techLogo: "java.png",
        },
      ],
      showButton: false,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Frameworks",
      logo: "server",
      techUsed: [
        {
          techName: "RetroFit",
          techLogo: "retrofit2.png",
        },
        {
          techName: "Volley",
          techLogo: "Volley.png",
        },
        {
          techName: "Dagger2",
          techLogo: "dagger2.png",
        },
        {
          techName: "Gradle",
          techLogo: "android_gradle.png",
        },
        {
          techName: "Glide",
          techLogo: "glide.png",
        },
      ],
    },
    {
      name: "IDE",
      logo: `${companyName}-MDA-option-CE`,
      techUsed: [
        {
          techName: "Android Studio",
          techLogo: "android_studio.png",
        },
      ],
    },
    {
      name: "Database",
      logo: "database-icon",
      techUsed: [
        {
          techName: "SQLite",
          techLogo: "sqlite.png",
        },
        {
          techName: "Realm",
          techLogo: "realm.png",
        },
      ],
      showButton: false,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
  ],
  showButton: false,
};

export const typeThreeBannerData = {
  heading:
    "Reach out to the largest pool of end-users with exceptional level of customization",
  description: "ON A VARIETY OF SCREEN SIZES AND DEVICE MODELS",
  details: [
    {
      iconName: `${companyName}-MDA-option-AP`,
      label: "Sought-after Android Portfolio",
      content:
        "Almost around 200 Android app projects have been successfully delivered to clients across numerous industries.",
    },
    {
      label: "Bug-free Apps",
      iconName: `${companyName}-MDA-option-BF`,
      content:
        "Clean and optimized code is what sets us apart. We leave no damaged impressions for the clients to correct them.",
    },
    {
      label: "Latest development tools",
      iconName: `${companyName}-MDA-option-LDT`,

      content:
        "The experience of working with latest technologies in Android has enabled Kdr teams to adopt proven methodologies.",
    },
    {
      label: "Build Faster",
      iconName: `${companyName}-MDA-option-OTIB`,

      content:
        "Without any glitches and delays, we believe in delivering faster services, thereby planning launches at the right time.",
    },
    {
      label: "Budgeted pricing",
      iconName: `${companyName}-MDA-option-BP`,

      content:
        "With us, you can be assured of best prices in the market without compromising for quality in any way.",
    },
    {
      label: "Dedicated Developers",
      iconName: `${companyName}-MDA-option-DD`,

      content:
        "For Android application development, we provide dedicated developers and project managers individually for all projects.",
    },
    {
      label: "Flexible Engagement Model",
      iconName: `${companyName}-MDA-option-BM`,

      content:
        "Our engagement models are flexible with the choice of hiring the team for full-time, part-time or on per hour basis.",
    },
    {
      iconName: `${companyName}-MDA-option-ANVAT`,
      label: "Adaptive to new versions and technologies",
      content:
        "We stay abreast of latest Android versions, updates and releases by Google Play.",
    },
  ],
};

export const resultInfo = {
  text1: "Only results matter to us",
  text2:
    "Because they help us gain client satisfaction and let our clients enjoy maximum profits",
  content: [
    {
      label: "Android Apps Developed By KDR Team",
      value: "100+",
      color: "green",
    },
    {
      label: "Client Retention Rate",
      value: "64%",
      color: "indigo",
    },
    {
      label: "App Downloads on App Stores",
      value: "1M+",
      color: "red",
    },
  ],
};
