export const data = [
  {
    logo: "M",
    comment:
      "I have been associated with Kdr Soft Solutions from the last few months and my experience has been excellent. Their website development team is very creative and supportive. Their have best of the tech technology and give best possible service at the most reasonable cost. I was able to get more hits on through my website and was able to generate more leads. This helped me gain more strength in the market.",
    name: "Mahesh",
    position: "Co-Founder",
    companyName: "MG Solutions",
  },
  {
    logo: "P",
    comment:
      "Kdr Soft Solutions keeps doing what comes naturally to them which is building teams with their clients, partners and creating exceptional software development and support. Job well done. I have used them for offshore development. Congratulations to all your teams.",
    name: "Pradeep",
    position: "Director",
    companyName: "PNS Soft Sol",
  },
  {
    logo: "S",
    comment:
      "Kdr Soft Solutions worked untiringly to meet my goals for App development. I noticed a rapid boost in my business through the app. Highly Recommended!",
    name: "Srikanth",
    position: "Delivery Lead",
    companyName: "BGM Media",
  },
  {
    logo: "S",
    comment:
      "Kdr Soft Solutions is Best of the Best! Enjoy working with this Team! We wanted a very responsive team to create an app that aggregates local deals and discounts and they deliver it in a timely fashion within our budget boundaries. Highly recommended off-shore development unit.",
    name: "Sharath ",
  },
  {
    logo: "R",
    comment:
      "KDR Soft is driven by a passion to create and deliver on new ideas and that is reflected in their work. It is a team that strives for excellence and is open to experimenting and creating new trends in the business. Our work relationship has been productive and we have collectively been able to achieve great results. I wish Team KDR Soft more triumphs in future.",
    name: "Ramya Sree",
  },
  {
    logo: "S",
    comment:
      "We would like to express our satisfaction on the cooperation regarding the development of our web application. Jagadish and the development team did a very professional job. We are satisfied with the solution given to us and with the communication flow through the project. We would like to recommend Jagadish and his development team. We look forward to working with them in future projects.",
    name: "Swaroopa",
  },


];
