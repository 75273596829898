import React from "react";
import { Link } from "react-router-dom";

export const RqAndMenu = ({ toggleMenu }) => {
  return (
    <>
      <div className="flex items-center justify-center">
        <Link
          to="request-quote"
          className={`hidden md:block bg-orange-600 px-3 leading-10 ml-3 font-bold rounded-md text-white uppercase text-sm hover:bg-gray-800`}
        >
          Request a quote
        </Link>
        <button
          className="xl:hidden ml-2 -mr-4 inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-gray-800 focus:outline-none focus:bg-gray-800 focus:text-white"
          onClick={toggleMenu}
        >
          <svg
            className="w-8 h-8 fill-current"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
};
