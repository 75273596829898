import { companyName } from "../../config";

export const imageTypeThreeBannerData = {
  tag: "BLOCKCHAIN TECHNOLOGIES",
  heading: "BLOCKCHAIN CONSULTING & DEVELOPMENT",
  description:
    "Kdr Soft Solutions's Blockchain Lab developers deliver enterprise-grade blockchain solutions, consultancy, and custom blockchain development services. Our blockchain experts will help you bring a high level of security and mobility to your enterprise by integrating the latest distributed ledger technologies, identity solutions, and smart contracts.",
  folderName: "solutions/solution",
  imageName: "blockchain.jpg",
};

export const type17Data = {
  heading: "BLOCKCHAIN SOLUTIONS",
  folderName: "why/Team",
  imageName: "bcbg.jpg",
  list: [
    {
      iconName: `${companyName}-blockchain-BTC`,
      heading: "BLOCKCHAIN TECHNOLOGY CONSULTING",
    },
    {
      iconName: `${companyName}-blockchain-KC`,
      heading: "AML/KYC COMPLIANCE",
    },
    {
      iconName: `${companyName}-blockchain-PAPBD`,
      heading: "PUBLIC AND PRIVATE BLOCKCHAINS DEVELOPMENT",
    },
    {
      iconName: `${companyName}-blockchain-CWAE`,
      heading: "CRYPTOCURRENCIES, WALLETS AND EXCHANGES",
    },
    {
      iconName: `${companyName}-blockchain-DA`,
      heading: "DISTRIBUTED APPLICATIONS (DAPPS) ",
    },
    {
      iconName: `${companyName}-blockchain-DES`,
      heading: "DISTRIBUTED ECOMMERCE SOLUTIONS ",
    },
    {
      iconName: `${companyName}-blockchain-SCDAA`,
      heading: "SMART CONTRACTS DEVELOPMENT AND AUDIT ",
    },
    {
      iconName: `${companyName}-blockchain-CHF`,
      heading: "CRYPTOCURRENCY HARD-FORKS ",
    },
  ],
  table: {
    heading: "TOOLS & TECHNOLOGIES",
    tools: [
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "hyperledger.png",
        folderName: "blockchain",
        name: "HYPERLEDGER",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "Ethereum.svg",
        folderName: "blockchain",
        name: "ETHEREUM",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "parity.png",
        folderName: "blockchain",
        name: "PARITY",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "exonum.png",
        folderName: "blockchain",
        name: "EXONUM",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "r3.png",
        folderName: "blockchain",
        name: "R3",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "multichain.png",
        folderName: "blockchain",
        name: "MULTICHAIN",
      },
      {
        iconName: `${companyName}-blockchain-BTC`,
        imgName: "NXT.png",
        folderName: "blockchain",
        name: "NXT",
      },
    ],
  },
};

export const type18Data = {
  heading: "BLOCKCHAIN DEVELOPMENT PROCESS",
  cardData: [
    {
      title: "1. CLARIFYING YOUR NEEDS",
      iconName: `${companyName}-blockchain-CYN`,
      description:
        "We gather your initial requirements during the first meeting. After the assessment, you receive an approximate budget and timeframe for the implementation of your blockchain solution.",
      points: [
        "High-level requirements list ",
        "Project assessment ",
        "Workshop option & Statement of Work ",
      ],
    },
    {
      title: "2. STRATEGY & CONCEPT WORKSHOP",
      iconName: `${companyName}-blockchain-SACW`,
      description:
        "A team of blockchain experts, business analysts, tech and product managers come up with a strategy that considers your existing and planned business processes. They identify limitations and risks, and create the initial project architecture concept with a technology stack.",
      points: [
        "MVP features and requirements ",
        "Clickable prototype ",
        "Complete project plan ",
        "Final development proposal ",
      ],
    },
    {
      title: "3. DESIGN & DEVELOPMENT",
      iconName: `${companyName}-blockchain-DAD`,
      description:
        "Once you confirm the implementation, we gather a development team for your project. You will be able to track the development process of your blockchain and every two weeks closely collaborate with your team — we use Scrum methodology.",
      points: [
        "Deployable product ",
        "Compete product documentation ",
        "Release plan ",
      ],
    },
    {
      title: "4. LAUNCH & SUPPORT",
      iconName: `${companyName}-blockchain-LAS`,
      description:
        "We start releasing your blockchain in coordination with all your teams, from sales to support. After the release, our quality assurance experts test the system making sure it is ready for production. Finally, we outline support and maintenance processes for your blockchain project to ensure its continuous operation.",
      points: ["Product launch ", "Support and maintenance "],
    },
  ],
};
