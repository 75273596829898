import React from "react";
import { Link } from "react-router-dom";

export const RequestQuoteBanner = () => {
  return (
    <div className="py-16 px-4 xl:px-0 flex flex-col bg-gray-300 justify-center items-center text-center">
      <p className="text-3xl capitalize">Need a proposal? Let's discuss the project!</p>
      <p className="text-base mt-2">
        GET IN TOUCH WITH US AND DISCUSS THE NEEDS AND REQUIREMENTS OF YOUR
        PROJECT.
      </p>
      <Link
        to="request-quote"
        className="uppercase bg-blue-700 text-white text-sm rounded-lg mt-6 hover:bg-blue-600 transition-all duration-150 shadow-md ease-in font-bold"
        style={{
          letterSpacing: "1px",
          padding: " 20px 30px 18px 30px",
        }}
      >
        Request a Quote
      </Link>
    </div>
  );
};
