import React,{useState} from 'react'

export const FormTextAreaField = ({svg, labelName, textAreaFieldId, required, name}) => {

    let [highLight, setHighLight] = useState(false)

    const onFocusHandler = () => {
        setHighLight(true)
    }

    const notFocusHandler = () => {
        setHighLight(false)
    }

    return (
        <div className="flex flex-col  w-full pt-4 pb-2">
          <div className="flex justify-start items-center">
            {svg}
            <label htmlFor={textAreaFieldId} className="uppercase ml-4 text-base" 
             style={{
                color: '#486e90'
            }}
            >{labelName}*</label>
          </div>
          <textarea id={textAreaFieldId} 
          onFocus={onFocusHandler}
          onBlur={notFocusHandler}
          required={required}
          name={name}
          className={`w-full h-40 mt-4 border-2 focus:outline-none ${highLight ? 'border-main-blue' : 'border-gray-500'}`}></textarea>
        </div>
    )
}
