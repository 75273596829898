import React from "react";
import { Icon } from "../../Icon/Icon";

const CardTypeSix = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 text-center md:text-left col-span-1 md:col-span-3 max-w-5xl mx-auto py-2 md:py-8 px-4 md:px-10 shadow group hover:shadow-2xl hover:rounded-md my-4 md:my-8">
      <Icon iconName={data.iconName} classes="w-56 h-56 hidden md:block m-auto text-gray-400 group-hover:text-blue-400" />
      <div>
        {data.title && (
          <h3 className="text-xl md:text-3xl font-bold uppercase">{data.title}</h3>
        )}
        {data.description && (
          <p className="text-sm text-gray-700 mt-3">
            {data.description}
          </p>
        )}
        {data.points && (
          <ul className="list-outside list-disc grid grid-cols-2 col-span-4 my-2 md:my-4">
            {data.points.map((point, index) => (
              <li
                className="my-2 ml-2 hover:text-blue-600 cursor-pointer"
                key={`${index}_card_six`}
              >
                {point}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CardTypeSix;
