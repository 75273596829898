import React from "react";

export const CardTypeFour = ({ data }) => {
  return (
    <div data-aos="fade-in" className="type-four-card border-2 border-gray-200 hover:shadow-md py-8 px-6 flex flex-col items-center justify-center rounded-lg">
      {data.imageURL && <img src={data.imageURL} className="mb-4" alt={data.name} />}
      {data.text1 && <p className="text-2xl capitalize">{data.text1}</p>}
      {data.text2 && <p className="text-sm uppercase text-gray-700 mt-2">"{data.text2}"</p>}
    </div>
  );
};
