import { companyName } from "../../config";

export const imageTypeTwoBannerData = {
  imageSection: {
    folderName: "services/Mobile",
    imageName: "mobileappdev.png",
    imageText2: "Mobile app development services",
    imageText3:
      "WE OFFER TOP-NOTCH SOLUTIONS IN INTERNET OF THINGS (IOT), AR, IBEACON, WEARABLES, GAMING & BEYOND",
    points: [
      "500+ Mobile Apps Delivered",
      "50+ Team strength",
      " 100 Happy clients",
      "64% Repeat business",
      "Pixel-perfect designs",
      "Interactive and seamless UX",
    ],
    buttonType: "button",
    buttonLink: "request-quote",
    buttonText: "View our latest Mobile app portfolio",
  },
};

export const stackUsedData = {
  text1: "Native, Cross-platform and HTML5 mobile app solutions",
  text2: "CUSTOMER-FOCUSED APPS TO GAME-CHANGING MOBILE APPLICATIONS",
  stackData: [
    {
      name: "iOS Apps",
      logo: `${companyName}-MAD-I`,
      techUsedPara:
        "Having their fingers on the pulse of app development in the future, our application developers are experts in building anything that concerns Apple’s product line. From iPhones to iPads to Macs, you are partnering with the most experienced mobile app development agency that will shape your entire mobile vision to be as profitable as possible.",
      techUsed: [
        {
          techName: "Swift",
          techLogo: "swift.png",
        },
        {
          techName: "React Native",
          techLogo: "react_native.png",
        },
        {
          techName: "Xcode",
          techLogo: "xcode.png",
        },
        {
          techName: "Touch ID",
          techLogo: "touchid.png",
        },
        {
          techName: "Apple Tv",
          techLogo: "appletv.png",
        },
        {
          techName: "Objective-C",
          techLogo: "objective-c.png",
        },
        {
          techName: "iBeacon",
          techLogo: "ibeacon-device.png",
        },
        {
          techName: "Apple Watch",
          techLogo: "apple-watch.png",
        },
        {
          techName: "In App Purchases",
          techLogo: "in_app_purchases.png",
        },
        {
          techName: "Cocoa Touch",
          techLogo: "cocoa-touch.png",
        },
        {
          techName: "Core Data",
          techLogo: "core_data.png",
        },
        {
          techName: "AFNetworking",
          techLogo: "anfetworking.png",
        },
        {
          techName: "3D Touch",
          techLogo: "apple-3d-touch.png",
        },
        {
          techName: "SiriKit",
          techLogo: "sirikit.png",
        },
        {
          techName: "HealthKit",
          techLogo: "apple-healthkit.png",
        },
        {
          techName: "CallKit",
          techLogo: "apple-callkit.png",
        },
        {
          techName: "iMessage Apps",
          techLogo: "iMessage.png",
        },
        {
          techName: "CocoaPods",
          techLogo: "cocoapods.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "ios app development services",
      buttonLink: "ios",
    },
    {
      name: "Android Apps",
      logo: `${companyName}-MAD-A`,
      techUsedPara:
        "Meeting the most challenging requirements with Android and exceeding app users’ expectations, our Android app developers build premier quality native apps in Java, supported by most powerful libraries including Android Gear, Butter Knife, Gson, Ormlite. Every Android solution is efficiently engineered and secure guaranteeing high-performance.",
      techUsed: [
        {
          techName: "Java",
          techLogo: "java.png",
        },
        {
          techName: "Android Nougat",
          techLogo: "android_nougat.png",
        },
        {
          techName: "Android Wear",
          techLogo: "android_gear.png",
        },
        {
          techName: "AndroidView Animations",
          techLogo: "androidview_animations.png",
        },
        {
          techName: "Android Tv",
          techLogo: "android-tv.png",
        },
        {
          techName: "Android Launcher Apps",
          techLogo: "android-launcher.png",
        },
        {
          techName: "BLE Device Integration",
          techLogo: "bl_deviceintegration.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "android app development services",
      buttonLink: "android",
    },
    {
      name: "Wearables",
      logo: `${companyName}-MAD-W`,
      techUsedPara:
        "At Kdr, we understand the needs of Millennials who are fitness freaks and workout enthusiasts and love the cutting-edge wrist gears, and we provide the slickest solutions to them. We also work for enterprise grade applications that integrate with a range of sensors and devices to empower mobility through wearable technology.",
      techUsed: [
        {
          techName: "Android Wear",
          techLogo: "android_gear.png",
        },
        {
          techName: "Fitness Tracker",
          techLogo: "fitness_tracker.png",
        },
        {
          techName: "Apple Watch",
          techLogo: "apple-watch.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "Wearable app development services",
      buttonLink: "wearable-app-development",
    },
    {
      name: "Hybrid Apps",
      logo: `${companyName}-MAD-HA`,
      techUsedPara:
        "The rise of smartphones, tablets, wearables and many more devices has brought the need to work across multiple devices. Our deep native and web experience with enterprise and consumer apps matched with our ability to create cross-platform mobile apps to deliver your business app to most people, more platforms in minimum time and lowest costs.",
      techUsed: [
        {
          techName: "PhoneGap",
          techLogo: "phonegap.png",
        },
        {
          techName: "HTML5",
          techLogo: "html.png",
        },
        {
          techName: "Angular JS",
          techLogo: "angularjs.png",
        },
        {
          techName: "Ionic",
          techLogo: "ionic.png",
        },
        {
          techName: "Sencha Touch",
          techLogo: "sencha_touch.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "Hybrid app development services",
      buttonLink: "cross-platform-app-development",
    },
    {
      name: "Xamarin Apps",
      logo: `${companyName}-MAD-X`,
      techUsedPara:
        "In an effort to create most powerful mobile app solutions for delivering amazing mobile apps, our team gained competence in Xamarin app development. Now, we can build native Android and iOS apps for multiple platforms using a shared C# codebase, same IDE, language and APIs everywhere using Xamarin.",
      techUsed: [
        {
          techName: "Xamarin for Visual Studio",
          techLogo: "xamarin_for_visual_studio.png",
        },
        {
          techName: "Xamarin Test Cloud",
          techLogo: "xamarin_test_cloud.png",
        },
        {
          techName: "Xamarin.Forms",
          techLogo: "xamarin_forms.png",
        },
        {
          techName: "Open Source",
          techLogo: "open_source.png",
        },
      ],
      showButton: true,
      buttonPrefix: "Know more about our",
      buttonText: "Xamarin app development services",
      buttonLink: "android",
    },
  ],
  showButton: false,
};

export const servicesOffered = {
  text1: "Services That We Offer",
  text2:
    "Fully editable Pages, Fast loading, Google Analytics, Integrated IDX, Interactive Map Research, Easy-to-save multiple search, Agent lead routing, Lead capture forms.",
  data: [
    [
      {
        linkLabel: "Consulting Services",
        link: "consulting-services",
      },
      {
        linkLabel: "UI/UX Design",
        link: "/ui-ux-designing",
      },
      {
        linkLabel: "Web Application Development",
        link: "web-development",
      },
    ],
    [
      {
        linkLabel: "Mobile Application Development",
        link: "cmobile-application-development",
      },
      {
        linkLabel: "Enterprise Software Development",
        link: "enterprise",
      },
      {
        linkLabel: "Product Strategy for Startups",
        link: "/start-up",
      },
    ],
    [
      {
        linkLabel: "Quality Assurance",
        link: "quality-assurance",
      },
      {
        linkLabel: "Staff Augmentation & Dedicated Developers",
        link: "staff-augmentation-dedicated-teams",
      },
      {
        linkLabel: "Maintenance & Support",
        link: "software-maintenance-support",
      },
    ],
  ],
  showButton: true,
  buttonLeftText1:
    "Find out about how our services can be integrated into your business.",
  buttonLeftText2: "THE FIRST STEP IS EASY!",
  buttonLink: "/request-quote",
  buttonText: "Request a Quote",
};

export const typeSixBannerData = {
  text1: "Building compelling mobile apps for iPhone and Android",
  text2:
    "Our mobile-first approach rank us among the top mobile app development companies in India, as we deliver complete mobility solutions that meet the needs of business, industry and end-users",
  left: {
    heading:
      "WE BUILD MOBILE APPS FOR EVERY USER, EVERY PLATFORM AND EVERY INDUSTRY",
    para1:
      "Kdr promises to deliver cutting-edge mobile app development services in every industry, since 2016.Our mobile app developers have grown tremendously to deliver the full range of mobile services.We hold notable presence as a top mobile app development company worldwide for creating most modern and interactive interfaces.",
    para2:
      "No technology is left untouched as we believe in adopting trends that change with time and user demands. Be it Apple TV, Android wear, AR/VR apps, Internet of Things, wearable, beacon, we’ve worked with infinite possibilities in mobile application development.",
    para3:
      "While we focus on your development needs, we’ll help you develop a mobile strategy that will take your business idea from conceptualization to a well-loved app on Apple’s App Store and Google Play. Among all the other mobile application development companies, what sets us apart is the end-to-end solution to create a unique mobile experience across multiple platforms, devices and OS versions, with continued support and maintenance to respond to your short-term and long-term needs.",
  },
  right: {
    points: [
      {
        iconName: `${companyName}-MAD-APS`,
        label: "App Prototyping & Strategy",
        link: "/app-prototype-and-strategy",
      },
      {
        iconName: `${companyName}-MAD-CMS`,
        label: "Cloud Computing",
        link: "/cloud-computing",
      },
      {
        iconName: `${companyName}-MAD-IWEAD`,
        label: "Integration with existing enterprise services & data",
        link: "/cloud-computing",
      },
      {
        iconName: `${companyName}-MAD-CMAD`,
        label: "Custom Mobile Application Development",
        link: "/cloud-computing",
      },
      {
        iconName: `${companyName}-MAD-CPAD`,
        label: "Cross-platform App Development",
        link: "/cross-platform-app-development",
      },
      {
        iconName: `${companyName}-MAD-MC`,
        label: "m-Commerce, B2B & B2C",
      },
      {
        iconName: `${companyName}-MAD-EAD`,
        label: "Enterprise Application Development",
        link: "/enterprise",
      },
      {
        iconName: `${companyName}-MAD-AMS`,
        label: "Application Maintenance Services",
      },
    ],
    buttonText: "let's discuss your project",
    buttonLink: "#workWithUs",
  },
};

export const userCentric = {
  text1: "User-centric mobile app development solutions",
  para: "SEAMLESS EXPERIENCES ACROSS DEVICES",
  process: [
    {
      iconName: `${companyName}-MAD-RA`,
      heading: "Rigorous Requirement Analysis",
    },
    {
      iconName: "dollar",
      heading: "Competitive Rates & Project Quotes",
    },
    {
      iconName: `${companyName}-MAD-EMA`,
      heading: "Expert Mobile App Developers",
    },
    {
      iconName: `${companyName}-MAD-PDM`,
      heading: "Proven Delivery Methods",
    },
    {
      iconName: "thumbs-up",
      heading: "90% Client Satisfaction",
    },
  ],
};
