import { companyName } from "../config";

export const startUpImageSection = {
  imageName: "startUp.jpg",
  imageText2: `We build world-class solutions for the Startup age`,
  imageText3:
    "BRIDGING THE GAP BETWEEN STARTUP AND ENTERPRISE - WE DO IT THE BEST",
  buttonText: "GET IN TOUCH",
};

export const text1 =
  "Startup app development is our obsession, and we are obsessively focused on startup’s mission";
export const text2 =
  "Having already worked with over 200+ startup projects, we are fueled to develop brilliant and powerful web and mobile apps across industries that will raise untold fortunes of millions of entrepreneurs who are struggling to launch lucrative businesses to meet a marketplace need.";

export const data = {
  heading: "We're a top-ranked startup app development company",
  description:
    "A WIDE RANGE OF ROBUST AND SCALABLE SOLUTIONS TO FOSTER YOUR IDEa",
  details: [
    {
      iconName: `${companyName}-Startup-ET`,
      label: "Experienced team",
      content:
        "Experts in Android, iOS, E and M-commerce, Magento, PhoneGap, Xamarin, HTML5 & others.",
    },
    {
      label: "Scalable development",
      iconName: `${companyName}-Startup-SD`,
      content:
        "A focused and phased approach to build-to-market, to scale and to grow.",
    },
    {
      label: "Custom-built solutions",
      iconName: `${companyName}-Startup-CBS`,
      content:
        "For every unique idea, we offer adaptability and flexibility to suit your business needs",
    },
    {
      label: "Cost-effective services",
      iconName: `${companyName}-Startup-CES`,
      content:
        "Be it a low-funded startup or a billion-dollar venture, we guarantee cost-effectiveness.",
    },
    {
      label: "Technology leadership",
      iconName: `${companyName}-Startup-TL`,
      content:
        "IoT, AR/VR, Cloud, Cross-platform, Wearable, Beacon- we create everything & beyond.",
    },
    {
      label: "Value proposition",
      iconName: `${companyName}-Startup-VP`,
      content:
        "The end-product is assured to add value to your business and deliver it to users.",
    },
    {
      label: "Innovation & novelty",
      iconName: `${companyName}-Startup-IN`,
      content:
        "Innovation is our core value and we love to go beyond the limitations to deliver out-of-the-box.",
    },
    {
      label: "Full support ecosystem",
      iconName: `${companyName}-Startup-FSE`,
      content:
        "Your queries and doubts are resolved and taken care of even after launch",
    },
  ],
};
export const successfulData = {
  heading: "Building successful enterprises from your startup idea",
  description: "THE FOUR STAGES FROM STARTUPS TO FULL-SIZED ENTERPRISES",
  details: [
    {
      label: "Streamlining the Idea",
      iconName: `${companyName}-Startup-STI`,
      content:
        "With proven industry experience and extensive market research across industries, we validate your idea and present a viable solution",
    },
    {
      label: "Discover your mission",
      iconName: `${companyName}-Startup-DM`,
      content:
        "As a startup serving agency, we dig deep inside your brain and recognize your vision, mission and expectations from the business solution",
    },
    {
      label: "Perfect product execution",
      iconName: `${companyName}-Startup-PPE`,
      content:
        "Realizing all the needs, we work towards developing secure, cleanly coded, mobile friendly, scalable and fully documented product (be it web or mobile)",
    },
    {
      label: "Long-term growth",
      iconName: `${companyName}-Startup-LTG`,
      content:
        "We work continuously towards upgrading and enhancing your online product, adding latest features to keep it fresh and ever-evolving",
    },
  ],
  buttonDetails: {
    buttonText: "Let's Discuss",
    buttonLink: "/request-quote",
  },
};

export const growth = {
  heading: "Optimizing startup partnership for growth",
  description: "COLLABORATIVE. TRANSPARENT. STRATEGIC",
  para:
    "Our startup app development services cover all bases. Once we work together, we develop that warm feeling of seeing startups grow because their success reflects our growth- and we love to excel like a team. A significant percentage of our resources is dedicated towards incubation projects.",
  buttonDetails: {
    buttonText: "Talk to our startup experts",
  },
};
