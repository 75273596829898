export const apps = [
  {
    appId: 1,
    logo: "nalgonda.png",
    shortName: "kdr",
    background: "#73b56e",
    name: "NALGONDA DISTRICT  TELANGANA GOVT",
    category: "Mobile Application Development",
    screenshot: "nalgov.png",
    shortDescription:
      "Paddy Procurement Portal for Revenue Department Nalgonda District, Telangana",
    tags: {
      android: {
        name: "android",
      },
    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image1URL: "kdr/n4",
    image2URL: "kdr/n2",
    image3URL: "kdr/n1",
    image4URL: "kdr/n3",
    image5URL: "kdr/n5",
    description:
      "Nalgonda district has topped the State in paddy procurement this Yasangi, purchasing 5.75 lakh metric tons out of the 6.24 lakh metric tons of estimated crop production by the farmers in the district, which translates into 95 per cent of procurement of the crop. We have developed solutions to help businesses stay ahead of customer satisfaction trends and propose that Nalgonda District an user friendly scalable solution to overcome the difficulties faced . Our solution easily integrates with a wide range of off-the-shelf CRM solutions and can enable to fully realize the benefits of improved productivity throughout the entire procurement cycle.",
  },
  {
    appId: 2,
    logo: "mananagaram.png",
    shortName: "kdr",
    background: "#094c80",
    name: "MANA NAGARAM  GHMC HYDERABAD",
    category: "Mobile Application Development",
    screenshot: "mana.png",
    shortDescription:
      "Town Hall meetings across various circles -Mana Nagaram by Municipal Administration & Urban Development Department ",
    tags: {
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },
    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],

    image1URL: "kdr/m3",
    image2URL: "kdr/m5",
    image3URL: "kdr/m1",
    image4URL: "kdr/m6",
    image5URL: "kdr/m7",
    description:
      "Town Hall meetings across various circles under GHMC limits as part of the new initiative called ‘Mana Nagaram’ by Municipal Administration and Urban Development Minister KT Rama Rao",
  },
  {
    appId: 3,
    shortName: "kdr",
    background: "#2b378a",
    logo: "startcareer.png",
    name: "SRKSC - START CAREER YOUR CAREER STARTS HERE ",
    category: "Mobile Application Development",
    screenshot: "sc.png",
    shortDescription:
      "Learn Magical Shortcut Tricks to crack the most competitive entrance exams – NEET IIT JEE.",
    tags: {
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },
    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image1URL: "kdr/s4",
    image2URL: "kdr/s2",
    image3URL: "kdr/s1",
    image4URL: "kdr/s3",
    image5URL: "kdr/s5",
    description:
      "Start Career in collaboration with MGTL provides Online Live coaching classes for NEET IIT JEE through expert IIT Level Trainers. With our methodologies , you can ease your preparation, and solve most challenging problems in seconds with tricky methods.  The following features will be part of our coaching.",
  },
  {
    appId: 4,
    shortName: "kdr",
    background: "#f74046",
    logo: "ecomlogo.png",
    name: "E Ecomm",
    category: "Ecommerce Application Development",
    screenshot: "ecomm.png",
    shortDescription:
      "This CMS Application is unique, totally business-oriented, customer-friendly, seller-friendly, and easy to use. Admin has full control over the seller activities and customer activities.",
    tags: {
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },
      Website: {
        name: "web",
      },
    },
    workedOn: [
      "Admin",
      "Seller Dashboard",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image3URL: "kdr/ecom",
    description:
      "This CMS has a nice and attractive front end and back end interface that are really awesome and eyecatching. The back end has a lot of nice and maintainable features that are needed for a modern and professional ecommerce website to handle the contents easily by client. Almost all kinds of changes are possible to do using the admin panel without having any kind of programming language knowledge.",
  },
  {
    appId: 5,
    shortName: "kdr",
    background: "#282828",
    logo: "wapp.png",
    name: "WordsApp : GRE Vocabulary Builder",
    category: "Mobile Application Development",
    screenshot: "wordsapp.png",
    shortDescription:
      "A tested technique which will help user understand variation in meanings of words. (Say miser and frugal).",
    tags: {
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },
    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image1URL: "kdr/w4",
    image2URL: "kdr/w2",
    image3URL: "kdr/w1",
    image4URL: "kdr/w3",
    image5URL: "kdr/w5",
    description:
      "Having trouble improving your vocabulary? Not able to remember words for your GRE, GMAT or CAT exam? Here we have a well organised database of 1500 high frequency GRE/GMAT words which are grouped in 117 sets, to keep all the alike words together. With meanings, use in sentences, root, pronunciation, synonyms, antonyms, word remembering techniques and for future will integrate an image which will help user understand and remember word.",
  },
  {
    appId: 6,
    shortName: "kdr",
    background: "#24367f",
    logo: "VanamaliLogo.png",
    name: "Sri Vanamali Medicals & Surgicals",
    category: "Web Application Development",
    screenshot: "vanamali.png",
    shortDescription:
      " Inventory Management System for Pharmacy Distribution",
    tags: {
    
      website: {
        name: "web",
      },
    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image3URL: "kdr/v1",
    description:
      "While developing this system we have catered all the details according to the business needs and requirements; such as dead stock management, expired products management, credit and paid customer management, paid/unpaid invoices and expense invoices as well as reports, sales reports profit/loss reports and expense reports, dashboard with interactive sales graphs. ",
  },
  {
    appId: 7,
    shortName: "kdr",
    background: "#fc6a57",
    logo: "snfoods.png",
    name: "SN Foods",
    category: "Food Delivery App with Admin Panel",
    screenshot: "snfood.png",
    shortDescription:
      "Food Delivery Management System With Both The User & Delivery Boy Application",
    tags: {
    
      website: {
        name: "web",
      },
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },

    },
    workedOn: [
      "Admin App",
      "User App",
      "Delivery App",
      "Customer Management",
      "Strategy",
      "UX/UI",
    ],
    image1URL: "kdr/f4",
    image2URL: "kdr/f2",
    image3URL: "kdr/f1",
    image4URL: "kdr/f8",
    image5URL: "kdr/f10",
    description:
      "Food delivery management system that comes with both the user app and delivery boy app. With this system, you will get a powerful admin panel which will help you manage the whole system smartly that accelerates your restaurant business.",
  },

  {
    appId: 8,
    shortName: "kdr",
    background: "#343a40",
    logo: "divinelogo.png",
    name: "Divine Propertys",
    category: "Real Estate & Property Management",
    screenshot: "divineprop.png",
    shortDescription:
      "Website for Real Estate & Property Management",
    tags: {
    
      website: {
        name: "web",
      },
     

    },
    workedOn: [
      "Backend",
      "Database",
      "Frontend",
      "Software Testing",
      "Strategy",
      "UX/UI",
    ],
    image3URL: "kdr/d1",
    description:
      "At Divine Properties, you are number one. Whether you are a property owner, tenant, or buyer, we value your business and will provide you with the individual attention and service you deserve. We believe in a strict Code of Ethics. We believe in integrity, commitment to excellence, a professional attitude, and personalized care.",
  },
  
  
  {
    appId: 9,
    shortName: "kdr",
    background: "#f36e1f",
    logo: "fb.png",
    name: "Feedbellie",
    category: "Food Delivery App with Admin Panel",
    screenshot: "feedbellie.png",
    shortDescription:
      "Multi-restaurant Food Ordering, Restaurant Management and Delivery Application",
    tags: {
    
      website: {
        name: "web",
      },
      iphone: {
        name: "ios",
      },
      android: {
        name: "android",
      },

    },
    workedOn: [
      "Website",
      "Admin App",
      "User App",
      "Delivery App",
      "Strategy",
      "UX/UI",
    ],
    image1URL: "kdr/fb3",
    image2URL: "kdr/fb2",
    image3URL: "kdr/fb1",
    image4URL: "kdr/fb4",
    image5URL: "kdr/fb5",
    description:
      "Feedbellie Food delivery management system that comes with both the user app and delivery boy app. With this system, you will get a powerful admin panel which will help you manage the whole system smartly that accelerates your restaurant business.",
  },

  // {
  //   appId: 10,
  //   shortName: "kdr",
  //   background: "#f36e1f",
  //   logo: "fb.png",
  //   name: "Unic Laboratories",
  //   category: "Food Delivery App with Admin Panel",
  //   screenshot: "feedbellie.png",
  //   shortDescription:
  //     "Multi-restaurant Food Ordering, Restaurant Management and Delivery Application",
  //   tags: {
    
  //     website: {
  //       name: "web",
  //     },
      
  //   },
  //   workedOn: [
  //     "Website",
  //     "Admin App",
  //     "User App",
  //     "Delivery App",
  //     "Strategy",
  //     "UX/UI",
  //   ],
  //   image1URL: "kdr/fb3",
  //   image2URL: "kdr/fb2",
  //   image3URL: "kdr/fb1",
  //   image4URL: "kdr/fb4",
  //   image5URL: "kdr/fb5",
  //   description:
  //     "Feedbellie Food delivery management system that comes with both the user app and delivery boy app. With this system, you will get a powerful admin panel which will help you manage the whole system smartly that accelerates your restaurant business.",
  // },
];
