import React from "react";
import { heroSectionTwoData } from "./heroSectionTwoData";

const HeroSectionTwo = () => {
  return (
    <div className="heroSectionTwoContainer">
      <video
        className="homePageVideo"
        autoPlay
        muted
        loop
        style={{
          zIndex: -2,
        }}
      >
        <source
          src={require("../../assets/video/wave.webm")}
          type="video/webm"
        />
        <source src={require("../../assets/video/wave.mp4")} type="video/mp4" />
      </video>
      <div
        className="absolute top-0 right-0 bottom-0 left-0 w-full h-full md:bg-gray-900 md:bg-opacity-30"
        style={{
          zIndex: -1,
        }}
      ></div>
      <div className="z-0 max-w-6xl m-auto h-full flex flex-col justify-center items-start font-extrabold">
        {heroSectionTwoData.heading1 && (
          <h2 className="text-lg md:text-2xl">{heroSectionTwoData.heading1}</h2>
        )}
        {heroSectionTwoData.heading2 && (
          <h1 className="text-4xl md:text-6xl mt-6 leading-tight">
            {heroSectionTwoData.heading2}
          </h1>
        )}
        {heroSectionTwoData.heading3 && (
          <h2 className="text-xl md:text-2xl mt-8">
            {heroSectionTwoData.heading3}
          </h2>
        )}
        {
          <a
            className="py-4 px-6 border-2 block mt-12 rounded-lg hover:bg-white hover:text-gray-800"
            href={heroSectionTwoData.anchorLink}
          >
            {heroSectionTwoData.anchorText}
          </a>
        }
      </div>
    </div>
  );
};

export default HeroSectionTwo;
