export const images = [
    'Telangana.PNG',
    'airtel.png',
    'goa.png',  
    'hp.png',
    'jbl.png',
    'dell.png',
    'ap.png',
    'crown.png',
    'mananagaram.png',
    'sunrisers.png',
    'paw.png',
    'hsf.png',
    'srpt.png',
    'talasha.png',
    'ghmc.png',
    'santos.png',
    'nalgonda.png',
    'klubf5.png',
    'jds.png',
    'bgm.png',
    'janahitha.png',
    'ffas.png',
    'nowl.png',
    'dorvenfoods.png',
    'vh.png',
    'tsforest.png',
    'startcareer.png',
    'vertisoft.png',
    'wa.png',
    'vanamali.png',
    'nbb.png',
    'dm.png',
    'shikara.png',
    'jd.png',
    'tfs.png',
    'Conneightion.png',
    'vhcabs.png',
    'veehaari.png',
    'dytronix.png',
    'svmovie.png',
    'teeksha.png',
    'vikram.png',
    'TRI.png',
    'fc.png',
    'dorvenretail.png',
    'kdrf.png',
    'kamlesh.png',
    'leafent.png',
    'feedbellie.png',
    "da.png",
    'jdesk.png',
    'kdrav.png',
    'rsd.png',
    'turtle.png',
    'manymore.png',



]
